import { Injectable } from '@angular/core';
import { NotificationTypes } from '@app/shared/enums';
import { INotification } from '@core/models';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private notifications: INotification[] = [];

  constructor() {}

  public getOne(id: number): Observable<INotification> {
    return of(null);
  }

  public getAll(): Observable<INotification[]> {
    return of(this.notifications);
  }

  public throwError(msg: string): void {
    this.add(NotificationTypes.DANGER, msg);
  }

  public add(
    type: NotificationTypes,
    text: string,
  ): Observable<INotification[]> {
    const count = this.notifications?.length;

    // add notification only if it does not already exist
    if (
      !this.notifications?.some(
        (notification) =>
          notification.type === type && notification.text === text,
      )
    ) {
      const notification = { id: count + 1, type, text };
      this.notifications = [...this.notifications, notification];
    }

    return of(this.notifications);
  }

  public remove(id: number): Observable<INotification[]> {
    this.notifications = this.notifications.filter(
      (notification) => notification.id !== id,
    );

    return of(this.notifications);
  }

  public reset(): Observable<INotification[]> {
    this.notifications = [];

    return of(this.notifications);
  }
}
