import { PresignedFields } from '@core/models';

export const constructFormDataPayload = (
  fields: PresignedFields,
  file: File,
): FormData => {
  const payload = new FormData();
  Object.keys(fields).forEach((key) => payload.append(key, fields[key]));
  payload.append('file', file);

  return payload;
};
