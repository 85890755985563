import { State as RootState } from '@app/store/app-state';
import { ApiError, FparDataSummary } from '@core/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { fparReportFeatureKey } from './reducer';
import { State } from './state';

const fparReportData = (state: State): FparDataSummary => state.fparReportData;
const getFparReportError = (state: State): ApiError => state.getFparReportError;
const getFparReportLoading = (state: State): boolean =>
  state.getFparReportLoading;

export const selectReportState = createFeatureSelector<RootState, State>(
  fparReportFeatureKey,
);

export const selectFparReportData = createSelector(
  selectReportState,
  fparReportData,
);
export const selectGetFparReportError = createSelector(
  selectReportState,
  getFparReportError,
);
export const selectGetFparReportLoading = createSelector(
  selectReportState,
  getFparReportLoading,
);
