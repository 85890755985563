<div
  [ngClass]="{
    active: dateSubmitted && reviewStatus,
    'review-status-box': true
  }"
>
  <h2>REVIEW STATUS</h2>
  <div class="review-status">
    <div class="review-status-type" *ngIf="submissionDue">
      <div>
        <p>Submission Due:</p>
        <p class="thick">{{ submissionDue }}</p>
      </div>
    </div>
    <div class="review-status-type" *ngIf="dateSubmitted && reviewStatus">
      <div>
        <p>Date Submitted:</p>
        <p class="thick">{{ dateSubmitted + '+0000' | date: 'MM/dd/yy' }}</p>
      </div>
      <div class="review-status-row">
        <p>Review Status:</p>
        <p class="thick">{{ reviewStatus }}</p>
        <mat-icon
          *ngIf="systemForced"
          [mdePopoverTriggerFor]="systemSubmissionStatus"
          mdePopoverTriggerOn="hover"
          mdePopoverPositionX="after"
          mdePopoverPositionY="above"
          #popoverTrigger="mdePopoverTrigger"
          aria-label="info icon"
          >info</mat-icon
        >
      </div>
    </div>
    <div class="review-status-type" *ngIf="lastReviewed">
      <div>
        <p>Last reviewed:</p>
        <p class="thick">{{ lastReviewed }}</p>
      </div>
    </div>
  </div>
</div>

<mde-popover
  #systemSubmissionStatus="mdePopover"
  [mdePopoverOverlapTrigger]="false"
  ><mat-card class="popover-content">
    <mat-card-content>
      This submission status was automatically updated by the system submission
      process.
    </mat-card-content>
  </mat-card></mde-popover
>
