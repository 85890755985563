import * as i0 from '@angular/core';
import { EventEmitter, TemplateRef, Component, ChangeDetectionStrategy, ViewEncapsulation, HostBinding, Input, Output, ViewChild, Injectable, Directive, Optional, HostListener, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i1$1 from '@angular/cdk/overlay';
import { ConnectionPositionPair, OverlayConfig, OverlayModule } from '@angular/cdk/overlay';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ESCAPE } from '@angular/cdk/keycodes';
import { trigger, state, style, transition, animate } from '@angular/animations';
import * as i2 from '@angular/cdk/a11y';
import { isFakeMousedownFromScreenReader, A11yModule } from '@angular/cdk/a11y';
import { TemplatePortal } from '@angular/cdk/portal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as i2$1 from '@angular/cdk/bidi';
const _c0 = ["*"];
function MdePopover_ng_template_0_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 3);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngStyle", ctx_r1.popoverArrowStyles);
  }
}
function MdePopover_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 0);
    i0.ɵɵlistener("keydown", function MdePopover_ng_template_0_Template_div_keydown_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1._handleKeydown($event));
    })("click", function MdePopover_ng_template_0_Template_div_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onClick());
    })("mouseover", function MdePopover_ng_template_0_Template_div_mouseover_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onMouseOver());
    })("mouseleave", function MdePopover_ng_template_0_Template_div_mouseleave_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.onMouseLeave());
    });
    i0.ɵɵtemplate(1, MdePopover_ng_template_0_div_1_Template, 1, 1, "div", 1);
    i0.ɵɵelementStart(2, "div", 2);
    i0.ɵɵprojection(3);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("mde-popover-overlap", ctx_r1.overlapTrigger);
    i0.ɵɵproperty("ngClass", ctx_r1._classList)("ngStyle", ctx_r1.popoverPanelStyles)("@.disabled", ctx_r1.disableAnimation)("@transformPopover", "enter");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", !ctx_r1.overlapTrigger);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngStyle", ctx_r1.popoverContentStyles)("cdkTrapFocus", ctx_r1.focusTrapEnabled)("cdkTrapFocusAutoCapture", ctx_r1.focusTrapAutoCaptureEnabled);
  }
}
const VALID_PositionX = ['before', 'start', 'center', 'end', 'after'];
const VALID_PositionY = ['above', 'start', 'center', 'end', 'below'];

/**
 * Throws an exception for the case when popover trigger doesn't have a valid mde-popover instance
 */
function throwMdePopoverMissingError() {
  throw Error(`mde-popover-trigger: must pass in an mde-popover instance.

    Example:
      <mde-popover #popover="mdePopover"></mde-popover>
      <button [mdePopoverTriggerFor]="popover"></button>`);
}
/**
 * Throws an exception for the case when popover's mdePopoverPositionX value isn't valid.
 * In other words, it doesn't match 'before', 'after' 'start', 'end' or 'center'.
 */
function throwMdePopoverInvalidPositionX() {
  throw Error(`mdePopoverPositionX value must be 'before', 'after', 'start', 'end' or 'center'.
      Example: <mde-popover mdePopoverPositionX="before" #popover="mdePopover"></mde-popover>`);
}
/**
 * Throws an exception for the case when popover's mdePopoverPositionY value isn't valid.
 * In other words, it doesn't match 'above', 'below', 'start', 'end' or 'center'.
 */
function throwMdePopoverInvalidPositionY() {
  throw Error(`mdePopoverPositionY value must be 'above', 'below', 'start', 'end' or 'center'.
      Example: <mde-popover mdePopoverPositionY="above" #popover="mdePopover"></mde-popover>`);
}

/**
 * Below are all the animations for the md-popover component.
 * Animation duration and timing values are based on AngularJS Material.
 */
/**
 * This animation controls the popover panel's entry and exit from the page.
 *
 * When the popover panel is added to the DOM, it scales in and fades in its border.
 *
 * When the popover panel is removed from the DOM, it simply fades out after a brief
 * delay to display the ripple.
 */
const transformPopover = trigger('transformPopover', [state('enter', style({
  opacity: 1,
  transform: `scale(1)`
})), transition('void => *', [style({
  opacity: 0,
  transform: `scale(0)`
}), animate(`200ms cubic-bezier(0.25, 0.8, 0.25, 1)`)]), transition('* => void', [animate('50ms 100ms linear', style({
  opacity: 0
}))])]);
class MdePopover {
  /** Position of the popover in the X axis. */
  get positionX() {
    return this._positionX;
  }
  set positionX(value) {
    if (!VALID_PositionX.includes(value)) {
      throwMdePopoverInvalidPositionX();
    }
    this._positionX = value;
    this.setPositionClasses();
  }
  /** Position of the popover in the Y axis. */
  get positionY() {
    return this._positionY;
  }
  set positionY(value) {
    if (!VALID_PositionY.includes(value)) {
      throwMdePopoverInvalidPositionY();
    }
    this._positionY = value;
    this.setPositionClasses();
  }
  /** Popover trigger event */
  get triggerEvent() {
    return this._triggerEvent;
  }
  set triggerEvent(value) {
    this._triggerEvent = value;
  }
  /** Popover scroll strategy */
  get scrollStrategy() {
    return this._scrollStrategy;
  }
  set scrollStrategy(value) {
    this._scrollStrategy = value;
  }
  /** Popover enter delay */
  get enterDelay() {
    return this._enterDelay;
  }
  set enterDelay(value) {
    this._enterDelay = value;
  }
  /** Popover leave delay */
  get leaveDelay() {
    return this._leaveDelay;
  }
  set leaveDelay(value) {
    this._leaveDelay = value;
  }
  /** Popover overlap trigger */
  get overlapTrigger() {
    return this._overlapTrigger;
  }
  set overlapTrigger(value) {
    this._overlapTrigger = value;
  }
  /** Popover target offset x */
  get targetOffsetX() {
    return this._targetOffsetX;
  }
  set targetOffsetX(value) {
    this._targetOffsetX = value;
  }
  /** Popover target offset y */
  get targetOffsetY() {
    return this._targetOffsetY;
  }
  set targetOffsetY(value) {
    this._targetOffsetY = value;
  }
  /** Popover arrow offset x */
  get arrowOffsetX() {
    return this._arrowOffsetX;
  }
  set arrowOffsetX(value) {
    this._arrowOffsetX = value;
  }
  /** Popover arrow offset y */
  get arrowOffsetY() {
    return this._arrowOffsetY;
  }
  set arrowOffsetY(value) {
    this._arrowOffsetY = value;
  }
  /** Popover arrow width */
  get arrowWidth() {
    return this._arrowWidth;
  }
  set arrowWidth(value) {
    this._arrowWidth = value;
  }
  /** Popover arrow color */
  get arrowColor() {
    return this._arrowColor;
  }
  set arrowColor(value) {
    this._arrowColor = value;
  }
  /**
   * Popover container close on click
   * default: true
   */
  get closeOnClick() {
    return this._closeOnClick;
  }
  set closeOnClick(value) {
    this._closeOnClick = coerceBooleanProperty(value);
  }
  /**
   * Disable animations of popover and all child elements
   * default: false
   */
  get disableAnimation() {
    return this._disableAnimation;
  }
  set disableAnimation(value) {
    this._disableAnimation = coerceBooleanProperty(value);
  }
  /**
   * Popover focus trap using cdkTrapFocus
   * default: true
   */
  get focusTrapEnabled() {
    return this._focusTrapEnabled;
  }
  set focusTrapEnabled(value) {
    this._focusTrapEnabled = coerceBooleanProperty(value);
  }
  /**
   * Popover focus trap auto capture using cdkTrapFocusAutoCapture
   * default: true
   */
  get focusTrapAutoCaptureEnabled() {
    return this._focusTrapAutoCaptureEnabled;
  }
  set focusTrapAutoCaptureEnabled(value) {
    this._focusTrapAutoCaptureEnabled = coerceBooleanProperty(value);
  }
  /**
   * This method takes classes set on the host md-popover element and applies them on the
   * popover template that displays in the overlay container.  Otherwise, it's difficult
   * to style the containing popover from outside the component.
   * @param classes list of class names
   */
  set panelClass(classes) {
    if (classes && classes.length) {
      this._classList = classes.split(' ').reduce((obj, className) => {
        obj[className] = true;
        return obj;
      }, {});
      this._elementRef.nativeElement.className = '';
      this.setPositionClasses();
    }
  }
  /**
   * This method takes classes set on the host md-popover element and applies them on the
   * popover template that displays in the overlay container.  Otherwise, it's difficult
   * to style the containing popover from outside the component.
   * @deprecated Use `panelClass` instead.
   */
  get classList() {
    return this.panelClass;
  }
  set classList(classes) {
    this.panelClass = classes;
  }
  constructor(_elementRef, zone) {
    this._elementRef = _elementRef;
    this.zone = zone;
    this.role = 'dialog';
    /** Settings for popover, view setters and getters for more detail */
    this._positionX = 'after';
    this._positionY = 'below';
    this._triggerEvent = 'hover';
    this._scrollStrategy = 'reposition';
    this._enterDelay = 200;
    this._leaveDelay = 200;
    this._overlapTrigger = true;
    this._disableAnimation = false;
    this._targetOffsetX = 0;
    this._targetOffsetY = 0;
    this._arrowOffsetX = 0;
    this._arrowOffsetY = 0;
    this._arrowWidth = 8;
    this._arrowColor = 'rgba(0, 0, 0, 0.12)';
    this._closeOnClick = true;
    this._focusTrapEnabled = true;
    this._focusTrapAutoCaptureEnabled = true;
    /** Config object to be passed into the popover's ngClass */
    this._classList = {};
    // TODO: Write comment description
    /** */
    this.containerPositioning = false;
    /** Closing disabled on popover */
    this.closeDisabled = false;
    /** Emits the current animation state whenever it changes. */
    this._onAnimationStateChange = new EventEmitter();
    /** Event emitted when the popover is closed. */
    this.close = new EventEmitter();
    this.setPositionClasses();
  }
  ngOnDestroy() {
    this._emitCloseEvent();
    this.close.complete();
  }
  /** Handle a keyboard event from the popover, delegating to the appropriate action. */
  _handleKeydown(event) {
    switch (event.keyCode) {
      case ESCAPE:
        this._emitCloseEvent();
        return;
    }
  }
  /**
   * This emits a close event to which the trigger is subscribed. When emitted, the
   * trigger will close the popover.
   */
  _emitCloseEvent() {
    this.close.emit();
  }
  /** Close popover on click if closeOnClick is true */
  onClick() {
    if (this.closeOnClick) {
      this._emitCloseEvent();
    }
  }
  /**
   * TODO: Refactor when @angular/cdk includes feature I mentioned on github see link below.
   * https://github.com/angular/material2/pull/5493#issuecomment-313085323
   */
  /** Disables close of popover when leaving trigger element and mouse over the popover */
  onMouseOver() {
    if (this.triggerEvent === 'hover') {
      this.closeDisabled = true;
    }
  }
  /** Enables close of popover when mouse leaving popover element */
  onMouseLeave() {
    if (this.triggerEvent === 'hover') {
      this.closeDisabled = false;
      this._emitCloseEvent();
    }
  }
  // TODO: Refactor how styles are set and updated on the component, use best practices.
  // TODO: If arrow left and right positioning is requested, see if flex direction can be used to work with order.
  /** Sets the current styles for the popover to allow for dynamically changing settings */
  setCurrentStyles() {
    // If popover is at corner of trigger then arrow disabled and padding removed
    if (this.positionX === 'after' || this.positionX === 'before') {
      if (this.positionY === 'above' || this.positionY === 'below') {
        this.popoverArrowStyles = {
          'border': '0px'
        };
        this.popoverContentStyles = {
          'padding': '0px'
        };
        return;
      }
    }
    // TODO: See if arrow position can be calculated automatically and allow override.
    // TODO: See if flex order is a better alternative to position arrow top or bottom.
    this.popoverArrowStyles = {
      'right': this.getArrowRightStyle(),
      'left': this.getArrowLeftStyle(),
      'position': 'absolute',
      'z-index': '99999',
      'top': this.positionY === 'center' ? `calc(50% - ${this.arrowOffsetY + this.arrowWidth}px)` : '',
      'border-top': this.positionY === 'center' ? this.arrowWidth + 'px solid transparent' : this.positionY === 'below' ? this.arrowWidth + 'px solid ' + this.arrowColor : '0px solid transparent',
      'border-right': this.positionY === 'center' && this.positionX === 'after' ? this.arrowWidth + 'px solid ' + this.arrowColor : this.arrowWidth + 'px solid transparent',
      'border-bottom': this.positionY === 'above' ? this.arrowWidth + 'px solid ' + this.arrowColor : this.arrowWidth + 'px solid transparent',
      'border-left': this.positionY === 'center' && this.positionX === 'before' ? this.arrowWidth + 'px solid ' + this.arrowColor : this.arrowWidth + 'px solid transparent'
    };
    // TODO: Remove if flex order is added.
    this.popoverContentStyles = {
      'padding': this.overlapTrigger === true ? '0px' : this.arrowWidth + 'px',
      // 'padding-bottom': this.overlapTrigger === true ? '0px' : (this.arrowWidth) + 'px',
      'margin-top': this.overlapTrigger === false && this.positionY === 'below' && this.containerPositioning === false ? -(this.arrowWidth * 2) + 'px' : '0px'
    };
  }
  /**
   * It's necessary to set position-based classes to ensure the popover panel animation
   * folds out from the correct direction.
   */
  setPositionClasses(posX = this.positionX, posY = this.positionY) {
    this._classList['mde-popover-before'] = posX === 'before';
    this._classList['mde-popover-after'] = posX === 'after';
    this._classList['mde-popover-above'] = posY === 'above';
    this._classList['mde-popover-below'] = posY === 'below';
    this._classList['mde-popover-center'] = posX === 'center' || posY === 'center';
  }
  getArrowLeftStyle() {
    if (this.positionX === 'center' && (this.positionY === 'above' || this.positionY === 'below')) {
      return `calc(50% + ${this.arrowOffsetX - this._arrowWidth}px)`;
    } else if (this.positionX === 'after') {
      if (this.positionY === 'center') {
        return -this.arrowWidth + 'px';
      } else {
        return this.arrowOffsetX + this.arrowWidth + 'px';
      }
    } else {
      return '';
    }
  }
  getArrowRightStyle() {
    if (this.positionX === 'before') {
      if (this.positionY === 'center') {
        return -this.arrowWidth + 'px';
      } else {
        return this.arrowOffsetX + this.arrowWidth + 'px';
      }
    } else {
      return '';
    }
  }
  static {
    this.ɵfac = function MdePopover_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MdePopover)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: MdePopover,
      selectors: [["mde-popover"]],
      viewQuery: function MdePopover_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(TemplateRef, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templateRef = _t.first);
        }
      },
      hostVars: 1,
      hostBindings: function MdePopover_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("role", ctx.role);
        }
      },
      inputs: {
        positionX: [0, "mdePopoverPositionX", "positionX"],
        positionY: [0, "mdePopoverPositionY", "positionY"],
        triggerEvent: [0, "mdePopoverTriggerOn", "triggerEvent"],
        scrollStrategy: [0, "mdePopoverScrollStrategy", "scrollStrategy"],
        enterDelay: [0, "mdePopoverEnterDelay", "enterDelay"],
        leaveDelay: [0, "mdePopoverLeaveDelay", "leaveDelay"],
        overlapTrigger: [0, "mdePopoverOverlapTrigger", "overlapTrigger"],
        targetOffsetX: [0, "mdePopoverOffsetX", "targetOffsetX"],
        targetOffsetY: [0, "mdePopoverOffsetY", "targetOffsetY"],
        arrowOffsetX: [0, "mdePopoverArrowOffsetX", "arrowOffsetX"],
        arrowOffsetY: [0, "mdePopoverArrowOffsetY", "arrowOffsetY"],
        arrowWidth: [0, "mdePopoverArrowWidth", "arrowWidth"],
        arrowColor: [0, "mdePopoverArrowColor", "arrowColor"],
        closeOnClick: [0, "mdePopoverCloseOnClick", "closeOnClick"],
        disableAnimation: [0, "mdePopoverDisableAnimation", "disableAnimation"],
        focusTrapEnabled: [0, "mdeFocusTrapEnabled", "focusTrapEnabled"],
        focusTrapAutoCaptureEnabled: [0, "mdeFocusTrapAutoCaptureEnabled", "focusTrapAutoCaptureEnabled"],
        panelClass: [0, "class", "panelClass"],
        classList: "classList"
      },
      outputs: {
        close: "close"
      },
      exportAs: ["mdePopover"],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      consts: [["role", "dialog", 1, "mde-popover-panel", 3, "keydown", "click", "mouseover", "mouseleave", "ngClass", "ngStyle"], ["class", "mde-popover-direction-arrow", 3, "ngStyle", 4, "ngIf"], [1, "mde-popover-content", 3, "ngStyle", "cdkTrapFocus", "cdkTrapFocusAutoCapture"], [1, "mde-popover-direction-arrow", 3, "ngStyle"]],
      template: function MdePopover_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵtemplate(0, MdePopover_ng_template_0_Template, 4, 10, "ng-template");
        }
      },
      dependencies: [i1.NgClass, i1.NgIf, i1.NgStyle, i2.CdkTrapFocus],
      styles: [".mde-popover-panel{display:flex;flex-direction:column;max-height:calc(100vh + 48px);position:relative}.mde-popover-panel.mde-popover-before.mde-popover-above{transform-origin:right bottom}[dir=rtl] .mde-popover-panel.mde-popover-before.mde-popover-above{transform-origin:left bottom}.mde-popover-panel.mde-popover-before.sat-popover-center{transform-origin:right center}[dir=rtl] .mde-popover-panel.mde-popover-before.sat-popover-center{transform-origin:left center}.mde-popover-panel.mde-popover-before.sat-popover-below{transform-origin:right top}[dir=rtl] .mde-popover-panel.mde-popover-before.sat-popover-below{transform-origin:left top}.mde-popover-panel.mde-popover-center.mde-popover-above{transform-origin:center bottom}.mde-popover-panel.mde-popover-center.mde-popover-below{transform-origin:center top}.mde-popover-panel.mde-popover-after.mde-popover-above{transform-origin:left bottom}[dir=rtl] .mde-popover-panel.mde-popover-after.mde-popover-above{transform-origin:right bottom}.mde-popover-panel.mde-popover-after.mde-popover-center{transform-origin:left center}[dir=rtl] .mde-popover-panel.mde-popover-after.mde-popover-center{transform-origin:right center}.mde-popover-panel.mde-popover-after.mde-popover-below{transform-origin:left top}[dir=rtl] .mde-popover-panel.mde-popover-after.mde-popover-below{transform-origin:right top}.mde-popover-ripple{position:absolute;inset:0}.mde-popover-below .mde-popover-direction-arrow{position:absolute;bottom:0;width:0;height:0;border-bottom-width:0!important;z-index:99999}.mde-popover-above .mde-popover-direction-arrow{position:absolute;top:0;width:0;height:0;border-top-width:0!important;z-index:99999}.mde-popover-after .mde-popover-direction-arrow{left:20px}.mde-popover-before .mde-popover-direction-arrow{right:20px}\n"],
      encapsulation: 2,
      data: {
        animation: [transformPopover]
      },
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MdePopover, [{
    type: Component,
    args: [{
      selector: 'mde-popover',
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      animations: [transformPopover],
      exportAs: 'mdePopover',
      template: "<ng-template>\r\n  <div class=\"mde-popover-panel\" role=\"dialog\" [class.mde-popover-overlap]=\"overlapTrigger\"\r\n       [ngClass]=\"_classList\" [ngStyle]=\"popoverPanelStyles\" (keydown)=\"_handleKeydown($event)\"\r\n       (click)=\"onClick()\" (mouseover)=\"onMouseOver()\" (mouseleave)=\"onMouseLeave()\" [@.disabled]=\"disableAnimation\"\r\n       [@transformPopover]=\"'enter'\">\r\n    <div class=\"mde-popover-direction-arrow\" [ngStyle]=\"popoverArrowStyles\" *ngIf=\"!overlapTrigger\"></div>\r\n    <div class=\"mde-popover-content\" [ngStyle]=\"popoverContentStyles\" [cdkTrapFocus]=\"focusTrapEnabled\" [cdkTrapFocusAutoCapture]=\"focusTrapAutoCaptureEnabled\">\r\n      <ng-content></ng-content>\r\n    </div>\r\n  </div>\r\n</ng-template>\r\n",
      styles: [".mde-popover-panel{display:flex;flex-direction:column;max-height:calc(100vh + 48px);position:relative}.mde-popover-panel.mde-popover-before.mde-popover-above{transform-origin:right bottom}[dir=rtl] .mde-popover-panel.mde-popover-before.mde-popover-above{transform-origin:left bottom}.mde-popover-panel.mde-popover-before.sat-popover-center{transform-origin:right center}[dir=rtl] .mde-popover-panel.mde-popover-before.sat-popover-center{transform-origin:left center}.mde-popover-panel.mde-popover-before.sat-popover-below{transform-origin:right top}[dir=rtl] .mde-popover-panel.mde-popover-before.sat-popover-below{transform-origin:left top}.mde-popover-panel.mde-popover-center.mde-popover-above{transform-origin:center bottom}.mde-popover-panel.mde-popover-center.mde-popover-below{transform-origin:center top}.mde-popover-panel.mde-popover-after.mde-popover-above{transform-origin:left bottom}[dir=rtl] .mde-popover-panel.mde-popover-after.mde-popover-above{transform-origin:right bottom}.mde-popover-panel.mde-popover-after.mde-popover-center{transform-origin:left center}[dir=rtl] .mde-popover-panel.mde-popover-after.mde-popover-center{transform-origin:right center}.mde-popover-panel.mde-popover-after.mde-popover-below{transform-origin:left top}[dir=rtl] .mde-popover-panel.mde-popover-after.mde-popover-below{transform-origin:right top}.mde-popover-ripple{position:absolute;inset:0}.mde-popover-below .mde-popover-direction-arrow{position:absolute;bottom:0;width:0;height:0;border-bottom-width:0!important;z-index:99999}.mde-popover-above .mde-popover-direction-arrow{position:absolute;top:0;width:0;height:0;border-top-width:0!important;z-index:99999}.mde-popover-after .mde-popover-direction-arrow{left:20px}.mde-popover-before .mde-popover-direction-arrow{right:20px}\n"]
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], {
    role: [{
      type: HostBinding,
      args: ['attr.role']
    }],
    positionX: [{
      type: Input,
      args: ['mdePopoverPositionX']
    }],
    positionY: [{
      type: Input,
      args: ['mdePopoverPositionY']
    }],
    triggerEvent: [{
      type: Input,
      args: ['mdePopoverTriggerOn']
    }],
    scrollStrategy: [{
      type: Input,
      args: ['mdePopoverScrollStrategy']
    }],
    enterDelay: [{
      type: Input,
      args: ['mdePopoverEnterDelay']
    }],
    leaveDelay: [{
      type: Input,
      args: ['mdePopoverLeaveDelay']
    }],
    overlapTrigger: [{
      type: Input,
      args: ['mdePopoverOverlapTrigger']
    }],
    targetOffsetX: [{
      type: Input,
      args: ['mdePopoverOffsetX']
    }],
    targetOffsetY: [{
      type: Input,
      args: ['mdePopoverOffsetY']
    }],
    arrowOffsetX: [{
      type: Input,
      args: ['mdePopoverArrowOffsetX']
    }],
    arrowOffsetY: [{
      type: Input,
      args: ['mdePopoverArrowOffsetY']
    }],
    arrowWidth: [{
      type: Input,
      args: ['mdePopoverArrowWidth']
    }],
    arrowColor: [{
      type: Input,
      args: ['mdePopoverArrowColor']
    }],
    closeOnClick: [{
      type: Input,
      args: ['mdePopoverCloseOnClick']
    }],
    disableAnimation: [{
      type: Input,
      args: ['mdePopoverDisableAnimation']
    }],
    focusTrapEnabled: [{
      type: Input,
      args: ['mdeFocusTrapEnabled']
    }],
    focusTrapAutoCaptureEnabled: [{
      type: Input,
      args: ['mdeFocusTrapAutoCaptureEnabled']
    }],
    panelClass: [{
      type: Input,
      args: ['class']
    }],
    classList: [{
      type: Input
    }],
    close: [{
      type: Output
    }],
    templateRef: [{
      type: ViewChild,
      args: [TemplateRef]
    }]
  });
})();
class PopoverService {
  constructor() {}
  /** Get fallback positions based around target alignments. */
  getFallbacks(hTarget, vTarget) {
    // Determine if the target alignments overlap the anchor
    const horizontalOverlapAllowed = hTarget !== 'before' && hTarget !== 'after';
    const verticalOverlapAllowed = vTarget !== 'above' && vTarget !== 'below';
    // If a target alignment doesn't cover the anchor, don't let any of the fallback alignments
    // cover the anchor
    const possibleHorizontalAlignments = horizontalOverlapAllowed ? ['before', 'start', 'center', 'end', 'after'] : ['before', 'after'];
    const possibleVerticalAlignments = verticalOverlapAllowed ? ['above', 'start', 'center', 'end', 'below'] : ['above', 'below'];
    // Create fallbacks for each allowed prioritized fallback alignment combo
    const fallbacks = [];
    this.prioritizeAroundTarget(hTarget, possibleHorizontalAlignments).forEach(h => {
      this.prioritizeAroundTarget(vTarget, possibleVerticalAlignments).forEach(v => {
        fallbacks.push(this.getPosition(h, v));
      });
    });
    // Remove the first item since it will be the target alignment and isn't considered a fallback
    return fallbacks.slice(1, fallbacks.length);
  }
  getPosition(xPosition, yPosition) {
    const {
      originX,
      overlayX
    } = this.getHorizontalConnectionPosPair(xPosition);
    const {
      originY,
      overlayY
    } = this.getVerticalConnectionPosPair(yPosition);
    return new ConnectionPositionPair({
      originX,
      originY
    }, {
      overlayX,
      overlayY
    });
  }
  /** Helper function to convert an overlay connection position to equivalent popover alignment. */
  getHorizontalPopoverAlignment(h) {
    if (h === 'start') {
      return 'after';
    }
    if (h === 'end') {
      return 'before';
    }
    return 'center';
  }
  /** Helper function to convert an overlay connection position to equivalent popover alignment. */
  getVerticalPopoverAlignment(v) {
    if (v === 'top') {
      return 'below';
    }
    if (v === 'bottom') {
      return 'above';
    }
    return 'center';
  }
  /** Helper function to convert alignment to origin/overlay position pair. */
  getHorizontalConnectionPosPair(h) {
    switch (h) {
      case 'before':
        return {
          originX: 'start',
          overlayX: 'end'
        };
      case 'start':
        return {
          originX: 'start',
          overlayX: 'start'
        };
      case 'end':
        return {
          originX: 'end',
          overlayX: 'end'
        };
      case 'after':
        return {
          originX: 'end',
          overlayX: 'start'
        };
      default:
        return {
          originX: 'center',
          overlayX: 'center'
        };
    }
  }
  getVerticalConnectionPosPair(v) {
    switch (v) {
      case 'above':
        return {
          originY: 'top',
          overlayY: 'bottom'
        };
      case 'start':
        return {
          originY: 'top',
          overlayY: 'top'
        };
      case 'end':
        return {
          originY: 'bottom',
          overlayY: 'bottom'
        };
      case 'below':
        return {
          originY: 'bottom',
          overlayY: 'top'
        };
      default:
        return {
          originY: 'center',
          overlayY: 'center'
        };
    }
  }
  prioritizeAroundTarget(target, options) {
    const targetIndex = options.indexOf(target);
    // Set the first item to be the target
    const reordered = [target];
    // Make left and right stacks where the highest priority item is last
    const left = options.slice(0, targetIndex);
    const right = options.slice(targetIndex + 1, options.length).reverse();
    // Alternate between stacks until one is empty
    while (left.length && right.length) {
      reordered.push(right.pop());
      reordered.push(left.pop());
    }
    // Flush out right side
    while (right.length) {
      reordered.push(right.pop());
    }
    // Flush out left side
    while (left.length) {
      reordered.push(left.pop());
    }
    return reordered;
  }
  getNonOverlapPosition(posx, posy) {
    let positionX = posx;
    let positionY = posy;
    switch (posx) {
      case 'start':
        positionX = posy === 'center' ? 'before' : posx;
        positionY = posy === 'start' ? 'above' : posy === 'end' ? 'below' : posy;
        break;
      case 'end':
        positionX = posy === 'center' ? 'after' : posx;
        positionY = posy === 'start' ? 'above' : posy === 'end' ? 'below' : posy;
        break;
      case 'center':
        positionY = posy === 'start' || posy === 'center' ? 'above' : posy === 'end' ? 'below' : posy;
        break;
    }
    return {
      PositionX: positionX,
      PositionY: positionY
    };
  }
  static {
    this.ɵfac = function PopoverService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PopoverService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PopoverService,
      factory: PopoverService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PopoverService, [{
    type: Injectable
  }], () => [], null);
})();

/**
 * This directive is intended to be used in conjunction with an mde-popover tag. It is
 * responsible for toggling the display of the provided popover instance.
 */
class MdePopoverTrigger {
  constructor(_overlay, _elementRef, _viewContainerRef, _dir, _changeDetectorRef, popoverService) {
    this._overlay = _overlay;
    this._elementRef = _elementRef;
    this._viewContainerRef = _viewContainerRef;
    this._dir = _dir;
    this._changeDetectorRef = _changeDetectorRef;
    this.popoverService = popoverService;
    this.ariaHaspopup = true;
    this.popoverOpened = new Subject();
    this.popoverClosed = new Subject();
    this._overlayRef = null;
    this._popoverOpen = false;
    this._halt = false;
    // tracking input type is necessary so it's possible to only auto-focus
    // the first item of the list when the popover is opened via the keyboard
    this._openedByMouse = false;
    this._onDestroy = new Subject();
    /** Popover backdrop close on click */
    this.backdropCloseOnClick = true;
    /** Disables popover trigger */
    this.disablePopoverTrigger = false;
    /** Popver container close on click of trigger, when trigger event is click */
    this.closeOnClickTrigger = true;
    /** Event emitted when the associated popover is opened. */
    this.opened = new EventEmitter();
    /** Event emitted when the associated popover is closed. */
    this.closed = new EventEmitter();
  }
  ngAfterViewInit() {
    this._checkPopover();
    this._setCurrentConfig();
    this.popover.close.subscribe(() => this.closePopover());
  }
  ngOnDestroy() {
    this.destroyPopover();
  }
  _setCurrentConfig() {
    if (VALID_PositionX.includes(this.positionX)) {
      this.popover.positionX = this.positionX;
    }
    if (VALID_PositionY.includes(this.positionY)) {
      this.popover.positionY = this.positionY;
    }
    if (this.triggerEvent) {
      this.popover.triggerEvent = this.triggerEvent;
    }
    if (this.enterDelay) {
      this.popover.enterDelay = this.enterDelay;
    }
    if (this.leaveDelay) {
      this.popover.leaveDelay = this.leaveDelay;
    }
    if (this.overlapTrigger === true || this.overlapTrigger === false) {
      this.popover.overlapTrigger = this.overlapTrigger;
    }
    if (this.targetOffsetX) {
      this.popover.targetOffsetX = this.targetOffsetX;
    }
    if (this.targetOffsetY) {
      this.popover.targetOffsetY = this.targetOffsetY;
    }
    if (this.arrowOffsetX) {
      this.popover.arrowOffsetX = this.arrowOffsetX;
    }
    if (this.arrowOffsetY) {
      this.popover.arrowOffsetY = this.arrowOffsetY;
    }
    if (this.arrowWidth) {
      this.popover.arrowWidth = this.arrowWidth;
    }
    if (this.arrowColor) {
      this.popover.arrowColor = this.arrowColor;
    }
    if (this.closeOnClick === true || this.closeOnClick === false) {
      this.popover.closeOnClick = this.closeOnClick;
    }
    if (!this.closeOnClickTrigger && this.triggerEvent === 'click' && this.backdropCloseOnClick) {
      this._elementRef.nativeElement.style.zIndex = "999999";
    }
    this.popover.setCurrentStyles();
  }
  /** Whether the popover is open. */
  get popoverOpen() {
    return this._popoverOpen;
  }
  onClick(event) {
    if (this.popover.triggerEvent === 'click') {
      if (this.closeOnClickTrigger) {
        this.togglePopover();
      } else if (!this._popoverOpen) {
        this.togglePopover();
      }
    }
  }
  onMouseEnter(event) {
    this._halt = false;
    if (this.popover.triggerEvent === 'hover') {
      this._mouseoverTimer = setTimeout(() => {
        this.openPopover();
      }, this.popover.enterDelay);
    }
  }
  onMouseLeave(event) {
    if (this.popover.triggerEvent === 'hover') {
      if (this._mouseoverTimer) {
        clearTimeout(this._mouseoverTimer);
        this._mouseoverTimer = null;
      }
      if (this._popoverOpen) {
        setTimeout(() => {
          if (!this.popover.closeDisabled) {
            this.closePopover();
          }
        }, this.popover.leaveDelay);
      } else {
        this._halt = true;
      }
    }
  }
  /** Toggles the popover between the open and closed states. */
  togglePopover() {
    return this._popoverOpen ? this.closePopover() : this.openPopover();
  }
  /** Opens the popover. */
  openPopover() {
    if (!this._popoverOpen && !this._halt && !this.disablePopoverTrigger) {
      this._createOverlay().attach(this._portal);
      this._subscribeToBackdrop();
      this._subscribeToDetachments();
      this._initPopover();
    }
  }
  /** Closes the popover. */
  closePopover() {
    if (this._overlayRef) {
      this._overlayRef.detach();
      this._resetPopover();
      if (!this.closeOnClickTrigger && this.triggerEvent === 'click') {
        this._elementRef.nativeElement.blur();
      }
    }
  }
  /** Removes the popover from the DOM. */
  destroyPopover() {
    if (this._mouseoverTimer) {
      clearTimeout(this._mouseoverTimer);
      this._mouseoverTimer = null;
    }
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._overlayRef = null;
      this._cleanUpSubscriptions();
    }
    this._onDestroy.next();
    this._onDestroy.complete();
  }
  /** Focuses the popover trigger. */
  focus() {
    this._elementRef.nativeElement.focus();
  }
  /** The text direction of the containing app. */
  get dir() {
    return this._dir && this._dir.value === 'rtl' ? 'rtl' : 'ltr';
  }
  /**
  * This method ensures that the popover closes when the overlay backdrop is clicked.
  * We do not use first() here because doing so would not catch clicks from within
  * the popover, and it would fail to unsubscribe properly. Instead, we unsubscribe
  * explicitly when the popover is closed or destroyed.
  */
  _subscribeToBackdrop() {
    if (this._overlayRef) {
      /** Only subscribe to backdrop if trigger event is click */
      if (this.triggerEvent === 'click' && this.backdropCloseOnClick === true) {
        this._overlayRef.backdropClick().pipe(takeUntil(this.popoverClosed), takeUntil(this._onDestroy)).subscribe(() => {
          this.popover._emitCloseEvent();
        });
      }
    }
  }
  _subscribeToDetachments() {
    if (this._overlayRef) {
      this._overlayRef.detachments().pipe(takeUntil(this.popoverClosed), takeUntil(this._onDestroy)).subscribe(() => {
        this._setPopoverClosed();
      });
    }
  }
  /**
  * This method sets the popover state to open and focuses the first item if
  * the popover was opened via the keyboard.
  */
  _initPopover() {
    this._setPopoverOpened();
  }
  /**
  * This method resets the popover when it's closed, most importantly restoring
  * focus to the popover trigger if the popover was opened via the keyboard.
  */
  _resetPopover() {
    this._setPopoverClosed();
    // Focus only needs to be reset to the host element if the popover was opened
    // by the keyboard and manually shifted to the first popover item.
    if (!this._openedByMouse) {
      this.focus();
    }
    this._openedByMouse = false;
  }
  /** set state rather than toggle to support triggers sharing a popover */
  _setPopoverOpened() {
    if (!this._popoverOpen) {
      this._popoverOpen = true;
      this.popoverOpened.next();
      this.opened.emit();
    }
  }
  /** set state rather than toggle to support triggers sharing a popover */
  _setPopoverClosed() {
    if (this._popoverOpen) {
      this._popoverOpen = false;
      this.popoverClosed.next();
      this.closed.emit();
    }
  }
  /**
  *  This method checks that a valid instance of MdPopover has been passed into
  *  mdPopoverTriggerFor. If not, an exception is thrown.
  */
  _checkPopover() {
    if (!this.popover) {
      throwMdePopoverMissingError();
    }
  }
  /**
  *  This method creates the overlay from the provided popover's template and saves its
  *  OverlayRef so that it can be attached to the DOM when openPopover is called.
  */
  _createOverlay() {
    if (!this._overlayRef) {
      this._portal = new TemplatePortal(this.popover.templateRef, this._viewContainerRef);
      const config = this._getOverlayConfig();
      this._subscribeToPositions(config.positionStrategy);
      this._overlayRef = this._overlay.create(config);
    }
    return this._overlayRef;
  }
  /**
  * This method builds the configuration object needed to create the overlay, the OverlayConfig.
  * @returns OverlayConfig
  */
  _getOverlayConfig() {
    const overlayState = new OverlayConfig();
    overlayState.positionStrategy = this._getPositionStrategy();
    /** Display overlay backdrop if trigger event is click */
    if (this.triggerEvent === 'click') {
      overlayState.hasBackdrop = true;
      overlayState.backdropClass = 'cdk-overlay-transparent-backdrop';
    }
    overlayState.direction = this.dir;
    overlayState.scrollStrategy = this._getOverlayScrollStrategy(this.popover.scrollStrategy);
    return overlayState;
  }
  /**
   * This method returns the scroll strategy used by the cdk/overlay.
   */
  _getOverlayScrollStrategy(strategy) {
    switch (strategy) {
      case 'noop':
        console.log('noop');
        return this._overlay.scrollStrategies.noop();
      case 'close':
        return this._overlay.scrollStrategies.close();
      case 'block':
        return this._overlay.scrollStrategies.block();
      case 'reposition':
      default:
        return this._overlay.scrollStrategies.reposition();
    }
  }
  /**
  * Listens to changes in the position of the overlay and sets the correct classes
  * on the popover based on the new position. This ensures the animation origin is always
  * correct, even if a fallback position is used for the overlay.
  */
  _subscribeToPositions(position) {
    this._positionSubscription = position.positionChanges.subscribe(change => {
      const posisionX = this.popoverService.getHorizontalPopoverAlignment(change.connectionPair.overlayX);
      let posisionY = this.popoverService.getVerticalPopoverAlignment(change.connectionPair.overlayY);
      if (!this.popover.overlapTrigger) {
        posisionY = posisionY === 'below' ? 'above' : posisionY === 'above' ? 'below' : posisionY;
      }
      // required for ChangeDetectionStrategy.OnPush
      this._changeDetectorRef.markForCheck();
      this.popover.zone.run(() => {
        this.popover.positionX = posisionX;
        this.popover.positionY = posisionY;
        this.popover.setCurrentStyles();
        this.popover.setPositionClasses(posisionX, posisionY);
      });
    });
  }
  /**
  * This method builds the position strategy for the overlay, so the popover is properly connected
  * to the trigger.
  * @returns ConnectedPositionStrategy
  */
  _getPositionStrategy() {
    let posx = this.popover.positionX;
    let posy = this.popover.positionY;
    /** Reverse overlayY and fallbackOverlayY when overlapTrigger is false */
    if (!this.popover.overlapTrigger) {
      const nonOverlapPositions = this.popoverService.getNonOverlapPosition(posx, posy);
      posx = nonOverlapPositions.PositionX;
      posy = nonOverlapPositions.PositionY;
    }
    const targetPosition = this.popoverService.getPosition(posx, posy);
    const positions = [targetPosition];
    const fallbackPosition = this.popoverService.getFallbacks(posx, posy);
    positions.push(...fallbackPosition);
    let offsetX = 0;
    let offsetY = 0;
    if (this.popover.targetOffsetX && !isNaN(Number(this.popover.targetOffsetX))) {
      offsetX = Number(this.popover.targetOffsetX);
      // offsetX = -16;
    }
    if (this.popover.targetOffsetY && !isNaN(Number(this.popover.targetOffsetY))) {
      offsetY = Number(this.popover.targetOffsetY);
      // offsetY = -10;
    }
    /**
     * For overriding position element, when mdePopoverTargetAt has a valid element reference.
     * Useful for sticking popover to parent element and offsetting arrow to trigger element.
     * If undefined defaults to the trigger element reference.
     */
    let element = this._elementRef;
    if (typeof this.targetElement !== 'undefined') {
      this.popover.containerPositioning = true;
      element = this.targetElement._elementRef;
    }
    return this._overlay.position().flexibleConnectedTo(element).withLockedPosition(true).withPositions(positions).withDefaultOffsetX(offsetX).withDefaultOffsetY(offsetY);
  }
  _cleanUpSubscriptions() {
    if (this._backdropSubscription) {
      this._backdropSubscription.unsubscribe();
    }
    if (this._positionSubscription) {
      this._positionSubscription.unsubscribe();
    }
    if (this._detachmentsSubscription) {
      this._detachmentsSubscription.unsubscribe();
    }
  }
  _handleMousedown(event) {
    if (event && !isFakeMousedownFromScreenReader(event)) {
      this._openedByMouse = true;
    }
  }
  static {
    this.ɵfac = function MdePopoverTrigger_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MdePopoverTrigger)(i0.ɵɵdirectiveInject(i1$1.Overlay), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i2$1.Directionality, 8), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(PopoverService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MdePopoverTrigger,
      selectors: [["", "mdePopoverTriggerFor", ""]],
      hostVars: 1,
      hostBindings: function MdePopoverTrigger_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function MdePopoverTrigger_click_HostBindingHandler($event) {
            return ctx.onClick($event);
          })("mouseenter", function MdePopoverTrigger_mouseenter_HostBindingHandler($event) {
            return ctx.onMouseEnter($event);
          })("mouseleave", function MdePopoverTrigger_mouseleave_HostBindingHandler($event) {
            return ctx.onMouseLeave($event);
          })("mousedown", function MdePopoverTrigger_mousedown_HostBindingHandler($event) {
            return ctx._handleMousedown($event);
          });
        }
        if (rf & 2) {
          i0.ɵɵattribute("aria-haspopup", ctx.ariaHaspopup);
        }
      },
      inputs: {
        popover: [0, "mdePopoverTriggerFor", "popover"],
        targetElement: [0, "mdePopoverTargetAt", "targetElement"],
        positionX: [0, "mdePopoverPositionX", "positionX"],
        positionY: [0, "mdePopoverPositionY", "positionY"],
        triggerEvent: [0, "mdePopoverTriggerOn", "triggerEvent"],
        enterDelay: [0, "mdePopoverEnterDelay", "enterDelay"],
        leaveDelay: [0, "mdePopoverLeaveDelay", "leaveDelay"],
        overlapTrigger: [0, "mdePopoverOverlapTrigger", "overlapTrigger"],
        targetOffsetX: [0, "mdePopoverOffsetX", "targetOffsetX"],
        targetOffsetY: [0, "mdePopoverOffsetY", "targetOffsetY"],
        arrowOffsetX: [0, "mdePopoverArrowOffsetX", "arrowOffsetX"],
        arrowOffsetY: [0, "mdePopoverArrowOffsetY", "arrowOffsetY"],
        arrowWidth: [0, "mdePopoverArrowWidth", "arrowWidth"],
        arrowColor: [0, "mdePopoverArrowColor", "arrowColor"],
        closeOnClick: [0, "mdePopoverCloseOnClick", "closeOnClick"],
        backdropCloseOnClick: [0, "mdePopoverBackdropCloseOnClick", "backdropCloseOnClick"],
        disablePopoverTrigger: [0, "mdeDisablePopoverTrigger", "disablePopoverTrigger"],
        closeOnClickTrigger: [0, "mdePopoverCloseOnClickTrigger", "closeOnClickTrigger"]
      },
      outputs: {
        opened: "opened",
        closed: "closed"
      },
      exportAs: ["mdePopoverTrigger"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MdePopoverTrigger, [{
    type: Directive,
    args: [{
      selector: '[mdePopoverTriggerFor]',
      exportAs: 'mdePopoverTrigger'
    }]
  }], () => [{
    type: i1$1.Overlay
  }, {
    type: i0.ElementRef
  }, {
    type: i0.ViewContainerRef
  }, {
    type: i2$1.Directionality,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: PopoverService
  }], {
    ariaHaspopup: [{
      type: HostBinding,
      args: ['attr.aria-haspopup']
    }],
    popover: [{
      type: Input,
      args: ['mdePopoverTriggerFor']
    }],
    targetElement: [{
      type: Input,
      args: ['mdePopoverTargetAt']
    }],
    positionX: [{
      type: Input,
      args: ['mdePopoverPositionX']
    }],
    positionY: [{
      type: Input,
      args: ['mdePopoverPositionY']
    }],
    triggerEvent: [{
      type: Input,
      args: ['mdePopoverTriggerOn']
    }],
    enterDelay: [{
      type: Input,
      args: ['mdePopoverEnterDelay']
    }],
    leaveDelay: [{
      type: Input,
      args: ['mdePopoverLeaveDelay']
    }],
    overlapTrigger: [{
      type: Input,
      args: ['mdePopoverOverlapTrigger']
    }],
    targetOffsetX: [{
      type: Input,
      args: ['mdePopoverOffsetX']
    }],
    targetOffsetY: [{
      type: Input,
      args: ['mdePopoverOffsetY']
    }],
    arrowOffsetX: [{
      type: Input,
      args: ['mdePopoverArrowOffsetX']
    }],
    arrowOffsetY: [{
      type: Input,
      args: ['mdePopoverArrowOffsetY']
    }],
    arrowWidth: [{
      type: Input,
      args: ['mdePopoverArrowWidth']
    }],
    arrowColor: [{
      type: Input,
      args: ['mdePopoverArrowColor']
    }],
    closeOnClick: [{
      type: Input,
      args: ['mdePopoverCloseOnClick']
    }],
    backdropCloseOnClick: [{
      type: Input,
      args: ['mdePopoverBackdropCloseOnClick']
    }],
    disablePopoverTrigger: [{
      type: Input,
      args: ['mdeDisablePopoverTrigger']
    }],
    closeOnClickTrigger: [{
      type: Input,
      args: ['mdePopoverCloseOnClickTrigger']
    }],
    opened: [{
      type: Output
    }],
    closed: [{
      type: Output
    }],
    onClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }],
    onMouseEnter: [{
      type: HostListener,
      args: ['mouseenter', ['$event']]
    }],
    onMouseLeave: [{
      type: HostListener,
      args: ['mouseleave', ['$event']]
    }],
    _handleMousedown: [{
      type: HostListener,
      args: ['mousedown', ['$event']]
    }]
  });
})();
class MdePopoverTarget {
  constructor(_elementRef) {
    this._elementRef = _elementRef;
  }
  static {
    this.ɵfac = function MdePopoverTarget_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MdePopoverTarget)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: MdePopoverTarget,
      selectors: [["mde-popover-target"], ["", "mdePopoverTarget", ""]],
      exportAs: ["mdePopoverTarget"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MdePopoverTarget, [{
    type: Directive,
    args: [{
      selector: 'mde-popover-target, [mdePopoverTarget]',
      exportAs: 'mdePopoverTarget'
    }]
  }], () => [{
    type: i0.ElementRef
  }], null);
})();
class MdePopoverModule {
  static {
    this.ɵfac = function MdePopoverModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || MdePopoverModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: MdePopoverModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [PopoverService],
      imports: [OverlayModule, CommonModule, A11yModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MdePopoverModule, [{
    type: NgModule,
    args: [{
      imports: [OverlayModule, CommonModule, A11yModule],
      exports: [MdePopover, MdePopoverTrigger, MdePopoverTarget],
      declarations: [MdePopover, MdePopoverTrigger, MdePopoverTarget],
      providers: [PopoverService]
    }]
  }], null, null);
})();

/*
 * Public API Surface of material-extended
 */

/**
 * Generated bundle index. Do not edit.
 */

export { MdePopover, MdePopoverModule, MdePopoverTarget, MdePopoverTrigger, VALID_PositionX, VALID_PositionY, transformPopover };
