<mat-form-field [formGroup]="selectForm">
  <div *ngIf="fieldNames[0].startsWith('<Not'); else elseBlock">
    <mat-select
      placeholder="Select column"
      (selectionChange)="addSelectedValue($event)"
      [formControlName]="fieldName"
    >
      <mat-option
        *ngFor="let name of fieldNames"
        [value]="{
          name,
          columnId,
          missingAllowed,
          validateDate,
          validateCode,
          validCodes,
          systemColumnName
        }"
        [disabled]="checkSelected(name, columnId)"
      >
        {{ name }}
      </mat-option>
    </mat-select>
  </div>
  <ng-template #elseBlock>
    <!-- auto-mapped values don't need placeholder, checkSelected or addSelectedValue -->
    <mat-select [formControlName]="fieldName" [(value)]="fieldValue">
      <mat-option [value]="fieldValue">
        {{ systemColumnName }}
      </mat-option>
    </mat-select>
  </ng-template>
  <mat-error
    *ngIf="
      selectForm.controls[fieldName].errors?.required &&
      selectForm.controls[fieldName].touched
    "
    >You must select a column</mat-error
  >
</mat-form-field>
