import { Injectable } from '@angular/core';
import { handleError } from '@core/helpers';
import { DashboardService, ReportingPeriodService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { GrantSelectors } from '../grant';
import {
  getBenchmarks,
  getBenchmarksFailure,
  getBenchmarksSuccess,
  getCardDetails,
  getCardDetailsFailure,
  getCardDetailsSuccess,
  getCardDetailsSuccessNoDetails,
  getCards,
  getCardsFailure,
  getCardsSuccess,
  getFilterData,
  getFilterDataFailure,
  getFilterDataSuccess,
  getGrantSubmissionReviewStatus,
  getGrantSubmissionReviewStatusFailure,
  getGrantSubmissionReviewStatusSuccess,
  getKeyDates,
  getKeyDatesFailure,
  getKeyDatesSuccess,
  getReportingPeriods,
  getReportingPeriodsFailure,
  getReportingPeriodsSuccess,
  getReportingPeriodState,
  getReportingPeriodStateFailure,
  getReportingPeriodStateSuccess,
} from './actions';

@Injectable()
export class DashboardEffects {
  getBenchmarks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBenchmarks),
      switchMap(() =>
        this.dashboardService.getBenchmarks().pipe(
          map((response) => getBenchmarksSuccess({ response })),
          catchError((error) => handleError(error, getBenchmarksFailure)),
        ),
      ),
    ),
  );

  getCardDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCardDetails),
      withLatestFrom(this.store$.select(GrantSelectors.selectSelectedGrant)),
      filter(([action, selectedGrant]) => !!selectedGrant),
      switchMap(([{ cardId, params }, { grantId }]) => {
        if (grantId) {
          params = { ...params, grantId };
        }
        return this.dashboardService.getCardDetails(cardId, params).pipe(
          map((response) =>
            response.success
              ? getCardDetailsSuccess({ response })
              : getCardDetailsSuccessNoDetails({ response }),
          ),
          catchError((error) => handleError(error, getCardDetailsFailure)),
        );
      }),
    ),
  );

  getCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCards),
      withLatestFrom(this.store$.select(GrantSelectors.selectSelectedGrant)),
      filter(([action, selectedGrant]) => !!selectedGrant),
      switchMap(([{ benchmarkId, params }, { grantId }]) => {
        if (grantId) {
          params = { ...params, grantId };
        }
        return this.dashboardService.getCards(benchmarkId, params).pipe(
          map((response) => getCardsSuccess({ response })),
          catchError((error) => handleError(error, getCardsFailure)),
        );
      }),
    ),
  );

  getFilterData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFilterData),
      switchMap(() =>
        this.dashboardService.getFilterData().pipe(
          map((filterData) => getFilterDataSuccess({ filterData })),
          catchError((error) => handleError(error, getFilterDataFailure)),
        ),
      ),
    ),
  );

  getGrantSubmissionReviewStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGrantSubmissionReviewStatus),
      switchMap(({ grantId }) =>
        this.dashboardService.getGrantSubmissionReviewStatus(grantId).pipe(
          map((submissionReviewStatus) =>
            getGrantSubmissionReviewStatusSuccess({ submissionReviewStatus }),
          ),
          catchError((error) =>
            handleError(error, getGrantSubmissionReviewStatusFailure),
          ),
        ),
      ),
    ),
  );

  getKeyDates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getKeyDates),
      switchMap(({ year }) =>
        this.dashboardService.getKeyDates(year).pipe(
          map((keyDates) => getKeyDatesSuccess({ keyDates })),
          catchError((error) => handleError(error, getKeyDatesFailure)),
        ),
      ),
    ),
  );

  getReportingPeriods$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getReportingPeriods),
      switchMap(({ grantId }) =>
        forkJoin([
          this.dashboardService.getReportingPeriods(grantId),
          this.reportingPeriodService.getAll(),
        ]).pipe(
          map(([{ data: reportingPeriodIds }, { data: reportingPeriods }]) =>
            getReportingPeriodsSuccess({
              reportingPeriods: reportingPeriods.filter((reportingPeriod) =>
                reportingPeriodIds.includes(reportingPeriod.id),
              ),
            }),
          ),
          catchError((error) => handleError(error, getReportingPeriodsFailure)),
        ),
      ),
    ),
  );

  getReportingPeriodState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getReportingPeriodState),
      switchMap(() =>
        this.reportingPeriodService.getReportingPeriodState().pipe(
          map((reportingPeriodState) =>
            getReportingPeriodStateSuccess({ reportingPeriodState }),
          ),
          catchError((error) =>
            handleError(error, getReportingPeriodStateFailure),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private dashboardService: DashboardService,
    private reportingPeriodService: ReportingPeriodService,
    private store$: Store,
  ) {}
}
