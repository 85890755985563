import { createAction, props } from '@ngrx/store';

export enum ClinicStepperActionTypes {
  RESET_CLINIC_STEPPER_REQUEST = '[Clinic Stepper] Reset Clinic Stepper Request',
  RESET_CLINIC_STEPPER_SUCCESS = '[Clinic Stepper] Reset Clinic Stepper Success',
  UPDATE_STEP_REQUEST = '[Clinic Stepper] Update Step Request',
  UPDATE_STEP_SUCCESS = '[Clinic Stepper] Update Step Success',
}

export const resetClinicStepper = createAction(
  ClinicStepperActionTypes.RESET_CLINIC_STEPPER_REQUEST,
);
export const resetClinicStepperSuccess = createAction(
  ClinicStepperActionTypes.RESET_CLINIC_STEPPER_SUCCESS,
);

export const updateStep = createAction(
  ClinicStepperActionTypes.UPDATE_STEP_REQUEST,
  props<{ stepIndex: number }>(),
);
export const updateStepSuccess = createAction(
  ClinicStepperActionTypes.UPDATE_STEP_SUCCESS,
  props<{ stepIndex: number }>(),
);
