import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { PortalColumnMappingComponent } from '@app/grant-portal/portal-column-mapping/portal-column-mapping.component';
import { ErrorStrings } from '@app/shared/enums';
import { AppStoreState, GrantActions } from '@app/store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GrantUploadMappingGuard
  
{
  constructor(private store$: Store<AppStoreState.State>) {}
  canDeactivate(
    component: PortalColumnMappingComponent,
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    return component.mappingStarted && !component.uploadComplete
      ? confirm(ErrorStrings.GRANT_UPLOAD_IN_PROGRESS)
        ? this.clearUpload()
        : false
      : true;
  }

  private clearUpload(): boolean {
    this.store$.dispatch(GrantActions.clearFileUploadData());
    return true;
  }
}
