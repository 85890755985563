import { Injectable } from '@angular/core';
import { GrantSubmissionStatus } from '@app/shared/enums';
import { handleError } from '@core/helpers';
import {
  GrantService,
  KeyDateService,
  ReportingPeriodService,
  SettingsService,
} from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  closeSubmissionPeriod,
  closeSubmissionPeriodFailure,
  closeSubmissionPeriodSuccess,
  createKeyDate,
  createKeyDateFailure,
  createKeyDateSuccess,
  createReportingPeriod,
  createReportingPeriodFailure,
  createReportingPeriodSuccess,
  deleteKeyDate,
  deleteKeyDateFailure,
  deleteKeyDateSuccess,
  deleteReportingPeriod,
  deleteReportingPeriodFailure,
  deleteReportingPeriodSuccess,
  forceGrantSubmission,
  forceGrantSubmissionFailure,
  forceGrantSubmissionSuccess,
  generateDashboardData,
  generateDashboardDataFailure,
  generateDashboardDataSuccess,
  getKeyDates,
  getKeyDatesFailure,
  getKeyDatesSuccess,
  getOverviewReport,
  getOverviewReportFailure,
  getOverviewReportSuccess,
  getRemovedUserReport,
  getRemovedUserReportFailure,
  getRemovedUserReportSuccess,
  getReportingPeriods,
  getReportingPeriodsFailure,
  getReportingPeriodsSuccess,
  updateKeyDate,
  updateKeyDateFailure,
  updateKeyDateSuccess,
  updateReportingPeriod,
  updateReportingPeriodFailure,
  updateReportingPeriodSuccess,
} from './actions';

@Injectable()
export class SettingsEffects {
  closeSubmissionPeriod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(closeSubmissionPeriod),
      switchMap(({ sendNotificationEmail }) =>
        this.reportingPeriodService
          .closeSubmissionPeriod(sendNotificationEmail)
          .pipe(
            map(() => closeSubmissionPeriodSuccess()),
            catchError((error) =>
              handleError(error, closeSubmissionPeriodFailure),
            ),
          ),
      ),
    ),
  );

  generateDashboardData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(generateDashboardData),
      switchMap(({ id }) =>
        this.reportingPeriodService.generateDashboardData(id).pipe(
          map(() => generateDashboardDataSuccess()),
          catchError((error) =>
            handleError(error, generateDashboardDataFailure),
          ),
        ),
      ),
    ),
  );

  createKeyDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createKeyDate),
      switchMap(({ payload }) =>
        this.keyDateService.create(payload).pipe(
          map(() => createKeyDateSuccess()),
          catchError((error) => handleError(error, createKeyDateFailure)),
        ),
      ),
    ),
  );

  createReportingPeriod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createReportingPeriod),
      switchMap(({ payload }) =>
        this.reportingPeriodService.create(payload).pipe(
          map(() => createReportingPeriodSuccess()),
          catchError((error) =>
            handleError(error, createReportingPeriodFailure),
          ),
        ),
      ),
    ),
  );

  deleteKeyDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteKeyDate),
      switchMap(({ id }) =>
        this.keyDateService.delete(id).pipe(
          map(() => deleteKeyDateSuccess()),
          catchError((error) => handleError(error, deleteKeyDateFailure)),
        ),
      ),
    ),
  );

  deleteReportingPeriod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteReportingPeriod),
      switchMap(({ id }) =>
        this.reportingPeriodService.delete(id).pipe(
          map(() => deleteReportingPeriodSuccess()),
          catchError((error) =>
            handleError(error, deleteReportingPeriodFailure),
          ),
        ),
      ),
    ),
  );

  forceGrantSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(forceGrantSubmission),
      switchMap(() =>
        this.grantService
          .grantSubmissionStatusChange({
            submissionStatus: GrantSubmissionStatus.ReadyForReview,
          })
          .pipe(
            map(() => forceGrantSubmissionSuccess()),
            catchError((error) =>
              handleError(error, forceGrantSubmissionFailure),
            ),
          ),
      ),
    ),
  );

  getRemovedUserReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRemovedUserReport),
      switchMap(() =>
        this.settingsService.getRemovedUserReport().pipe(
          map((response) => getRemovedUserReportSuccess({ response })),
          catchError((error) =>
            handleError(error, getRemovedUserReportFailure),
          ),
        ),
      ),
    ),
  );

  getKeyDates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getKeyDates),
      switchMap(() =>
        this.keyDateService.getAll().pipe(
          map((response) => getKeyDatesSuccess({ response })),
          catchError((error) => handleError(error, getKeyDatesFailure)),
        ),
      ),
    ),
  );

  getOverviewReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOverviewReport),
      switchMap(() =>
        this.settingsService.getOverviewReport().pipe(
          map((response) => getOverviewReportSuccess({ response })),
          catchError((error) => handleError(error, getOverviewReportFailure)),
        ),
      ),
    ),
  );

  getReportingPeriods$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getReportingPeriods),
      switchMap(() =>
        this.reportingPeriodService.getAll().pipe(
          map((response) => getReportingPeriodsSuccess({ response })),
          catchError((error) => handleError(error, getReportingPeriodsFailure)),
        ),
      ),
    ),
  );

  updateKeyDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateKeyDate),
      switchMap(({ id, payload }) =>
        this.keyDateService.update(id, payload).pipe(
          map(() => updateKeyDateSuccess()),
          catchError((error) => handleError(error, updateKeyDateFailure)),
        ),
      ),
    ),
  );

  updateReportingPeriod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateReportingPeriod),
      switchMap(({ id, payload }) =>
        this.reportingPeriodService.update(id, payload).pipe(
          map(() => updateReportingPeriodSuccess()),
          catchError((error) =>
            handleError(error, updateReportingPeriodFailure),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private grantService: GrantService,
    private keyDateService: KeyDateService,
    private reportingPeriodService: ReportingPeriodService,
    private settingsService: SettingsService,
  ) {}
}
