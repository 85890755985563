/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  ICognitoUserAttributeData,
  IMfaSettings,
  UserData,
} from 'amazon-cognito-identity-js';
import { environment as env } from 'environments/environment';
import { Observable, Observer, of, throwError } from 'rxjs';

export enum CognitoAuthErrorTypes {
  PASSWORD_RESET_REQUIRED = 'PasswordResetRequiredException',
  USER_NOT_FOUND = 'UserNotFoundException',
  USER_LAMBDA_VALIDATION_EXCEPTION = 'UserLambdaValidationException',
  PASSWORD_EXPIRED = 'PostAuthentication failed with error PasswordExpired.',
}

export interface CognitoAccount {
  [key: string]: any;
}

export interface CognitoErrorResponse {
  message: string;
  code: string;
  name: string;
}

export type CognitoResponse = { next: string; results: any };
export type CognitoUserData = UserData;
export type IMFASettings = IMfaSettings;
export type UserAttribute = {} & ICognitoUserAttributeData;

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  user: CognitoUser;
  mfaInProgress = false;
  newPasswordInProgress = false;

  signUp(
    username: string,
    password: string,
    attributes: [{ Name: string; Value: string }],
  ): Observable<any> {
    const userPool = this.getUserPool();

    const attributeList = attributes.map(
      (attribute) => new CognitoUserAttribute(attribute),
    );

    return new Observable((observer: Observer<any>) => {
      userPool.signUp(
        username,
        password,
        attributeList,
        null,
        (err, result) => {
          if (err) {
            return observer.error(err);
          }
          observer.next(result);
        },
      );
    });
  }

  sendPasswordResetCode(username: string): Observable<any> {
    const userData = {
      Username: username,
      Pool: this.getUserPool(),
      Storage: sessionStorage,
    };
    this.user = new CognitoUser(userData);

    return new Observable((observer: Observer<any>) => {
      this.user.forgotPassword({
        onSuccess: (result) => {
          observer.next(result);
        },

        onFailure: (err) => observer.error(err),
      });
    });
  }

  confirmPassword(
    verificationCode: string,
    newPassword: string,
  ): Observable<CognitoResponse> {
    return new Observable((observer) => {
      this.user.confirmPassword(verificationCode, newPassword, {
        onSuccess: () => {
          observer.next({ next: '', results: true });
        },

        onFailure: (err) => observer.error(err),
      });
    });
  }

  confirmSignUp(username: string, code: string): Observable<any> {
    const userData = {
      Username: username,
      Pool: this.getUserPool(),
      Storage: sessionStorage,
    };
    this.user = new CognitoUser(userData);
    return new Observable((observer) => {
      this.user.confirmRegistration(code, true, (err, result) => {
        if (err) {
          return observer.error(err);
        }
        observer.next(result);
      });
    });
  }

  getInputVerificationCode(name: string): Observable<CognitoResponse> {
    return new Observable((observer: Observer<CognitoResponse>) => {
      this.user.getAttributeVerificationCode(name, {
        onSuccess: () =>
          observer.next({ next: 'verifyInputCode', results: null }),
        onFailure: (err) => observer.error(err),
      });
    });
  }

  signIn(username: string, password: string): Observable<CognitoResponse> {
    const userPool = this.getUserPool();
    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: password,
    });
    const userData = {
      Username: username,
      Pool: userPool,
      Storage: sessionStorage,
    };

    this.user = new CognitoUser(userData);

    if (!env.production || (env.production && env.staging)) {
      this.user.setAuthenticationFlowType('CUSTOM_AUTH');
    }

    return new Observable((observer: Observer<CognitoResponse>) => {
      this.user.authenticateUser(authDetails, {
        onSuccess: (session, confirmationNecessary) => {
          this.user.getUserData((err, data) => {
            if (err) {
              observer.error(err);
              return;
            }

            observer.next(
              !data?.PreferredMfaSetting
                ? { next: 'setupMFA', results: { ...data } }
                : { next: 'continue', results: { ...data } },
            );
          });
        },
        onFailure: (err) => observer.error(err),
        selectMFAType: (challengeName, challengeParameters) => {
          observer.next({
            next: 'setupMFA',
            results: { challengeName, challengeParameters },
          });
        },
        mfaSetup: (challengeName, challengeParameters) => {
          observer.next({
            next: 'setupMFA',
            results: { challengeName, challengeParameters },
          });
        },
        mfaRequired: (challengeName, challengeParameters) => {
          this.mfaInProgress = true;
          observer.next({
            next: 'enterMFACode',
            results: { challengeName, challengeParameters },
          });
        },
        totpRequired: (challengeName, challengeParameters) => {
          this.mfaInProgress = true;
          observer.next({
            next: 'enterMFACode',
            results: { challengeName, challengeParameters },
          });
        },
        customChallenge: (challengeParameters) => {
          this.mfaInProgress = true;
          observer.next({
            next: 'enterMFACode',
            results: { challengeName: 'CUSTOM_CHALLENGE', challengeParameters },
          });
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          this.newPasswordInProgress = true;
          observer.next({
            next: 'newUser',
            results: { userAttributes, requiredAttributes },
          });
        },
      });
    });
  }

  completeNewPasswordChallenge(
    newPassword: string,
    requiredAttributeData: any,
  ): Observable<CognitoResponse> {
    return new Observable((observer: Observer<CognitoResponse>) => {
      this.user.completeNewPasswordChallenge(
        newPassword,
        requiredAttributeData,
        {
          onSuccess: (results) => observer.next({ next: '', results }),
          onFailure: (err) => observer.error(err),
          customChallenge: (challengeName) => {
            observer.next({
              next: 'customChallenge',
              results: { challengeName },
            });
          },
          mfaRequired: (challengeName, challengeParameters) => {
            observer.next({
              next: 'enterMFACode',
              results: { challengeName, challengeParameters },
            });
          },
          mfaSetup: (challengeName, challengeParameters) => {
            observer.next({
              next: 'setMFAOption',
              results: { challengeName, challengeParameters },
            });
          },
        },
      );
    });
  }

  confirmSignIn(code: string, mfaType: string): Observable<CognitoUserSession> {
    return new Observable((observer: Observer<CognitoUserSession>) => {
      this.user.sendMFACode(
        code,
        {
          onSuccess: (result: CognitoUserSession) => {
            observer.next(result);
          },
          onFailure: (err) => observer.error(err),
        },
        mfaType,
      );
    });
  }

  sendCustomChallengeAnswer(answerChallenge: string): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      this.user.sendCustomChallengeAnswer(answerChallenge, {
        onSuccess: (result: CognitoUserSession) => {
          observer.next(result);
        },
        onFailure: (err) => observer.error(err),
      });
    });
  }

  associateSoftwareToken(): Observable<CognitoResponse> {
    return new Observable((observer: Observer<CognitoResponse>) => {
      this.user.associateSoftwareToken({
        onFailure: (err) => observer.error(err),
        associateSecretCode: (secretCode) => {
          observer.next({ next: 'verifyInputCode', results: secretCode });
        },
      });
    });
  }

  verifySoftwareToken(
    AccessToken: string,
    UserCode: string,
    FriendlyDeviceName: string,
  ): Observable<CognitoUserSession> {
    return new Observable((observer: Observer<CognitoUserSession>) => {
      this.user.verifySoftwareToken(UserCode, FriendlyDeviceName, {
        onSuccess: (result: CognitoUserSession) => {
          observer.next(result);
        },
        onFailure: (err) => observer.error(err),
      });
    });
  }

  setMFAPreference(
    smsMfaSettings: IMfaSettings,
    softwareTokenMfaSettings: IMfaSettings,
  ): Observable<CognitoResponse> {
    return new Observable((observer: Observer<CognitoResponse>) => {
      this.user.setUserMfaPreference(
        smsMfaSettings,
        softwareTokenMfaSettings,
        (err, results) => {
          if (err) {
            observer.error(err);
            return;
          }

          observer.next({ next: '', results });
        },
      );
    });
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    const user: CognitoUser = this.getUserPool().getCurrentUser();

    return new Observable((observer: Observer<string>) => {
      user.getSession((err, data: CognitoUserSession) => {
        if (err) {
          observer.error(err);
          return;
        }
        user.changePassword(oldPassword, newPassword, (er, result) => {
          if (er) {
            observer.error(er);
            return;
          }
          observer.next(result);
        });
      });
    });
  }

  /*
    getCurrentUser returns a Cognito user object if user is signed in
    or null-value if there is no current user
  */
  getUserDetails(): Observable<CognitoAccount> {
    return new Observable((observer: Observer<CognitoAccount>) => {
      const userDetails = this.getIdToken().payload;
      if (userDetails) {
        const result = {
          username: userDetails['cognito:username'],
          firstName: userDetails.name,
          lastName: userDetails.family_name,
          email: userDetails.email,
          cognitoId: userDetails.sub,
        };
        observer.next(result);
      } else {
        return observer.error(null);
      }
    });
  }

  getSignInUserSession(): Observable<CognitoUserSession | null> {
    return of(this.user.getSignInUserSession());
  }

  sessionIsValid(): boolean {
    const user: CognitoUser = this.getUserPool().getCurrentUser();
    let isValid = false;

    if (user) {
      user.getSession((err, session) => {
        if (err) {
          isValid = false;
        }

        isValid = session.isValid();
      });
    }

    return isValid;
  }

  getSessionValidity(): Observable<boolean> {
    const user: CognitoUser = this.getUserPool().getCurrentUser();

    if (user) {
      return new Observable((observer: Observer<boolean>) => {
        user.getSession((err, session) => {
          if (err) {
            return observer.error(err);
          }
          observer.next(session.isValid());
        });
      });
    } else {
      return of(false);
    }
  }

  getSession(): Observable<CognitoUserSession | null> {
    const user: CognitoUser = this.getUserPool().getCurrentUser();

    if (user) {
      return new Observable((observer: Observer<CognitoUserSession | null>) => {
        user.getSession((err, session) => {
          if (err) {
            return observer.error(err);
          }
          observer.next(session);
        });
      });
    } else {
      return throwError(null);
    }
  }

  getAccessToken(): CognitoAccessToken | null {
    const user: CognitoUser = this.getUserPool().getCurrentUser();
    let token: CognitoAccessToken | null = null;

    if (user) {
      user.getSession((err, session: CognitoUserSession) => {
        if (err) {
          return err;
        }
        token = session.getAccessToken();
      });
    }

    return token;
  }

  getIdToken(): CognitoIdToken | null {
    const user: CognitoUser = this.getUserPool().getCurrentUser();
    let token: CognitoIdToken | null = null;

    if (user) {
      user.getSession((err, session: CognitoUserSession) => {
        if (err) {
          return err;
        }
        token = session.getIdToken();
      });
    }

    return token;
  }

  getUserAttributes(): Observable<CognitoUserAttribute[]> {
    return new Observable((observer: Observer<CognitoUserAttribute[]>) => {
      this.user.getUserAttributes((err, result) => {
        if (err) {
          observer.error(err);
          return;
        }

        observer.next(result);
      });
    });
  }

  getUserData(): Observable<UserData> {
    const user: CognitoUser = this.getUserPool().getCurrentUser();

    return new Observable((observer: Observer<UserData>) => {
      user.getSession((err, data: CognitoUserSession) => {
        if (err) {
          observer.error(err);
          return;
        }

        user.refreshSession(data.getRefreshToken(), (refreshError, result) => {
          this.user = user;

          user.getUserData((userErr, userData) => {
            if (err) {
              console.log(userErr);
            }
            observer.next(userData);
          });
        });
      });
    });
  }

  hasMFA(): Observable<boolean> {
    const user: CognitoUser = this.getUserPool().getCurrentUser();

    return new Observable((observer: Observer<boolean>) => {
      user.getSession((err, data: CognitoUserSession) => {
        if (err) {
          observer.error(err);
          return;
        }

        user.refreshSession(data.getRefreshToken(), (refreshError, result) => {
          this.user = user;

          user.getUserData((userErr, userData) => {
            if (err) {
              console.log(userErr);
            }
            const isCustom =
              userData.UserAttributes.findIndex(
                (attribute) => attribute.Name === 'custom:metadata',
              ) !== -1;
            const setting = userData.PreferredMfaSetting ?? '';
            observer.next(setting.length > 0 || isCustom);
          });
        });
      });
    });
  }

  refreshSession(): void {
    const user: CognitoUser = this.getUserPool().getCurrentUser();
    user.getSession((err, data: CognitoUserSession) => {
      if (err) {
        console.log(err);
      }
      user.refreshSession(data.getRefreshToken(), (refreshError, result) => {
        this.user = user;
      });
    });
  }

  signOut(): Observable<void> {
    const user: CognitoUser = this.getUserPool().getCurrentUser();
    return of(user ? user.signOut() : null);
  }

  updateUserAttributes(
    attributes: ICognitoUserAttributeData[],
  ): Observable<string> {
    const user: CognitoUser = this.getUserPool().getCurrentUser();
    return new Observable((observer: Observer<string>) => {
      user.getSession((err, data: CognitoUserSession) => {
        if (err) {
          observer.error(err);
          return;
        }
        this.user = user;
        user.updateAttributes(attributes, (saveErr, result) => {
          if (saveErr) {
            observer.error(saveErr);
            return;
          }
          observer.next(result);
        });
      });
    });
  }

  verifyAttribute(
    attributeName: string,
    confirmationCode: string,
  ): Observable<string> {
    return new Observable((observer: Observer<string>) => {
      this.user.verifyAttribute(attributeName, confirmationCode, {
        onSuccess: (success) => observer.next(success),
        onFailure: (err) => observer.error(err),
      });
    });
  }

  private getUserPool(): CognitoUserPool {
    return new CognitoUserPool({
      UserPoolId: env.aws.userPoolId,
      ClientId: env.aws.clientId,
      Storage: sessionStorage,
    });
  }
}
