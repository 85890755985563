import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TableArchiveEffects } from './effects';
import { reducer, tableArchiveFeatureKey } from './reducer';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([TableArchiveEffects]),
    StoreModule.forFeature(tableArchiveFeatureKey, reducer),
  ],
})
export class TableArchiveModule {}
