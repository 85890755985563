<div class="app-content-status" *ngIf="loading || progress">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
<div
  class="animate__animated animate__faster animate__fadeIn app-content-wrapper"
  *ngIf="!loading"
>
  <div #contentContainerHeader class="app-content-header">
    <h1 *ngIf="contentContainerTitle" class="mat-h1">
      <ng-content
        select="app-content-container-title, [contentContainerTitle]"
      ></ng-content>
    </h1>
    <ng-content [select]="[app - content - header]"> </ng-content>
  </div>
  <ng-content></ng-content>
</div>
