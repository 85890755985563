import { Account, ApiError, StoreError } from '@core/models';
import { CognitoUserData } from '@core/services';

export interface State {
  account: Account;
  accountError: ApiError;
  accountLoading: boolean;
  mfaTypeError: string;
  mfaTypeLoading: boolean;
  updateAccountError: ApiError;
  updateAccountLoading: boolean;
  userData: CognitoUserData;
  userDataError: StoreError;
  userDataLoading: boolean;
  userDetails: Account;
  userDetailsError: StoreError;
  userDetailsLoading: boolean;
}

export const initialState: State = {
  account: null,
  accountError: null,
  accountLoading: false,
  mfaTypeError: '',
  mfaTypeLoading: false,
  updateAccountError: null,
  updateAccountLoading: false,
  userData: null,
  userDataError: null,
  userDataLoading: false,
  userDetails: null,
  userDetailsError: null,
  userDetailsLoading: false,
};
