import { Action, createReducer, on } from '@ngrx/store';
import { resetPortalStepper, updateStep, updateStepSuccess } from './actions';
import { State, initialState } from './state';

export const portalStepperFeatureKey = 'portalStepper';

const portalStepperReducer = createReducer(
  initialState,
  on(resetPortalStepper, () => initialState),
  on(updateStep, (state) => ({
    ...state,
  })),
  on(updateStepSuccess, (state, { stepIndex: currentStepIndex }) => ({
    ...state,
    currentStepIndex,
    maxStepIndex:
      state.maxStepIndex === null || currentStepIndex > state.maxStepIndex
        ? currentStepIndex
        : state.maxStepIndex,
  })),
);

export const reducer = (state: State | undefined, action: Action) =>
  portalStepperReducer(state, action);
