import {
  ApiError,
  KeyDate,
  OverviewReport,
  RemovedUserReport,
  ReportingPeriod,
} from '@core/models';

export interface State {
  removedUserReport: RemovedUserReport[];
  removedUserReportError: ApiError;
  removedUserReportLoading: boolean;
  closeSubmissionPeriodError: ApiError;
  closeSubmissionPeriodLoading: boolean;
  createKeyDateError: ApiError;
  createKeyDateLoading: boolean;
  createReportingPeriodError: ApiError;
  createReportingPeriodLoading: boolean;
  deleteKeyDateError: ApiError;
  deleteKeyDateLoading: boolean;
  deleteReportingPeriodError: ApiError;
  deleteReportingPeriodLoading: boolean;
  forceGrantSubmissionError: ApiError;
  forceGrantSubmissionLoading: boolean;
  generateDashboardDataError: ApiError;
  generateDashboardDataLoading: boolean;
  keyDates: KeyDate[];
  keyDatesError: ApiError;
  keyDatesLoading: boolean;
  overviewReport: OverviewReport[];
  overviewReportError: ApiError;
  overviewReportLoading: boolean;
  reportingPeriods: ReportingPeriod[];
  reportingPeriodsError: ApiError;
  reportingPeriodsLoading: boolean;
  updateKeyDateError: ApiError;
  updateKeyDateLoading: boolean;
  updateReportingPeriodError: ApiError;
  updateReportingPeriodLoading: boolean;
}

export const initialState: State = {
  removedUserReport: [],
  removedUserReportError: null,
  removedUserReportLoading: false,
  closeSubmissionPeriodError: null,
  closeSubmissionPeriodLoading: false,
  createKeyDateError: null,
  createKeyDateLoading: false,
  createReportingPeriodError: null,
  createReportingPeriodLoading: false,
  deleteKeyDateError: null,
  deleteKeyDateLoading: false,
  deleteReportingPeriodError: null,
  deleteReportingPeriodLoading: false,
  forceGrantSubmissionError: null,
  forceGrantSubmissionLoading: false,
  generateDashboardDataError: null,
  generateDashboardDataLoading: false,
  keyDates: [],
  keyDatesError: null,
  keyDatesLoading: false,
  overviewReport: [],
  overviewReportError: null,
  overviewReportLoading: false,
  reportingPeriods: [],
  reportingPeriodsError: null,
  reportingPeriodsLoading: false,
  updateKeyDateError: null,
  updateKeyDateLoading: false,
  updateReportingPeriodError: null,
  updateReportingPeriodLoading: false,
};
