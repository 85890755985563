import { State as AppState } from '@app/store/app-state';
import { ResourceAccessObject } from '@core/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { resourceAccessFeatureKey } from './reducer';
import { State } from './state';

const getResourceAccess = (state: State): ResourceAccessObject =>
  state.resourceAccess;
const getResourceAccessError = (state: State): string =>
  state.resourceAccessError;
const getResourceAccessLoading = (state: State): boolean =>
  state.resourceAccessLoading;

export const resourceAccessState = createFeatureSelector<AppState, State>(
  resourceAccessFeatureKey,
);

export const selectResourceAccess = createSelector(
  resourceAccessState,
  getResourceAccess,
);

export const selectResourceAccessError = createSelector(
  resourceAccessState,
  getResourceAccessError,
);
export const selectResourceAccessLoading = createSelector(
  resourceAccessState,
  getResourceAccessLoading,
);
