<div
  class="flex-column file-upload"
  matTooltip="Select a file or drag and drop a file here."
>
  <div
    class="drag-area"
    (drop)="drop($event)"
    (dragover)="drag($event)"
    (click)="dataFile.click()"
    (keyup.enter)="dataFile.click()"
    tabindex="0"
  >
    <div
      class="content-wrapper"
      [class.no-file-selected]="!validFile"
      [class.file-selected]="validFile"
    >
      <span class="label">Amendment File</span>
      <ng-container *ngIf="!validFile">
        <span class="icon-wrapper"><mat-icon>upload_file</mat-icon></span>
        <span class="label"
          >No file selected.<br />Select a file or drag and drop a file
          here.</span
        >
      </ng-container>
      <ng-container *ngIf="validFile">
        <span class="icon-wrapper"><mat-icon>done</mat-icon></span>
        <div class="flex-column file-info">
          <span
            ><label>File Name:</label> <span>{{ validFile.name }}</span></span
          >
          <span
            ><label>File Size:</label>
            <span>{{ byteConverter(validFile.size) }} KB</span></span
          >
        </div>
      </ng-container>
    </div>
    <label for="amendmentFile">
      <input
        #dataFile
        type="file"
        id="amendmentFile"
        hidden
        (change)="selectAmendmentFile($event.target.files[0])"
        accept=".pdf"
        [disabled]="disabled"
      />
    </label>
  </div>
</div>
