<div class="category mat-h1">Data Quality Issues</div>
<div class="description" *ngIf="issueGroups.length">
  <p>
    The following data quality issues were identified. If possible, please fix
    the issues by uploading a new data file and/or editing the numbers in the
    tables. Once data are updated, click
    <b>Check Data Quality</b> again to re-run checks. If you cannot fix an
    issue, please provide an explanation for OPA to review. Once all data
    quality issues are either fixed or have an explanation, click
    <b>Continue</b> to proceed to data submission.
  </p>
</div>
<div class="issue-list-container">
  <div *ngIf="issueGroups.length === 0" class="no-issues">
    <span><mat-icon>done</mat-icon></span>
    <span>No issues found.</span>
  </div>
  <div *ngFor="let group of issueGroups" class="issue-group">
    <div class="issue-group-header">
      <h3 class="group-label">
        {{ group.label }}
      </h3>
      <mat-chip-set readonly>
        <mat-chip class="issue-count">Issues: {{ group.issueCount }}</mat-chip>
      </mat-chip-set>
    </div>
    <div
      *ngFor="let issue of group.list; let index = index"
      class="issue-container"
    >
      <app-fpar-issue
        [canResolve]="canResolve"
        [issue]="issue"
        [index]="index"
        [grant]="{ grantId, tableId }"
        [readOnly]="readOnly"
        [reportingPeriodId]="reportingPeriodId"
        [systemForcedStatusChange]="systemForcedStatusChange"
        (editingComments)="setEditingComments($event)"
        (hold)="markOnHold(grantId, tableId, $event)"
        (resolve)="setResolution(grantId, $event)"
        (activateSubIssue)="onActivateSubIssue($event)"
      ></app-fpar-issue>
    </div>
  </div>
</div>
