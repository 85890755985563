import { State as AppState } from '@app/store/app-state';
import {
  ApiError,
  KeyDate,
  OverviewReport,
  RemovedUserReport,
  ReportingPeriod,
} from '@core/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { settingsFeatureKey } from './reducer';
import { State } from './state';

const getCloseSubmissionPeriodError = (state: State): ApiError =>
  state.closeSubmissionPeriodError;
const getCloseSubmissionPeriodLoading = (state: State): boolean =>
  state.closeSubmissionPeriodLoading;

const getCreateKeyDateError = (state: State): ApiError =>
  state.createKeyDateError;
const getCreateKeyDateLoading = (state: State): boolean =>
  state.createKeyDateLoading;

const getCreateReportingPeriodError = (state: State): ApiError =>
  state.createReportingPeriodError;
const getCreateReportingPeriodLoading = (state: State): boolean =>
  state.createReportingPeriodLoading;

const getDeleteKeyDateError = (state: State): ApiError =>
  state.deleteKeyDateError;
const getDeleteKeyDateLoading = (state: State): boolean =>
  state.deleteKeyDateLoading;

const getDeleteReportingPeriodError = (state: State): ApiError =>
  state.deleteReportingPeriodError;
const getDeleteReportingPeriodLoading = (state: State): boolean =>
  state.deleteReportingPeriodLoading;

const getForceGrantSubmissionError = (state: State): ApiError =>
  state.forceGrantSubmissionError;
const getForceGrantSubmissionLoading = (state: State): boolean =>
  state.forceGrantSubmissionLoading;

const getGenerateDashboardDataError = (state: State): ApiError =>
  state.generateDashboardDataError;
const getGenerateDashboardDataLoading = (state: State): boolean =>
  state.generateDashboardDataLoading;

const getRemovedUserReport = (state: State): RemovedUserReport[] =>
  state.removedUserReport;
const getRemovedUserReportError = (state: State): ApiError =>
  state.removedUserReportError;
const getRemovedUserReportLoading = (state: State): boolean =>
  state.removedUserReportLoading;

const getKeyDates = (state: State): KeyDate[] => state.keyDates;
const getKeyDatesError = (state: State): ApiError => state.keyDatesError;
const getKeyDatesLoading = (state: State): boolean => state.keyDatesLoading;

const getOverviewReport = (state: State): OverviewReport[] =>
  state.overviewReport;
const getOverviewReportError = (state: State): ApiError =>
  state.overviewReportError;
const getOverviewReportLoading = (state: State): boolean =>
  state.overviewReportLoading;

const getReportingPeriods = (state: State): ReportingPeriod[] =>
  state.reportingPeriods;
const getReportingPeriodsError = (state: State): ApiError =>
  state.reportingPeriodsError;
const getReportingPeriodsLoading = (state: State): boolean =>
  state.reportingPeriodsLoading;

const getUpdateKeyDateError = (state: State): ApiError =>
  state.updateKeyDateError;
const getUpdateKeyDateLoading = (state: State): boolean =>
  state.updateKeyDateLoading;

const getUpdateReportingPeriodError = (state: State): ApiError =>
  state.updateReportingPeriodError;
const getUpdateReportingPeriodLoading = (state: State): boolean =>
  state.updateReportingPeriodLoading;

export const settingsState = createFeatureSelector<AppState, State>(
  settingsFeatureKey,
);

export const selectCloseSubmissionPeriodError = createSelector(
  settingsState,
  getCloseSubmissionPeriodError,
);
export const selectCloseSubmissionPeriodLoading = createSelector(
  settingsState,
  getCloseSubmissionPeriodLoading,
);

export const selectCreateKeyDateError = createSelector(
  settingsState,
  getCreateKeyDateError,
);
export const selectCreateKeyDateLoading = createSelector(
  settingsState,
  getCreateKeyDateLoading,
);

export const selectCreateReportingPeriodError = createSelector(
  settingsState,
  getCreateReportingPeriodError,
);
export const selectCreateReportingPeriodLoading = createSelector(
  settingsState,
  getCreateReportingPeriodLoading,
);

export const selectDeleteKeyDateError = createSelector(
  settingsState,
  getDeleteKeyDateError,
);
export const selectDeleteKeyDateLoading = createSelector(
  settingsState,
  getDeleteKeyDateLoading,
);

export const selectDeleteReportingPeriodError = createSelector(
  settingsState,
  getDeleteReportingPeriodError,
);
export const selectDeleteReportingPeriodLoading = createSelector(
  settingsState,
  getDeleteReportingPeriodLoading,
);

export const selectForceGrantSubmissionError = createSelector(
  settingsState,
  getForceGrantSubmissionError,
);
export const selectForceGrantSubmissionLoading = createSelector(
  settingsState,
  getForceGrantSubmissionLoading,
);

export const selectGenerateDashboardDataError = createSelector(
  settingsState,
  getGenerateDashboardDataError,
);
export const selectGenerateDashboardDataLoading = createSelector(
  settingsState,
  getGenerateDashboardDataLoading,
);

export const selectRemovedUserReport = createSelector(
  settingsState,
  getRemovedUserReport,
);
export const selectRemovedUserReportError = createSelector(
  settingsState,
  getRemovedUserReportError,
);
export const selectRemovedUserReportLoading = createSelector(
  settingsState,
  getRemovedUserReportLoading,
);

export const selectKeyDates = createSelector(settingsState, getKeyDates);
export const selectKeyDatesError = createSelector(
  settingsState,
  getKeyDatesError,
);
export const selectKeyDatesLoading = createSelector(
  settingsState,
  getKeyDatesLoading,
);

export const selectOverviewReport = createSelector(
  settingsState,
  getOverviewReport,
);
export const selectOverviewReportError = createSelector(
  settingsState,
  getOverviewReportError,
);
export const selectOverviewReportLoading = createSelector(
  settingsState,
  getOverviewReportLoading,
);

export const selectReportingPeriods = createSelector(
  settingsState,
  getReportingPeriods,
);
export const selectReportingPeriodsError = createSelector(
  settingsState,
  getReportingPeriodsError,
);
export const selectReportingPeriodsLoading = createSelector(
  settingsState,
  getReportingPeriodsLoading,
);

export const selectUpdateKeyDateError = createSelector(
  settingsState,
  getUpdateKeyDateError,
);
export const selectUpdateKeyDateLoading = createSelector(
  settingsState,
  getUpdateKeyDateLoading,
);

export const selectUpdateReportingPeriodError = createSelector(
  settingsState,
  getUpdateReportingPeriodError,
);
export const selectUpdateReportingPeriodLoading = createSelector(
  settingsState,
  getUpdateReportingPeriodLoading,
);
