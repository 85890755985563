import { PortalStep } from '@app/shared/enums';
import { createAction, props } from '@ngrx/store';

export enum PortalStepperActionTypes {
  RESET_PORTAL_STEPPER = '[Portal Stepper] Reset Portal Stepper',
  UPDATE_STEP_REQUEST = '[Portal Stepper] Update Step Request',
  UPDATE_STEP_SUCCESS = '[Portal Stepper] Update Step Success',
}

export const resetPortalStepper = createAction(
  PortalStepperActionTypes.RESET_PORTAL_STEPPER,
);

export const updateStep = createAction(
  PortalStepperActionTypes.UPDATE_STEP_REQUEST,
  props<{ stepIndex: PortalStep }>(),
);
export const updateStepSuccess = createAction(
  PortalStepperActionTypes.UPDATE_STEP_SUCCESS,
  props<{ stepIndex: PortalStep }>(),
);
