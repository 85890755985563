import { ApiError, ReportingPeriod, ReportingPeriodState } from '@core/models';

export interface State {
  createReportingPeriodError: ApiError;
  createReportingPeriodLoading: boolean;
  deleteReportingPeriodError: ApiError;
  deleteReportingPeriodLoading: boolean;
  reportingPeriods: ReportingPeriod[];
  reportingPeriodsError: ApiError;
  reportingPeriodsLoading: boolean;
  reportingPeriodState: ReportingPeriodState;
  reportingPeriodStateError: ApiError;
  reportingPeriodStateLoading: boolean;
  updateReportingPeriodError: ApiError;
  updateReportingPeriodLoading: boolean;
}

export const initialState: State = {
  createReportingPeriodError: null,
  createReportingPeriodLoading: false,
  deleteReportingPeriodError: null,
  deleteReportingPeriodLoading: false,
  reportingPeriods: [],
  reportingPeriodsError: null,
  reportingPeriodsLoading: false,
  reportingPeriodState: null,
  reportingPeriodStateError: null,
  reportingPeriodStateLoading: false,
  updateReportingPeriodError: null,
  updateReportingPeriodLoading: false,
};
