import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@core/models';
import { environment as env } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FparReportService {
  private baseUrl = `${env.apiURL}api/v1/data-submission-report`;
  constructor(private http: HttpClient) {}
  public getFparReport(
    grantId: number,
    reportingPeriodId?: number,
  ): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(`${this.baseUrl}/${grantId}`, {
      params: { reportingPeriodId },
    });
  }
}
