import { Action, createReducer, on } from '@ngrx/store';
import {
  resetClinicStepperSuccess,
  updateStep,
  updateStepSuccess,
} from './actions';
import { initialState, State } from './state';

export const clinicStepperFeatureKey = 'clinicStepper';

const clinicStepperReducer = createReducer(
  initialState,
  on(resetClinicStepperSuccess, (state) => ({
    ...state,
    currentStepIndex: null,
    maxStepIndex: null,
  })),

  on(updateStep, (state) => ({
    ...state,
  })),
  on(updateStepSuccess, (state, { stepIndex }) => ({
    ...state,
    currentStepIndex: stepIndex,
    maxStepIndex:
      state.maxStepIndex === null || stepIndex > state.maxStepIndex
        ? stepIndex
        : state.maxStepIndex,
  })),
);

export const reducer = (state: State | undefined, action: Action) =>
  clinicStepperReducer(state, action);
