export class StringUtil {
  public static isHtml(text: string): boolean {
    return /<(?=.*? .*?\/ ?>|br|hr|input|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>/i.test(
      text,
    );
  }

  public static isString(text: any): boolean {
    return typeof text === 'string';
  }

  public static padStart(
    str: string,
    targetLength: number,
    padString: string,
  ): string {
    padString = String(typeof padString !== 'undefined' ? padString : ' ');
    if (str.length > targetLength) {
      return str;
    } else {
      targetLength = targetLength - str.length;
      if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length); // append to original to ensure we are longer than needed
      }
      return padString.slice(0, targetLength) + str;
    }
  }

  public static paramsToQueryString(params?: any): string {
    if (!params) {
      return '';
    }
    return Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
  }
}
