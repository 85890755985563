<div class="issue-container">
  <div class="row">
    <div class="index">
      <div class="issue-index">#{{ index + 1 }}</div>
    </div>
    <div class="column">
      <div class="issue-description">{{ issue.displayMessage }}</div>
      <div class="issue-status" [class.invisible]="!issueStatus.length">
        <mat-chip-set readonly>
          <mat-chip class="newIssue" *ngIf="issue.newIssue">{{
            IssueStatuses.NewIssue
          }}</mat-chip>
          <mat-chip [ngClass]="issueStatus.toLowerCase()">{{
            issueStatus
          }}</mat-chip>
        </mat-chip-set>
      </div>
      <app-fpar-sub-issue
        [canResolve]="canResolve"
        [subIssues]="issue.tableCellIssues"
        *ngIf="issue.tableCellIssues.length > 0"
        class="sub-issue"
        (activateSubIssue)="onActivateSubIssue($event)"
      >
      </app-fpar-sub-issue>
      <ng-container *ngIf="issueComments$ | async as comments">
        <div class="comments" *ngFor="let comment of comments">
          <app-fpar-comment
            [allowReplies]="
              !readOnly && (canResolve || issueStatus === IssueStatuses.OnHold)
            "
            [comment]="comment"
            [issueResolved]="issue.resolved"
            [saving]="saving$ | async"
            [readOnly]="readOnly || issue.resolved"
            (save)="
              saveComment(grant.grantId, grant.tableId, issue.checkId, $event)
            "
            (edit)="setEditingComment($event)"
            (delete)="
              deleteComment(grant.grantId, grant.tableId, issue.checkId, $event)
            "
          >
          </app-fpar-comment>
        </div>
      </ng-container>
      <ng-container *ngIf="forcedSystemUpdateNoComments()">
        <app-banner
          class="no-comment-notification"
          [type]="notificationTypes.INFO"
          >A comment was not provided for this data quality issue at the time
          the data was submitted for OPA review.</app-banner
        >
        <div class="comments">
          <app-fpar-comment
            [allowReplies]="
              !readOnly && (canResolve || issueStatus === IssueStatuses.OnHold)
            "
            [issueResolved]="issue.resolved"
            [saving]="saving$ | async"
            [readOnly]="readOnly || issue.resolved"
            (save)="
              saveComment(grant.grantId, grant.tableId, issue.checkId, $event)
            "
            (edit)="setEditingComment($event)"
            (delete)="
              deleteComment(grant.grantId, grant.tableId, issue.checkId, $event)
            "
          >
          </app-fpar-comment>
        </div>
      </ng-container>

      <div class="resolution">
        <button
          *ngIf="!readOnly && !canResolve && !hasComments && !issue.resolved"
          type="button"
          mat-flat-button
          color="accent"
          (click)="setOnHold()"
        >
          <mat-icon>flag</mat-icon><span>Flag issue</span>
        </button>
        <button
          *ngIf="canResolve && issue.resolved"
          type="button"
          mat-stroked-button
          color="default"
          (click)="setResolved()"
        >
          <mat-icon>undo</mat-icon>
          <span>Unresolve issue</span>
        </button>
        <button
          *ngIf="canResolve && !issue.resolved"
          type="button"
          mat-flat-button
          color="primary"
          (click)="setResolved()"
        >
          <mat-icon>verified_user</mat-icon>
          <span>Resolve issue</span>
        </button>
      </div>
    </div>
  </div>
</div>
