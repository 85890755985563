import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'dataQualityIssues',
})
export class DataQualityIssuesPipe implements PipeTransform {
  transform(value: number): string {
    const icon = 'warning';
    const issueOrIssues = value === 1 ? 'issue' : 'issues';
    const matIcon: SafeHtml = icon
      ? `<span class=\"material-icons-outlined \">${icon}</span>`
      : '';
    return value > 0
      ? `${matIcon} <span>${value} ${issueOrIssues}</span>`.trimLeft()
      : '';
  }
}
