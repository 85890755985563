<div *ngIf="displayOnly" class="display-only" [class.highlight]="hasSubIssue">
  <span *ngIf="isCurrency && displayValue">$&nbsp;</span
  ><span>{{ displayValue }}</span>
</div>
<mat-form-field
  *ngIf="!displayOnly"
  appearance="outline"
  class="width-auto"
  [class.highlight]="hasSubIssue"
>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input
    matInput
    type="text"
    title="{{ label }}"
    [formControl]="numberControl"
    (change)="onChange()"
    (blur)="onTouched()"
    (keyup)="onChange()"
    [required]="required"
  />
  <span matPrefix *ngIf="isCurrency">$&nbsp;</span>
  <mat-error *ngIf="hasError('required')">
    {{ getLabel() }} is required.
  </mat-error>
  <mat-error *ngIf="hasError('minimumValue')">
    Must be greater than {{ min }}.
  </mat-error>
  <mat-error *ngIf="hasError('maximumValue')">
    Must be less than {{ max }}.
  </mat-error>
  <mat-error *ngIf="hasError('numericValue')">
    Must be a numeric value.
  </mat-error>
  <mat-error *ngIf="hasError('positiveNumber')">
    Must be a postive number.
  </mat-error>
</mat-form-field>
