import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ReportingPeriodEffects } from './effects';
import { reducer, reportingPeriodFeatureKey } from './reducer';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([ReportingPeriodEffects]),
    StoreModule.forFeature(reportingPeriodFeatureKey, reducer),
  ],
})
export class ReportingPeriodModule {}
