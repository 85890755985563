import { Injectable } from '@angular/core';
import { environment as env } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  get appTitle(): string {
    return env.appTitle;
  }
}
