import { ResourceAccessObject } from '@core/models';
import { Action, createReducer, on } from '@ngrx/store';
import {
  getResourceAccess,
  getResourceAccessFailure,
  getResourceAccessSuccess,
} from './actions';
import { initialState, State } from './state';

export const resourceAccessFeatureKey = 'resourceAccess';

// Function to map the user permissions array to a resource access object
const accessArrayToObject = (array: string[]): ResourceAccessObject =>
  array.reduce((obj: ResourceAccessObject, item: string) => {
    obj[item] = true;
    return obj;
  }, {});

const resourcesReducer = createReducer(
  initialState,
  on(getResourceAccess, (state) => ({
    ...state,
    getResourceAccessLoading: true,
  })),
  on(getResourceAccessFailure, (state, { message }) => ({
    ...state,
    resources: null,
    getResourceAccessError: message,
    getResourceAccessLoading: false,
  })),
  on(getResourceAccessSuccess, (state, { resourceAccess }) => ({
    ...state,
    resourceAccess: accessArrayToObject(resourceAccess.data),
    getResourceAccessLoading: false,
  })),
);

export const reducer = (state: State | undefined, action: Action) =>
  resourcesReducer(state, action);
