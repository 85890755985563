import { State as AppState } from '@app/store/app-state';
import {
  ApiError,
  FparTable,
  GrantSubmission,
  GrantSubmissionTableStatus,
  ReportingPeriod,
} from '@core/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { tableArchiveFeatureKey } from './reducer';
import { State } from './state';

const getTableOverview = (
  state: State,
): GrantSubmission<GrantSubmissionTableStatus> => state.tableOverview;
const getTableOverviewError = (state: State): ApiError =>
  state.tableOverviewError;
const getTableOverviewLoading = (state: State): boolean =>
  state.tableOverviewLoading;
const getReportingPeriods = (state: State): ReportingPeriod[] =>
  state.reportingPeriods;
const getReportingPeriodsError = (state: State): ApiError =>
  state.reportingPeriodsError;
const getReportingPeriodsLoading = (state: State): boolean =>
  state.reportingPeriodsLoading;

const getSelectedGrantId = (state: State): number => state.selectedGrantId;
const getSelectedReportingPeriodId = (state: State): number =>
  state.selectedReportingPeriodId;

const getTable = (state: State): FparTable => state.table;
const getTableError = (state: State): ApiError => state.tableError;
const getTableLoading = (state: State): boolean => state.tableLoading;

export const tableArchiveState = createFeatureSelector<AppState, State>(
  tableArchiveFeatureKey,
);

export const selectTableOverview = createSelector(
  tableArchiveState,
  getTableOverview,
);
export const selectTableOverviewError = createSelector(
  tableArchiveState,
  getTableOverviewError,
);
export const selectTableOverviewLoading = createSelector(
  tableArchiveState,
  getTableOverviewLoading,
);

export const selectReportingPeriods = createSelector(
  tableArchiveState,
  getReportingPeriods,
);
export const selectReportingPeriodsError = createSelector(
  tableArchiveState,
  getReportingPeriodsError,
);
export const selectReportingPeriodsLoading = createSelector(
  tableArchiveState,
  getReportingPeriodsLoading,
);

export const selectSelectedGrantId = createSelector(
  tableArchiveState,
  getSelectedGrantId,
);
export const selectSelectedReportingPeriodId = createSelector(
  tableArchiveState,
  getSelectedReportingPeriodId,
);

export const selectTable = createSelector(tableArchiveState, getTable);
export const selectTableError = createSelector(
  tableArchiveState,
  getTableError,
);
export const selectTableLoading = createSelector(
  tableArchiveState,
  getTableLoading,
);
