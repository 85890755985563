import { Action, createReducer, on } from '@ngrx/store';
import { getSelectedGrantFailure } from '../grant/actions';
import {
  clearSelectedGrantSuccess,
  clearSelectedTable,
  getSelectedGrantSuccess,
  setSelectedGrantFailure,
  setSelectedGrantSuccess,
  setSelectedTable,
} from './actions';
import { initialState, State } from './state';

export const portalReviewFeatureKey = 'portalReview';

const portalReviewReducer = createReducer(
  initialState,
  on(clearSelectedGrantSuccess, (state) => ({
    ...state,
    currentGrant: null,
  })),
  on(clearSelectedTable, (state) => ({
    ...state,
    currentTable: null,
  })),

  on(
    getSelectedGrantFailure,
    setSelectedGrantFailure,
    (state, { message }) => ({
      ...state,
      getSetSelectedGrantError: message,
    }),
  ),

  on(
    getSelectedGrantSuccess,
    setSelectedGrantSuccess,
    (state, { currentGrant }) => ({
      ...state,
      currentGrant,
    }),
  ),
  on(setSelectedTable, (state, { currentTable }) => ({
    ...state,
    currentTable,
  })),
);

export const reducer = (state: State | undefined, action: Action) =>
  portalReviewReducer(state, action);
