import { Action, createReducer, on } from '@ngrx/store';
import {
  getFparReport,
  getFparReportFailure,
  getFparReportSuccess,
} from './actions';
import { initialState, State } from './state';

export const fparReportFeatureKey = 'fparReport';

const fparReportReducer = createReducer(
  initialState,

  on(getFparReport, (state) => ({
    ...state,
    getFparReportLoading: true,
  })),
  on(getFparReportFailure, (state, { error, message }) => ({
    ...state,
    getFparReportError: { error, message },
    getFparReportLoading: false,
  })),
  on(getFparReportSuccess, (state, { response }) => ({
    ...state,
    getFparReportError: null,
    getFparReportLoading: false,
    fparReportData: response.data,
  })),
);

export const reducer = (state: State | undefined, action: Action) =>
  fparReportReducer(state, action);
