import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppStoreState, ReportingPeriodSelectors } from '@app/store';
import { ReportingPeriodState } from '@core/models';
import { Store } from '@ngrx/store';
import { Observable, Subject, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-fpar-banner',
  templateUrl: './fpar-banner.component.html',
  styleUrls: ['./fpar-banner.component.scss'],
})
export class FparBannerComponent implements OnDestroy, OnInit {
  public dataAvailable: boolean;
  public reportingPeriodStateLoading$: Observable<boolean>;
  public submissionOpen: boolean;
  public validationOpen: boolean;

  private destroyed$ = new Subject<boolean>();
  private reportingPeriodState$: Observable<ReportingPeriodState>;

  constructor(private store$: Store<AppStoreState.State>) {
    this.reportingPeriodState$ = this.store$.select(
      ReportingPeriodSelectors.selectReportingPeriodState,
    );
    this.reportingPeriodStateLoading$ = this.store$.select(
      ReportingPeriodSelectors.selectReportingPeriodStateLoading,
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.reportingPeriodState$
      .pipe(
        filter((reportingPeriodState) => !!reportingPeriodState),
        takeUntil(this.destroyed$),
      )
      .subscribe((reportingPeriodState) => {
        const { dataAvailable, submissionOpen, validationOpen } =
          reportingPeriodState;
        this.dataAvailable = dataAvailable;
        this.submissionOpen = submissionOpen;
        this.validationOpen = validationOpen;
      });
  }
}
