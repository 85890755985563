import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FparTablesEffects } from './effects';
import { fparTableFeatureKey, reducer } from './reducer';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([FparTablesEffects]),
    StoreModule.forFeature(fparTableFeatureKey, reducer),
  ],
})
export class FparTableModule {}
