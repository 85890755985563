import { NotificationTypes } from '@app/shared/enums';
import { INotification } from '@core/models';
import { createAction, props } from '@ngrx/store';

export enum NotificationActionTypes {
  ADD_NOTIFICATION_REQUEST = '[Notification] Add Notification',
  ADD_NOTIFICATION_FAILURE = '[Notification] Add Notification Failure',
  ADD_NOTIFICATION_SUCCESS = '[Notification] Add Notification Success',
  GET_NOTIFICATIONS_REQUEST = '[Notification] Get All Notifications',
  GET_NOTIFICATIONS_FAILURE = '[Notification] Get All Notifications Failure',
  GET_NOTIFICATIONS_SUCCESS = '[Notification] Get All Notifications Success',
  REMOVE_NOTIFICATION_REQUEST = '[Notification] Remove Notification',
  REMOVE_NOTIFICATION_FAILURE = '[Notification] Remove Notification Failure',
  REMOVE_NOTIFICATION_SUCCESS = '[Notification] Remove Notification Success',
  RESET_NOTIFICATIONS_REQUEST = '[Notification] Reset Notifications',
  RESET_NOTIFICATIONS_FAILURE = '[Notification] Reset Notifications Failure',
  RESET_NOTIFICATIONS_SUCCESS = '[Notification] Reset Notifications Success',
}

export const add = createAction(
  NotificationActionTypes.ADD_NOTIFICATION_REQUEST,
  props<{ notificationType: NotificationTypes; notificationText: string }>(),
);
export const addFailure = createAction(
  NotificationActionTypes.ADD_NOTIFICATION_FAILURE,
  props<{ message: string }>(),
);
export const addSuccess = createAction(
  NotificationActionTypes.ADD_NOTIFICATION_SUCCESS,
  props<{ notifications: INotification[] }>(),
);

export const remove = createAction(
  NotificationActionTypes.REMOVE_NOTIFICATION_REQUEST,
  props<{ id: number }>(),
);
export const removeFailure = createAction(
  NotificationActionTypes.REMOVE_NOTIFICATION_FAILURE,
  props<{ message: string }>(),
);
export const removeSuccess = createAction(
  NotificationActionTypes.REMOVE_NOTIFICATION_SUCCESS,
  props<{ notifications: INotification[] }>(),
);

export const reset = createAction(
  NotificationActionTypes.RESET_NOTIFICATIONS_REQUEST,
);
export const resetFailure = createAction(
  NotificationActionTypes.RESET_NOTIFICATIONS_FAILURE,
  props<{ message: string }>(),
);
export const resetSuccess = createAction(
  NotificationActionTypes.RESET_NOTIFICATIONS_SUCCESS,
  props<{ notifications: INotification[] }>(),
);

export const getNotifications = createAction(
  NotificationActionTypes.GET_NOTIFICATIONS_REQUEST,
);
export const getNotificationsFailure = createAction(
  NotificationActionTypes.GET_NOTIFICATIONS_FAILURE,
  props<{ message: string }>(),
);
export const getNotificationsSuccess = createAction(
  NotificationActionTypes.GET_NOTIFICATIONS_SUCCESS,
  props<{ notifications: INotification[] }>(),
);
