import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IdleTimerService {
  private seconds = 0;
  private count = 0;
  private timerSubscription: Subscription;
  private timer: Observable<number>;
  private resetOnTrigger = false;
  private timerExpired: Subject<number> = new Subject<number>();

  constructor() {}

  public startTimer(seconds: number): Observable<number> {
    if (
      !this.timerSubscription ||
      (this.timerSubscription && this.timerSubscription.closed)
    ) {
      this.seconds = seconds;

      this.timer = timer(this.seconds * 1000);
      this.timerSubscription = this.timer.subscribe((n) => {
        this.timerComplete(n);
      });
    }

    return this.timerExpired;
  }

  public stopTimer(): void {
    this.timerSubscription?.unsubscribe();
  }

  public resetTimer(): void {
    if (this.seconds === 0) {
      return;
    }

    if (this.timerSubscription) {
      this.stopTimer();
      this.startTimer(this.seconds);
    }
  }

  private timerComplete(n: number): void {
    this.timerExpired.next(++this.count);

    if (this.resetOnTrigger) {
      this.startTimer(this.seconds);
    }
  }
}
