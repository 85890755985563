import {
  ApiError,
  CldbHistoryOfChangesEntry,
  ClinicServices,
  Site,
  SiteDetails,
  UserGrantSelection,
} from '@core/models';

export interface State {
  associatedGrant: UserGrantSelection;
  associatedGrantError: ApiError;
  associatedGrantLoading: boolean;
  clinicServices: ClinicServices[];
  createSiteError: ApiError;
  createSiteLoading: boolean;
  currentSiteId: string;
  getClinicServicesError: ApiError;
  getClinicServicesLoading: boolean;
  historyOfChanges: CldbHistoryOfChangesEntry[];
  historyOfChangesError: ApiError;
  historyOfChangesLoading: boolean;
  noaFile: File;
  publishSiteError: ApiError;
  publishSiteLoading: boolean;
  removeSiteError: ApiError;
  removeSiteLoading: boolean;
  siteDetails: SiteDetails;
  siteDetailsError: ApiError;
  siteDetailsLoading: boolean;
  sites: Site[];
  sitesError: ApiError;
  sitesLoading: boolean;
  updateServicesError: ApiError;
  updateServicesLoading: boolean;
  updateSiteError: ApiError;
  updateSiteHoursError: ApiError;
  updateSiteHoursLoading: boolean;
  updateSiteLoading: boolean;
}

export const initialState: State = {
  associatedGrant: {
    grantId: null,
    subrecipientId: null,
  },
  associatedGrantError: null,
  associatedGrantLoading: false,
  clinicServices: null,
  createSiteError: null,
  createSiteLoading: false,
  currentSiteId: null,
  getClinicServicesError: null,
  getClinicServicesLoading: false,
  historyOfChanges: [],
  historyOfChangesError: null,
  historyOfChangesLoading: false,
  noaFile: null,
  publishSiteError: null,
  publishSiteLoading: false,
  removeSiteError: null,
  removeSiteLoading: false,
  siteDetails: null,
  siteDetailsError: null,
  siteDetailsLoading: false,
  sites: [],
  sitesError: null,
  sitesLoading: false,
  updateServicesError: null,
  updateServicesLoading: false,
  updateSiteError: null,
  updateSiteHoursError: null,
  updateSiteHoursLoading: false,
  updateSiteLoading: false,
};
