import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PortalStepperEffects } from './effects';
import { portalStepperFeatureKey, reducer } from './reducer';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([PortalStepperEffects]),
    StoreModule.forFeature(portalStepperFeatureKey, reducer),
  ],
})
export class PortalStepperModule {}
