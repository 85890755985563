import { SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  Output,
  PipeTransform,
  ProviderToken,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SafeHtml } from '@angular/platform-browser';
import { GrantSubmissionStatus } from '@app/shared/enums';
import { AppInjector } from '@app/shared/utilities';
import { TableColumn } from '@core/models';
import { PageSizeOptions } from '../paging/page-size-options';

export interface DataTableOptions {
  ariaLabelProperty?: string;
  ariaLabelPipe?: ProviderToken<any>;
  ariaLabelPipeArgs?: any | any[];
  disableRow?: boolean; // apply opacity to non-selectable rows
  disableSort?: boolean;
  displayedColumns: string[];
  hidePaginator?: boolean;
  hideSearch?: boolean;
  isHtml?: boolean;
  multiSelect?: boolean; // multi select will supercede single select
  searchWidth?: string;
  singleSelect?: boolean;
  skipScrollMargin?: boolean;
  selectedRows?: any[];
  sortActive?: string;
  sortDirection?: SortDirection;
  tableColumns: TableColumn[];
  tableFooter?: string[];
  wrapHeaderText?: boolean;
}

const DISABLED_ROW = 'disabled-row';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent implements OnChanges {
  @Input() dataSource: MatTableDataSource<any>;
  @Input() options: DataTableOptions;
  @Input() isRowSelectable: (row: any) => boolean;
  @Input() selectableRows = false;
  @Input() hasSubrecipients = false;

  @Output() rowSelectionChange: EventEmitter<any> = new EventEmitter();
  @Output() rowTouched: EventEmitter<any> = new EventEmitter();
  @Output() searchFilterChange: EventEmitter<string> = new EventEmitter();

  public pageSizeOptions = PageSizeOptions;
  public selection: SelectionModel<any> = new SelectionModel(true, []);

  private injector: Injector;
  private matSort: MatSort;
  private searchText: string;

  constructor() {
    this.injector = AppInjector.get();
  }

  public get disableRow(): boolean {
    return !!this.options?.disableRow;
  }

  public get disableSort(): boolean {
    return !!this.options?.disableSort;
  }

  public get displayedColumns(): string[] {
    return this.multiSelect || this.singleSelect
      ? ['selectRow', ...(this.options?.displayedColumns || [])]
      : this.options?.displayedColumns || [];
  }

  public get hidePaginator(): boolean {
    return !!this.options?.hidePaginator;
  }

  public get hideSearch(): boolean {
    return !!this.options?.hideSearch;
  }

  public get multiSelect(): boolean {
    return this.options?.multiSelect || false;
  }

  public get searchWidth(): string {
    return this.options?.searchWidth || '100%';
  }

  public get singleSelect(): boolean {
    return this.options?.singleSelect || false;
  }

  public get skipScrollMargin(): boolean {
    return !!this.options?.skipScrollMargin;
  }

  public get sortActive(): string | undefined {
    return this.options?.sortActive;
  }

  public get sortDirection(): 'asc' | 'desc' {
    return this.options?.sortDirection || 'asc';
  }

  public get tableColumns(): TableColumn[] {
    return this.options?.tableColumns;
  }

  public get showTableTenFootnote(): boolean {
    return this.dataSource.data.some((datum) => datum.tableName === 'Table 10');
  }

  private get ariaLabelProperty(): string {
    return this.options?.ariaLabelProperty;
  }

  private get ariaLabelPipe(): ProviderToken<any> {
    return this.options?.ariaLabelPipe;
  }

  private get ariaLabelPipeArgs(): any | any[] {
    return this.options?.ariaLabelPipeArgs;
  }

  private get selectedRows(): any[] {
    return this.options?.selectedRows || [];
  }

  @ViewChild(MatPaginator) set paginator(matPaginator: MatPaginator) {
    if (this.dataSource) {
      this.dataSource.paginator = matPaginator;
    }
  }
  @ViewChild(MatSort) set sort(matSort: MatSort) {
    this.matSort = matSort;
    if (this.dataSource) {
      this.dataSource.sort = matSort;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selection.clear();
    if (this.selectedRows) {
      this.selection.select(...this.selectedRows);
    }
    if (this.dataSource) {
      if (this.matSort) {
        this.dataSource.sort = this.matSort;
      }

      if (!changes.dataSource.isFirstChange()) {
        this.dataSource.filter = this.searchText || '';
      }
    }
  }

  public allRowsSelected(): boolean {
    return this.selection.selected.length === this.dataSource.data.length;
  }

  public applyFilter(filterValue: string): void {
    this.dataSource.filter = (filterValue || '').trim().toLowerCase() || '';
    this.searchText = this.dataSource.filter;
    this.searchFilterChange.emit(this.dataSource.filter);
  }

  public getColumnStyle(column: TableColumn): Record<string, string> {
    return {
      'max-width': column.width || column.maxWidth || 'auto',
      'min-width': column.width || column.minWidth || 'auto',
      width: column.width || 'auto',
    };
  }

  public getIcon(element: any, column: TableColumn): string {
    return !column.icon
      ? ''
      : typeof column.icon === 'string'
        ? column.icon
        : column.icon(element);
  }

  public getIconColor(element: any, column: TableColumn): string {
    return !column.iconColor
      ? ''
      : typeof column.iconColor === 'string'
        ? column.iconColor
        : column.iconColor(element);
  }

  public getElementValue(element: any, column: TableColumn): string | SafeHtml {
    const value = column.isDate
      ? element[column.column] + '+0000'
      : element[column.column];

    const { pipe: pipeToken, pipeArgs, pipeElement } = column;
    const pipe = this.getPipe(pipeToken);
    return pipe
      ? pipe.transform(pipeElement ? element : value, pipeArgs)
      : value;
  }

  public getRowClass(row: any): string {
    const rowClass = [];
    // row classes related to selectable tables
    if (
      this.selectableRows ||
      (this.isRowSelectable && this.isRowSelectable(row))
    ) {
      rowClass.push('selectable-row');
    } else if (
      this.disableRow &&
      this.isRowSelectable &&
      !this.isRowSelectable(row)
    ) {
      rowClass.push(DISABLED_ROW);
    }
    // row classes related to subrecipiants
    if (this.hasSubrecipients && row.siteType === 'Subrecipient') {
      rowClass.push('subrecipient');
    }
    if (this.hasSubrecipients && row.parentSubrecipientId) {
      rowClass.push('child-service-site');
    }
    return rowClass.join(' ');
  }

  /* The label for the checkbox on the passed row */
  public inputAriaLabel(row?: any): string {
    const rowClass = this.getRowClass(row);
    if (
      !this.selectableRows ||
      rowClass === '' ||
      rowClass.split(' ').includes(DISABLED_ROW)
    ) {
      return;
    }
    return !row
      ? `${!this.allRowsSelected() ? 'Select' : 'Deselect'} all`
      : `${!this.selection.isSelected(row) ? 'Select' : 'Deselect'} ${
          this.formatRowLabel(row) || 'row'
        }`;
  }

  public readyForReviewRow(reviewStatus: string): boolean {
    return reviewStatus === GrantSubmissionStatus.ReadyForReview;
  }

  public onRowSelected(row: any): void {
    this.selection.toggle(row);
    this.rowSelectionChange.emit(this.selection.selected);
    this.rowTouched.emit(row);
  }

  public toggleAllRows(): void {
    if (this.allRowsSelected()) {
      this.selection.clear();
    } else {
      this.selection.select(...this.dataSource.filteredData);
    }
    this.rowSelectionChange.emit(this.selection.selected);
  }

  private formatRowLabel(row: any): string {
    if (!this.ariaLabelProperty && !this.ariaLabelPipe) {
      return '';
    }
    const value =
      row && this.ariaLabelProperty ? row[this.ariaLabelProperty] : row;
    const pipe = this.getPipe(this.ariaLabelPipe);

    return pipe ? pipe.transform(value, this.ariaLabelPipeArgs) : value;
  }

  private getPipe(pipeToken: ProviderToken<any>): PipeTransform | undefined {
    return pipeToken ? this.injector.get<any>(pipeToken) : undefined;
  }
}
