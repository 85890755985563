// Configuration file for application-specific settings
import { ApplicationConfiguration } from '@core/models';
import versions from './_versions';

export const config: ApplicationConfiguration = {
  apiURL: 'https://a6bqhfqbd1.execute-api.us-east-1.amazonaws.com/qa/',
  appDescription: 'Family Planning Annual Report Data System',
  appOwner: 'Mathematica',
  appRoutes: {
    signIn: '/auth/signin',
    signOut: '/auth/signout',
  },
  appTitle: 'FPAR',
  aws: {
    clientId: '7notjfmkjjh4ie4hobbasjb470',
    region: 'us-east-1',
    userPoolId: 'us-east-1_zHdCOcFpz',
  },
  debugMode: true,
  production: false,
  serviceWorker: false,
  staging: false,
  timeoutLength: 14,
  version: versions.version,
};
