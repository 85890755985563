import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  ReportingPeriodApiResponse,
  ReportingPeriodPayload,
  ReportingPeriodStateApiResponse,
} from '@core/models';
import { environment as env } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportingPeriodService {
  private baseUrl = `${env.apiURL}api/v1`;
  private reportingPeriods = 'reporting-periods';
  constructor(private http: HttpClient) {}

  public closeSubmissionPeriod(
    sendNotificationEmail: boolean,
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      `${this.baseUrl}/close-submission-period`,
      { submissionOpen: false, sendNotificationEmail },
    );
  }

  public create(payload: ReportingPeriodPayload): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      `${this.baseUrl}/${this.reportingPeriods}`,
      payload,
    );
  }

  public delete(id: number | string): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      `${this.baseUrl}/${this.reportingPeriods}/${id}`,
    );
  }

  public generateDashboardData(id: number | string): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      `${this.baseUrl}/generate-dashboard-data/${id}`,
      {
        dataAvailable: true,
      },
    );
  }

  public getAll(): Observable<ReportingPeriodApiResponse> {
    return this.http.get<ReportingPeriodApiResponse>(
      `${this.baseUrl}/${this.reportingPeriods}`,
    );
  }

  public getByGrantId(grantId: number): Observable<ReportingPeriodApiResponse> {
    return this.http.get<ReportingPeriodApiResponse>(
      `${this.baseUrl}/${this.reportingPeriods}`,
      {
        params: { grantId },
      },
    );
  }

  public getReportingPeriodState(): Observable<ReportingPeriodStateApiResponse> {
    return this.http.get<ReportingPeriodStateApiResponse>(
      `${this.baseUrl}/reporting-period-state`,
    );
  }

  public update(
    id: number | string,
    payload: ReportingPeriodPayload,
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      `${this.baseUrl}/${this.reportingPeriods}/${id}`,
      payload,
    );
  }
}
