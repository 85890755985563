import { State as AppState } from '@app/store/app-state';
import { FparTable, GrantSubmission } from '@core/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { portalReviewFeatureKey } from './reducer';
import { State } from './state';

const getCurrentGrant = (state: State): GrantSubmission => state.currentGrant;
const getCurrentTable = (state: State): FparTable => state.currentTable;

export const portalReviewState = createFeatureSelector<AppState, State>(
  portalReviewFeatureKey,
);

export const selectCurrentGrant = createSelector(
  portalReviewState,
  getCurrentGrant,
);

export const selectCurrentTable = createSelector(
  portalReviewState,
  getCurrentTable,
);
