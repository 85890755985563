import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ClinicStepperEffects } from './effects';
import { clinicStepperFeatureKey, reducer } from './reducer';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([ClinicStepperEffects]),
    StoreModule.forFeature(clinicStepperFeatureKey, reducer),
  ],
})
export class ClinicStepperModule {}
