// will need to duplicate actions from fpar-table to store fpar table data
import {
  ApiError,
  FparTableApiResponse,
  GrantSubmissionTableOverviewApiResponse,
  ReportingPeriodApiResponse,
} from '@core/models';
import { createAction, props } from '@ngrx/store';

export enum TableArchiveActionTypes {
  GET_TABLE_OVERVIEW_FAILURE = '[Table Archive] Get Grant Table Overview Failure',
  GET_TABLE_OVERVIEW_REQUEST = '[Table Archive] Get Grant Table Overview Request',
  GET_TABLE_OVERVIEW_SUCCESS = '[Table Archive] Get Grant Table Overview Success',
  GET_REPORTING_PERIODS_FAILURE = '[Table Archive] Get Reporting Periods Failure',
  GET_REPORTING_PERIODS_REQUEST = '[Table Archive] Get Reporting Periods Request',
  GET_REPORTING_PERIODS_SUCCESS = '[Table Archive] Get Reporting Periods Success',
  GET_TABLE_REQUEST = '[Table Archive] Get Table Request',
  GET_TABLE_FAILURE = '[Table Archive] Get Table Failure',
  GET_TABLE_SUCCESS = '[Table Archive] Get Table Success',
  RESET_SELECTED_REPORTING_PERIOD_ID = '[Table Archive] Reset Selected Reporting Period ID Request',
  RESET_TABLE_ARCHIVE_DATA = '[Table Archive] Reset Table Archive Data',
  UPDATE_SELECTED_GRANT_ID = '[Table Archive] Update Selected Grant ID Request',
  UPDATE_SELECTED_REPORTING_PERIOD_ID = '[Table Archive] Update Selected Reporting Period ID Request',
}

export const getTableOverview = createAction(
  TableArchiveActionTypes.GET_TABLE_OVERVIEW_REQUEST,
  props<{ grantId: number; reportingPeriodId: number }>(),
);
export const getTableOverviewFailure = createAction(
  TableArchiveActionTypes.GET_TABLE_OVERVIEW_FAILURE,
  props<ApiError>(),
);
export const getTableOverviewSuccess = createAction(
  TableArchiveActionTypes.GET_TABLE_OVERVIEW_SUCCESS,
  props<{
    tableOverview: GrantSubmissionTableOverviewApiResponse;
  }>(),
);

export const getReportingPeriods = createAction(
  TableArchiveActionTypes.GET_REPORTING_PERIODS_REQUEST,
  props<{ grantId: number }>(),
);
export const getReportingPeriodsFailure = createAction(
  TableArchiveActionTypes.GET_REPORTING_PERIODS_FAILURE,
  props<ApiError>(),
);
export const getReportingPeriodsSuccess = createAction(
  TableArchiveActionTypes.GET_REPORTING_PERIODS_SUCCESS,
  props<{ response: ReportingPeriodApiResponse }>(),
);

export const getTable = createAction(
  TableArchiveActionTypes.GET_TABLE_REQUEST,
  props<{ grantId: number; tableId: number; reportingPeriodId: number }>(),
);
export const getTableFailure = createAction(
  TableArchiveActionTypes.GET_TABLE_FAILURE,
  props<ApiError>(),
);
export const getTableSuccess = createAction(
  TableArchiveActionTypes.GET_TABLE_SUCCESS,
  props<{ response: FparTableApiResponse }>(),
);

export const resetTableArchiveData = createAction(
  TableArchiveActionTypes.RESET_TABLE_ARCHIVE_DATA,
);

export const updateSelectedGrantId = createAction(
  TableArchiveActionTypes.UPDATE_SELECTED_GRANT_ID,
  props<{ grantId: number }>(),
);
export const updateSelectedReportingPeriodId = createAction(
  TableArchiveActionTypes.UPDATE_SELECTED_REPORTING_PERIOD_ID,
  props<{ reportingPeriodId: number }>(),
);
