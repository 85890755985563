<div class="upload-status-box">
  <h3 class="upload-status-title">UPLOAD DATA STATUS</h3>
  <div *ngFor="let status of dataStatuses" class="upload-status-content">
    <span class="icon-status">
      <mat-icon
        tabindex="1"
        aria-hidden="false"
        aria-label="Not started"
        *ngIf="!status.finished"
        >block
      </mat-icon>
      <mat-icon
        tabindex="1"
        aria-hidden="false"
        aria-label="Completed"
        class="finished"
        *ngIf="status.finished"
      >
        check_circle</mat-icon
      >
    </span>
    <span tabindex="1" class="type"> {{ status.type }} </span>
  </div>
</div>
