import { Action, createReducer, on } from '@ngrx/store';
import {
  getBenchmarks,
  getBenchmarksFailure,
  getBenchmarksSuccess,
  getCardDetails,
  getCardDetailsFailure,
  getCardDetailsSuccess,
  getCardDetailsSuccessNoDetails,
  getCards,
  getCardsFailure,
  getCardsSuccess,
  getFilterData,
  getFilterDataFailure,
  getFilterDataSuccess,
  getGrantSubmissionReviewStatus,
  getGrantSubmissionReviewStatusFailure,
  getGrantSubmissionReviewStatusSuccess,
  getKeyDates,
  getKeyDatesFailure,
  getKeyDatesSuccess,
  getReportingPeriods,
  getReportingPeriodsFailure,
  getReportingPeriodsSuccess,
  getReportingPeriodState,
  getReportingPeriodStateFailure,
  getReportingPeriodStateSuccess,
  resetCardDetails,
  resetCardList,
  setSelectedBenchmark,
  setSelectedGrantId,
  setSelectedReportingPeriod,
} from './actions';
import { initialState, State } from './state';

export const dashboardFeatureKey = 'dashboard';

const dashboardReducer = createReducer(
  initialState,

  on(getBenchmarks, (state) => ({ ...state, benchmarksLoading: true })),
  on(getBenchmarksFailure, (state, { error, message }) => ({
    ...state,
    benchmarks: [],
    benchmarksError: { error, message },
    benchmarksLoading: false,
  })),
  on(getBenchmarksSuccess, (state, { response }) => ({
    ...state,
    benchmarks: response.data,
    benchmarksLoading: false,
  })),

  on(getCardDetails, (state) => ({ ...state, cardDetailsLoading: true })),
  on(getCardDetailsFailure, (state, { error, message }) => ({
    ...state,
    cardDetails: null,
    cardDetailsError: { error, message },
    cardDetailsLoading: false,
  })),
  on(getCardDetailsSuccess, (state, { response }) => ({
    ...state,
    cardDetails: response.data[0],
    cardDetailsLoading: false,
  })),
  on(getCardDetailsSuccessNoDetails, (state, { response }) => ({
    ...state,
    cardDetails: response.data[0],
    cardDetailsLoading: false,
  })),

  on(getCards, (state) => ({ ...state, cardsLoading: true })),
  on(getCardsFailure, (state, { error, message }) => ({
    ...state,
    cards: [],
    cardsError: { error, message },
    cardsLoading: false,
  })),
  on(getCardsSuccess, (state, { response }) => ({
    ...state,
    cards: response.data.cards,
    cardsLoading: false,
    reportingPeriodState: response.data.reportingPeriodState,
  })),

  on(getFilterData, (state) => ({ ...state, filterDataLoading: true })),
  on(getFilterDataFailure, (state, { error, message }) => ({
    ...state,
    filterData: [],
    filterDataError: { error, message },
    filterDataLoading: false,
  })),
  on(getFilterDataSuccess, (state, { filterData }) => ({
    ...state,
    filterData: filterData.data,
    filterDataLoading: false,
  })),

  on(getKeyDates, (state) => ({ ...state, keyDatesLoading: true })),
  on(getKeyDatesFailure, (state, { error, message }) => ({
    ...state,
    keyDates: [],
    keyDatesError: { error, message },
    keyDatesLoading: false,
  })),
  on(getKeyDatesSuccess, (state, { keyDates }) => ({
    ...state,
    keyDates: keyDates.data,
    keyDatesLoading: false,
  })),

  on(getGrantSubmissionReviewStatus, (state) => ({
    ...state,
    grantSubmissionReviewStatusLoading: true,
  })),
  on(getGrantSubmissionReviewStatusFailure, (state, { error, message }) => ({
    ...state,
    grantSubmissionReviewStatus: null,
    grantSubmissionReviewStatusError: { error, message },
    grantSubmissionReviewStatusLoading: false,
  })),
  on(
    getGrantSubmissionReviewStatusSuccess,
    (state, { submissionReviewStatus }) => ({
      ...state,
      grantSubmissionReviewStatus: submissionReviewStatus.data[0],
      grantSubmissionReviewStatusLoading: false,
      grantSubmissionReviewStatusError: null,
    }),
  ),

  on(getReportingPeriods, (state) => ({
    ...state,
    reportingPeriodsLoading: true,
  })),
  on(getReportingPeriodsFailure, (state, { error, message }) => ({
    ...state,
    reportingPeriods: [],
    reportingPeriodsError: { error, message },
    reportingPeriodsLoading: false,
  })),
  on(getReportingPeriodsSuccess, (state, { reportingPeriods }) => ({
    ...state,
    reportingPeriods,
    reportingPeriodsLoading: false,
  })),

  on(getReportingPeriodState, (state) => ({
    ...state,
    reportingPeriodStateLoading: true,
  })),
  on(getReportingPeriodStateFailure, (state, { error, message }) => ({
    ...state,
    reportingPeriodState: null,
    reportingPeriodStateError: { error, message },
    reportingPeriodStateLoading: false,
  })),
  on(getReportingPeriodStateSuccess, (state, { reportingPeriodState }) => ({
    ...state,
    reportingPeriodState: reportingPeriodState.data,
    reportingPeriodStateLoading: false,
  })),

  on(resetCardDetails, (state) => ({
    ...state,
    cardDetails: initialState.cardDetails,
  })),
  on(resetCardList, (state) => ({
    ...state,
    cards: initialState.cards,
  })),

  on(setSelectedBenchmark, (state, { selectedBenchmark }) => ({
    ...state,
    selectedBenchmark,
  })),
  on(setSelectedGrantId, (state, { selectedGrantId }) => ({
    ...state,
    selectedGrantId,
  })),
  on(setSelectedReportingPeriod, (state, { selectedReportingPeriod }) => ({
    ...state,
    selectedReportingPeriod,
  })),
);

export const reducer = (state: State | undefined, action: Action) =>
  dashboardReducer(state, action);
