import { State as AppState } from '@app/store/app-state';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { userAppFeatureKey } from './reducer';
import { State } from './state';

export const getSecurityNoticeShown = (state: State): boolean =>
  state.securityNoticeShown;

export const selectUserAppState = createFeatureSelector<AppState, State>(
  userAppFeatureKey,
);

export const selectSecurityNoticeShown = createSelector(
  selectUserAppState,
  getSecurityNoticeShown,
);

export const selectToggleState = (toggleKey: string) =>
  createSelector(
    selectUserAppState,
    (state: State): boolean => state.toggles[toggleKey],
  );
