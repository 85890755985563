<h1 mat-dialog-title>{{ title }}</h1>

<mat-dialog-content>
  <div *ngIf="message" class="message">{{ message }}</div>
  <div *ngIf="innerHtml" class="message" [innerHTML]="innerHtml"></div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-flat-button [color]="confirmColor" [mat-dialog-close]="true">
    {{ yesButtonText }}
  </button>
  <button cdkFocusInitial mat-stroked-button [mat-dialog-close]="false">
    {{ noButtonText }}
  </button>
</mat-dialog-actions>
