import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { GrantService } from '@core/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GrantSelectionGuard  {
  constructor(
    private router: Router,
    private grantService: GrantService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    return this.grantService
      .userGrantSelected()
      .pipe(
        map((grantSelected) =>
          grantSelected ? true : this.router.parseUrl('/auth/select-grant'),
        ),
      );
  }
}
