import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CardDetailsParameters,
  DashboardBenchmarksApiResponse,
  DashboardCardDetailsApiResponse,
  DashboardCardsApiResponse,
  DashboardReportingPeriodsApiResponse,
  GrantSubmissionReviewStatusApiResponse,
  KeyDatesApiResponse,
} from '@core/models';
import { environment as env } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  private baseUrl = `${env.apiURL}api/v1/`;
  private dashboardUrl = `${this.baseUrl}dashboard`;
  private keyDates = 'key-dates';
  private grants = 'grants';

  constructor(private http: HttpClient) {}

  public getBenchmarks(): Observable<DashboardBenchmarksApiResponse> {
    return this.http.get<DashboardBenchmarksApiResponse>(
      `${this.dashboardUrl}/benchmarks`,
    );
  }

  public getCardDetails(
    cardId: string | number,
    params?: CardDetailsParameters,
  ): Observable<DashboardCardDetailsApiResponse> {
    return this.http.get<DashboardCardDetailsApiResponse>(
      `${this.dashboardUrl}/cards/${cardId}`,
      { params: { ...params } },
    );
  }

  public getCards(
    benchmarkId: number,
    params?: {
      facilityIds?: number[];
      grantId?: number;
      reportingPeriodId?: number;
    },
  ): Observable<DashboardCardsApiResponse> {
    return this.http.get<DashboardCardsApiResponse>(`${this.dashboardUrl}`, {
      params: { ...params, benchmarkId },
    });
  }

  public getGrantSubmissionReviewStatus(
    grantId: number,
  ): Observable<GrantSubmissionReviewStatusApiResponse> {
    return this.http.get<GrantSubmissionReviewStatusApiResponse>(
      `${this.baseUrl}${this.grants}/${grantId}/status`,
    );
  }

  public getKeyDates(year: number): Observable<KeyDatesApiResponse> {
    return this.http.get<KeyDatesApiResponse>(
      `${this.baseUrl}${this.keyDates}`,
      {
        params: { year },
      },
    );
  }

  public getReportingPeriods(
    grantId: number,
  ): Observable<DashboardReportingPeriodsApiResponse> {
    const params = grantId ? { grantId } : {};

    return this.http.get<DashboardReportingPeriodsApiResponse>(
      `${this.dashboardUrl}/reporting-periods`,
      { params },
    );
  }

  public getFilterData(): Observable<any> {
    return this.http.get<any>(`${this.dashboardUrl}/dropdowns`);
  }
}
