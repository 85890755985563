import { State as RootState } from '@app/store/app-state';
import {
  ApiError,
  GrantCoverSheetAdminProjectDirector,
  GrantCoverSheetContactPerson,
  GrantCoverSheetMetaData,
} from '@core/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { grantProfileCoverSheetFeatureKey } from './reducer';
import { State } from './state';

const getAdminProjectDirector = (
  state: State,
): GrantCoverSheetAdminProjectDirector => state.adminProjectDirector;
const getAdminProjectDirectorError = (state: State): ApiError =>
  state.adminProjectDirectorError;
const getAdminProjectDirectorLoading = (state: State): boolean =>
  state.adminProjectDirectorLoading;

const getContactPerson = (state: State): GrantCoverSheetContactPerson =>
  state.contactPerson;
const getContactPersonError = (state: State): ApiError =>
  state.contactPersonError;
const getContactPersonLoading = (state: State): boolean =>
  state.contactPersonLoading;

const getMetaData = (state: State): GrantCoverSheetMetaData => state.metaData;
const getMetaDataError = (state: State): ApiError => state.metaDataError;
const getMetaDataLoading = (state: State): boolean => state.metaDataLoading;

const getUpdateAdminProjectDirectorError = (state: State): ApiError =>
  state.adminProjectDirectorError;
const getUpdateAdminProjectDirectorLoading = (state: State): boolean =>
  state.adminProjectDirectorLoading;

const getUpdateContactPersonError = (state: State): ApiError =>
  state.contactPersonError;
const getUpdateContactPersonLoading = (state: State): boolean =>
  state.contactPersonLoading;

export const selectGrantProfileCoverSheetState = createFeatureSelector<
  RootState,
  State
>(grantProfileCoverSheetFeatureKey);

export const selectAdminProjectDirector = createSelector(
  selectGrantProfileCoverSheetState,
  getAdminProjectDirector,
);
export const selectAdminProjectDirectorLoading = createSelector(
  selectGrantProfileCoverSheetState,
  getAdminProjectDirectorLoading,
);
export const selectAdminProjectDirectorError = createSelector(
  selectGrantProfileCoverSheetState,
  getAdminProjectDirectorError,
);

export const selectContactPerson = createSelector(
  selectGrantProfileCoverSheetState,
  getContactPerson,
);
export const selectContactPersonLoading = createSelector(
  selectGrantProfileCoverSheetState,
  getContactPersonLoading,
);
export const selectContactPersonError = createSelector(
  selectGrantProfileCoverSheetState,
  getContactPersonError,
);

export const selectMetaData = createSelector(
  selectGrantProfileCoverSheetState,
  getMetaData,
);
export const selectMetaDataLoading = createSelector(
  selectGrantProfileCoverSheetState,
  getMetaDataLoading,
);
export const selectMetaDataError = createSelector(
  selectGrantProfileCoverSheetState,
  getMetaDataError,
);

export const selectUpdateAdminProjectDirectorLoading = createSelector(
  selectGrantProfileCoverSheetState,
  getUpdateAdminProjectDirectorLoading,
);
export const selectUpdateAdminProjectDirectorError = createSelector(
  selectGrantProfileCoverSheetState,
  getUpdateAdminProjectDirectorError,
);

export const selectUpdateContactPersonLoading = createSelector(
  selectGrantProfileCoverSheetState,
  getUpdateContactPersonLoading,
);
export const selectUpdateContactPersonError = createSelector(
  selectGrantProfileCoverSheetState,
  getUpdateContactPersonError,
);
