<div class="supplemental-info-container">
  <mat-expansion-panel expanded>
    <mat-expansion-panel-header>
      <mat-panel-title class="mat-h2">Supplemental Information</mat-panel-title>
      <mat-panel-description>
        <div class="status">
          <mat-icon
            [class]="numberOfSitesStatusDetails[supplementalStatus]?.class"
            [innerText]="numberOfSitesStatusDetails[supplementalStatus]?.icon"
          >
          </mat-icon>
          <span>{{ supplementalStatus }}</span>
        </div>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <form [formGroup]="supplementalInfoForm" autocomplete="off">
      <div class="reporting-period">
        <h4>Grant Reporting Period</h4>
        <fieldset class="radio-button-input">
          <label for="customReportingPeriods"
            >Does your data reflect the full reporting period of
            <b>{{ minDate | date: 'MMMM dd, y' }}</b>
            through
            <b>{{ maxDate | date: 'MMMM dd, y' }}</b>
            ?
          </label>
          <mat-radio-group
            id="customReportingPeriods"
            class="radio-group"
            formControlName="customReportingPeriods"
          >
            <mat-radio-button class="radio-button" [value]="false"
              >Yes
            </mat-radio-button>
            <mat-radio-button class="radio-button" [value]="true">
              No
            </mat-radio-button>
          </mat-radio-group>
        </fieldset>
        <fieldset
          class="text-input"
          *ngIf="supplementalInfoForm.controls.customReportingPeriods.value"
        >
          <label for="customReportingPeriodDateRange"
            >Provide the start date and end date of the reporting period for
            which you are providing data.</label
          >
          <div id="customReportingPeriodDateRange" class="date-range-container">
            <mat-form-field>
              <mat-label>Start Date</mat-label>
              <input
                matInput
                title="Start Date"
                [matDatepicker]="startDate"
                formControlName="reportingPeriodStart"
                [min]="minDate"
                [max]="maxDate"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="startDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #startDate></mat-datepicker>
              <mat-error>
                <app-form-errors
                  [field]="supplementalInfoForm.controls.reportingPeriodStart"
                ></app-form-errors
              ></mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>End Date</mat-label>
              <input
                matInput
                title="End Date"
                [matDatepicker]="endDate"
                formControlName="reportingPeriodEnd"
                [min]="minDate"
                [max]="maxDate"
                [errorStateMatcher]="dateMatcher"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="endDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #endDate></mat-datepicker>
              <mat-error>
                <app-form-errors
                  [field]="supplementalInfoForm.controls.reportingPeriodEnd"
                ></app-form-errors>
              </mat-error>
            </mat-form-field>
            <mat-error>
              <app-form-errors [field]="supplementalInfoForm"></app-form-errors>
            </mat-error>
          </div>
        </fieldset>
      </div>
      <mat-divider></mat-divider>
      <div class="sites-supported">
        <h4>Number of Sites Supported</h4>
        <fieldset class="flex-row flex-wrap text-input">
          <label for="numberOfSubrecipients"
            >Number of subrecipients supported by the Title X Grant:</label
          >
          <mat-form-field id="numberOfSubrecipients">
            <mat-label>Number of subrecipients</mat-label>
            <input
              matInput
              name="titlexSubrecipients"
              title="Title X Subrecipients"
              type="number"
              min="0"
              formControlName="titlexSubrecipients"
            />
            <mat-error>
              <app-form-errors
                [field]="supplementalInfoForm.controls.titlexSubrecipients"
                [message]="numberErrorMessage"
              ></app-form-errors>
            </mat-error>
          </mat-form-field>
          <!-- </fieldset>
        <fieldset class="flex-row flex-wrap text-input"> -->
          <label for="numberOfFamilyPlanningServiceSites"
            >Number of family planning service sites supported by the Title X
            Grant:</label
          >
          <mat-form-field id="numberOfFamilyPlanningServiceSites">
            <mat-label>Number of sites</mat-label>
            <input
              matInput
              name="titlexSites"
              title="Title X Sites"
              type="number"
              min="0"
              formControlName="titlexSites"
            />
            <mat-error>
              <app-form-errors
                [field]="supplementalInfoForm.controls.titlexSites"
                [message]="numberErrorMessage"
              ></app-form-errors>
            </mat-error>
          </mat-form-field>
          <!-- </fieldset>
        <fieldset class="flex-row flex-wrap text-input"> -->
          <label for="numberOfTelehealthServiceSites"
            >Number of Title X clinics offering telehealth services:</label
          >
          <mat-form-field id="numberOfTelehealthServiceSites">
            <mat-label>Number of clinics</mat-label>
            <input
              matInput
              name="telehealthClinics"
              title="Telehealth Clinics"
              type="number"
              min="0"
              formControlName="telehealthClinics"
              [errorStateMatcher]="telehealthMatcher"
            />
            <mat-error>
              <app-form-errors
                [field]="supplementalInfoForm.controls.telehealthClinics"
                [message]="numberErrorMessage"
              ></app-form-errors>
              <span *ngIf="supplementalInfoForm.hasError('maxSitesExceeded')"
                >Cannot exceed the number of sites entered above</span
              >
            </mat-error>
          </mat-form-field>
          <!-- </fieldset>
        <fieldset class="radio-button-input"> -->
          <label for="differentNumberOfSites"
            >Is the number of service sites supported by the Title X Grant
            different from the number provided in the grant application?
          </label>
          <mat-radio-group
            id="differentNumberOfSites"
            class="radio-group"
            formControlName="differentFromApp"
          >
            <mat-radio-button class="radio-button" [value]="true"
              >Yes
            </mat-radio-button>
            <mat-radio-button class="radio-button" [value]="false">
              No
            </mat-radio-button>
          </mat-radio-group>
          <!-- </fieldset> -->
          <ng-container
            *ngIf="supplementalInfoForm.controls.differentFromApp.value"
          >
            <label for="reasonForDifference">What is the reason?</label>
            <mat-form-field
              id="reasonForDifference"
              class="mat-mdc-form-field-100-percent"
            >
              <mat-label>Reason for difference</mat-label>
              <textarea
                matInput
                title="Reason for difference"
                aria-required="true"
                name="differentFromAppReason"
                formControlName="differentFromAppReason"
              >
              </textarea>
            </mat-form-field>
          </ng-container>
        </fieldset>
      </div>
    </form>
    <mat-action-row *ngIf="!readOnly">
      <button
        mat-flat-button
        type="button"
        color="primary"
        (click)="updateSupplementalInfoForm()"
        [disabled]="
          supplementalInfoForm.invalid ||
          supplementalInfoForm.pristine ||
          supplementalInfoForm.disabled
        "
      >
        {{ supplementalInfoForm.disabled ? 'Saving...' : 'Save' }}
      </button>
    </mat-action-row>
  </mat-expansion-panel>
</div>
