<h2 mat-dialog-title>Flag Issue</h2>
<mat-dialog-content>
  <div
    class="progress-container"
    [class.invisible]="(saving$ | async) === false"
  >
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <p>
    Please provide a brief explanation for your decision not to correct this
    issue.
  </p>
  <form [formGroup]="form">
    <fieldset>
      <mat-form-field class="fill-white">
        <textarea
          #textAreaComment
          matInput
          formControlName="comment"
          cdkFocusInitial
        ></textarea>
      </mat-form-field>
    </fieldset>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-flat-button
    color="primary"
    (click)="save()"
    [disabled]="form.invalid"
  >
    Save
  </button>
  <button mat-stroked-button (click)="close()">Cancel</button>
</mat-dialog-actions>
