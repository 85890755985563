import { Action, createReducer, on } from '@ngrx/store';
import {
  closeSubmissionPeriod,
  closeSubmissionPeriodFailure,
  closeSubmissionPeriodSuccess,
  createKeyDate,
  createKeyDateFailure,
  createKeyDateSuccess,
  createReportingPeriod,
  createReportingPeriodFailure,
  createReportingPeriodSuccess,
  deleteKeyDate,
  deleteKeyDateFailure,
  deleteKeyDateSuccess,
  deleteReportingPeriod,
  deleteReportingPeriodFailure,
  deleteReportingPeriodSuccess,
  forceGrantSubmission,
  forceGrantSubmissionFailure,
  forceGrantSubmissionSuccess,
  generateDashboardData,
  generateDashboardDataFailure,
  generateDashboardDataSuccess,
  getKeyDates,
  getKeyDatesFailure,
  getKeyDatesSuccess,
  getOverviewReport,
  getOverviewReportFailure,
  getOverviewReportSuccess,
  getRemovedUserReport,
  getRemovedUserReportFailure,
  getRemovedUserReportSuccess,
  getReportingPeriods,
  getReportingPeriodsFailure,
  getReportingPeriodsSuccess,
  updateKeyDate,
  updateKeyDateFailure,
  updateKeyDateSuccess,
  updateReportingPeriod,
  updateReportingPeriodFailure,
  updateReportingPeriodSuccess,
} from './actions';
import { initialState, State } from './state';

export const settingsFeatureKey = 'settings';

const settingsReducer = createReducer(
  initialState,
  on(closeSubmissionPeriod, (state) => ({
    ...state,
    closeSubmissionPeriodLoading: true,
  })),
  on(
    closeSubmissionPeriodFailure,
    (state, { error: closeSubmissionPeriodError }) => ({
      ...state,
      closeSubmissionPeriodError,
      closeSubmissionPeriodLoading: false,
    }),
  ),
  on(closeSubmissionPeriodSuccess, (state) => ({
    ...state,
    closeSubmissionPeriodLoading: false,
  })),
  on(createKeyDate, (state) => ({
    ...state,
    createKeyDateLoading: true,
  })),
  on(createKeyDateFailure, (state, { error: createKeyDateError }) => ({
    ...state,
    createKeyDateError,
    createKeyDateLoading: false,
  })),
  on(createKeyDateSuccess, (state) => ({
    ...state,
    createKeyDateLoading: false,
  })),

  on(createReportingPeriod, (state) => ({
    ...state,
    createReportingPeriodLoading: true,
  })),
  on(
    createReportingPeriodFailure,
    (state, { error: createReportingPeriodError }) => ({
      ...state,
      createReportingPeriodError,
      createReportingPeriodLoading: false,
    }),
  ),
  on(createReportingPeriodSuccess, (state) => ({
    ...state,
    createReportingPeriodLoading: false,
  })),

  on(deleteKeyDate, (state) => ({
    ...state,
    deleteKeyDateLoading: true,
  })),
  on(deleteKeyDateFailure, (state, { error: deleteKeyDateError }) => ({
    ...state,
    deleteKeyDateError,
    deleteKeyDateLoading: false,
  })),
  on(deleteKeyDateSuccess, (state) => ({
    ...state,
    deleteKeyDateLoading: false,
  })),

  on(deleteReportingPeriod, (state) => ({
    ...state,
    deleteReportingPeriodLoading: true,
  })),
  on(
    deleteReportingPeriodFailure,
    (state, { error: deleteReportingPeriodError }) => ({
      ...state,
      deleteReportingPeriodError,
      deleteReportingPeriodLoading: false,
    }),
  ),
  on(deleteReportingPeriodSuccess, (state) => ({
    ...state,
    deleteReportingPeriodLoading: false,
  })),

  on(forceGrantSubmission, (state) => ({
    ...state,
    forceGrantSubmissionLoading: true,
  })),
  on(
    forceGrantSubmissionFailure,
    (state, { error: forceGrantSubmissionError }) => ({
      ...state,
      forceGrantSubmissionError,
      forceGrantSubmissionLoading: false,
    }),
  ),
  on(forceGrantSubmissionSuccess, (state) => ({
    ...state,
    forceGrantSubmissionLoading: false,
  })),

  on(generateDashboardData, (state) => ({
    ...state,
    generateDashboardDataLoading: true,
  })),
  on(
    generateDashboardDataFailure,
    (state, { error: generateDashboardDataError }) => ({
      ...state,
      generateDashboardDataError,
      generateDashboardDataLoading: false,
    }),
  ),
  on(generateDashboardDataSuccess, (state) => ({
    ...state,
    generateDashboardDataLoading: false,
  })),

  on(getRemovedUserReport, (state) => ({
    ...state,
    removedUserReport: [],
    removedUserReportLoading: true,
  })),
  on(
    getRemovedUserReportFailure,
    (state, { error: removedUserReportError }) => ({
      ...state,
      removedUserReportError,
      removedUserReportLoading: false,
    }),
  ),
  on(getRemovedUserReportSuccess, (state, { response }) => ({
    ...state,
    removedUserReport: response.data,
    removedUserReportLoading: false,
  })),

  on(getKeyDates, (state) => ({
    ...state,
    keyDates: [],
    keyDatesLoading: true,
  })),
  on(getKeyDatesFailure, (state, { error: keyDatesError }) => ({
    ...state,
    keyDatesError,
    keyDatesLoading: false,
  })),
  on(getKeyDatesSuccess, (state, { response }) => ({
    ...state,
    keyDates: response.data,
    keyDatesLoading: false,
  })),

  on(getOverviewReport, (state) => ({
    ...state,
    overviewReport: [],
    overviewReportLoading: true,
  })),
  on(getOverviewReportFailure, (state, { error: overviewReportError }) => ({
    ...state,
    overviewReportError,
    overviewReportLoading: false,
  })),
  on(getOverviewReportSuccess, (state, { response }) => ({
    ...state,
    overviewReport: response.data,
    overviewReportLoading: false,
  })),

  on(getReportingPeriods, (state) => ({
    ...state,
    reportingPeriods: [],
    reportingPeriodsLoading: true,
  })),
  on(getReportingPeriodsFailure, (state, { error: reportingPeriodsError }) => ({
    ...state,
    reportingPeriodsError,
    reportingPeriodsLoading: false,
  })),
  on(getReportingPeriodsSuccess, (state, { response }) => ({
    ...state,
    reportingPeriods: response.data,
    reportingPeriodsLoading: false,
  })),

  on(updateKeyDate, (state) => ({
    ...state,
    updateKeyDateLoading: true,
  })),
  on(updateKeyDateFailure, (state, { error: updateKeyDateError }) => ({
    ...state,
    updateKeyDateError,
    updateKeyDateLoading: false,
  })),
  on(updateKeyDateSuccess, (state) => ({
    ...state,
    updateKeyDateLoading: false,
  })),

  on(updateReportingPeriod, (state) => ({
    ...state,
    updateReportingPeriodLoading: true,
  })),
  on(
    updateReportingPeriodFailure,
    (state, { error: updateReportingPeriodError }) => ({
      ...state,
      updateReportingPeriodError,
      updateReportingPeriodLoading: false,
    }),
  ),
  on(updateReportingPeriodSuccess, (state) => ({
    ...state,
    updateReportingPeriodLoading: false,
  })),
);

export const reducer = (state: State | undefined, action: Action) =>
  settingsReducer(state, action);
