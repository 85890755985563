import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  CsvTypes,
  FileUploadTypes,
  NotificationTypes,
} from '@app/shared/enums';
import { AppStoreState, GrantActions, NotificationActions } from '@app/store';
import { GrantFileUploadResults } from '@core/models';
import { Store } from '@ngrx/store';
import * as Papa from 'papaparse';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @Input() fileStatus: string;
  @Input() fileType: FileUploadTypes;

  @Output() addFile = new EventEmitter<GrantFileUploadResults>();

  public parseErrors: Papa.ParseError[] = [];
  public validFile: File;

  constructor(private store$: Store<AppStoreState.State>) {}

  ngOnInit(): void {}

  public byteConverter(bytes: number): number {
    const k = 1024;
    const convertedBytes = +(bytes / k).toFixed(2);
    return convertedBytes;
  }

  public drag(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();
  }

  public drop(event: DragEvent): void {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    this.selectDataFile(file);
  }

  public selectDataFile(csvFile: File): void {
    if (Object.values(CsvTypes).includes(csvFile.type as CsvTypes)) {
      Papa.parse(csvFile, {
        skipEmptyLines: true,
        complete: (results) => {
          this.parseErrors = [...results.errors];
          if (!results.errors.length) {
            this.addFile.emit({
              results,
              type: this.fileType,
              file: csvFile,
            });
            this.validFile = csvFile;
            const noError = { type: this.fileStatus, finished: true };
            this.store$.dispatch(
              GrantActions.setFileUploadStatus({ status: noError }),
            );
          } else {
            this.validFile = null;
            const errorFile = { type: this.fileStatus, finished: false };
            this.store$.dispatch(
              GrantActions.setFileUploadStatus({ status: errorFile }),
            );
          }
        },
        error: (error) => {
          console.log(error);
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.DANGER,
              notificationText:
                'An error occurred reading the file. Please try uploading again.',
            }),
          );
        },
        dynamicTyping: true,
        header: true,
        worker: true,
      });
    } else {
      this.validFile = null;
      this.store$.dispatch(
        NotificationActions.add({
          notificationType: NotificationTypes.DANGER,
          notificationText:
            'The file you chose is not a CSV file. Please choose a different file.',
        }),
      );
      this.addFile.emit(null);
      const invalidFile = { type: this.fileStatus, finished: false };
      this.store$.dispatch(
        GrantActions.setFileUploadStatus({ status: invalidFile }),
      );
    }
  }
}
