import { Injectable } from '@angular/core';

import { GranteeProfileCoverSheetComponent } from '@app/profile-cover-sheet/profile-cover-sheet.component';

@Injectable({
  providedIn: 'root',
})
export class ProfileCoverSheetGuard
  
{
  public canDeactivate(component: GranteeProfileCoverSheetComponent): boolean {
    if (component?.isDirty()) {
      return confirm(
        'Are you sure you want to abandon your changes on the current form?',
      );
    } else {
      return true;
    }
  }
}
