import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import {
  clearSecurityNoticeShown,
  clearSecurityNoticeShownSuccess,
  clearToggleSetting,
  clearToggleSettingSuccess,
  getUserAppState,
  getUserAppStateSuccess,
  setSecurityNoticeShown,
  setSecurityNoticeShownSuccess,
  updateToggleSetting,
  updateToggleSettingSuccess,
} from './actions';

const userAppStateKey = 'userAppState';

@Injectable()
export class UserAppEffects {
  clearNotificationShown$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clearSecurityNoticeShown),
      map(() => {
        sessionStorage.removeItem('securityNoticeShown');
        return clearSecurityNoticeShownSuccess();
      }),
    ),
  );

  clearToggleSetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clearToggleSetting),
      map(() => {
        localStorage.removeItem('userAppState');
        return clearToggleSettingSuccess();
      }),
    ),
  );

  getUserAppState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserAppState),
      map(() => {
        const userAppState = JSON.parse(localStorage.getItem(userAppStateKey));
        return getUserAppStateSuccess(userAppState);
      }),
    ),
  );

  setToggleState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateToggleSetting),
      map((toggleName) => {
        const { type, ...toggle } = toggleName;
        const userAppState = JSON.parse(localStorage.getItem(userAppStateKey));
        let updatedUserAppState;
        if (userAppState) {
          updatedUserAppState = {
            ...userAppState,
            toggles: { ...userAppState.toggles, ...toggle },
          };
        } else {
          updatedUserAppState = {
            toggles: { ...toggle },
          };
        }
        localStorage.setItem(
          userAppStateKey,
          JSON.stringify(updatedUserAppState),
        );
        return updateToggleSettingSuccess();
      }),
    ),
  );

  setNotificationShown$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSecurityNoticeShown),
      map(() => {
        sessionStorage.setItem('securityNoticeShown', 'true');
        return setSecurityNoticeShownSuccess();
      }),
    ),
  );

  constructor(private actions$: Actions) {}
}
