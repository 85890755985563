import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  DataQualityResultsApiResponse,
  FparTableApiResponse,
  FparTableCommentPayload,
  FparTableIssueCommentApiResponse,
  FparTableIssueCommentPayload,
  FparTableIssuePayload,
  FparTablePayload,
} from '@core/models';
import { environment as env } from 'environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class FparTableService {
  private baseUrl = `${env.apiURL}api/v1/grants/`;
  constructor(private http: HttpClient) {}

  public addTableComment(
    grantId: number,
    tableId: number,
    payload: FparTableCommentPayload,
  ): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      `${this.getTableUrl(grantId, tableId)}/comments`,
      payload,
    );
  }

  public addTableIssueComment(
    grantId: number,
    tableId: number,
    checkId: string,
    payload: FparTableIssueCommentPayload,
  ): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      `${this.getTableUrl(grantId, tableId)}/checks/${checkId}/comments`,
      payload,
    );
  }

  public dataQualityCheck(grantId: number): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      `${this.baseUrl}${grantId}/data-quality-task`,
      {},
    );
  }

  public dataQualityResults(
    grantId: number,
  ): Observable<DataQualityResultsApiResponse> {
    return this.http.get<DataQualityResultsApiResponse>(
      `${this.baseUrl}${grantId}/data-quality-results`,
      {},
    );
  }

  public deleteTableComment(
    grantId: number,
    tableId: number,
    commentId: number,
  ): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      `${this.getTableUrl(grantId, tableId)}/comments/${commentId}`,
    );
  }

  public deleteTableIssueComment(
    grantId: number,
    tableId: number,
    checkId: string,
    commentId: number,
  ): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      `${this.getTableUrl(
        grantId,
        tableId,
      )}/checks/${checkId}/comments/${commentId}`,
    );
  }

  public getTableComments(
    grantId: number,
    tableId: number,
  ): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      `${this.getTableUrl(grantId, tableId)}/comments`,
    );
  }

  public getTableIssueComments(
    grantId: number,
    tableId: number,
    checkId: string,
    reportingPeriodId?: number,
  ): Observable<FparTableIssueCommentApiResponse> {
    return this.http.get<ApiResponse>(
      `${this.getTableUrl(grantId, tableId)}/checks/${checkId}/comments`,
      { params: { reportingPeriodId } },
    );
  }

  public getTable(
    grantId: number,
    tableId: number,
    reportingPeriodId?: number,
  ): Observable<FparTableApiResponse> {
    return this.http.get<FparTableApiResponse>(
      this.getTableUrl(grantId, tableId),
      { params: { reportingPeriodId } },
    );
  }

  public updateTable(
    grantId: number,
    tableId: number,
    payload: FparTablePayload,
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.getTableUrl(grantId, tableId),
      payload,
    );
  }

  public updateTableComment(
    grantId: number,
    tableId: number,
    payload: FparTableCommentPayload,
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      `${this.getTableUrl(grantId, tableId)}/comments/${payload.id}`,
      payload,
    );
  }

  public updateTableIssue(
    grantId: number,
    issueId: number,
    payload: FparTableIssuePayload,
  ): Observable<ApiResponse> {
    return this.http.patch<ApiResponse>(
      `${this.baseUrl}${grantId}/issues/${issueId}`,
      payload,
    );
  }

  public updateTableIssueComment(
    grantId: number,
    tableId: number,
    checkId: string,
    commentId: number,
    payload: FparTableIssueCommentPayload,
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      `${this.getTableUrl(
        grantId,
        tableId,
      )}/checks/${checkId}/comments/${commentId}`,
      payload,
    );
  }

  private getTableUrl(grantId: number, tableId: number): string {
    return `${this.baseUrl}${grantId}/tables/${tableId}`;
  }
}
