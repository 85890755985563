import { ApiResponse } from './api-response.interface';

export interface ColumnMapping {
  columnId: number;
  name: string;
}

export interface FileUploadData {
  fileName: string;
  columnMapping: ColumnMapping[];
}

export interface FileUploadTaskPayload {
  combined?: FileUploadData;
  encounters?: FileUploadData;
  labs?: FileUploadData;
  validationOnly: boolean;
}

export interface PresignedFields {
  bucket: string;
  'X-Amz-Algorithm': string;
  'X-Amz-Credential': string;
  'X-Amz-Date': string;
  'X-Amz-Security-Token': string;
  Policy: string;
  'X-Amz-Signature': string;
}

export interface PresignedPost {
  url: string;
  fields: PresignedFields;
}

export enum UploadFileType {
  Combined = 'combined',
  Encounters = 'encounters',
  Labs = 'labs',
}

export interface FileUploadPresignedPost {
  presignedPosts: {
    [key in UploadFileType]?: PresignedPost;
  }
}

export interface UserUploadedFiles {
  combined?: File;
  encounters?: File;
  labs?: File;
}

export interface UserUploadedFileData {
  [key: string]: string | number;
}

export interface UserUploadedFileDataFrame {
  [key: string]: (string | number)[];
}

export type FileUploadTaskApiResponse = ApiResponse<FileUploadPresignedPost[]>;
