import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FparTableCellIssue } from '@core/models';

@Component({
  selector: 'app-fpar-sub-issue',
  templateUrl: './fpar-sub-issue.component.html',
  styleUrls: ['./fpar-sub-issue.component.scss'],
})
export class FparSubIssueComponent implements OnInit {
  @Input() canResolve = false;
  @Input() flatDisplay = false;
  @Input() subIssues: FparTableCellIssue[] = [];
  @Output() activateSubIssue = new EventEmitter<FparTableCellIssue>();
  public panelOpen = false;

  constructor() {}

  ngOnInit(): void {}

  public setActiveSubIssue(subIssue: FparTableCellIssue, $event: Event): void {
    $event.preventDefault();
    document
      .getElementById(`${subIssue.columnId}-${subIssue.rowId}`)
      .scrollIntoView({ behavior: 'smooth', block: 'center' });

    this.activateSubIssue.emit(subIssue);
  }
}
