import { KeyValuePair } from '@core/models';

export const errorStrings: KeyValuePair[] = [
  {
    key: 'invalid_credentials',
    value: 'Please check your username or password and try again.',
  },
  {
    key: 'server_connect_failure',
    value:
      'There was a problem connecting to the server.  Please try again in a few minutes.',
  },
  {
    key: 'user_not_found',
    value: 'The email address you have entered was not found.',
  },
];
