import {
  ApiError,
  Card,
  CardDetails,
  DashboardBenchmark,
  DashboardFilterData,
  GrantSubmissionReviewStatus,
  KeyDate,
  ReportingPeriod,
  ReportingPeriodState,
} from '@core/models';

export interface State {
  benchmarks: DashboardBenchmark[];
  benchmarksError: ApiError;
  benchmarksLoading: boolean;
  cardDetails: CardDetails;
  cardDetailsError: ApiError;
  cardDetailsLoading: boolean;
  cards: Card[];
  cardsError: ApiError;
  cardsLoading: boolean;
  filterData: DashboardFilterData[];
  filterDataError: ApiError;
  filterDataLoading: boolean;
  grantSubmissionReviewStatus: GrantSubmissionReviewStatus;
  grantSubmissionReviewStatusError: ApiError;
  grantSubmissionReviewStatusLoading: boolean;
  keyDates: KeyDate[];
  keyDatesError: ApiError;
  keyDatesLoading: boolean;
  reportingPeriods: ReportingPeriod[];
  reportingPeriodsError: ApiError;
  reportingPeriodsLoading: boolean;
  reportingPeriodState: ReportingPeriodState;
  reportingPeriodStateError: ApiError;
  reportingPeriodStateLoading: boolean;
  selectedBenchmark: DashboardBenchmark;
  selectedGrantId: number;
  selectedReportingPeriod: ReportingPeriod;
}

export const initialState: State = {
  benchmarks: [],
  benchmarksError: null,
  benchmarksLoading: false,
  cardDetails: null,
  cardDetailsError: null,
  cardDetailsLoading: false,
  cards: [],
  cardsError: null,
  cardsLoading: false,
  filterData: [],
  filterDataError: null,
  filterDataLoading: false,
  grantSubmissionReviewStatus: null,
  grantSubmissionReviewStatusError: null,
  grantSubmissionReviewStatusLoading: false,
  keyDates: [],
  keyDatesError: null,
  keyDatesLoading: false,
  reportingPeriods: [],
  reportingPeriodsError: null,
  reportingPeriodsLoading: false,
  reportingPeriodState: null,
  reportingPeriodStateError: null,
  reportingPeriodStateLoading: false,
  selectedBenchmark: null,
  selectedGrantId: null,
  selectedReportingPeriod: null,
};
