import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {
  AppStoreState,
  GrantSelectors,
  ReportingPeriodSelectors,
} from '@app/store';
import { ReportingPeriodState, UserGrantItem } from '@core/models';
import { Store } from '@ngrx/store';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PreferredApproachGuard {
  private grantDetails$: Observable<UserGrantItem>;
  private reportingPeriodState$: Observable<ReportingPeriodState>;

  constructor(
    private router: Router,
    private store$: Store<AppStoreState.State>,
  ) {
    this.grantDetails$ = this.store$.select(GrantSelectors.selectGrantDetails);
    this.reportingPeriodState$ = this.store$.select(
      ReportingPeriodSelectors.selectReportingPeriodState,
    );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    return combineLatest([this.grantDetails$, this.reportingPeriodState$]).pipe(
      filter(
        ([grantDetails, reportingPeriodState]) =>
          !!grantDetails && !!reportingPeriodState,
      ),
      map(([grantDetails, reportingPeriodState]) => {
        const routeToUpload =
          (grantDetails?.preferredApproach && !grantDetails.hasUploaded) ||
          (!reportingPeriodState.submissionOpen &&
            reportingPeriodState.validationOpen);
        return routeToUpload
          ? this.router.parseUrl('/grant/portal/upload/start')
          : true;
      }),
    );
  }
}
