import { Action, createReducer, on } from '@ngrx/store';
import {
  createReportingPeriod,
  createReportingPeriodFailure,
  createReportingPeriodSuccess,
  deleteReportingPeriod,
  deleteReportingPeriodSuccess,
  deleteReportingPeriodFailure,
  getReportingPeriods,
  getReportingPeriodsFailure,
  getReportingPeriodsSuccess,
  getReportingPeriodState,
  getReportingPeriodStateFailure,
  getReportingPeriodStateSuccess,
  updateReportingPeriod,
  updateReportingPeriodFailure,
  updateReportingPeriodSuccess,
} from './actions';
import { initialState, State } from './state';

export const reportingPeriodFeatureKey = 'reporting-period';

const settingsReducer = createReducer(
  initialState,
  on(createReportingPeriod, (state) => ({
    ...state,
    createReportingPeriodLoading: true,
  })),
  on(
    createReportingPeriodFailure,
    (state, { error: createReportingPeriodError }) => ({
      ...state,
      createReportingPeriodError,
      createReportingPeriodLoading: false,
    }),
  ),
  on(createReportingPeriodSuccess, (state) => ({
    ...state,
    createReportingPeriodLoading: false,
  })),
  on(deleteReportingPeriod, (state) => ({
    ...state,
    deleteReportingPeriodLoading: true,
  })),
  on(
    deleteReportingPeriodFailure,
    (state, { error: deleteReportingPeriodError }) => ({
      ...state,
      deleteReportingPeriodError,
      deleteReportingPeriodLoading: false,
    }),
  ),
  on(deleteReportingPeriodSuccess, (state) => ({
    ...state,
    deleteReportingPeriodLoading: false,
  })),
  on(getReportingPeriods, (state) => ({
    ...state,
    reportingPeriods: [],
    reportingPeriodsLoading: true,
  })),
  on(getReportingPeriodsFailure, (state, { error: reportingPeriodsError }) => ({
    ...state,
    reportingPeriodsError,
    reportingPeriodsLoading: false,
  })),
  on(getReportingPeriodsSuccess, (state, { response }) => ({
    ...state,
    reportingPeriods: response.data,
    reportingPeriodsLoading: false,
  })),
  on(getReportingPeriodState, (state) => ({
    ...state,
    reportingPeriodStateLoading: true,
  })),
  on(getReportingPeriodStateFailure, (state, { error, message }) => ({
    ...state,
    reportingPeriodState: null,
    reportingPeriodStateError: { error, message },
    reportingPeriodStateLoading: false,
  })),
  on(getReportingPeriodStateSuccess, (state, { reportingPeriodState }) => ({
    ...state,
    reportingPeriodState: reportingPeriodState.data,
    reportingPeriodStateLoading: false,
  })),
  on(updateReportingPeriod, (state) => ({
    ...state,
    updateReportingPeriodLoading: true,
  })),
  on(
    updateReportingPeriodFailure,
    (state, { error: updateReportingPeriodError }) => ({
      ...state,
      updateReportingPeriodError,
      updateReportingPeriodLoading: false,
    }),
  ),
  on(updateReportingPeriodSuccess, (state) => ({
    ...state,
    updateReportingPeriodLoading: false,
  })),
);

export const reducer = (state: State | undefined, action: Action) =>
  settingsReducer(state, action);
