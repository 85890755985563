import { Injectable } from '@angular/core';
import { handleError } from '@core/helpers/format-error';
import { SiteService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  createSite,
  createSiteFailure,
  createSiteSuccess,
  getClinicServices,
  getClinicServicesFailure,
  getClinicServicesSuccess,
  getGrantIdByCldbSite,
  getGrantIdByCldbSiteFailure,
  getGrantIdByCldbSiteSuccess,
  getHistoryOfChanges,
  getHistoryOfChangesFailure,
  getHistoryOfChangesSuccess,
  getSiteDetails,
  getSiteDetailsFailure,
  getSiteDetailsSuccess,
  getSites,
  getSitesFailure,
  getSitesSuccess,
  postNoaFileToS3Failure,
  postNoaFileToS3Success,
  publishSite,
  publishSiteFailure,
  publishSiteSuccess,
  removeSite,
  removeSiteFailure,
  removeSiteSuccess,
  updateProvidesServices,
  updateProvidesServicesSuccess,
  updateServices,
  updateServicesFailure,
  updateServicesSuccess,
  updateSite,
  updateSiteFailure,
  updateSiteHours,
  updateSiteHoursFailure,
  updateSiteHoursSuccess,
  updateSiteSuccess,
} from './actions';
import { selectNoaFile } from './selectors';

@Injectable()
export class SiteEffects {
  createSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createSite),
      switchMap(({ grantId, payload }) =>
        this.siteService.createSite(grantId, payload).pipe(
          map((response) => createSiteSuccess({ response })),
          catchError((error) => handleError(error, createSiteFailure)),
        ),
      ),
    ),
  );

  getClinicServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getClinicServices),
      switchMap(() =>
        this.siteService.getClinicServices().pipe(
          map((clinicServices) => getClinicServicesSuccess({ clinicServices })),
          catchError((error) => handleError(error, getClinicServicesFailure)),
        ),
      ),
    ),
  );

  getGrantIdByCldbSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGrantIdByCldbSite),
      switchMap(({ siteId }) =>
        this.siteService.getGrantIdByCldbSite(siteId).pipe(
          map((associatedGrant) =>
            getGrantIdByCldbSiteSuccess({ associatedGrant }),
          ),
          catchError((error) =>
            handleError(error, getGrantIdByCldbSiteFailure),
          ),
        ),
      ),
    ),
  );

  getHistoryOfChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getHistoryOfChanges),
      switchMap(({ grantId, siteId }) =>
        this.siteService.getHistoryOfChanges(grantId, siteId).pipe(
          map((historyOfChanges) =>
            getHistoryOfChangesSuccess({ historyOfChanges }),
          ),
          catchError((error) => handleError(error, getHistoryOfChangesFailure)),
        ),
      ),
    ),
  );

  getSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSites),
      switchMap(({ grantId, subrecipientId }) =>
        this.siteService.getSites(grantId, subrecipientId).pipe(
          map((sites) => getSitesSuccess({ sites })),
          catchError((error) => handleError(error, getSitesFailure)),
        ),
      ),
    ),
  );

  getSiteDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSiteDetails),
      switchMap(({ grantId, siteId }) =>
        this.siteService.getSiteDetails(grantId, siteId).pipe(
          map((siteDetails) => getSiteDetailsSuccess({ siteDetails })),
          catchError((error) => handleError(error, getSiteDetailsFailure)),
        ),
      ),
    ),
  );

  removeSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeSite),
      switchMap(({ grantId, siteId, payload }) =>
        this.siteService.removeSite(grantId, siteId, payload).pipe(
          map((response) => removeSiteSuccess({ response })),
          catchError((error) => handleError(error, removeSiteFailure)),
        ),
      ),
    ),
  );

  publishSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(publishSite),
      switchMap(({ grantId, siteId, published }) =>
        this.siteService.publishSite(grantId, siteId, published).pipe(
          map((publishSiteResponse) =>
            publishSiteSuccess({ publishSiteResponse }),
          ),
          catchError((error) => handleError(error, publishSiteFailure)),
        ),
      ),
    ),
  );

  updateProvidesServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProvidesServices),
      map(({ selection }) => updateProvidesServicesSuccess({ selection })),
    ),
  );

  updateServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateServices),
      switchMap(({ grantId, siteId, payload }) =>
        this.siteService.updateServices(grantId, siteId, payload).pipe(
          map((updateServicesResponse) =>
            updateServicesSuccess({ updateServicesResponse }),
          ),
          catchError((error) => handleError(error, updateServicesFailure)),
        ),
      ),
    ),
  );

  updateSite$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSite),
      switchMap(({ grantId, siteId, payload }) =>
        this.siteService.updateSite(grantId, siteId, payload).pipe(
          map((updateSiteResponse) =>
            updateSiteSuccess({ updateSiteResponse }),
          ),
          catchError((error) => handleError(error, updateSiteFailure)),
        ),
      ),
    ),
  );

  updateSiteHours$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSiteHours),
      switchMap(({ grantId, siteId, payload }) =>
        this.siteService.updateSiteHours(grantId, siteId, payload).pipe(
          map((updateSiteHoursResponse) =>
            updateSiteHoursSuccess({ updateSiteHoursResponse }),
          ),
          catchError((error) => handleError(error, updateSiteHoursFailure)),
        ),
      ),
    ),
  );

  uploadNoaFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createSiteSuccess, removeSiteSuccess),
      withLatestFrom(this.store$.select(selectNoaFile)),
      switchMap(([response, noaFile]) =>
        this.siteService
          .postNoaFileToS3(response.response.data[0], noaFile)
          .pipe(
            map((postFileResponse) =>
              postNoaFileToS3Success({ postFileResponse }),
            ),
            catchError((error) => handleError(error, postNoaFileToS3Failure)),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private siteService: SiteService,
    private store$: Store,
  ) {}
}
