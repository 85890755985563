import { State as RootState } from '@app/store/app-state';
import { ApiError, DataQualityResults, FparTable } from '@core/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { fparTableFeatureKey } from './reducer';
import { State } from './state';

const dataQualityCheckError = (state: State): ApiError =>
  state.dataQualityCheckError;
const dataQualityCheckLoading = (state: State): boolean =>
  state.dataQualityCheckLoading;

const dataQualityResults = (state: State): DataQualityResults =>
  state.dataQualityResults;
const dataQualityResultsError = (state: State): ApiError =>
  state.dataQualityResultsError;
const dataQualityResultsLoading = (state: State): boolean =>
  state.dataQualityResultsLoading;

const getAddTableCommentError = (state: State): ApiError =>
  state.addTableCommentError;
const getAddTableCommentLoading = (state: State): boolean =>
  state.addTableCommentLoading;

const getAddTableIssueCommentError = (state: State): ApiError =>
  state.addTableIssueCommentError;
const getAddTableIssueCommentLoading = (state: State): boolean =>
  state.addTableIssueCommentLoading;

const getDeleteTableCommentError = (state: State): ApiError =>
  state.deleteTableCommentError;
const getDeleteTableCommentLoading = (state: State): boolean =>
  state.deleteTableCommentLoading;

const getDeleteTableIssueCommentError = (state: State): ApiError =>
  state.deleteTableIssueCommentError;
const getDeleteTableIssueCommentLoading = (state: State): boolean =>
  state.deleteTableIssueCommentLoading;

const getHoldTableIssueError = (state: State): ApiError =>
  state.holdTableIssueError;
const getHoldTableIssueLoading = (state: State): boolean =>
  state.holdTableIssueLoading;

const getTable = (state: State): FparTable => state.table;
const getTableError = (state: State): ApiError => state.tableError;
const getTableIssuesError = (state: State): ApiError => state.tableIssuesError;
const getTableIssuesLoading = (state: State): boolean =>
  state.tableIssuesLoading;
const getTableLoading = (state: State): boolean => state.tableLoading;

const getTableIssueCommentsError = (state: State): ApiError =>
  state.tableIssueCommentsError;
const getTableIssueCommentsLoading = (state: State): boolean =>
  state.tableIssueCommentsLoading;

const getUpdateTableError = (state: State): ApiError => state.updateTableError;
const getUpdateTableLoading = (state: State): boolean =>
  state.updateTableLoading;
const getUpdateTableCommentError = (state: State): ApiError =>
  state.updateTableCommentError;
const getUpdateTableCommentLoading = (state: State): boolean =>
  state.updateTableCommentLoading;

const getUpdateTableIssueError = (state: State): ApiError =>
  state.updateTableIssueError;
const getUpdateTableIssueLoading = (state: State): boolean =>
  state.updateTableIssueLoading;

const getUpdateTableIssueCommentError = (state: State): ApiError =>
  state.updateTableIssueCommentError;
const getUpdateTableIssueCommentLoading = (state: State): boolean =>
  state.updateTableIssueCommentLoading;

export const selectTableState = createFeatureSelector<RootState, State>(
  fparTableFeatureKey,
);

export const selectDataQualityCheckError = createSelector(
  selectTableState,
  dataQualityCheckError,
);
export const selectDataQualityCheckLoading = createSelector(
  selectTableState,
  dataQualityCheckLoading,
);
export const selectDataQualityResults = createSelector(
  selectTableState,
  dataQualityResults,
);
export const selectDataQualityResultsError = createSelector(
  selectTableState,
  dataQualityResultsError,
);
export const selectDataQualityResultsLoading = createSelector(
  selectTableState,
  dataQualityResultsLoading,
);
export const selectAddTableCommentError = createSelector(
  selectTableState,
  getAddTableCommentError,
);
export const selectAddTableCommentLoading = createSelector(
  selectTableState,
  getAddTableCommentLoading,
);

export const selectAddTableIssueCommentError = createSelector(
  selectTableState,
  getAddTableIssueCommentError,
);
export const selectAddTableIssueCommentLoading = createSelector(
  selectTableState,
  getAddTableIssueCommentLoading,
);

export const selectDeleteTableCommentError = createSelector(
  selectTableState,
  getDeleteTableCommentError,
);
export const selectDeleteTableCommentLoading = createSelector(
  selectTableState,
  getDeleteTableCommentLoading,
);

export const selectDeleteTableIssueCommentError = createSelector(
  selectTableState,
  getDeleteTableIssueCommentError,
);
export const selectDeleteTableIssueCommentLoading = createSelector(
  selectTableState,
  getDeleteTableIssueCommentLoading,
);

export const selectHoldTableIssueError = createSelector(
  selectTableState,
  getHoldTableIssueError,
);
export const selectHoldTableIssueLoading = createSelector(
  selectTableState,
  getHoldTableIssueLoading,
);

export const selectTable = createSelector(selectTableState, getTable);
export const selectTableError = createSelector(selectTableState, getTableError);
export const selectTableLoading = createSelector(
  selectTableState,
  getTableLoading,
);
export const selectTableIssuesError = createSelector(
  selectTableState,
  getTableIssuesError,
);
export const selectTableIssuesLoading = createSelector(
  selectTableState,
  getTableIssuesLoading,
);

export const selectTableIssueCommentsError = createSelector(
  selectTableState,
  getTableIssueCommentsError,
);
export const selectTableIssueCommentsLoading = createSelector(
  selectTableState,
  getTableIssueCommentsLoading,
);

export const selectUpdateTableError = createSelector(
  selectTableState,
  getUpdateTableError,
);
export const selectUpdateTableLoading = createSelector(
  selectTableState,
  getUpdateTableLoading,
);

export const selectUpdateTableCommentError = createSelector(
  selectTableState,
  getUpdateTableCommentError,
);
export const selectUpdateTableCommentLoading = createSelector(
  selectTableState,
  getUpdateTableCommentLoading,
);

export const selectUpdateTableIssueError = createSelector(
  selectTableState,
  getUpdateTableIssueError,
);
export const selectUpdateTableIssueLoading = createSelector(
  selectTableState,
  getUpdateTableIssueLoading,
);

export const selectUpdateTableIssueCommentError = createSelector(
  selectTableState,
  getUpdateTableIssueCommentError,
);
export const selectUpdateTableIssueCommentLoading = createSelector(
  selectTableState,
  getUpdateTableIssueCommentLoading,
);
