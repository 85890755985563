import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  OverviewReportApiResponse,
  RemovedUserReportApiResponse,
} from '@core/models';
import { environment as env } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private baseUrl = `${env.apiURL}api/v1`;
  private reportsUrl = `${this.baseUrl}/reports`;

  constructor(private http: HttpClient) {}

  public getOverviewReport(): Observable<OverviewReportApiResponse> {
    return this.http.get<OverviewReportApiResponse>(
      `${this.reportsUrl}/submissions`,
    );
  }

  public getRemovedUserReport(): Observable<RemovedUserReportApiResponse> {
    return this.http.get<RemovedUserReportApiResponse>(
      `${this.reportsUrl}/removed-users`,
    );
  }
}
