/* eslint-disable @typescript-eslint/naming-convention */
import { ResourceAccessApiResponse, ResourceAccessPayload } from '@core/models';
import { createAction, props } from '@ngrx/store';

export enum UserActionTypes {
  GET_RESOURCE_ACCESS_FAILURE = '[User] Get Resources Failure',
  GET_RESOURCE_ACCESS_REQUEST = '[User] Get Resources',
  GET_RESOURCE_ACCESS_SUCCESS = '[User] Get Resources Success',
}

export const getResourceAccess = createAction(
  UserActionTypes.GET_RESOURCE_ACCESS_REQUEST,
  props<{ payload: ResourceAccessPayload }>(),
);
export const getResourceAccessFailure = createAction(
  UserActionTypes.GET_RESOURCE_ACCESS_FAILURE,
  props<{ message: string }>(),
);
export const getResourceAccessSuccess = createAction(
  UserActionTypes.GET_RESOURCE_ACCESS_SUCCESS,
  props<{ resourceAccess: ResourceAccessApiResponse }>(),
);
