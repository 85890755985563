import { Action, createReducer, on } from '@ngrx/store';
import {
  associateSoftwareToken,
  associateSoftwareTokenFailure,
  associateSoftwareTokenSuccess,
  changeExpiredPassword,
  changeExpiredPasswordFailure,
  changeExpiredPasswordSuccess,
  confirmCustomSignIn,
  confirmCustomSignInFailure,
  confirmSignIn,
  confirmSignInFailure,
  confirmSignInSuccess,
  getInputVerificationCode,
  getInputVerificationCodeFailure,
  getInputVerificationCodeSuccess,
  resetPassword,
  resetPasswordFailure,
  resetPasswordSuccess,
  sendPasswordResetCode,
  sendPasswordResetCodeFailure,
  sendPasswordResetCodeSuccess,
  setMFASelection,
  setMFASelectionFailure,
  setMFASelectionSuccess,
  setNewUser,
  setNewUserFailure,
  setNewUserSuccess,
  signedOutFromInterceptor,
  signIn,
  signInFailure,
  signInSuccess,
  signOut,
  signOutFailure,
  signOutSuccess,
  updateUserAttributes,
  updateUserAttributesFailure,
  updateUserAttributesSuccess,
  verifyAttribute,
  verifyAttributeFailure,
  verifyAttributeSuccess,
  verifySoftwareToken,
  verifySoftwareTokenFailure,
  verifySoftwareTokenSuccess,
} from './actions';
import { initialState, State } from './state';

export const authenticationFeatureKey = 'authentication';

const authenticationReducer = createReducer(
  initialState,
  on(associateSoftwareToken, (state) => ({
    ...state,
    associateSoftwareTokenLoading: true,
  })),
  on(associateSoftwareTokenFailure, (state, { error, message }) => ({
    ...state,
    associateSoftwareToken: null,
    associateSoftwareTokenError: { error, message },
    associateSoftwareTokenLoading: false,
  })),
  on(associateSoftwareTokenSuccess, (state, { response }) => ({
    ...state,
    associateSoftwareToken: response,
    associateSoftwareTokenLoading: false,
  })),
  on(changeExpiredPassword, (state) => ({
    ...state,
    changeExpiredPasswordLoading: true,
  })),
  on(changeExpiredPasswordFailure, (state, { error, message }) => ({
    ...state,
    changeExpiredPasswordError: { error, message },
    changeExpiredPasswordLoading: false,
  })),
  on(changeExpiredPasswordSuccess, (state) => ({
    ...state,
    changeExpiredPasswordError: null,
    changeExpiredPasswordLoading: false,
  })),
  on(confirmSignIn, (state) => ({
    ...state,
    confirmSignInLoading: true,
  })),
  on(confirmSignInFailure, (state, { error, message }) => ({
    ...state,
    confirmSignIn: null,
    confirmSignInError: { error, message },
    confirmSignInLoading: false,
  })),
  on(confirmSignInSuccess, (state, { response }) => ({
    ...state,
    confirmSignIn: response,
    confirmSignInFailure: null,
    confirmSignInLoading: false,
  })),
  on(confirmCustomSignIn, (state) => ({
    ...state,
    confirmCustomSignInLoading: true,
  })),
  on(confirmCustomSignInFailure, (state, { error, message }) => ({
    ...state,
    confirmCustomSignIn: null,
    confirmCustomSignInError: { error, message },
    confirmCustomSignInLoading: false,
  })),
  on(confirmSignInSuccess, (state, { response }) => ({
    ...state,
    confirmCustomSignIn: response,
    confirmCustomSignInError: null,
    confirmCustomSignInLoading: false,
  })),
  on(getInputVerificationCode, (state) => ({
    ...state,
    inputVerificationCodeLoading: true,
  })),
  on(getInputVerificationCodeFailure, (state, { error, message }) => ({
    ...state,
    inputVerificationCode: null,
    inputVerificationCodeError: { error, message },
    inputVerificationCodeLoading: false,
  })),
  on(getInputVerificationCodeSuccess, (state, { response }) => ({
    ...state,
    inputVerificationCode: response,
    inputVerificationCodeLoading: false,
  })),
  on(resetPassword, (state) => ({
    ...state,
    resetPasswordLoading: true,
  })),
  on(resetPasswordFailure, (state, { error, message }) => ({
    ...state,
    resetPassword: null,
    resetPasswordError: { error, message },
    resetPasswordLoading: false,
  })),
  on(resetPasswordSuccess, (state, { response }) => ({
    ...state,
    resetPassword: response,
    resetPasswordLoading: false,
  })),
  on(sendPasswordResetCode, (state) => ({
    ...state,
    sendPasswordResetCodeLoading: true,
  })),
  on(sendPasswordResetCodeFailure, (state, { error, message }) => ({
    ...state,
    sendPasswordResetCode: null,
    sendPasswordResetCodeError: { error, message },
    sendPasswordResetCodeLoading: false,
  })),
  on(sendPasswordResetCodeSuccess, (state, { response }) => ({
    ...state,
    sendPasswordResetCode: response,
    sendPasswordResetCodeLoading: false,
  })),
  on(setMFASelection, (state) => ({
    ...state,
    setMFASelectionLoading: true,
  })),
  on(setMFASelectionFailure, (state, { error, message }) => ({
    ...state,
    setMFASelection: null,
    setMFASelectionError: { error, message },
    setMFASelectionLoading: false,
  })),
  on(setMFASelectionSuccess, (state, { response }) => ({
    ...state,
    setMFASelection: response,
    setMFASelectionLoading: false,
  })),
  on(setNewUser, (state) => ({
    ...state,
    setNewUserLoading: true,
  })),
  on(setNewUserFailure, (state, { error, message }) => ({
    ...state,
    setNewUser: null,
    setNewUserError: { error, message },
    setNewUserLoading: false,
  })),
  on(setNewUserSuccess, (state, { response }) => ({
    ...state,
    setNewUser: response,
    setNewUserLoading: false,
  })),
  on(signedOutFromInterceptor, (state) => ({
    ...state,
    signedOutFromInterceptor: true,
  })),
  on(signIn, (state) => ({
    ...state,
    signInLoading: true,
  })),
  on(signInFailure, (state, { error, message }) => ({
    ...state,
    signIn: null,
    signInError: { error, message },
    signInLoading: false,
  })),
  on(signInSuccess, (state, { response }) => ({
    ...state,
    signedOutFromInterceptor: false,
    signIn: response,
    signInLoading: false,
  })),
  on(signOut, (state) => ({
    ...state,
    signOutLoading: true,
  })),
  on(signOutFailure, (state, { error, message }) => ({
    ...state,
    signOut: null,
    signOutError: { error, message },
    signOutLoading: false,
  })),
  on(signOutSuccess, (state) => ({
    ...state,
    signOutLoading: false,
  })),
  on(updateUserAttributes, (state) => ({
    ...state,
    updateUserAttributesLoading: true,
  })),
  on(updateUserAttributesFailure, (state, { error, message }) => ({
    ...state,
    updateUserAttributes: null,
    updateUserAttributesError: { error, message },
    updateUserAttributesLoading: false,
  })),
  on(updateUserAttributesSuccess, (state, { response }) => ({
    ...state,
    updateUserAttributes: response,
    updateUserAttributesLoading: false,
  })),
  on(verifyAttribute, (state) => ({
    ...state,
    verifyAttributeLoading: true,
  })),
  on(verifyAttributeFailure, (state, { error, message }) => ({
    ...state,
    verifyAttribute: null,
    verifyAttributeError: { error, message },
    verifyAttributeLoading: false,
  })),
  on(verifyAttributeSuccess, (state, { response }) => ({
    ...state,
    verifyAttribute: response,
    verifyAttributeLoading: false,
  })),

  on(verifySoftwareToken, (state) => ({
    ...state,
    verifySoftwareTokenLoading: true,
  })),
  on(verifySoftwareTokenFailure, (state, { error, message }) => ({
    ...state,
    verifySoftwareToken: null,
    verifySoftwareTokenError: { error, message },
    verifySoftwareTokenLoading: false,
  })),
  on(verifySoftwareTokenSuccess, (state, { response }) => ({
    ...state,
    verifySoftwareToken: response,
    verifySoftwareTokenLoading: false,
  })),
);

export const reducer = (state: State | undefined, action: Action) =>
  authenticationReducer(state, action);
