import { HttpErrorResponse } from '@angular/common/http';
import { ApiResponse } from '@core/models';

import { createAction, props } from '@ngrx/store';

export enum FparReportActionTypes {
  GET_FPAR_REPORT_REQUEST = '[FPAR Report] Get FPAR report request',
  GET_FPAR_REPORT_FAILURE = '[FPAR Report] Get FPAR report failure',
  GET_FPAR_REPORT_SUCCESS = '[FPAR Report] Get FPAR report success',
}

export const getFparReport = createAction(
  FparReportActionTypes.GET_FPAR_REPORT_REQUEST,
  props<{
    grantId: number;
    reportingPeriodId?: number;
  }>(),
);
export const getFparReportFailure = createAction(
  FparReportActionTypes.GET_FPAR_REPORT_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getFparReportSuccess = createAction(
  FparReportActionTypes.GET_FPAR_REPORT_SUCCESS,
  props<{ response: ApiResponse }>(),
);
