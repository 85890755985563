import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AccountEffects } from './effects';
import { accountFeatureKey, reducer } from './reducer';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([AccountEffects]),
    StoreModule.forFeature(accountFeatureKey, reducer),
  ],
})
export class AccountModule {}
