/* eslint-disable @typescript-eslint/naming-convention */
import { HttpErrorResponse } from '@angular/common/http';
import {
  AddSiteApiResponse,
  AddSitePayload,
  ApiResponse,
  ClinicServicesApiResponse,
  HistoryOfChangesApiResponse,
  RemoveSiteApiResponse,
  RemoveSitePayload,
  ServicesPayload,
  SiteApiResponse,
  SiteDetailsApiResponse,
  SiteHoursOfOperationPayload,
  SiteLocationPayload,
  UserGrantSelectionApiResponse,
} from '@core/models';
import { createAction, props } from '@ngrx/store';

export enum SiteActionTypes {
  CREATE_SITE_FAILURE = '[Site] Create Site Failure',
  CREATE_SITE_REQUEST = '[Site] Create Site Request',
  CREATE_SITE_SUCCESS = '[Site] Create Site Success',
  GET_CLINIC_SERVICES_FAILURE = '[Site] Get Clinic Services Failure',
  GET_CLINIC_SERVICES_REQUEST = '[Site] Get Clinic Services Request',
  GET_CLINIC_SERVICES_SUCCESS = '[Site] Get Clinic Services Success',
  GET_GRANT_ID_BY_CLDB_SITE = '[Site] Get Grant ID by CLDB Site',
  GET_GRANT_ID_BY_CLDB_SITE_FAILURE = '[Site] Get Grant ID by CLDB Site Failure',
  GET_GRANT_ID_BY_CLDB_SITE_SUCCESS = '[Site] Get Grant ID by CLDB Site Success',
  GET_HISTORY_OF_CHANGES_FAILURE = '[HistoryOfChanges] Get History of Changes Failure',
  GET_HISTORY_OF_CHANGES_SERVICES_REQUEST = '[HistoryOfChanges] Get History of Changes Request',
  GET_HISTORY_OF_CHANGES_SERVICES_SUCCESS = '[HistoryOfChanges] Get History of Changes Success',
  GET_SITE_DETAILS_FAILURE = '[Site] Get Site Details Failure',
  GET_SITE_DETAILS_REQUEST = '[Site] Get Site Details Request',
  GET_SITE_DETAILS_SUCCESS = '[Site] Get Site Details Success',
  GET_SITES_FAILURE = '[Site] Get Sites Failure',
  GET_SITES_REQUEST = '[Site] Get Sites Request',
  GET_SITES_SUCCESS = '[Site] Get Sites Success',
  POST_NOA_FILE_TO_S3_FAILURE = '[Site] Post NOA File to S3 Failure',
  POST_NOA_FILE_TO_S3_SUCCESS = '[Site] Post NOA File to S3 Success',
  PUBLISH_SITE_FAILURE = '[Site] Publish Site Failure',
  PUBLISH_SITE_REQUEST = '[Site] Publish Site Request',
  PUBLISH_SITE_SUCCESS = '[Site] Publish Site Success',
  REMOVE_SITE_FAILURE = '[Site] Remove Site Failure',
  REMOVE_SITE_REQUEST = '[Site] Remove Site Request',
  REMOVE_SITE_SUCCESS = '[Site] Remove Site Success',
  RESET_SITE_DETAILS_REQUEST = '[Site] Reset Site Details Request',
  SET_NOA_FILE_REQUEST = '[Site] Set NOA File Request',
  UPDATE_PROVIDES_SERVICES_REQUEST = '[Site] Update Provides Services Request',
  UPDATE_PROVIDES_SERVICES_SUCCESS = '[Site] Update Provides Services Success',
  UPDATE_SERVICES_FAILURE = '[Site] Update Services Failure',
  UPDATE_SERVICES_REQUEST = '[Site] Update Services Request',
  UPDATE_SERVICES_SUCCESS = '[Site] Update Services Success',
  UPDATE_SITE_FAILURE = '[Site] Update Site Failure',
  UPDATE_SITE_REQUEST = '[Site] Update Site Request',
  UPDATE_SITE_SUCCESS = '[Site] Update Site Success',
  UPDATE_SITE_HOURS_FAILURE = '[Site] Update Site Hours Failure',
  UPDATE_SITE_HOURS_REQUEST = '[Site] Update Site Hours Request',
  UPDATE_SITE_HOURS_SUCCESS = '[Site] Update Site Hours Success',
}

export const createSite = createAction(
  SiteActionTypes.CREATE_SITE_REQUEST,
  props<{ grantId: number; payload: AddSitePayload }>(),
);
export const createSiteFailure = createAction(
  SiteActionTypes.CREATE_SITE_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const createSiteSuccess = createAction(
  SiteActionTypes.CREATE_SITE_SUCCESS,
  props<{ response: AddSiteApiResponse }>(),
);

export const getClinicServices = createAction(
  SiteActionTypes.GET_CLINIC_SERVICES_REQUEST,
);
export const getClinicServicesFailure = createAction(
  SiteActionTypes.GET_CLINIC_SERVICES_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getClinicServicesSuccess = createAction(
  SiteActionTypes.GET_CLINIC_SERVICES_SUCCESS,
  props<{ clinicServices: ClinicServicesApiResponse }>(),
);

export const getGrantIdByCldbSite = createAction(
  SiteActionTypes.GET_GRANT_ID_BY_CLDB_SITE,
  props<{ siteId: string }>(),
);
export const getGrantIdByCldbSiteFailure = createAction(
  SiteActionTypes.GET_GRANT_ID_BY_CLDB_SITE_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getGrantIdByCldbSiteSuccess = createAction(
  SiteActionTypes.GET_GRANT_ID_BY_CLDB_SITE_SUCCESS,
  props<{ associatedGrant: UserGrantSelectionApiResponse }>(),
);

export const getHistoryOfChanges = createAction(
  SiteActionTypes.GET_HISTORY_OF_CHANGES_SERVICES_REQUEST,
  props<{ grantId: number; siteId: string }>(),
);
export const getHistoryOfChangesFailure = createAction(
  SiteActionTypes.GET_HISTORY_OF_CHANGES_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getHistoryOfChangesSuccess = createAction(
  SiteActionTypes.GET_HISTORY_OF_CHANGES_SERVICES_SUCCESS,
  props<{ historyOfChanges: HistoryOfChangesApiResponse }>(),
);

export const getSites = createAction(
  SiteActionTypes.GET_SITES_REQUEST,
  props<{ grantId: number; subrecipientId?: number }>(),
);
export const getSitesFailure = createAction(
  SiteActionTypes.GET_SITES_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getSitesSuccess = createAction(
  SiteActionTypes.GET_SITES_SUCCESS,
  props<{ sites: SiteApiResponse }>(),
);

export const getSiteDetails = createAction(
  SiteActionTypes.GET_SITE_DETAILS_REQUEST,
  props<{ grantId: number; siteId: string }>(),
);
export const getSiteDetailsFailure = createAction(
  SiteActionTypes.GET_SITE_DETAILS_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getSiteDetailsSuccess = createAction(
  SiteActionTypes.GET_SITE_DETAILS_SUCCESS,
  props<{ siteDetails: SiteDetailsApiResponse }>(),
);

export const postNoaFileToS3Failure = createAction(
  SiteActionTypes.POST_NOA_FILE_TO_S3_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const postNoaFileToS3Success = createAction(
  SiteActionTypes.POST_NOA_FILE_TO_S3_SUCCESS,
  props<{ postFileResponse: null[] }>(),
);

export const publishSite = createAction(
  SiteActionTypes.PUBLISH_SITE_REQUEST,
  props<{ grantId: number; siteId: string; published: boolean }>(),
);
export const publishSiteFailure = createAction(
  SiteActionTypes.PUBLISH_SITE_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const publishSiteSuccess = createAction(
  SiteActionTypes.PUBLISH_SITE_SUCCESS,
  props<{ publishSiteResponse: ApiResponse }>(),
);

export const removeSite = createAction(
  SiteActionTypes.REMOVE_SITE_REQUEST,
  props<{ grantId: number; payload: RemoveSitePayload; siteId: string }>(),
);
export const removeSiteFailure = createAction(
  SiteActionTypes.REMOVE_SITE_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const removeSiteSuccess = createAction(
  SiteActionTypes.REMOVE_SITE_SUCCESS,
  props<{ response: RemoveSiteApiResponse }>(),
);

export const resetSiteDetails = createAction(
  SiteActionTypes.RESET_SITE_DETAILS_REQUEST,
);

export const setNoaFile = createAction(
  SiteActionTypes.SET_NOA_FILE_REQUEST,
  props<{ noaFile: File }>(),
);

export const updateProvidesServices = createAction(
  SiteActionTypes.UPDATE_PROVIDES_SERVICES_REQUEST,
  props<{ selection: boolean }>(),
);

export const updateProvidesServicesSuccess = createAction(
  SiteActionTypes.UPDATE_PROVIDES_SERVICES_SUCCESS,
  props<{ selection: boolean }>(),
);

export const updateServices = createAction(
  SiteActionTypes.UPDATE_SERVICES_REQUEST,
  props<{ grantId: number; siteId?: string; payload: ServicesPayload }>(),
);
export const updateServicesSuccess = createAction(
  SiteActionTypes.UPDATE_SERVICES_SUCCESS,
  props<{ updateServicesResponse: ApiResponse }>(),
);
export const updateServicesFailure = createAction(
  SiteActionTypes.UPDATE_SERVICES_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);

export const updateSite = createAction(
  SiteActionTypes.UPDATE_SITE_REQUEST,
  props<{
    grantId: number;
    siteId: string;
    payload: SiteLocationPayload;
  }>(),
);
export const updateSiteFailure = createAction(
  SiteActionTypes.UPDATE_SITE_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const updateSiteSuccess = createAction(
  SiteActionTypes.UPDATE_SITE_SUCCESS,
  props<{ updateSiteResponse: SiteApiResponse }>(),
);

export const updateSiteHours = createAction(
  SiteActionTypes.UPDATE_SITE_HOURS_REQUEST,
  props<{
    grantId: number;
    siteId: string;
    payload: SiteHoursOfOperationPayload;
  }>(),
);
export const updateSiteHoursFailure = createAction(
  SiteActionTypes.UPDATE_SITE_HOURS_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const updateSiteHoursSuccess = createAction(
  SiteActionTypes.UPDATE_SITE_HOURS_SUCCESS,
  props<{ updateSiteHoursResponse: ApiResponse }>(),
);
