export enum AppMenu {
  Account = 'account',
  Administration = 'settings',
  CoverSheet = 'profile-cover-sheet',
  MyFPAR = 'myfpar',
  MyGrants = 'my-grants',
  ClinicLocator = 'clinic-locator',
  Dashboard = 'dashboard',
  GrantPortal = 'grant-portal',
  Grants = 'grants',
  Help = 'help',
  ReviewPortal = 'review-portal',
  TableArchive = 'table-archive',
  Users = 'users',
}
