import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FparReportEffects } from './effects';
import { fparReportFeatureKey, reducer } from './reducer';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([FparReportEffects]),
    StoreModule.forFeature(fparReportFeatureKey, reducer),
  ],
})
export class FparReportModule {}
