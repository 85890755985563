import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import {
  resetClinicStepper,
  resetClinicStepperSuccess,
  updateStep,
  updateStepSuccess,
} from './actions';

@Injectable()
export class ClinicStepperEffects {
  resetClinicStepper$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetClinicStepper),
      map(() => resetClinicStepperSuccess()),
    ),
  );
  updateCurrentStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateStep),
      map(({ stepIndex }) => updateStepSuccess({ stepIndex })),
    ),
  );

  constructor(private actions$: Actions) {}
}
