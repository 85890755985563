export interface PasswordCriteria {
  [key: string]: boolean;
}
export const checkPasswordCriteria = (password: string): PasswordCriteria => {
  const criteria = {
    size: password.length > 7,
    minCapitals: !!password.match(/[A-Z]/g),
    minNumbers: !!password.match(/[0-9]/g),
    specialChar: !!password.match(/[!,@,#,$,%,^,&,*,?,_,~,-,£,(,)]/g),
  };

  return criteria;
};
