<form
  [formGroup]="tableForm"
  autocomplete="off"
  (ngSubmit)="submit()"
  [class.disabled]="disabled"
>
  <div class="scroll-container">
    <table mat-table [dataSource]="dataSource">
      <ng-container *ngFor="let column of table.columns">
        <ng-container
          [matColumnDef]="column.column"
          [sticky]="sticky && column.isLabel"
          [stickyEnd]="sticky && column.isTotal"
        >
          <th scope="col" mat-header-cell *matHeaderCellDef>
            <ng-container
              *ngIf="!column.range || checkColumnHeaderRange(column)"
            >
              {{ column.headerName }}
            </ng-container>
          </th>
          <td
            mat-cell
            *matCellDef="let element; let index = index"
            [formArrayName]="element.isOtherSpecify ? 'other' : 'table'"
            [class.active]="
              isHighlightedRow(element.rowId) &&
              isHighlightedColumn(column.column)
            "
          >
            <ng-container
              *ngIf="
                column.isId ||
                (column.isLabel &&
                  (!element.isOtherSpecify ||
                    (element.isOtherSpecify && readOnly)))
              "
            >
              {{ element[column.column] }}
            </ng-container>
            <ng-container *ngIf="column.isId && element.idLabel">
              {{ element.idLabel }}
            </ng-container>
            <ng-container
              *ngIf="column.isLabel && element.isOtherSpecify && !readOnly"
              [formGroupName]="getOtherIndex(index)"
            >
              <div class="flex-row other-input">
                <mat-form-field>
                  <mat-label>Other (specify)</mat-label>
                  <input matInput formControlName="text" title="Other" />
                </mat-form-field>
                <span
                  class="flex-row other-actions"
                  *ngIf="
                    element.rowId === latestOtherRowId ||
                    element.idLabel === latestOtherRowId
                  "
                >
                  <button
                    *ngIf="otherRows.length > 1"
                    mat-icon-button
                    type="button"
                    class="remove-other"
                    [disabled]="disabled"
                    (click)="removeOtherRow(element.idLabel ?? element.rowId)"
                    matTooltip="Remove this row"
                  >
                    <mat-icon>remove_circle_outline</mat-icon>
                  </button>
                  <button
                    [class.invisible]="otherRows.length === table.other.max"
                    mat-icon-button
                    type="button"
                    class="add-other"
                    [disabled]="disabled"
                    (click)="addOtherRow()"
                    matTooltip="Add another row"
                  >
                    <mat-icon>add_circle_outline</mat-icon>
                  </button>
                </span>
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                !element.rowId &&
                !column.isId &&
                !column.isLabel &&
                !column.isOtherSpecify &&
                !element.total &&
                table.rows[index + 1] &&
                table.rows[index + 1].hasOwnProperty(column.column)
              "
            >
              <span
                *ngIf="
                  !column.alternates || !checkColumnForAlternates(column, index)
                "
              >
                {{ column.headerName }}
              </span>
              <span *ngIf="checkColumnForAlternates(column, index)">
                {{ getAlternateColumnHeaderName(column, index) }}
              </span>
            </ng-container>
            <ng-container
              *ngIf="
                (element.rowId ||
                  (element.idLabel && element.isOtherSpecify)) &&
                columnTotals.hasOwnProperty(column.column) &&
                element.hasOwnProperty(column.column)
              "
              [formGroupName]="
                element.isOtherSpecify ? getOtherIndex(index) : index
              "
            >
              <app-number-input
                class="input"
                [class.active]="!readOnly"
                [decimals]="getColumnDecimals(column.column)"
                [formControlName]="column.column"
                [displayOnly]="readOnly"
                [isCurrency]="currency"
                [max]="maxInputSize"
                [hasSubIssue]="hasSubIssues(element.rowId, column.column)"
              >
              </app-number-input>
              <app-view-issue-link
                id="{{ column.column }}-{{ element.rowId }}"
                *ngIf="hasSubIssues(element.rowId, column.column)"
                (popoverClose)="onPopoverClosed($event)"
                (popoverOpen)="onPopoverOpen($event)"
                (next)="onNextSubIssue()"
                (previous)="onPreviousSubIssue()"
                [subIssue]="getSubIssue(element.rowId, column.column)"
                [subIssueIndex]="getSubIssueIndex(element.rowId, column.column)"
                [currentSubIssueIndex]="currentSubIssueIndex"
              >
              </app-view-issue-link>
            </ng-container>
            <ng-container
              *ngIf="
                element.total &&
                columnTotals.hasOwnProperty(column.column) &&
                element.total.hasOwnProperty(column.column)
              "
            >
              <app-number-input
                [value]="getSubTotal(element.total, column.column)"
                [decimals]="getColumnDecimals(column.column)"
                [displayOnly]="true"
                [isCurrency]="currency"
              >
              </app-number-input>
            </ng-container>
            <ng-container *ngIf="element.rowId && column.isTotal">
              <app-number-input
                [value]="rowTotals[index]"
                [decimals]="getColumnDecimals(column.column)"
                [displayOnly]="true"
                [isCurrency]="currency"
              >
              </app-number-input>
            </ng-container>
          </td>
          <td mat-footer-cell *matFooterCellDef>
            <b *ngIf="column.isLabel">
              {{ table.totalRowLabel ?? 'Total' }}
            </b>
            <ng-container
              *ngIf="
                !column.hideColumnTotal &&
                columnTotals.hasOwnProperty(column.column) &&
                (!column?.customTotal || column?.customTotal?.length)
              "
            >
              <app-number-input
                [value]="columnTotals[column.column]"
                [displayOnly]="true"
                [isCurrency]="currency"
              >
              </app-number-input>
            </ng-container>
            <ng-container *ngIf="column.isTotal">
              <app-number-input
                [value]="overallTotal"
                [decimals]="getColumnDecimals(column.column)"
                [displayOnly]="true"
                [isCurrency]="currency"
              >
              </app-number-input>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        [class.subheading]="!row.rowId && !row.total && !row.isOtherSpecify"
        [class.subtotal]="!row.rowId && row.total"
        [class.otherSpecify]="row.isOtherSpecify"
      ></tr>
      <tr
        mat-footer-row
        class="sticky-footer"
        *matFooterRowDef="displayedColumns"
        [hidden]="table.hideTotalRow"
      ></tr>
    </table>
  </div>
  <div class="action-buttons">
    <button
      *ngIf="!readOnly"
      mat-flat-button
      color="primary"
      type="submit"
      class="submit-button"
      [disabled]="tableForm.invalid || disabled || tableForm.pristine"
    >
      <ng-container *ngIf="disabled">Saving...</ng-container>
      <ng-container *ngIf="!disabled">Save</ng-container>
    </button>
    <button mat-stroked-button type="button" (click)="goBack()">Go Back</button>
  </div>
</form>
