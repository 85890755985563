import { State as RootState } from '@app/store/app-state';
import { Account, ApiError, StoreError } from '@core/models';
import { CognitoUserData } from '@core/services';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { accountFeatureKey } from './reducer';
import { State } from './state';

const getAccount = (state: State): Account => state.account;
const getAccountError = (state: State): ApiError => state.accountError;
const getAccountLoading = (state: State): boolean => state.accountLoading;
const getUserData = (state: State): CognitoUserData => state.userData;
const getUserDataError = (state: State): StoreError => state.userDataError;
const getUserDataLoading = (state: State): boolean => state.userDataLoading;
const getUserDetails = (state: State): Account => state.userDetails;
const getUserDetailsError = (state: State): StoreError =>
  state.userDetailsError;
const getUserDetailsLoading = (state: State): boolean =>
  state.userDetailsLoading;

const getUpdateAccountError = (state: State): ApiError =>
  state.updateAccountError;
const getUpdateAccountLoading = (state: State): boolean =>
  state.updateAccountLoading;

export const selectAccountState = createFeatureSelector<RootState, State>(
  accountFeatureKey,
);

export const selectAccount = createSelector(selectAccountState, getAccount);
export const selectAccountError = createSelector(
  selectAccountState,
  getAccountError,
);
export const selectAccountLoading = createSelector(
  selectAccountState,
  getAccountLoading,
);

export const selectUserData = createSelector(selectAccountState, getUserData);
export const selectUserDataError = createSelector(
  selectAccountState,
  getUserDataError,
);
export const selectUserDataLoading = createSelector(
  selectAccountState,
  getUserDataLoading,
);

export const selectUserDetails = createSelector(
  selectAccountState,
  getUserDetails,
);
export const selectUserDetailsError = createSelector(
  selectAccountState,
  getUserDetailsError,
);
export const selectUserDetailsLoading = createSelector(
  selectAccountState,
  getUserDetailsLoading,
);

export const selectUpdateAccountError = createSelector(
  selectAccountState,
  getUpdateAccountError,
);
export const selectUpdateAccountLoading = createSelector(
  selectAccountState,
  getUpdateAccountLoading,
);
