<div class="grant-list-container">
  <mat-selection-list>
    <ng-container *ngFor="let grant of grants; let i = index">
      <mat-list-item
        matRipple
        *ngIf="grantOnPage(i)"
        (click)="setGrantSelection(grant.grantId, grant.subrecipientId)"
      >
        <span matListItemTitle role="heading" aria-level="3">{{
          grant | fullGrantDisplay
        }}</span>
        <span matListItemLine>{{ grant.roleName }}</span>
      </mat-list-item>
    </ng-container>
  </mat-selection-list>
</div>

<mat-paginator
  #paginator
  class="demo-paginator"
  (page)="handlePageEvent($event)"
  [length]="grants.length"
  [pageSize]="pageSize"
  [showFirstLastButtons]="true"
  [hidePageSize]="true"
  role="navigation"
  aria-label="Select Grant page"
>
</mat-paginator>
