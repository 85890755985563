import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { MdePopoverModule } from '@muntazir86/material-extended';
import { QRCodeModule } from 'angularx-qrcode';
import { MarkdownModule } from 'ngx-markdown';
import { SecurableResourceDirective } from './directives';
import { MaterialModule } from './material.module';
import { PipesModule } from './pipes/pipes.module';
import {
  AmendmentUploadComponent,
  BannerComponent,
  ColumnMappingComponent,
  ContentContainerComponent,
  ContentContainerTitleComponent,
  DataTableComponent,
  EmptyComponent,
  FieldMappingComponent,
  FileUploadComponent,
  FormErrorsComponent,
  FparBannerComponent,
  FparCardComponent,
  FparCommentComponent,
  FparIssueComponent,
  FparSubIssueComponent,
  FparTableCommentsComponent,
  FparTableComponent,
  FparTableIssuesComponent,
  IssueOnHoldPanelComponent,
  MyGrantsFilterComponent,
  NumberInputComponent,
  PageLoaderComponent,
  PasswordErrorComponent,
  QrCodeComponent,
  ReviewStatusBoxComponent,
  SubmissionClosedComponent,
  SubmissionCommentComponent,
  SupplementalInfoComponent,
  UploadStatusComponent,
  UserGrantListComponent,
  ValidationWarningsComponent,
  ViewIssueLinkComponent,
} from './ui';
import {
  ConfirmDialogComponent,
  IdleTimeoutDialogComponent,
  InactiveUserDialogComponent,
  NotAvailableDialogComponent,
  SelectUserGrantDialogComponent,
} from './ui/dialogs';

const COMPONENTS = [
  AmendmentUploadComponent,
  BannerComponent,
  ColumnMappingComponent,
  ConfirmDialogComponent,
  InactiveUserDialogComponent,
  ContentContainerComponent,
  ContentContainerTitleComponent,
  DataTableComponent,
  EmptyComponent,
  FieldMappingComponent,
  FileUploadComponent,
  FparCommentComponent,
  FparBannerComponent,
  FparCardComponent,
  FparIssueComponent,
  FparSubIssueComponent,
  FparTableComponent,
  FparTableCommentsComponent,
  FparTableIssuesComponent,
  FormErrorsComponent,
  IdleTimeoutDialogComponent,
  IssueOnHoldPanelComponent,
  MyGrantsFilterComponent,
  NotAvailableDialogComponent,
  NumberInputComponent,
  PageLoaderComponent,
  PasswordErrorComponent,
  QrCodeComponent,
  ReviewStatusBoxComponent,
  SelectUserGrantDialogComponent,
  SecurableResourceDirective,
  SubmissionClosedComponent,
  SubmissionCommentComponent,
  SupplementalInfoComponent,
  UserGrantListComponent,
  UploadStatusComponent,
  ValidationWarningsComponent,
  ViewIssueLinkComponent,
];

const MODULES = [
  CommonModule,
  FormsModule,
  MarkdownModule.forRoot(),
  MaterialModule,
  MdePopoverModule,
  PipesModule,
  QRCodeModule,
  ReactiveFormsModule,
  RouterModule,
];

@NgModule({
  declarations: COMPONENTS,
  imports: MODULES,
  exports: [
    FormsModule,
    MarkdownModule,
    MaterialModule,
    MdePopoverModule,
    PipesModule,
    ReactiveFormsModule,
    ...COMPONENTS,
  ],
  providers: [{ provide: MatDialogRef, useValue: {} }, DatePipe],
})
export class SharedModule {}
