import { State as RootState } from '@app/store/app-state';
import {
  ApiError,
  Card,
  CardDetails,
  DashboardBenchmark,
  DashboardFilterData,
  GrantSubmissionReviewStatus,
  KeyDate,
  ReportingPeriod,
  ReportingPeriodState,
} from '@core/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { dashboardFeatureKey } from './reducer';
import { State } from './state';

const getBenchmarks = (state: State): DashboardBenchmark[] => state.benchmarks;
const getBenchmarksError = (state: State): ApiError => state.benchmarksError;
const getBenchmarksLoading = (state: State): boolean => state.benchmarksLoading;

const getCardDetails = (state: State): CardDetails => state.cardDetails;
const getCardDetailsError = (state: State): ApiError => state.cardDetailsError;
const getCardDetailsLoading = (state: State): boolean =>
  state.cardDetailsLoading;

const getCards = (state: State): Card[] => state.cards;
const getCardsError = (state: State): ApiError => state.cardsError;
const getCardsLoading = (state: State): boolean => state.cardsLoading;

const getFilterData = (state: State): DashboardFilterData[] => state.filterData;
const getFilterDataError = (state: State): ApiError => state.filterDataError;
const getfilterDataLoading = (state: State): boolean => state.filterDataLoading;

const getGrantSubmissionReviewStatus = (
  state: State,
): GrantSubmissionReviewStatus => state.grantSubmissionReviewStatus;
const getGrantSubmissionReviewStatusError = (state: State): ApiError =>
  state.grantSubmissionReviewStatusError;
const getGrantSubmissionReviewStatusLoading = (state: State): boolean =>
  state.grantSubmissionReviewStatusLoading;

const getKeyDates = (state: State): KeyDate[] => state.keyDates;
const getKeyDatesError = (state: State): ApiError => state.keyDatesError;
const getKeyDatesLoading = (state: State): boolean => state.keyDatesLoading;

const getReportingPeriods = (state: State): ReportingPeriod[] =>
  state.reportingPeriods;
const getReportingPeriodsError = (state: State): ApiError =>
  state.reportingPeriodsError;
const getReportingPeriodsLoading = (state: State): boolean =>
  state.reportingPeriodsLoading;

const getReportingPeriodState = (state: State): ReportingPeriodState =>
  state.reportingPeriodState;
const getReportingPeriodStateError = (state: State): ApiError =>
  state.reportingPeriodStateError;
const getReportingPeriodStateLoading = (state: State): boolean =>
  state.reportingPeriodStateLoading;

const getSelectedBenchmark = (state: State): DashboardBenchmark =>
  state.selectedBenchmark;
const getSelectedGrantId = (state: State): number => state.selectedGrantId;
const getSelectedReportingPeriod = (state: State): ReportingPeriod =>
  state.selectedReportingPeriod;

export const selectDashboardState = createFeatureSelector<RootState, State>(
  dashboardFeatureKey,
);

export const selectBenchmarks = createSelector(
  selectDashboardState,
  getBenchmarks,
);
export const selectBenchmarksError = createSelector(
  selectDashboardState,
  getBenchmarksError,
);
export const selectBenchmarksLoading = createSelector(
  selectDashboardState,
  getBenchmarksLoading,
);

export const selectCardDetails = createSelector(
  selectDashboardState,
  getCardDetails,
);
export const selectCardDetailsError = createSelector(
  selectDashboardState,
  getCardDetailsError,
);
export const selectCardDetailsLoading = createSelector(
  selectDashboardState,
  getCardDetailsLoading,
);

export const selectCards = createSelector(selectDashboardState, getCards);
export const selectCardsError = createSelector(
  selectDashboardState,
  getCardsError,
);
export const selectCardsLoading = createSelector(
  selectDashboardState,
  getCardsLoading,
);

export const selectFilterData = createSelector(
  selectDashboardState,
  getFilterData,
);
export const selectFilterDataError = createSelector(
  selectDashboardState,
  getFilterDataError,
);
export const selectfilterDataLoading = createSelector(
  selectDashboardState,
  getfilterDataLoading,
);

export const selectGrantSubmissionReviewStatus = createSelector(
  selectDashboardState,
  getGrantSubmissionReviewStatus,
);
export const selectGrantSubmissionReviewStatusError = createSelector(
  selectDashboardState,
  getGrantSubmissionReviewStatusError,
);
export const selectGrantSubmissionReviewStatusLoading = createSelector(
  selectDashboardState,
  getGrantSubmissionReviewStatusLoading,
);

export const selectKeyDates = createSelector(selectDashboardState, getKeyDates);
export const selectKeyDatesError = createSelector(
  selectDashboardState,
  getKeyDatesError,
);
export const selectKeyDatesLoading = createSelector(
  selectDashboardState,
  getKeyDatesLoading,
);

export const selectReportingPeriods = createSelector(
  selectDashboardState,
  getReportingPeriods,
);
export const selectReportingPeriodsError = createSelector(
  selectDashboardState,
  getReportingPeriodsError,
);
export const selectReportingPeriodsLoading = createSelector(
  selectDashboardState,
  getReportingPeriodsLoading,
);

export const selectReportingPeriodState = createSelector(
  selectDashboardState,
  getReportingPeriodState,
);
export const selectReportingPeriodStateError = createSelector(
  selectDashboardState,
  getReportingPeriodStateError,
);
export const selectReportingPeriodStateLoading = createSelector(
  selectDashboardState,
  getReportingPeriodStateLoading,
);

export const selectSelectedBenchmark = createSelector(
  selectDashboardState,
  getSelectedBenchmark,
);
export const selectSelectedGrantId = createSelector(
  selectDashboardState,
  getSelectedGrantId,
);
export const selectSelectedReportingPeriod = createSelector(
  selectDashboardState,
  getSelectedReportingPeriod,
);
