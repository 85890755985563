import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AssignableRolesApiResponse,
  CreateUserPayload,
  ReactivateUserPayload,
  RemoveUserPayload,
  ResetUserMFAPayload,
  UpdateUserApiResponse,
  UpdateUserPayload,
  UserApiResponse,
  UserGrantSelection,
  UsersApiResponse,
} from '@core/models';
import { environment as env } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private baseUrl = `${env.apiURL}api/v1`;
  private users = 'users';

  constructor(private http: HttpClient) {}

  public createUser(payload: CreateUserPayload): Observable<any> {
    return this.http.post(`${this.baseUrl}/${this.users}`, payload);
  }

  public getAssignableRoles(
    payload: UserGrantSelection,
  ): Observable<AssignableRolesApiResponse> {
    return this.http.get<AssignableRolesApiResponse>(
      `${this.baseUrl}/assignable-roles`,
      { params: payload },
    );
  }

  public getUser(grantId: number, id: string): Observable<UserApiResponse> {
    return this.http.get<UserApiResponse>(
      `${this.baseUrl}/${this.users}/${id}`,
    );
  }

  public getUsers(payload: UserGrantSelection): Observable<UsersApiResponse> {
    return this.http.get<UsersApiResponse>(`${this.baseUrl}/${this.users}`, {
      params: payload,
    });
  }

  public removeUser(payload: RemoveUserPayload): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${this.users}`, {
      params: { ...payload },
    });
  }

  public reactivateUser(payload: ReactivateUserPayload): Observable<any> {
    return this.http.put(`${this.baseUrl}/${this.users}/reactivate`, payload);
  }

  public resetUserMFA(payload: ResetUserMFAPayload): Observable<any> {
    return this.http.put(`${this.baseUrl}/${this.users}/resetMFA`, payload);
  }

  public updateUser(
    payload: UpdateUserPayload,
  ): Observable<UpdateUserApiResponse> {
    return this.http.put<UpdateUserApiResponse>(
      `${this.baseUrl}/${this.users}`,
      payload,
    );
  }
}
