import {
  ApiError,
  FparTable,
  GrantSubmission,
  GrantSubmissionTableStatus,
  ReportingPeriod,
} from '@core/models';

export interface State {
  reportingPeriods: ReportingPeriod[];
  reportingPeriodsError: ApiError;
  reportingPeriodsLoading: boolean;
  selectedGrantId: number;
  selectedReportingPeriodId: number;
  table: FparTable;
  tableError: ApiError;
  tableLoading: boolean;
  tableOverview: GrantSubmission<GrantSubmissionTableStatus>;
  tableOverviewError: ApiError;
  tableOverviewLoading: boolean;
}

export const initialState: State = {
  reportingPeriods: [],
  reportingPeriodsError: null,
  reportingPeriodsLoading: false,
  selectedGrantId: null,
  selectedReportingPeriodId: null,
  table: null,
  tableError: null,
  tableLoading: false,
  tableOverview: null,
  tableOverviewError: null,
  tableOverviewLoading: false,
};
