import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { removeProperties } from '@app/shared/utilities';
import {
  FparDecimalConfig,
  FparTableCellIssue,
  FparTableColumn,
  FparTableOtherConfig,
  FparTablePayload,
  FparTableRow,
  FparTableSubTotal,
} from '@core/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-fpar-table',
  templateUrl: './fpar-table.component.html',
  styleUrls: ['./fpar-table.component.scss'],
})
export class FparTableComponent implements OnChanges, OnDestroy, OnInit {
  @Input() currency = false;
  @Input() currentSubIssueIndex: number;
  @Input() disabled = false;
  @Input() maxInputSize = null;
  @Input() readOnly = false;
  @Input() sticky = false;
  @Input() subIssues: FparTableCellIssue[] = [];
  @Input() table: {
    columns: FparTableColumn[];
    tableDecimals?: FparDecimalConfig[];
    other?: FparTableOtherConfig;
    rows: FparTableRow[];
    totalRowLabel: string;
    hideTotalRow: boolean;
  };

  @Output() back = new EventEmitter();
  @Output() subIssueIndex = new EventEmitter<number>();
  @Output() submitTable = new EventEmitter<FparTablePayload>();
  @Output() tableFormIsDirty = new EventEmitter<boolean>();

  @ViewChild(MatTable) fparTable: MatTable<FparTableRow>;

  public columnTotals: Record<string, number> = {};
  public currentSubIssue: FparTableCellIssue;
  public dataSource: FparTableRow[];
  public displayedColumns: string[] = [];
  public latestOtherRowId = '';
  public otherIndex = 0;
  public overallTotal = 0;
  public rowTotals: number[] = [];
  public tableForm: UntypedFormGroup;

  private columnKeys: string[] = [];
  private destroyed$ = new Subject<boolean>();
  private excludedColumns: string[] = [];
  private excludedFormColumns: string[] = [];
  private highlightedColumn: string;
  private highlightedRow: string;
  private tableChangeInputs = ['currency', 'table'];
  private tableFormStatus: Record<string, () => void>;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.tableFormStatus = {
      true: () => this.tableForm.disable(),
      false: () => this.tableForm.enable(),
    };
  }

  public get dirty(): boolean {
    return !!this.tableForm.dirty;
  }

  public get formRows(): UntypedFormArray {
    return this.tableForm.get('table') as UntypedFormArray;
  }

  public get otherRows(): UntypedFormArray {
    return this.tableForm.get('other') as UntypedFormArray;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.tableChangeInputs.some((input) => changes?.hasOwnProperty(input))
    ) {
      this.resetTableForm();
      this.dataSource = Object.assign([], this.table.rows);

      this.displayedColumns = this.table.columns.map((column) => column.column);
      this.excludedColumns = this.table.columns
        .filter((column) => column.isId || column.isLabel || column.isTotal)
        .map((column) => column.column);

      this.excludedFormColumns = this.table.columns
        .filter((column) => column.isLabel || column.isTotal)
        .map((column) => column.column);

      this.columnKeys = this.table.columns
        .map((column) => column.column)
        .filter((key) => !this.excludedColumns.includes(key));

      this.generateFormData(this.table.rows, this.table.other);
    }

    if (this.tableForm) {
      this.tableFormStatus[this.disabled.toString()]();
    }

    if (changes?.currentSubIssueIndex?.currentValue !== undefined) {
      const subIssueIndex =
        changes.currentSubIssueIndex.currentValue === -1
          ? changes.currentSubIssueIndex.previousValue
          : changes.currentSubIssueIndex.currentValue;

      this.toggleCellHighlight(subIssueIndex);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngOnInit(): void {}

  public addOtherRow(): void {
    const curRowId = parseInt(this.latestOtherRowId, 10);
    const curRowIndex = this.dataSource.findIndex(
      (row) =>
        row?.idLabel === curRowId.toString() ||
        row?.rowId === curRowId.toString(),
    );
    const idLabel = (curRowId + 1).toString();
    const rowId = null;
    const rowLabel = '';
    const startIndex = curRowIndex + 1;

    const newColumnKeys = this.columnKeys.filter((key) =>
      this.table.other.columns.includes(key),
    );
    const newRow = Object.assign(
      { idLabel, rowId, rowLabel, isOtherSpecify: true },
      ...newColumnKeys.map((key) => ({ [key]: null })),
    );

    this.dataSource.splice(startIndex, 0, newRow);
    this.otherRows.push(this.getOtherGroup(newRow));
    this.tableForm.markAsDirty();
    this.fparTable.renderRows();

    this.latestOtherRowId = idLabel;
  }

  public checkColumnForAlternates(
    column: FparTableColumn,
    index: number,
  ): boolean {
    return (
      !!column.alternates?.find(
        (alt) =>
          alt.range.includes(this.table.rows[index + 1].rowId as string) ||
          alt.range.includes(this.table.rows[index + 1].idLabel),
      ) || false
    );
  }

  public checkColumnHeaderRange(column: FparTableColumn): boolean {
    return false;
  }

  public cleanForm(): void {
    this.tableForm.markAsPristine();
  }

  public getAlternateColumnHeaderName(
    column: FparTableColumn,
    index: number,
  ): string {
    return column.alternates?.find(
      (alt) =>
        alt.range.includes(this.table.rows[index + 1].rowId as string) ||
        alt.range.includes(this.table.rows[index + 1].idLabel),
    ).headerName;
  }

  public getColumnDecimals(column: string): number {
    return (
      this.table.tableDecimals?.find((decimals) => decimals.column === column)
        ?.decimals ?? 0
    );
  }

  public getOtherIndex(index: number): number {
    const startIndex = this.dataSource.findIndex(
      (row) => row.rowId === this.table.other.startAfter,
    );
    return index - (startIndex + 1);
  }

  public getSubIssue(rowId: string, column: string): FparTableCellIssue {
    return this.subIssues.find(
      (subIssue) => subIssue.rowId === rowId && subIssue.columnId === column,
    );
  }

  public getSubIssueIndex(rowId: string, column: string): number {
    return this.subIssues.findIndex(
      (subIssue) => subIssue.rowId === rowId && subIssue.columnId === column,
    );
  }

  public getSubTotal(total: FparTableSubTotal, column: string): number {
    return total[column]
      .map((rowIds, index) =>
        this.getColumnSubTotal(
          total[column].length > 1 ? this.columnKeys[index] : column,
          rowIds,
        ),
      )
      .reduce(this.simpleAddReducer, 0);
  }

  public goBack(): void {
    this.back.emit();
  }

  public hasSubIssues(rowId: string | null, column: string): boolean {
    return this.subIssues.some(
      (subIssue) =>
        subIssue.rowId === rowId?.toString() && subIssue.columnId === column,
    );
  }

  public isHighlightedCell(rowId: string, columnId: string): boolean {
    return this.isHighlightedColumn(columnId) && this.isHighlightedRow(rowId);
  }

  public isHighlightedColumn(columnId: string): boolean {
    return this.highlightedColumn === columnId;
  }

  public isHighlightedRow(rowId: string): boolean {
    return this.highlightedRow === rowId;
  }

  public onNextSubIssue(): void {
    let currentIndex = this.currentSubIssueIndex;

    if (currentIndex === this.subIssues.length - 1) {
      currentIndex = -1;
    }

    currentIndex++;
    this.subIssueIndex.emit(currentIndex);
  }

  public onPopoverClosed(index: number): void {
    this.subIssueIndex.emit(index);
  }

  public onPopoverOpen(index: number): void {
    this.subIssueIndex.emit(index);
  }

  public onPreviousSubIssue(): void {
    let currentIndex = this.currentSubIssueIndex;

    if (currentIndex === 0) {
      currentIndex = this.subIssues.length;
    }

    currentIndex--;
    this.subIssueIndex.emit(currentIndex);
  }

  public removeOtherRow(idLabel: string): void {
    const curRowIndex = this.dataSource.findIndex(
      (row) => row?.idLabel === idLabel || row?.rowId?.toString() === idLabel,
    );

    this.dataSource.splice(curRowIndex, 1);
    this.otherRows.removeAt(this.otherRows.length - 1);
    this.tableForm.markAsDirty();
    this.fparTable.renderRows();

    const otherSpecifyRows = this.dataSource.filter(
      (row) => row?.isOtherSpecify,
    );
    this.latestOtherRowId =
      otherSpecifyRows[otherSpecifyRows.length - 1].idLabel ??
      otherSpecifyRows[otherSpecifyRows.length - 1].rowId.toString();
  }

  public submit(): void {
    if (this.tableForm.invalid) {
      return;
    }

    this.submitTable.emit({
      ...this.tableForm.value,
      table: this.tableForm.value.table.filter((row) => row.rowId !== null),
    });
  }

  private generateFormData(
    rows: FparTableRow[],
    other: FparTableOtherConfig,
  ): void {
    if (!rows.length) {
      return;
    }

    if (!this.formRows.length) {
      rows.forEach((row) => {
        if (!row.isOtherSpecify) {
          this.formRows.push(this.getFormGroup(row));
        } else {
          this.otherRows.push(this.getOtherGroup(row));
          this.otherIndex = this.otherRows.length;
          this.latestOtherRowId = row.rowId.toString();
        }
      });
    }

    if (!!other && this.otherRows.length === 0) {
      this.latestOtherRowId = other.startAfter.toString();
      this.addOtherRow();
      this.cleanForm();
    }

    this.tableForm.updateValueAndValidity();
  }

  private getColumnSubTotal(
    column: string,
    rowIds: string[] = [],
    columnIds?: string[],
  ): number {
    return columnIds
      ? columnIds
          .map((columnId) => this.getColumnSubTotal(columnId, rowIds))
          .reduce(this.simpleAddReducer, 0)
      : [...this.formRows.value, ...this.otherRows.value]
          .filter(
            (row) =>
              (row.rowId || (row.idLabel && !row.total)) &&
              row.hasOwnProperty(column) &&
              (!rowIds.length ||
                (rowIds.length &&
                  (rowIds.includes(row.rowId) ||
                    rowIds.includes(row.idLabel)))),
          )
          .map((row) =>
            typeof row[column] === 'string'
              ? parseFloat(row[column])
              : row[column],
          )
          .reduce(this.simpleAddReducer, 0);
  }

  private getFormGroup(row: FparTableRow): UntypedFormGroup {
    const newRow = removeProperties(row, ...this.excludedFormColumns);
    return this.formBuilder.group(newRow);
  }

  private getOtherGroup(row: FparTableRow): UntypedFormGroup {
    const newRow = removeProperties(
      row,
      ...this.excludedFormColumns,
      'isOtherSpecify',
    );
    return this.formBuilder.group({
      ...newRow,
      text: row.rowLabel,
    });
  }

  private removeCellHighlight(): void {
    this.highlightedColumn = null;
    this.highlightedRow = null;
  }

  private resetTableForm(): void {
    this.tableForm = this.formBuilder.group({
      table: this.formBuilder.array([]),
      other: this.formBuilder.array([]),
    });

    this.tableForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((newFormValues) => {
        this.tableFormIsDirty.emit(this.tableForm.dirty);
        this.otherIndex = this.otherRows.length;

        // Set column totals
        this.columnKeys.forEach((key) => {
          const customTotal = this.table.columns.find(
            (column) => column.column === key,
          )?.customTotal;

          this.columnTotals[key] = customTotal
            ? this.getColumnSubTotal(key, [], customTotal)
            : this.getColumnSubTotal(key);
        });

        // Set row totals
        this.rowTotals = newFormValues.table
          .map((row) => removeProperties(row, ...this.excludedColumns))
          .map((row) =>
            Object.keys(row).length
              ? Object.values(row)
                  .map((value) =>
                    typeof value === 'string'
                      ? parseFloat(value.toString())
                      : value,
                  )
                  .reduce(this.simpleAddReducer)
              : 0,
          );

        // Set overall total
        this.overallTotal = this.rowTotals.reduce(this.simpleAddReducer, 0);
      });
  }

  private setCellHighlight(rowId: string, column: string): void {
    this.highlightedColumn = column;
    this.highlightedRow = rowId;
  }

  private simpleAddReducer(previous: number, current: number): number {
    return previous + current;
  }

  private toggleCellHighlight(index: number): void {
    const { columnId, rowId } = this.subIssues[index];

    if (this.isHighlightedCell(rowId, columnId)) {
      this.removeCellHighlight();
      return;
    }

    this.setCellHighlight(rowId, columnId);
  }
}
