import { Injectable } from '@angular/core';
import { ReviewService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import {
  clearSelectedGrant,
  clearSelectedGrantSuccess,
  getSelectedGrant,
  getSelectedGrantSuccess,
  setSelectedGrant,
  setSelectedGrantSuccess,
} from './actions';

@Injectable()
export class PortalReviewEffects {
  clearSelectedGrant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clearSelectedGrant),
      map(() => {
        localStorage.removeItem('currentGrant');
        return clearSelectedGrantSuccess();
      }),
    ),
  );

  getSelectedGrant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSelectedGrant),
      switchMap(() =>
        this.reviewService
          .getSelectedGrant()
          .pipe(
            map((currentGrant) => getSelectedGrantSuccess({ currentGrant })),
          ),
      ),
    ),
  );

  setSelectedGrant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSelectedGrant),
      switchMap(({ grant }) =>
        this.reviewService
          .setSelectedGrant(grant)
          .pipe(
            map((currentGrant) => setSelectedGrantSuccess({ currentGrant })),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private reviewService: ReviewService,
  ) {}
}
