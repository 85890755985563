import { State as AppState } from '@app/store/app-state';
import { CurrentStep } from '@core/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { clinicStepperFeatureKey } from './reducer';
import { State } from './state';

const getCurrentStep = (state: State): CurrentStep => ({
  currentStepIndex: state.currentStepIndex,
  maxStepIndex: state.maxStepIndex,
});

export const clinicStepperState = createFeatureSelector<AppState, State>(
  clinicStepperFeatureKey,
);

export const selectCurrentStep = createSelector(
  clinicStepperState,
  getCurrentStep,
);
