import { Injectable } from '@angular/core';
import { handleError } from '@core/helpers';
import { UserService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  createUser,
  createUserFailure,
  createUserSuccess,
  getAssignableRoles,
  getAssignableRolesFailure,
  getAssignableRolesSuccess,
  getUser,
  getUserFailure,
  getUserSuccess,
  getUsers,
  getUsersFailure,
  getUsersSuccess,
  reactivateUser,
  reactivateUserFailure,
  reactivateUserSuccess,
  removeUser,
  removeUserFailure,
  removeUserSuccess,
  resetUserMFA,
  resetUserMFAFailure,
  resetUserMFASuccess,
  updateUser,
  updateUserFailure,
  updateUserSuccess,
} from './actions';

@Injectable()
export class UserEffects {
  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createUser),
      switchMap(({ payload }) =>
        this.userService.createUser(payload).pipe(
          map(() => createUserSuccess()),
          catchError((error) => handleError(error, createUserFailure)),
        ),
      ),
    ),
  );

  getAssignableRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAssignableRoles),
      switchMap(({ grantId, subrecipientId }) =>
        this.userService.getAssignableRoles({ grantId, subrecipientId }).pipe(
          map(
            (assignableRoles) => getAssignableRolesSuccess({ assignableRoles }),
            catchError((error) =>
              handleError(error, getAssignableRolesFailure),
            ),
          ),
        ),
      ),
    ),
  );

  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUser),
      switchMap(({ grantId, id }) =>
        this.userService.getUser(grantId, id).pipe(
          map(
            (user) => getUserSuccess({ user }),
            catchError((error) => handleError(error, getUserFailure)),
          ),
        ),
      ),
    ),
  );

  getUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUsers),
      switchMap(({ grantId, subrecipientId }) =>
        this.userService.getUsers({ grantId, subrecipientId }).pipe(
          map(
            (users) => getUsersSuccess({ users }),
            catchError((error) => handleError(error, getUsersFailure)),
          ),
        ),
      ),
    ),
  );

  removeUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeUser),
      switchMap(({ payload }) =>
        this.userService.removeUser(payload).pipe(
          map(() => removeUserSuccess()),
          catchError((error) => handleError(error, removeUserFailure)),
        ),
      ),
    ),
  );

  reactivateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reactivateUser),
      switchMap(({ payload }) =>
        this.userService.reactivateUser(payload).pipe(
          map(() => reactivateUserSuccess()),
          catchError((error) => handleError(error, reactivateUserFailure)),
        ),
      ),
    ),
  );

  resetUserMFA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetUserMFA),
      switchMap(({ payload }) =>
        this.userService.resetUserMFA(payload).pipe(
          map(() => resetUserMFASuccess()),
          catchError((error) => handleError(error, resetUserMFAFailure)),
        ),
      ),
    ),
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateUser),
      switchMap(({ payload }) =>
        this.userService.updateUser(payload).pipe(
          map(() => updateUserSuccess()),
          catchError((error) => handleError(error, updateUserFailure)),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
  ) {}
}
