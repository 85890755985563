import { HttpErrorResponse } from '@angular/common/http';
import {
  AssignableRolesApiResponse,
  CreateUserPayload,
  ReactivateUserPayload,
  RemoveUserPayload,
  ResetUserMFAPayload,
  UpdateUserPayload,
  UserApiResponse,
  UsersApiResponse,
} from '@core/models';
import { createAction, props } from '@ngrx/store';

export enum UserActionTypes {
  CREATE_USER_FAILURE = '[User] Create User Failure',
  CREATE_USER_REQUEST = '[User] Create User Request',
  CREATE_USER_SUCCESS = '[User] Create User Success',

  GET_ASSIGNABLE_ROLES_FAILURE = '[User] Get Assignable Roles Failure',
  GET_ASSIGNABLE_ROLES_REQUEST = '[User] Get Assignable Roles Request',
  GET_ASSIGNABLE_ROLES_SUCCESS = '[User] Get Assignable Roles Success',

  GET_USER_FAILURE = '[User] Get User Failure',
  GET_USER_REQUEST = '[User] Get User Request',
  GET_USER_SUCCESS = '[User] Get User Success',

  GET_USERS_FAILURE = '[User] Get Users Failure',
  GET_USERS_REQUEST = '[User] Get Users Request',
  GET_USERS_SUCCESS = '[User] Get Users Success',

  REACTIVATE_USER_FAILURE = '[User] Reactivate User Failure',
  REACTIVATE_USER_REQUEST = '[User] Reactivate User Request',
  REACTIVATE_USER_SUCCESS = '[User] Reactivate User Success',

  REMOVE_USER_FAILURE = '[User] Remove User Failure',
  REMOVE_USER_REQUEST = '[User] Remove User Request',
  REMOVE_USER_SUCCESS = '[User] Remove User Success',

  RESET_USER_MFA_FAILURE = '[User] Reset User MFA Failure',
  RESET_USER_MFA_REQUEST = '[User] Reset User MFA Request',
  RESET_USER_MFA_SUCCESS = '[User] Reset User MFA Success',

  UPDATE_USER_FAILURE = '[User] Update User Failure',
  UPDATE_USER_REQUEST = '[User] Update User Request',
  UPDATE_USER_SUCCESS = '[User] Update User Success',
}

export const createUser = createAction(
  UserActionTypes.CREATE_USER_REQUEST,
  props<{ payload: CreateUserPayload }>(),
);
export const createUserFailure = createAction(
  UserActionTypes.CREATE_USER_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const createUserSuccess = createAction(
  UserActionTypes.CREATE_USER_SUCCESS,
);

export const getAssignableRoles = createAction(
  UserActionTypes.GET_ASSIGNABLE_ROLES_REQUEST,
  props<{ grantId?: number; subrecipientId?: number }>(),
);
export const getAssignableRolesFailure = createAction(
  UserActionTypes.GET_ASSIGNABLE_ROLES_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getAssignableRolesSuccess = createAction(
  UserActionTypes.GET_ASSIGNABLE_ROLES_SUCCESS,
  props<{ assignableRoles: AssignableRolesApiResponse }>(),
);

export const getUser = createAction(
  UserActionTypes.GET_USER_REQUEST,
  props<{ grantId?: number; id: string }>(),
);
export const getUserFailure = createAction(
  UserActionTypes.GET_USER_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getUserSuccess = createAction(
  UserActionTypes.GET_USER_SUCCESS,
  props<{ user: UserApiResponse }>(),
);

export const getUsers = createAction(
  UserActionTypes.GET_USERS_REQUEST,
  props<{ grantId?: number; subrecipientId?: number }>(),
);
export const getUsersFailure = createAction(
  UserActionTypes.GET_USERS_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getUsersSuccess = createAction(
  UserActionTypes.GET_USERS_SUCCESS,
  props<{ users: UsersApiResponse }>(),
);

export const reactivateUser = createAction(
  UserActionTypes.REACTIVATE_USER_REQUEST,
  props<{ payload: ReactivateUserPayload }>(),
);
export const reactivateUserFailure = createAction(
  UserActionTypes.REACTIVATE_USER_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const reactivateUserSuccess = createAction(
  UserActionTypes.REACTIVATE_USER_SUCCESS,
);

export const removeUser = createAction(
  UserActionTypes.REMOVE_USER_REQUEST,
  props<{ payload: RemoveUserPayload }>(),
);
export const removeUserFailure = createAction(
  UserActionTypes.REMOVE_USER_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const removeUserSuccess = createAction(
  UserActionTypes.REMOVE_USER_SUCCESS,
);

export const resetUserMFA = createAction(
  UserActionTypes.RESET_USER_MFA_REQUEST,
  props<{ payload: ResetUserMFAPayload }>(),
);
export const resetUserMFAFailure = createAction(
  UserActionTypes.RESET_USER_MFA_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const resetUserMFASuccess = createAction(
  UserActionTypes.RESET_USER_MFA_SUCCESS,
);

export const updateUser = createAction(
  UserActionTypes.UPDATE_USER_REQUEST,
  props<{ payload: UpdateUserPayload }>(),
);
export const updateUserFailure = createAction(
  UserActionTypes.UPDATE_USER_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const updateUserSuccess = createAction(
  UserActionTypes.UPDATE_USER_SUCCESS,
);
