import { ApiError, FparDataSummary } from '@core/models';

export interface State {
  fparReportData: FparDataSummary;
  getFparReportError: ApiError;
  getFparReportLoading: boolean;
}

export const initialState: State = {
  fparReportData: null,
  getFparReportError: null,
  getFparReportLoading: false,
};
