import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { updateStep, updateStepSuccess } from './actions';

@Injectable()
export class PortalStepperEffects {
  updateCurrentStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateStep),
      map(({ stepIndex }) => updateStepSuccess({ stepIndex })),
    ),
  );

  constructor(private actions$: Actions) {}
}
