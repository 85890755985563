import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ChangePasswordPayload,
  ConfirmSignInPayload,
  ConfirmSignInResponse,
  NewUserPayload,
  NewUserResponse,
  PasswordResetCodeResponse,
  ResetPasswordPayload,
  SetMFAPreferencePayload,
  SignInResponse,
  SignInUser,
  UpdateUserAttributesPayload,
  VerifyAttributePayload,
} from '@auth/auth.models';
import { VerifySoftwareTokenParams } from '@core/models';
import {
  CognitoUserAttribute,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { environment as env } from 'environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  CognitoResponse,
  CognitoService,
  CognitoUserData,
} from './cognito.service';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = env.apiURL;
  private baseUrl = 'api/v1/';
  private userProfile = 'user-profile';
  private signingIn = false;
  private signingOut = false;
  private tokenKey = 'token';

  constructor(
    private cognito: CognitoService,
    private http: HttpClient,
  ) {}

  public associateSoftwareToken(): Observable<CognitoResponse> {
    return this.cognito.associateSoftwareToken();
  }

  public changeExpiredPassword(
    payload: ChangePasswordPayload,
  ): Observable<any> {
    return this.http.put(
      `${this.apiUrl}${this.baseUrl}${this.userProfile}/change-password`,
      payload,
    );
  }

  public changePassword(
    payload: ChangePasswordPayload,
  ): Observable<CognitoResponse> {
    return this.cognito.changePassword(
      payload.oldPassword,
      payload.newPassword,
    );
  }

  public confirmSignIn(
    payload: ConfirmSignInPayload,
  ): Observable<ConfirmSignInResponse> {
    this.signingIn = true;
    return this.cognito.confirmSignIn(payload.code, payload.mfaType);
  }

  public confirmCustomSignIn(challengeAnswer: string): Observable<any> {
    this.signingIn = true;
    return this.cognito.sendCustomChallengeAnswer(challengeAnswer);
  }

  public getInputVerificationCode(name: string): Observable<CognitoResponse> {
    return this.cognito.getInputVerificationCode(name);
  }

  public getSignInUserSession(): Observable<CognitoUserSession> {
    return this.cognito.getSignInUserSession();
  }

  /**
   * Gets the auth token from cognito
   *
   * @returns the auth token string
   */
  public getAccessToken(): string {
    return this.cognito.getAccessToken().getJwtToken();
  }
  public getToken(): string {
    return this.cognito.getIdToken().getJwtToken();
  }

  public getUserAttributes(): Observable<CognitoUserAttribute[]> {
    return this.cognito.getUserAttributes();
  }

  public getUserData(): Observable<CognitoUserData> {
    return this.cognito.getUserData();
  }

  public hasMFA(): Observable<boolean> {
    return this.cognito.hasMFA();
  }

  public isSignedIn(): boolean {
    //const decoded = this.getDecodedToken();
    //return decoded && decoded.isFullyAuthenticated ? true : false;

    return this.cognito.sessionIsValid();
  }

  public isSigningIn(): boolean {
    return this.signingIn;
  }

  public isSigningOut(): boolean {
    return this.signingOut;
  }

  public refreshSession(): void {
    return this.cognito.refreshSession();
  }

  public resetPassword(
    payload: ResetPasswordPayload,
  ): Observable<CognitoResponse> {
    this.signingIn = true;
    return this.cognito.confirmPassword(payload.resetCode, payload.newPassword);
  }

  public sendPasswordResetCode(
    email: string,
  ): Observable<PasswordResetCodeResponse> {
    this.signingIn = true;
    return this.cognito.sendPasswordResetCode(email);
  }

  public setMFAPreference(
    payload: SetMFAPreferencePayload,
  ): Observable<CognitoResponse> {
    this.signingIn = true;
    return this.cognito.setMFAPreference(
      payload.smsSettings,
      payload.tokenSettings,
    );
  }

  public setNewUser(payload: NewUserPayload): Observable<NewUserResponse> {
    this.signingIn = true;
    return this.cognito.completeNewPasswordChallenge(
      payload.newPassword,
      payload.requiredAttributeData,
    );
  }

  public signIn(user: SignInUser): Observable<SignInResponse> {
    this.signingIn = true;

    return this.cognito.signIn(user.username, user.password);
  }

  public signOut(): Observable<void> {
    this.signingOut = true;

    return this.cognito.signOut().pipe(
      tap(() => {
        localStorage.clear();
        this.signingOut = false;
      }),
    );
  }

  public updateUserAttributes(
    attributes: UpdateUserAttributesPayload,
  ): Observable<string> {
    return this.cognito.updateUserAttributes(attributes);
  }

  public verifyAttribute(payload: VerifyAttributePayload): Observable<string> {
    return this.cognito.verifyAttribute(
      payload.attributeName,
      payload.confirmationCode,
    );
  }

  public verifySoftwareToken(
    payload: VerifySoftwareTokenParams,
  ): Observable<CognitoUserSession> {
    return this.cognito.verifySoftwareToken(
      payload.AccessToken,
      payload.UserCode,
      payload.FriendlyDeviceName,
    );
  }
}
