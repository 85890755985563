import { Injectable } from '@angular/core';
import { handleError } from '@core/helpers/format-error';
import { GrantProfileCoverSheetService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  getAdminProjectDirector,
  getAdminProjectDirectorFailure,
  getAdminProjectDirectorSuccess,
  getContactPerson,
  getContactPersonFailure,
  getContactPersonSuccess,
  getMetaData,
  getMetaDataFailure,
  getMetaDataSuccess,
  updateAdminProjectDirector,
  updateAdminProjectDirectorFailure,
  updateAdminProjectDirectorSuccess,
  updateContactPerson,
  updateContactPersonFailure,
  updateContactPersonSuccess,
} from './actions';

@Injectable()
export class GrantProfileCoverSheetEffects {
  getAdminProjectDirector$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAdminProjectDirector),
      switchMap(({ grantId }) =>
        this.grantProfileCoverSheetService
          .getAdminProjectDirector(grantId)
          .pipe(
            map(
              (adminProjectDirector) =>
                getAdminProjectDirectorSuccess({ adminProjectDirector }),
              catchError((error) =>
                handleError(error, getAdminProjectDirectorFailure),
              ),
            ),
          ),
      ),
    ),
  );

  getContactPerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getContactPerson),
      switchMap(({ grantId }) =>
        this.grantProfileCoverSheetService.getContactPerson(grantId).pipe(
          map(
            (contactPerson) => getContactPersonSuccess({ contactPerson }),
            catchError((error) => handleError(error, getContactPersonFailure)),
          ),
        ),
      ),
    ),
  );

  getMetaData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMetaData),
      switchMap(({ grantId }) =>
        this.grantProfileCoverSheetService.getMetaData(grantId).pipe(
          map(
            (metaData) => getMetaDataSuccess({ metaData }),
            catchError((error) => handleError(error, getMetaDataFailure)),
          ),
        ),
      ),
    ),
  );

  updateAdminProjectDirector$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAdminProjectDirector),
      switchMap(({ payload }) =>
        this.grantProfileCoverSheetService
          .updateAdminProjectDirector(payload)
          .pipe(
            map(() => updateAdminProjectDirectorSuccess()),
            catchError((error) =>
              handleError(error, updateAdminProjectDirectorFailure),
            ),
          ),
      ),
    ),
  );

  updateContactPerson$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateContactPerson),
      switchMap(({ payload }) =>
        this.grantProfileCoverSheetService.updateContactPerson(payload).pipe(
          map(() => updateContactPersonSuccess()),
          catchError((error) => handleError(error, updateContactPersonFailure)),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private grantProfileCoverSheetService: GrantProfileCoverSheetService,
  ) {}
}
