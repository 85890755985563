<h2 mat-dialog-title>
  <mat-icon *ngIf="dialogIcon">{{ dialogIcon }}</mat-icon>
  {{ dialogTitle }}
</h2>

<mat-dialog-content>
  <div *ngIf="!isHtmlMessage">
    {{ dialogData.dialogContent }}
  </div>

  <div *ngIf="isHtmlMessage" [innerHTML]="dialogData.dialogContent"></div>
</mat-dialog-content>

<mat-dialog-actions
  class="dialog-actions"
  [ngClass]="{ single: dialogData.dialogButton === DialogButton.ok }"
>
  <ng-container *ngIf="dialogData.dialogButton !== DialogButton.ok">
    <button mat-stroked-button (click)="close()">
      {{ getButtonText(false) }}
    </button>
  </ng-container>

  <button mat-flat-button color="primary" (click)="submit()">
    {{ getButtonText(true) }}
  </button>
</mat-dialog-actions>
