import { UploadStatus } from '@app/shared/enums';
import {
  ApiError,
  CustomReportingPeriod,
  FileUploadResult,
  FparDataColumns,
  FparSubmissionComment,
  Grant,
  GrantFileUpload,
  GrantFileUploadStatus,
  GrantNumberOfSites,
  GrantSubmission,
  GrantSubmissionTableOverview,
  GrantSubmissionTableStatus,
  Subrecipient,
  UserGrantItem,
  UserGrantSelection,
} from '@core/models';

export interface State {
  acceptSubmissionError: ApiError;
  acceptSubmissionLoading: boolean;
  customReportingPeriod: CustomReportingPeriod;
  customReportingPeriodError: ApiError;
  customReportingPeriodLoading: boolean;
  dataColumns: FparDataColumns;
  dataColumnsError: ApiError;
  dataColumnsLoading: boolean;
  favoriteGrants: number[];
  favoriteGrantsError: ApiError;
  favoriteGrantsLoading: boolean;
  fileUploadResults: FileUploadResult;
  fileUploadResultsError: ApiError;
  fileUploadResultsLoading: boolean;
  fileUploadTaskError: ApiError;
  fileUploadTaskLoading: boolean;
  grant: Grant;
  grantError: ApiError;
  grantLoading: boolean;
  grantDetails: UserGrantItem;
  grantDetailsError: ApiError;
  grantDetailsLoading: boolean;
  grantDetailsSilentError: ApiError;
  grantDetailsSilentLoading: boolean;
  grantFileUpload: GrantFileUpload;
  grantFileUploadStatus: GrantFileUploadStatus[];
  grantHasOpenIssues: boolean;
  grantList: UserGrantItem[];
  grantListError: ApiError;
  grantListLoading: boolean;
  grants: Grant[];
  grantsError: ApiError;
  grantsLoading: boolean;
  grantSubmissions: GrantSubmission[];
  grantSubmissionsError: ApiError;
  grantSubmissionsLoading: boolean;
  grantSubmissionTableOverview: GrantSubmissionTableOverview;
  grantSubmissionTableOverviewError: ApiError;
  grantSubmissionTableOverviewLoading: boolean;
  numberOfSites: GrantNumberOfSites;
  numberOfSitesError: ApiError;
  numberOfSitesLoading: boolean;
  postFilesToS3Error: ApiError;
  postFilesToS3Loading: boolean;
  returnSubmissionError: ApiError;
  returnSubmissionLoading: boolean;
  selectedGrant: UserGrantSelection;
  selectedGrantError: string;
  selectedGrantLoading: boolean;
  setSelectedGrantError: ApiError;
  setSelectedGrantLoading: boolean;
  submissionComment: FparSubmissionComment;
  submissionCommentError: ApiError;
  submissionCommentLoading: boolean;
  submitGrantError: ApiError;
  submitGrantLoading: boolean;
  subrecipients: Subrecipient[];
  subrecipientsError: ApiError;
  subrecipientsLoading: boolean;
  tableOverview: GrantSubmission<GrantSubmissionTableStatus>;
  tableOverviewError: ApiError;
  tableOverviewLoading: boolean;
  updateMyGrantsError: ApiError;
  updateMyGrantsLoading: boolean;
}

export const initialState: State = {
  acceptSubmissionError: null,
  acceptSubmissionLoading: false,
  customReportingPeriod: null,
  customReportingPeriodError: null,
  customReportingPeriodLoading: false,
  dataColumns: null,
  dataColumnsError: null,
  dataColumnsLoading: false,
  favoriteGrants: [],
  favoriteGrantsError: null,
  favoriteGrantsLoading: false,
  fileUploadResults: null,
  fileUploadResultsError: null,
  fileUploadResultsLoading: false,
  fileUploadTaskError: null,
  fileUploadTaskLoading: false,
  grant: null,
  grantError: null,
  grantLoading: false,
  grantDetails: null,
  grantDetailsError: null,
  grantDetailsLoading: false,
  grantDetailsSilentError: null,
  grantDetailsSilentLoading: false,
  grantFileUpload: {
    combined: {
      data: null,
      fieldNames: null,
      file: null,
      fileName: null,
      parseErrors: null,
    },
    encounters: {
      data: null,
      fieldNames: null,
      file: null,
      fileName: null,
      parseErrors: null,
    },
    labs: {
      data: null,
      fieldNames: null,
      file: null,
      fileName: null,
      parseErrors: null,
    },
  },
  grantFileUploadStatus: [
    { type: UploadStatus.ENCOUNTER_LEVEL_DATA_FILE, finished: false },
    { type: UploadStatus.LAB_RESULTS_DATA_FILE, finished: false },
    { type: UploadStatus.MAPPING_TO_DATABASE, finished: false },
  ],
  grantHasOpenIssues: false,
  grantList: null,
  grantListError: null,
  grantListLoading: false,
  grants: [],
  grantsError: null,
  grantsLoading: false,
  grantSubmissions: [],
  grantSubmissionsError: null,
  grantSubmissionsLoading: false,
  grantSubmissionTableOverview: null,
  grantSubmissionTableOverviewError: null,
  grantSubmissionTableOverviewLoading: false,
  numberOfSites: {
    grantId: null,
    titlexSubrecipients: null,
    titlexSites: null,
    differentFromApp: null,
    differentFromAppReason: null,
    telehealthClinics: null,
    status: null,
  },
  numberOfSitesError: null,
  numberOfSitesLoading: false,
  postFilesToS3Error: null,
  postFilesToS3Loading: false,
  returnSubmissionError: null,
  returnSubmissionLoading: false,
  selectedGrant: null,
  selectedGrantError: '',
  selectedGrantLoading: false,
  setSelectedGrantError: null,
  setSelectedGrantLoading: false,
  submissionComment: null,
  submissionCommentError: null,
  submissionCommentLoading: false,
  submitGrantError: null,
  submitGrantLoading: false,
  subrecipients: [],
  subrecipientsError: null,
  subrecipientsLoading: false,
  tableOverview: null,
  tableOverviewError: null,
  tableOverviewLoading: false,
  updateMyGrantsError: null,
  updateMyGrantsLoading: false,
};
