import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fpar-card',
  templateUrl: './fpar-card.component.html',
  styleUrls: ['./fpar-card.component.scss'],
})
export class FparCardComponent implements OnInit {
  @Input() cardIcon: string;
  @Input() cardTitle: string;
  @Input() cardSubTitle: string;
  @Input() cardLinkIcon: string;
  @Input() cardLinkUrl: string;
  @Input() cardLinkText: string;
  @Input() buttonType: string;
  @Input() showButtonIcon: boolean;

  constructor() {}

  ngOnInit(): void {}
}
