import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NotificationTypes } from '@app/shared/enums';
import { GrantActions, GrantSelectors, NotificationActions } from '@app/store';
import { UserGrantItem } from '@core/models';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  templateUrl: './select-user-grant.component.html',
  styleUrls: ['./select-user-grant.component.scss'],
})
export class SelectUserGrantDialogComponent implements OnDestroy, OnInit {
  public grants: UserGrantItem[];
  public grants$: Observable<UserGrantItem[]>;
  public grantsLoading$: Observable<boolean>;

  private destroyed$ = new Subject<boolean>();

  constructor(
    private actions$: Actions,
    private dialog: MatDialogRef<SelectUserGrantDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private router: Router,
    private store$: Store,
  ) {
    this.grants$ = this.store$.select(GrantSelectors.selectGrantList);
    this.grantsLoading$ = this.store$.select(
      GrantSelectors.selectGrantListLoading,
    );

    this.actions$
      .pipe(
        ofType(
          GrantActions.getGrantListByUserFailure,
          GrantActions.setSelectedGrantFailure,
        ),
        takeUntil(this.destroyed$),
        tap(({ message }) => {
          this.store$.dispatch(
            NotificationActions.add({
              notificationType: NotificationTypes.DANGER,
              notificationText: message,
            }),
          );
        }),
      )
      .subscribe();

    this.actions$
      .pipe(
        ofType(GrantActions.setSelectedGrantSuccess),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.close();
        this.router.navigateByUrl('/');
      });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public setGrantSelection(
    grantId: number | null,
    subrecipientId: number | null,
  ): void {
    this.store$.dispatch(
      GrantActions.setSelectedGrant({ grantId, subrecipientId }),
    );
  }

  public close(): void {
    this.dialog.close();
  }
}
