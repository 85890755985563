import { Action, createReducer, on } from '@ngrx/store';
import {
  getReportingPeriods,
  getReportingPeriodsFailure,
  getReportingPeriodsSuccess,
  getTable,
  getTableFailure,
  getTableOverview,
  getTableOverviewFailure,
  getTableOverviewSuccess,
  getTableSuccess,
  resetTableArchiveData,
  updateSelectedGrantId,
  updateSelectedReportingPeriodId,
} from './actions';
import { initialState, State } from './state';

export const tableArchiveFeatureKey = 'tableArchive';

const tableArchiveReducer = createReducer(
  initialState,
  on(getReportingPeriods, (state) => ({
    ...state,
    reportingPeriods: [],
    reportingPeriodsLoading: true,
  })),
  on(getReportingPeriodsFailure, (state, { error: reportingPeriodsError }) => ({
    ...state,
    reportingPeriodsError,
    reportingPeriodsLoading: false,
  })),
  on(getReportingPeriodsSuccess, (state, { response }) => ({
    ...state,
    reportingPeriods: response.data,
    reportingPeriodsLoading: false,
  })),

  on(getTableOverview, (state) => ({
    ...state,
    tableOverviewLoading: true,
  })),
  on(getTableOverviewFailure, (state, { error, message }) => ({
    ...state,
    tableOverview: null,
    tableOverviewError: { error, message },
    tableOverviewLoading: false,
  })),
  on(getTableOverviewSuccess, (state, { tableOverview }) => ({
    ...state,
    tableOverview: tableOverview.data[0],
    tableOverviewError: null,
    tableOverviewLoading: false,
  })),

  on(getTable, (state) => ({
    ...state,
    table: null,
    tableLoading: true,
  })),
  on(getTableFailure, (state, { error, message }) => ({
    ...state,
    table: null,
    tableError: { error, message },
    tableLoading: false,
  })),
  on(getTableSuccess, (state, { response }) => ({
    ...state,
    table: response.data,
    tableError: null,
    tableLoading: false,
  })),

  on(resetTableArchiveData, (state) => ({
    ...state,
    tableOverview: null,
    reportingPeriods: [],
    selectedGrantId: null,
    selectedReportingPeriodId: null,
  })),
  on(updateSelectedGrantId, (state, { grantId }) => ({
    ...state,
    selectedGrantId: grantId,
    tableOverview: null,
    selectedReportingPeriodId: null,
  })),
  on(updateSelectedReportingPeriodId, (state, { reportingPeriodId }) => ({
    ...state,
    selectedReportingPeriodId: reportingPeriodId,
  })),
);

export const reducer = (state: State | undefined, action: Action) =>
  tableArchiveReducer(state, action);
