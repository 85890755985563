import { Injectable } from '@angular/core';
import { handleError } from '@core/helpers';
import { FparTableService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  addTableComment,
  addTableCommentFailure,
  addTableCommentSuccess,
  addTableIssueComment,
  addTableIssueCommentFailure,
  addTableIssueCommentSuccess,
  dataQualityCheck,
  dataQualityCheckFailure,
  dataQualityCheckSuccess,
  dataQualityResults,
  dataQualityResultsFailure,
  dataQualityResultsSuccess,
  deleteTableComment,
  deleteTableCommentFailure,
  deleteTableCommentSuccess,
  deleteTableIssueComment,
  deleteTableIssueCommentFailure,
  deleteTableIssueCommentSuccess,
  getTable,
  getTableComments,
  getTableCommentsFailure,
  getTableCommentsSuccess,
  getTableFailure,
  getTableIssueComments,
  getTableIssueCommentsSuccess,
  getTableIssues,
  getTableIssuesFailure,
  getTableIssuesSuccess,
  getTableSuccess,
  holdTableIssue,
  holdTableIssueFailure,
  holdTableIssueSuccess,
  updateTable,
  updateTableComment,
  updateTableCommentFailure,
  updateTableCommentSuccess,
  updateTableFailure,
  updateTableIssue,
  updateTableIssueComment,
  updateTableIssueCommentFailure,
  updateTableIssueCommentSuccess,
  updateTableIssueFailure,
  updateTableIssueSuccess,
  updateTableSuccess,
} from './actions';
@Injectable()
export class FparTablesEffects {
  addTableComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addTableComment),
      switchMap(({ grantId, tableId, payload }) =>
        this.fparTableService.addTableComment(grantId, tableId, payload).pipe(
          map(() => addTableCommentSuccess()),
          catchError((error) => handleError(error, addTableCommentFailure)),
        ),
      ),
    ),
  );

  addTableIssueComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addTableIssueComment),
      switchMap(({ grantId, tableId, checkId, payload }) =>
        this.fparTableService
          .addTableIssueComment(grantId, tableId, checkId, payload)
          .pipe(
            map(() => addTableIssueCommentSuccess()),
            catchError((error) =>
              handleError(error, addTableIssueCommentFailure),
            ),
          ),
      ),
    ),
  );

  dataQualityCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dataQualityCheck),
      switchMap(({ grantId }) =>
        this.fparTableService.dataQualityCheck(grantId).pipe(
          map((response) => dataQualityCheckSuccess({ response })),
          catchError((error) => handleError(error, dataQualityCheckFailure)),
        ),
      ),
    ),
  );

  dataQualityResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dataQualityResults),
      switchMap(({ grantId }) =>
        this.fparTableService.dataQualityResults(grantId).pipe(
          map((response) => dataQualityResultsSuccess({ response })),
          catchError((error) => handleError(error, dataQualityResultsFailure)),
        ),
      ),
    ),
  );

  deleteTableComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTableComment),
      switchMap(({ grantId, tableId, commentId }) =>
        this.fparTableService
          .deleteTableComment(grantId, tableId, commentId)
          .pipe(
            map(() => deleteTableCommentSuccess()),
            catchError((error) =>
              handleError(error, deleteTableCommentFailure),
            ),
          ),
      ),
    ),
  );

  deleteTableIssueComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteTableIssueComment),
      switchMap(({ grantId, tableId, checkId, commentId }) =>
        this.fparTableService
          .deleteTableIssueComment(grantId, tableId, checkId, commentId)
          .pipe(
            map(() => deleteTableIssueCommentSuccess()),
            catchError((error) =>
              handleError(error, deleteTableIssueCommentFailure),
            ),
          ),
      ),
    ),
  );

  getTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTable),
      switchMap(({ grantId, tableId }) =>
        this.fparTableService.getTable(grantId, tableId).pipe(
          map((response) => getTableSuccess({ response })),
          catchError((error) => handleError(error, getTableFailure)),
        ),
      ),
    ),
  );

  getTableComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTableComments),
      switchMap(({ grantId, tableId }) =>
        this.fparTableService.getTableComments(grantId, tableId).pipe(
          map((response) => getTableCommentsSuccess({ response })),
          catchError((error) => handleError(error, getTableCommentsFailure)),
        ),
      ),
    ),
  );

  getTableIssues$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTableIssues),
      switchMap(({ grantId, tableId }) =>
        this.fparTableService.getTable(grantId, tableId).pipe(
          map((response) => getTableIssuesSuccess({ response })),
          catchError((error) => handleError(error, getTableIssuesFailure)),
        ),
      ),
    ),
  );

  getTableIssueComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTableIssueComments),
      mergeMap(({ grantId, tableId, checkId, reportingPeriodId }) =>
        this.fparTableService
          .getTableIssueComments(grantId, tableId, checkId, reportingPeriodId)
          .pipe(
            map((response) =>
              getTableIssueCommentsSuccess({
                response: { apiResponse: response, checkId },
              }),
            ),
            catchError((error) => handleError(error, getTableFailure)),
          ),
      ),
    ),
  );

  holdTableIssue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(holdTableIssue),
      switchMap(({ grantId, tableId, checkId, payload }) =>
        this.fparTableService
          .addTableIssueComment(grantId, tableId, checkId, payload)
          .pipe(
            map(() => holdTableIssueSuccess()),
            catchError((error) => handleError(error, holdTableIssueFailure)),
          ),
      ),
    ),
  );

  updateTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTable),
      switchMap(({ grantId, tableId, payload }) =>
        this.fparTableService.updateTable(grantId, tableId, payload).pipe(
          map(() => updateTableSuccess()),
          catchError((error) => handleError(error, updateTableFailure)),
        ),
      ),
    ),
  );

  updateTableComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTableComment),
      switchMap(({ grantId, tableId, payload }) =>
        this.fparTableService
          .updateTableComment(grantId, tableId, payload)
          .pipe(
            map(() => updateTableCommentSuccess()),
            catchError((error) =>
              handleError(error, updateTableCommentFailure),
            ),
          ),
      ),
    ),
  );

  updateTableIssue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTableIssue),
      switchMap(({ grantId, issueId, payload }) =>
        this.fparTableService.updateTableIssue(grantId, issueId, payload).pipe(
          map(() => updateTableIssueSuccess({ payload })),
          catchError((error) => handleError(error, updateTableIssueFailure)),
        ),
      ),
    ),
  );

  updateTableIssueComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateTableIssueComment),
      switchMap(({ grantId, tableId, checkId, commentId, payload }) =>
        this.fparTableService
          .updateTableIssueComment(
            grantId,
            tableId,
            checkId,
            commentId,
            payload,
          )
          .pipe(
            map(() => updateTableIssueCommentSuccess()),
            catchError((error) =>
              handleError(error, updateTableIssueCommentFailure),
            ),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private fparTableService: FparTableService,
  ) {}
}
