import { Injectable } from '@angular/core';
import { handleError } from '@core/helpers';
import { ReportingPeriodService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  createReportingPeriod,
  createReportingPeriodSuccess,
  createReportingPeriodFailure,
  deleteReportingPeriod,
  deleteReportingPeriodSuccess,
  deleteReportingPeriodFailure,
  getReportingPeriods,
  getReportingPeriodsFailure,
  getReportingPeriodsSuccess,
  getReportingPeriodState,
  getReportingPeriodStateSuccess,
  getReportingPeriodStateFailure,
  updateReportingPeriod,
  updateReportingPeriodFailure,
  updateReportingPeriodSuccess,
} from './actions';

@Injectable()
export class ReportingPeriodEffects {
  createReportingPeriod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createReportingPeriod),
      switchMap(({ payload }) =>
        this.reportingPeriodService.create(payload).pipe(
          map(() => createReportingPeriodSuccess()),
          catchError((error) =>
            handleError(error, createReportingPeriodFailure),
          ),
        ),
      ),
    ),
  );

  deleteReportingPeriod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteReportingPeriod),
      switchMap(({ id }) =>
        this.reportingPeriodService.delete(id).pipe(
          map(() => deleteReportingPeriodSuccess()),
          catchError((error) =>
            handleError(error, deleteReportingPeriodFailure),
          ),
        ),
      ),
    ),
  );

  getReportingPeriods$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getReportingPeriods),
      switchMap(() =>
        this.reportingPeriodService.getAll().pipe(
          map((response) => getReportingPeriodsSuccess({ response })),
          catchError((error) => handleError(error, getReportingPeriodsFailure)),
        ),
      ),
    ),
  );

  getReportingPeriodState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getReportingPeriodState),
      switchMap(() =>
        this.reportingPeriodService.getReportingPeriodState().pipe(
          map((reportingPeriodState) =>
            getReportingPeriodStateSuccess({ reportingPeriodState }),
          ),
          catchError((error) =>
            handleError(error, getReportingPeriodStateFailure),
          ),
        ),
      ),
    ),
  );

  updateReportingPeriod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateReportingPeriod),
      switchMap(({ id, payload }) =>
        this.reportingPeriodService.update(id, payload).pipe(
          map(() => updateReportingPeriodSuccess()),
          catchError((error) =>
            handleError(error, updateReportingPeriodFailure),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private reportingPeriodService: ReportingPeriodService,
  ) {}
}
