import { HttpErrorResponse } from '@angular/common/http';
import {
  CardDetailsParameters,
  DashboardBenchmark,
  DashboardBenchmarksApiResponse,
  DashboardCardDetailsApiResponse,
  DashboardCardsApiResponse,
  DashboardFilterDataApiResponse,
  GrantSubmissionReviewStatusApiResponse,
  KeyDatesApiResponse,
  ReportingPeriod,
  ReportingPeriodStateApiResponse,
} from '@core/models';
import { createAction, props } from '@ngrx/store';

export enum DashboardActionTypes {
  GET_BENCHMARKS_REQUEST = '[Dashboard] Get Benchmarks Request',
  GET_BENCHMARKS_FAILURE = '[Dashboard] Get Benchmarks Failure',
  GET_BENCHMARKS_SUCCESS = '[Dashboard] Get Benchmarks Success',
  GET_CARD_DETAILS_REQUEST = '[Dashboard] Get Card Details Request',
  GET_CARD_DETAILS_FAILURE = '[Dashboard] Get Card Details Failure',
  GET_CARD_DETAILS_SUCCESS = '[Dashboard] Get Card Details Success',
  GET_CARD_DETAILS_SUCCESS_NO_DETAILS = '[Dashboard] Get Card Details Success No Details Available',
  GET_CARDS_REQUEST = '[Dashboard] Get Cards Request',
  GET_CARDS_FAILURE = '[Dashboard] Get Cards Failure',
  GET_CARDS_SUCCESS = '[Dashboard] Get Cards Success',
  GET_FILTER_DATA_REQUEST = '[Dashboard] Get Filter Data Request',
  GET_FILTER_DATA_FAILURE = '[Dashboard] Get Filter Data Failure',
  GET_FILTER_DATA_SUCCESS = '[Dashboard] Get Filter Data Success',
  GET_GRANT_SUBMISSION_REVIEW_STATUS_REQUEST = '[Dashboard] Get Grant Submission Review Status Request',
  GET_GRANT_SUBMISSION_REVIEW_STATUS_FAILURE = '[Dashboard] Get Grant Submission Review Status Failure',
  GET_GRANT_SUBMISSION_REVIEW_STATUS_SUCCESS = '[Dashboard] Get Grant Submission Review Status Success',
  GET_KEY_DATES_REQUEST = '[Dashboard] Get Key Dates Request',
  GET_KEY_DATES_FAILURE = '[Dashboard] Get Key Dates Failure',
  GET_KEY_DATES_SUCCESS = '[Dashboard] Key Dates Success',
  GET_REPORTING_PERIODS_REQUEST = '[Dashboard] Get Reporting Periods Request',
  GET_REPORTING_PERIODS_FAILURE = '[Dashboard] Get Reporting Periods Failure',
  GET_REPORTING_PERIODS_SUCCESS = '[Dashboard] Get Reporting Periods Success',
  GET_REPORTING_PERIOD_STATE_REQUEST = '[Dashboard] Get Reporting Period State Request',
  GET_REPORTING_PERIOD_STATE_FAILURE = '[Dashboard] Get Reporting Period State Failure',
  GET_REPORTING_PERIOD_STATE_SUCCESS = '[Dashboard] Get Reporting Period State Success',
  RESET_CARD_DETAILS = '[Dashboard] Reset Dashboard Card Details',
  RESET_CARD_LIST = '[Dashboard] Reset Dashboard Card List',
  SET_SELECTED_BENCHMARK = '[Dashboard] Set Selected Benchmark',
  SET_SELECTED_GRANT_ID = '[Dashboard] Set Selected GrantId',
  SET_SELECTED_REPORTING_PERIOD = '[Dashboard] Set Selected Reporting Period',
}

export const getBenchmarks = createAction(
  DashboardActionTypes.GET_BENCHMARKS_REQUEST,
);
export const getBenchmarksSuccess = createAction(
  DashboardActionTypes.GET_BENCHMARKS_SUCCESS,
  props<{ response: DashboardBenchmarksApiResponse }>(),
);
export const getBenchmarksFailure = createAction(
  DashboardActionTypes.GET_BENCHMARKS_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);

export const getCardDetails = createAction(
  DashboardActionTypes.GET_CARD_DETAILS_REQUEST,
  props<{
    cardId: string | number;
    params?: CardDetailsParameters;
  }>(),
);

export const getCardDetailsSuccess = createAction(
  DashboardActionTypes.GET_CARD_DETAILS_SUCCESS,
  props<{ response: DashboardCardDetailsApiResponse }>(),
);

export const getCardDetailsSuccessNoDetails = createAction(
  DashboardActionTypes.GET_CARD_DETAILS_SUCCESS_NO_DETAILS,
  props<{ response: DashboardCardDetailsApiResponse }>(),
);

export const getCardDetailsFailure = createAction(
  DashboardActionTypes.GET_CARD_DETAILS_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);

export const getCards = createAction(
  DashboardActionTypes.GET_CARDS_REQUEST,
  props<{
    benchmarkId: number;
    params?: {
      facilityIds?: number[];
      grantId?: number;
      reportingPeriodId?: number;
    };
  }>(),
);
export const getCardsSuccess = createAction(
  DashboardActionTypes.GET_CARDS_SUCCESS,
  props<{ response: DashboardCardsApiResponse }>(),
);
export const getCardsFailure = createAction(
  DashboardActionTypes.GET_CARDS_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);

export const getFilterData = createAction(
  DashboardActionTypes.GET_FILTER_DATA_REQUEST,
);
export const getFilterDataFailure = createAction(
  DashboardActionTypes.GET_FILTER_DATA_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getFilterDataSuccess = createAction(
  DashboardActionTypes.GET_FILTER_DATA_SUCCESS,
  props<{ filterData: DashboardFilterDataApiResponse }>(),
);

export const getGrantSubmissionReviewStatus = createAction(
  DashboardActionTypes.GET_GRANT_SUBMISSION_REVIEW_STATUS_REQUEST,
  props<{ grantId: number }>(),
);

export const getGrantSubmissionReviewStatusSuccess = createAction(
  DashboardActionTypes.GET_GRANT_SUBMISSION_REVIEW_STATUS_SUCCESS,
  props<{ submissionReviewStatus: GrantSubmissionReviewStatusApiResponse }>(),
);

export const getGrantSubmissionReviewStatusFailure = createAction(
  DashboardActionTypes.GET_GRANT_SUBMISSION_REVIEW_STATUS_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);

export const getKeyDates = createAction(
  DashboardActionTypes.GET_KEY_DATES_REQUEST,
  props<{ year: number }>(),
);

export const getKeyDatesSuccess = createAction(
  DashboardActionTypes.GET_KEY_DATES_SUCCESS,
  props<{ keyDates: KeyDatesApiResponse }>(),
);

export const getKeyDatesFailure = createAction(
  DashboardActionTypes.GET_KEY_DATES_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);

export const getReportingPeriods = createAction(
  DashboardActionTypes.GET_REPORTING_PERIODS_REQUEST,
  props<{ grantId: number }>(),
);
export const getReportingPeriodsSuccess = createAction(
  DashboardActionTypes.GET_REPORTING_PERIODS_SUCCESS,
  props<{ reportingPeriods: ReportingPeriod[] }>(),
);
export const getReportingPeriodsFailure = createAction(
  DashboardActionTypes.GET_REPORTING_PERIODS_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);

export const getReportingPeriodState = createAction(
  DashboardActionTypes.GET_REPORTING_PERIOD_STATE_REQUEST,
);
export const getReportingPeriodStateSuccess = createAction(
  DashboardActionTypes.GET_REPORTING_PERIOD_STATE_SUCCESS,
  props<{ reportingPeriodState: ReportingPeriodStateApiResponse }>(),
);
export const getReportingPeriodStateFailure = createAction(
  DashboardActionTypes.GET_REPORTING_PERIOD_STATE_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);

export const resetCardDetails = createAction(
  DashboardActionTypes.RESET_CARD_DETAILS,
);
export const resetCardList = createAction(DashboardActionTypes.RESET_CARD_LIST);

export const setSelectedBenchmark = createAction(
  DashboardActionTypes.SET_SELECTED_BENCHMARK,
  props<{ selectedBenchmark: DashboardBenchmark }>(),
);

export const setSelectedGrantId = createAction(
  DashboardActionTypes.SET_SELECTED_GRANT_ID,
  props<{ selectedGrantId: number }>(),
);

export const setSelectedReportingPeriod = createAction(
  DashboardActionTypes.SET_SELECTED_REPORTING_PERIOD,
  props<{ selectedReportingPeriod: ReportingPeriod }>(),
);
