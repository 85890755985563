import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  GrantCoverSheetAdminProjectDirector,
  GrantCoverSheetAdminProjectDirectorApiResponse,
  GrantCoverSheetContactPerson,
  GrantCoverSheetContactPersonApiResponse,
  GrantCoverSheetMetaDataApiResponse,
} from '@core/models';
import { environment as env } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GrantProfileCoverSheetService {
  private adminProjectDirector = 'admin-project-director';
  private apiUrl = env.apiURL;
  private baseUrl = 'api/v1/';
  private granteeContactPerson = 'grantee-contact-person';
  private grantCoverSheet = 'grant-cover-sheet';

  constructor(private http: HttpClient) {}

  public getAdminProjectDirector(
    grantId: number,
  ): Observable<GrantCoverSheetAdminProjectDirectorApiResponse> {
    return this.http.get<GrantCoverSheetAdminProjectDirectorApiResponse>(
      `${this.apiUrl}${this.baseUrl}${this.grantCoverSheet}/${this.adminProjectDirector}`,
      {
        params: { grantId },
      },
    );
  }

  public getContactPerson(
    grantId: number,
  ): Observable<GrantCoverSheetContactPersonApiResponse> {
    return this.http.get<GrantCoverSheetContactPersonApiResponse>(
      `${this.apiUrl}${this.baseUrl}${this.grantCoverSheet}/${this.granteeContactPerson}`,
      {
        params: { grantId },
      },
    );
  }

  public getMetaData(
    grantId: number,
  ): Observable<GrantCoverSheetMetaDataApiResponse> {
    return this.http.get<GrantCoverSheetMetaDataApiResponse>(
      `${this.apiUrl}${this.baseUrl}${this.grantCoverSheet}/metadata/${grantId}`,
    );
  }

  public updateAdminProjectDirector(
    payload: GrantCoverSheetAdminProjectDirector,
  ): Observable<any> {
    return this.http.put(
      `${this.apiUrl}${this.baseUrl}${this.grantCoverSheet}/${this.adminProjectDirector}`,
      payload,
    );
  }

  public updateContactPerson(
    payload: GrantCoverSheetContactPerson,
  ): Observable<any> {
    return this.http.put(
      `${this.apiUrl}${this.baseUrl}${this.grantCoverSheet}/${this.granteeContactPerson}`,
      payload,
    );
  }
}
