import { Renderer2 } from '@angular/core';
import * as Papa from 'papaparse';

export const exportAsCsv = (
  data: any,
  fileName: string,
  renderer: Renderer2,
  timestamp?: string,
): void => {
  const csv = Papa.unparse(data);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);

  if (!timestamp) {
    timestamp = new Date().toISOString().replace(/[T:]/g, '_').split('.')[0];
  }

  const fileTitle = `${fileName}_${timestamp}`;

  const link = renderer.createElement('a');
  link.href = url;
  link.setAttribute('download', fileTitle);
  link.click();
  link.remove();
};
