import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const changePasswordValidator =
  // ----------
  // checks the values of the old and new passwords to
  // ensure they do not match
  // ----------


    (): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      const oldPassword = control.get('oldPassword').value;
      const newPassword = control.get('newPassword').value;

      const invalid =
        !!(oldPassword === newPassword) &&
        oldPassword.length &&
        newPassword.length;

      return invalid ? { newPasswordMatchesOld: true } : null;
    };

export const passwordValidator =
  // ----------
  // checks the value of an individual control for
  // leading or trailing spaces
  // ----------


    (): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      const invalid =
        control.value.length > 1 && !control.value.match(/^[\S]+.*[\S]+$/g);

      return invalid ? { whiteSpaceError: true } : null;
    };
