<qrcode
  [qrdata]="qrcode"
  [width]="width"
  [elementType]=""
  [cssClass]=""
  [alt]=""
  [version]=""
  [errorCorrectionLevel]=""
  [margin]=""
  [scale]=""
  [colorDark]=""
  [colorLight]=""
></qrcode>
