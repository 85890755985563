import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialog } from '@core/models';

@Component({
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  public confirmColor: string;
  public message: string;
  public innerHtml: string;
  public noButtonText: string;
  public title: string;
  public yesButtonText: string;

  constructor(@Inject(MAT_DIALOG_DATA) private data: ConfirmDialog) {}

  public ngOnInit(): void {
    this.confirmColor = this.data?.confirmColor || 'primary';
    this.title = this.data?.title;
    this.message = this.data?.message;
    this.innerHtml = this.data?.innerHtml;
    this.noButtonText = this.data?.noButtonText || 'No';
    this.yesButtonText = this.data?.yesButtonText || 'Yes';
  }
}
