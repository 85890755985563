import { Component, Injector, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppInjector } from '@app/shared/utilities';
import { AuthenticationActions } from '@app/store';
import { Actions, ofType } from '@ngrx/effects';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
@Component({
  template: '',
})
export class BaseDialogComponent implements OnDestroy {
  protected actions$: Actions;
  protected destroyed$ = new Subject<boolean>();
  protected dialogRef: MatDialogRef<BaseDialogComponent>;

  private injector: Injector;

  constructor() {
    this.injector = AppInjector.get();
    this.actions$ = this.injector.get(Actions);
    this.dialogRef = this.injector.get(MatDialogRef);

    this.actions$
      .pipe(
        ofType(AuthenticationActions.signOutSuccess),
        takeUntil(this.destroyed$),
        tap(() => this.dialogRef.close()),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
