import { Action, createReducer, on } from '@ngrx/store';
import {
  acceptGrantSubmission,
  acceptGrantSubmissionFailure,
  acceptGrantSubmissionSuccess,
  clearFileUploadData,
  deleteCustomReportingPeriod,
  deleteCustomReportingPeriodFailure,
  deleteCustomReportingPeriodSuccess,
  fileUploadTask,
  fileUploadTaskFailure,
  fileUploadTaskSuccess,
  getCustomReportingPeriod,
  getCustomReportingPeriodFailure,
  getCustomReportingPeriodSuccess,
  getDataColumns,
  getDataColumnsFailure,
  getDataColumnsSuccess,
  getFavoriteGrants,
  getFavoriteGrantsFailure,
  getFavoriteGrantsSuccess,
  getFileUploadResults,
  getFileUploadResultsFailure,
  getFileUploadResultsSuccess,
  getGrant,
  getGrantDetails,
  getGrantDetailsFailure,
  getGrantDetailsSilent,
  getGrantDetailsSilentFailure,
  getGrantDetailsSilentSuccess,
  getGrantDetailsSuccess,
  getGrantFailure,
  getGrantList,
  getGrantListByUser,
  getGrantListByUserFailure,
  getGrantListByUserSuccess,
  getGrantListFailure,
  getGrantListSuccess,
  getGrantSubmissionTableOverview,
  getGrantSubmissionTableOverviewFailure,
  getGrantSubmissionTableOverviewSuccess,
  getGrantSubmissionsList,
  getGrantSubmissionsListFailure,
  getGrantSubmissionsListSuccess,
  getGrantSuccess,
  getNumberOfSites,
  getNumberOfSitesFailure,
  getNumberOfSitesSuccess,
  getSelectedGrant,
  getSelectedGrantFailure,
  getSelectedGrantSuccess,
  getSubmissionComment,
  getSubmissionCommentFailure,
  getSubmissionCommentSuccess,
  getSubrecipients,
  getSubrecipientsFailure,
  getSubrecipientsSuccess,
  getTableOverview,
  getTableOverviewFailure,
  getTableOverviewSuccess,
  postFilesToS3Failure,
  postFilesToS3Success,
  resetFileUploadStatus,
  resetNumberOfSites,
  returnGrantSubmission,
  returnGrantSubmissionFailure,
  returnGrantSubmissionSuccess,
  setCombinedUpload,
  setEncounterUpload,
  setFileUploadStatus,
  setLabsUpload,
  setSelectedGrant,
  setSelectedGrantFailure,
  setSelectedGrantSuccess,
  submitGrant,
  submitGrantFailure,
  submitGrantSuccess,
  updateCustomReportingPeriod,
  updateCustomReportingPeriodFailure,
  updateCustomReportingPeriodSuccess,
  updateFavoriteGrants,
  updateFavoriteGrantsFailure,
  updateFavoriteGrantsSuccess,
  updateNumberOfSites,
  updateNumberOfSitesFailure,
  updateNumberOfSitesSuccess,
} from './actions';
import { State, initialState } from './state';

export const grantFeatureKey = 'grant';

const grantReducer = createReducer(
  initialState,
  on(acceptGrantSubmission, (state) => ({
    ...state,
    acceptSubmissionLoading: true,
  })),
  on(acceptGrantSubmissionFailure, (state, { error, message }) => ({
    ...state,
    acceptSubmissionError: { error, message },
    acceptSubmissionLoading: false,
  })),
  on(acceptGrantSubmissionSuccess, (state) => ({
    ...state,
    acceptSubmissionLoading: false,
  })),

  on(clearFileUploadData, (state) => ({
    ...state,
    grantFileUpload: null,
  })),

  on(deleteCustomReportingPeriod, (state) => ({
    ...state,
    customReportingPeriodLoading: true,
  })),
  on(deleteCustomReportingPeriodFailure, (state, { error, message }) => ({
    ...state,
    customReportingPeriodError: { error, message },
    customReportingPeriodLoading: false,
  })),
  on(deleteCustomReportingPeriodSuccess, (state) => ({
    ...state,
    customReportingPeriod: null,
    customReportingPeriodError: null,
    customReportingPeriodLoading: false,
  })),

  on(fileUploadTask, (state) => ({
    ...state,
    fileUploadTaskLoading: true,
  })),
  on(fileUploadTaskFailure, (state, { error, message }) => ({
    ...state,
    fileUploadTaskError: { error, message },
    fileUploadTaskLoading: false,
  })),
  on(fileUploadTaskSuccess, (state) => ({
    ...state,
    fileUploadTaskLoading: false,
    postFilesToS3Loading: true,
  })),

  on(getDataColumns, (state) => ({ ...state, getDataColumnsLoading: true })),
  on(getDataColumnsFailure, (state, { error, message }) => ({
    ...state,
    dataColumns: null,
    dataColumnsError: { error, message },
    dataColumnsLoading: false,
  })),
  on(getDataColumnsSuccess, (state, { dataColumns }) => ({
    ...state,
    dataColumns: dataColumns.data[0],
    dataColumnsLoading: false,
  })),

  on(getFavoriteGrants, (state) => ({ ...state, favoriteGrantsLoading: true })),
  on(getFavoriteGrantsFailure, (state, { error, message }) => ({
    ...state,
    favoriteGrants: null,
    favoriteGrantsError: { error, message },
    favoriteGrantsLoading: false,
  })),
  on(getFavoriteGrantsSuccess, (state, { response }) => ({
    ...state,
    favoriteGrants: response.data,
    favoriteGrantsError: null,
    favoriteGrantsLoading: false,
  })),

  on(getFileUploadResults, (state) => ({
    ...state,
    fileUploadResultsLoading: true,
  })),
  on(getFileUploadResultsFailure, (state, { error, message }) => ({
    ...state,
    fileUploadResults: null,
    fileUploadResultsError: { error, message },
    fileUploadResultsLoading: false,
  })),
  on(getFileUploadResultsSuccess, (state, { response }) => ({
    ...state,
    fileUploadResults: response.data[0],
    fileUploadResultsLoading: false,
  })),

  on(getGrant, (state) => ({ ...state, grantLoading: true })),
  on(getGrantFailure, (state, { error, message }) => ({
    ...state,
    grant: null,
    grantError: { error, message },
    grantLoading: false,
  })),
  on(getGrantSuccess, (state, { grant }) => ({
    ...state,
    grant: grant.data[0],
    grantLoading: false,
  })),
  on(getGrantDetails, (state) => ({ ...state, grantDetailsLoading: true })),
  on(getGrantDetailsFailure, (state, { error, message }) => ({
    ...state,
    grantDetails: null,
    grantDetailsError: { error, message },
    grantDetailsLoading: false,
  })),
  on(getGrantDetailsSuccess, (state, { grant }) => ({
    ...state,
    grantDetails: grant.data[0],
    grantDetailsError: null,
    grantDetailsLoading: false,
  })),
  on(getGrantDetailsSilent, (state) => ({
    ...state,
    grantDetailsSilentLoading: true,
  })),
  on(getGrantDetailsSilentFailure, (state, { error, message }) => ({
    ...state,
    grantDetails: null,
    grantDetailsSilentError: { error, message },
    grantDetailsSilentLoading: false,
  })),
  on(getGrantDetailsSilentSuccess, (state, { grant }) => ({
    ...state,
    grantDetails: grant.data[0],
    grantDetailsSilentError: null,
    grantDetailsSilentLoading: false,
  })),
  on(getGrantList, (state) => ({ ...state, grantListLoading: true })),
  on(getGrantListFailure, (state, { error, message }) => ({
    ...state,
    grants: null,
    grantListError: { error, message },
    grantListLoading: false,
  })),
  on(getGrantListSuccess, (state, { grants }) => ({
    ...state,
    grants: grants.data,
    grantListError: null,
    grantListLoading: false,
  })),
  on(getGrantListByUser, (state) => ({ ...state, grantListLoading: true })),
  on(getGrantListByUserFailure, (state, { error, message }) => ({
    ...state,
    grantList: null,
    grantListError: { error, message },
    grantListLoading: false,
  })),
  on(getGrantListByUserSuccess, (state, { grantList }) => ({
    ...state,
    grantList: grantList.data,
    grantListLoading: false,
  })),
  on(getGrantSubmissionsList, (state) => ({
    ...state,
    grantSubmissionsLoading: true,
  })),
  on(getGrantSubmissionsListFailure, (state, { error, message }) => ({
    ...state,
    grantSubmissions: [],
    grantSubmissionsError: { error, message },
    grantSubmissionsLoading: false,
  })),
  on(getGrantSubmissionsListSuccess, (state, { grantSubmissions }) => ({
    ...state,
    grantSubmissions: grantSubmissions.data,
    grantSubmissionsLoading: false,
  })),
  on(getGrantSubmissionTableOverview, (state) => ({
    ...state,
    grantSubmissionTableOverviewLoading: true,
  })),
  on(getGrantSubmissionTableOverviewFailure, (state, { error, message }) => ({
    ...state,
    grantSubmissionTableOverview: null,
    grantSubmissionTableOverviewError: { error, message },
    grantSubmissionTableOverviewLoading: false,
  })),
  on(
    getGrantSubmissionTableOverviewSuccess,
    (state, { grantSubmissionTableOverview }) => ({
      ...state,
      grantSubmissionTableOverview: grantSubmissionTableOverview.data[0],
      grantSubmissionTableOverviewError: null,
      grantSubmissionTableOverviewLoading: false,
      grantHasOpenIssues:
        grantSubmissionTableOverview.data[0].fparTableOverviews.some(
          (table) => table.issueCount > 0,
        ),
    }),
  ),
  on(getCustomReportingPeriod, (state) => ({
    ...state,
    customReportingPeriodLoading: true,
  })),
  on(getCustomReportingPeriodFailure, (state, { error, message }) => ({
    ...state,
    customReportingPeriod: null,
    customReportingPeriodError: { error, message },
    customReportingPeriodLoading: false,
  })),
  on(getCustomReportingPeriodSuccess, (state, { customReportingPeriod }) => ({
    ...state,
    customReportingPeriod: customReportingPeriod.data[0] || null,
    customReportingPeriodLoading: false,
  })),
  on(getNumberOfSites, (state) => ({ ...state, numberOfSitesLoading: true })),
  on(getNumberOfSitesFailure, (state, { error, message }) => ({
    ...state,
    numberOfSites: null,
    numberOfSitesError: { error, message },
    numberOfSitesLoading: false,
  })),
  on(getNumberOfSitesSuccess, (state, { numberOfSites }) => ({
    ...state,
    numberOfSites: {
      ...state.numberOfSites,
      ...numberOfSites.data[0],
    },
    numberOfSitesLoading: false,
  })),
  on(getSelectedGrant, (state) => ({ ...state, selectedGrantLoading: true })),
  on(getSelectedGrantFailure, (state, { message }) => ({
    ...state,
    selectedGrant: null,
    selectedGrantError: message,
    selectedGrantLoading: false,
  })),
  on(getSelectedGrantSuccess, (state, { selectedGrant }) => ({
    ...state,
    selectedGrant: state.selectedGrant ? state.selectedGrant : selectedGrant,
    selectedGrantLoading: false,
  })),

  on(getSubmissionComment, (state) => ({
    ...state,
    submissionCommentLoading: true,
  })),
  on(getSubmissionCommentFailure, (state, { error, message }) => ({
    ...state,
    submissionComment: null,
    submissionCommentError: { error, message },
    submissionCommentLoading: false,
  })),
  on(getSubmissionCommentSuccess, (state, { submissionComment }) => ({
    ...state,
    submissionComment: submissionComment.data[0] || null,
    submissionCommentLoading: false,
  })),

  on(getSubrecipients, (state) => ({
    ...state,
    subrecipientsLoading: true,
  })),
  on(getSubrecipientsSuccess, (state, { subrecipients }) => ({
    ...state,
    subrecipients: subrecipients.data,
    subrecipientsLoading: false,
  })),
  on(getSubrecipientsFailure, (state, { error, message }) => ({
    ...state,
    subrecipientsError: { error, message },
    subrecipientsLoading: false,
  })),

  on(getTableOverview, (state) => ({ ...state, tableOverviewLoading: true })),
  on(getTableOverviewFailure, (state, { error, message }) => ({
    ...state,
    tableOverview: null,
    tableOverviewError: { error, message },
    tableOverviewLoading: false,
  })),
  on(getTableOverviewSuccess, (state, { tableOverview }) => ({
    ...state,
    tableOverview: tableOverview.data[0] || null,
    tableOverviewLoading: false,
  })),

  on(postFilesToS3Failure, (state, { error, message }) => ({
    ...state,
    postFilesToS3Error: { error, message },
    postFilesToS3Loading: false,
  })),
  on(postFilesToS3Success, (state) => ({
    ...state,
    postFilesToS3Loading: false,
    fileUploadResultLoading: true,
  })),
  on(resetFileUploadStatus, (state) => ({
    ...state,
    grantFileUploadStatus: initialState.grantFileUploadStatus,
  })),

  on(resetNumberOfSites, (state) => ({
    ...state,
    numberOfSites: {
      grantId: null,
      titlexSubrecipients: null,
      titlexSites: null,
      differentFromApp: null,
      differentFromAppReason: null,
      telehealthClinics: null,
      status: null,
    },
  })),

  on(returnGrantSubmission, (state) => ({
    ...state,
    acceptSubmissionLoading: true,
  })),
  on(returnGrantSubmissionFailure, (state, { error, message }) => ({
    ...state,
    returnSubmissionError: { error, message },
    returnSubmissionLoading: false,
  })),
  on(returnGrantSubmissionSuccess, (state) => ({
    ...state,
    returnSubmissionLoading: false,
  })),

  on(setCombinedUpload, (state, { upload: combined }) => ({
    ...state,
    grantFileUpload: { ...state.grantFileUpload, combined },
  })),
  on(setEncounterUpload, (state, { upload: encounters }) => ({
    ...state,
    grantFileUpload: { ...state.grantFileUpload, encounters },
  })),
  on(setFileUploadStatus, (state, { status: fileStatus }) => ({
    ...state,
    grantFileUploadStatus: [
      ...state.grantFileUploadStatus.filter(
        (status) => status.type !== fileStatus.type,
      ),
      fileStatus,
    ],
  })),
  on(setLabsUpload, (state, { upload: labs }) => ({
    ...state,
    grantFileUpload: { ...state.grantFileUpload, labs },
  })),

  on(setSelectedGrant, (state) => ({
    ...state,
    setSelectedGrantLoading: true,
  })),
  on(setSelectedGrantFailure, (state, { error, message }) => ({
    ...state,
    selectedGrant: null,
    setSelectedGrantError: { error, message },
    setSelectedGrantLoading: false,
  })),
  on(setSelectedGrantSuccess, (state, { selectedGrant }) => ({
    ...state,
    selectedGrant,
    setSelectedGrantLoading: false,
  })),
  on(submitGrant, (state) => ({
    ...state,
    submitGrantLoading: true,
  })),
  on(submitGrantFailure, (state, { error, message }) => ({
    ...state,
    submitGrantError: { error, message },
    submitGrantLoading: false,
  })),
  on(submitGrantSuccess, (state) => ({
    ...state,
    submitGrantLoading: false,
  })),
  on(updateFavoriteGrants, (state) => ({
    ...state,
    updateMyGrantsLoading: true,
  })),
  on(updateFavoriteGrantsFailure, (state, { error, message }) => ({
    ...state,
    updateMyGrantsError: { error, message },
    updateMyGrantsLoading: false,
  })),
  on(updateFavoriteGrantsSuccess, (state, { favoriteGrants }) => ({
    ...state,
    favoriteGrants,
    updateMyGrantsError: null,
    updateMyGrantsLoading: false,
  })),
  on(updateCustomReportingPeriod, (state) => ({
    ...state,
    customReportingPeriodLoading: true,
  })),
  on(updateCustomReportingPeriodFailure, (state, { error, message }) => ({
    ...state,
    customReportingPeriodError: { error, message },
    customReportingPeriodLoading: false,
  })),
  on(updateCustomReportingPeriodSuccess, (state) => ({
    ...state,
    customReportingPeriodError: null,
    customReportingPeriodLoading: false,
  })),
  on(updateNumberOfSites, (state) => ({
    ...state,
    numberOfSitesLoading: true,
  })),
  on(updateNumberOfSitesFailure, (state, { error, message }) => ({
    ...state,
    numberOfSitesError: { error, message },
    numberOfSitesLoading: false,
  })),
  on(updateNumberOfSitesSuccess, (state) => ({
    ...state,
    numberOfSitesLoading: false,
  })),
);

export const reducer = (state: State | undefined, action: Action) =>
  grantReducer(state, action);
