import { ApiError, ReportingPeriod, ReportingPeriodState } from '@core/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { reportingPeriodFeatureKey } from './reducer';
import { State } from './state';

const getCreateReportingPeriodError = (state: State): ApiError =>
  state.createReportingPeriodError;
const getCreateReportingPeriodLoading = (state: State): boolean =>
  state.createReportingPeriodLoading;
const getDeleteReportingPeriodError = (state: State): ApiError =>
  state.deleteReportingPeriodError;
const getDeleteReportingPeriodLoading = (state: State): boolean =>
  state.deleteReportingPeriodLoading;
const getReportingPeriods = (state: State): ReportingPeriod[] =>
  state.reportingPeriods;
const getReportingPeriodsError = (state: State): ApiError =>
  state.reportingPeriodsError;
const getReportingPeriodsLoading = (state: State): boolean =>
  state.reportingPeriodsLoading;
const getReportingPeriodState = (state: State): ReportingPeriodState =>
  state.reportingPeriodState;
const getReportingPeriodStateError = (state: State): ApiError =>
  state.reportingPeriodStateError;
const getReportingPeriodStateLoading = (state: State): boolean =>
  state.reportingPeriodStateLoading;
const getUpdateReportingPeriodError = (state: State): ApiError =>
  state.updateReportingPeriodError;
const getUpdateReportingPeriodLoading = (state: State): boolean =>
  state.updateReportingPeriodLoading;

export const reportingPeriodState = createFeatureSelector<State>(
  reportingPeriodFeatureKey,
);

export const selectCreateReportingPeriodError = createSelector(
  reportingPeriodState,
  getCreateReportingPeriodError,
);
export const selectCreateReportingPeriodLoading = createSelector(
  reportingPeriodState,
  getCreateReportingPeriodLoading,
);
export const selectDeleteReportingPeriodError = createSelector(
  reportingPeriodState,
  getDeleteReportingPeriodError,
);
export const selectDeleteReportingPeriodLoading = createSelector(
  reportingPeriodState,
  getDeleteReportingPeriodLoading,
);
export const selectReportingPeriods = createSelector(
  reportingPeriodState,
  getReportingPeriods,
);
export const selectReportingPeriodsError = createSelector(
  reportingPeriodState,
  getReportingPeriodsError,
);
export const selectReportingPeriodsLoading = createSelector(
  reportingPeriodState,
  getReportingPeriodsLoading,
);

export const selectReportingPeriodState = createSelector(
  reportingPeriodState,
  getReportingPeriodState,
);
export const selectReportingPeriodStateError = createSelector(
  reportingPeriodState,
  getReportingPeriodStateError,
);
export const selectReportingPeriodStateLoading = createSelector(
  reportingPeriodState,
  getReportingPeriodStateLoading,
);

export const selectUpdateReportingPeriodError = createSelector(
  reportingPeriodState,
  getUpdateReportingPeriodError,
);
export const selectUpdateReportingPeriodLoading = createSelector(
  reportingPeriodState,
  getUpdateReportingPeriodLoading,
);
