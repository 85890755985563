import { ApiError, DataQualityResults, FparTable } from '@core/models';

export interface State {
  addTableCommentError: ApiError;
  addTableCommentLoading: boolean;
  addTableIssueCommentError: ApiError;
  addTableIssueCommentLoading: boolean;
  dataQualityCheckError: ApiError;
  dataQualityCheckLoading: boolean;
  dataQualityResults: DataQualityResults;
  dataQualityResultsError: ApiError;
  dataQualityResultsLoading: boolean;
  deleteTableCommentError: ApiError;
  deleteTableCommentLoading: boolean;
  deleteTableIssueCommentError: ApiError;
  deleteTableIssueCommentLoading: boolean;
  holdTableIssueError: ApiError;
  holdTableIssueLoading: boolean;
  table: FparTable;
  tableCommentsError: ApiError;
  tableCommentsLoading: boolean;
  tableError: ApiError;
  tableIssuesError: ApiError;
  tableIssuesLoading: boolean;
  tableIssueCommentsError: ApiError;
  tableIssueCommentsLoading: boolean;
  tableLoading: boolean;
  updateTableError: ApiError;
  updateTableLoading: boolean;
  updateTableCommentError: ApiError;
  updateTableCommentLoading: boolean;
  updateTableIssueError: ApiError;
  updateTableIssueLoading: boolean;
  updateTableIssueCommentError: ApiError;
  updateTableIssueCommentLoading: boolean;
}

export const initialState: State = {
  addTableCommentError: null,
  addTableCommentLoading: false,
  addTableIssueCommentError: null,
  addTableIssueCommentLoading: false,
  dataQualityCheckError: null,
  dataQualityCheckLoading: false,
  dataQualityResults: null,
  dataQualityResultsError: null,
  dataQualityResultsLoading: false,
  deleteTableCommentError: null,
  deleteTableCommentLoading: false,
  deleteTableIssueCommentError: null,
  deleteTableIssueCommentLoading: false,
  holdTableIssueError: null,
  holdTableIssueLoading: false,
  table: null,
  tableCommentsError: null,
  tableCommentsLoading: false,
  tableError: null,
  tableIssuesError: null,
  tableIssuesLoading: false,
  tableIssueCommentsError: null,
  tableIssueCommentsLoading: false,
  tableLoading: false,
  updateTableError: null,
  updateTableLoading: false,
  updateTableCommentError: null,
  updateTableCommentLoading: false,
  updateTableIssueError: null,
  updateTableIssueLoading: false,
  updateTableIssueCommentError: null,
  updateTableIssueCommentLoading: false,
};
