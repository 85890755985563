import { Component, Input, OnInit } from '@angular/core';
import { GrantFileUploadStatus } from '@core/models';

@Component({
  selector: 'app-upload-data-status',
  templateUrl: './upload-data-status.component.html',
  styleUrls: ['./upload-data-status.component.scss'],
})
export class UploadStatusComponent implements OnInit {
  public dataStatuses: GrantFileUploadStatus[];

  @Input()
  set orderStatuses(statuses: GrantFileUploadStatus[]) {
    this.dataStatuses = [...statuses].sort((obj1, obj2) =>
      obj1.type.localeCompare(obj2.type),
    );
  }

  ngOnInit(): void {}
}
