import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { handleError } from '@core/helpers/format-error';
import { FileUploadResultApiResponse } from '@core/models';
import { GrantService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { StatusCodes } from 'http-status-codes';
import { interval, Subject, timer } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import {
  acceptFileUpload,
  acceptFileUploadFailure,
  acceptFileUploadSuccess,
  acceptGrantSubmission,
  acceptGrantSubmissionFailure,
  acceptGrantSubmissionSuccess,
  deleteCustomReportingPeriod,
  deleteCustomReportingPeriodFailure,
  deleteCustomReportingPeriodSuccess,
  deleteSubmissionComment,
  deleteSubmissionCommentFailure,
  deleteSubmissionCommentSuccess,
  fileUploadTask,
  fileUploadTaskFailure,
  fileUploadTaskSuccess,
  getCustomReportingPeriod,
  getCustomReportingPeriodFailure,
  getCustomReportingPeriodSuccess,
  getDataColumns,
  getDataColumnsFailure,
  getDataColumnsSuccess,
  getFavoriteGrants,
  getFavoriteGrantsFailure,
  getFavoriteGrantsSuccess,
  getFileUploadResults,
  getFileUploadResultsFailure,
  getFileUploadResultsSuccess,
  getGrant,
  getGrantDetails,
  getGrantDetailsFailure,
  getGrantDetailsSilent,
  getGrantDetailsSilentFailure,
  getGrantDetailsSilentSuccess,
  getGrantDetailsSuccess,
  getGrantFailure,
  getGrantList,
  getGrantListByUser,
  getGrantListByUserFailure,
  getGrantListByUserSuccess,
  getGrantListFailure,
  getGrantListSuccess,
  getGrantSubmissionsList,
  getGrantSubmissionsListSuccess,
  getGrantSubmissionTableOverview,
  getGrantSubmissionTableOverviewFailure,
  getGrantSubmissionTableOverviewSuccess,
  getGrantSuccess,
  getNumberOfSites,
  getNumberOfSitesFailure,
  getNumberOfSitesSuccess,
  getSelectedGrant,
  getSelectedGrantFailure,
  getSelectedGrantSuccess,
  getSubmissionComment,
  getSubmissionCommentFailure,
  getSubmissionCommentSuccess,
  getSubrecipients,
  getSubrecipientsFailure,
  getSubrecipientsSuccess,
  getTableOverview,
  getTableOverviewFailure,
  getTableOverviewSuccess,
  postFilesToS3Failure,
  postFilesToS3Success,
  returnGrantSubmission,
  returnGrantSubmissionFailure,
  returnGrantSubmissionSuccess,
  setSelectedGrant,
  setSelectedGrantFailure,
  setSelectedGrantSuccess,
  submitGrant,
  submitGrantFailure,
  submitGrantSuccess,
  updateCustomReportingPeriod,
  updateCustomReportingPeriodFailure,
  updateCustomReportingPeriodSuccess,
  updateFavoriteGrants,
  updateFavoriteGrantsFailure,
  updateFavoriteGrantsSuccess,
  updateNumberOfSites,
  updateNumberOfSitesFailure,
  updateNumberOfSitesSuccess,
  updateSubmissionComment,
  updateSubmissionCommentFailure,
  updateSubmissionCommentSuccess,
} from './actions';
import { selectUserUploadedFiles } from './selectors';

@Injectable()
export class GrantEffects {
  acceptSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(acceptGrantSubmission),
      switchMap(({ grantId, payload }) =>
        this.grantService.updateGrantSubmission(grantId, payload).pipe(
          map(
            (response) => acceptGrantSubmissionSuccess({ response }),
            catchError((error) =>
              handleError(error, acceptGrantSubmissionFailure),
            ),
          ),
        ),
      ),
    ),
  );

  acceptFileUpload$ = createEffect(() =>
    this.actions$.pipe(
      ofType(acceptFileUpload),
      switchMap(({ grantId, userAccepted }) =>
        this.grantService.acceptFileUpload(grantId, userAccepted).pipe(
          map(
            (response) => acceptFileUploadSuccess({ response }),
            catchError((error) => handleError(error, acceptFileUploadFailure)),
          ),
        ),
      ),
    ),
  );

  deleteCustomReportingPeriod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteCustomReportingPeriod),
      switchMap(({ reportingPayload }) =>
        this.grantService.deleteCustomReportingPeriod(reportingPayload).pipe(
          map(() =>
            deleteCustomReportingPeriodSuccess({
              grantId: reportingPayload.grantId,
            }),
          ),
          catchError((error) =>
            handleError(error, deleteCustomReportingPeriodFailure),
          ),
        ),
      ),
    ),
  );

  deleteSubmissionComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteSubmissionComment),
      switchMap(({ grantId, commentId }) =>
        this.grantService.deleteSubmissionComment(grantId, commentId).pipe(
          map(() => deleteSubmissionCommentSuccess()),
          catchError((error) =>
            handleError(error, deleteSubmissionCommentFailure),
          ),
        ),
      ),
    ),
  );

  fileUploadTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fileUploadTask),
      switchMap(({ grantId, payload }) =>
        this.grantService.fileUploadTask(grantId, payload).pipe(
          map(
            (response) => fileUploadTaskSuccess({ response }),
            catchError((error) => handleError(error, fileUploadTaskFailure)),
          ),
        ),
      ),
    ),
  );

  fileUploadTaskSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fileUploadTaskSuccess),
      withLatestFrom(this.store$.select(selectUserUploadedFiles)),
      switchMap(([fileUploadTaskResponse, userUploadedFiles]) =>
        this.grantService
          .postFilesToS3(
            fileUploadTaskResponse.response.data[0],
            userUploadedFiles,
          )
          .pipe(
            map(
              (response) => postFilesToS3Success({ response }),
              catchError((error) => handleError(error, postFilesToS3Failure)),
            ),
          ),
      ),
    ),
  );

  getCustomReportingPeriod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getCustomReportingPeriod),
      switchMap(({ grantId }) =>
        this.grantService.getCustomReportingPeriod(grantId).pipe(
          map(
            (customReportingPeriod) =>
              getCustomReportingPeriodSuccess({ customReportingPeriod }),
            catchError((error) =>
              handleError(error, getCustomReportingPeriodFailure),
            ),
          ),
        ),
      ),
    ),
  );

  getDataColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDataColumns),
      switchMap(() =>
        this.grantService.getDataColumns().pipe(
          map(
            (dataColumns) => getDataColumnsSuccess({ dataColumns }),
            catchError((error) => handleError(error, getDataColumnsFailure)),
          ),
        ),
      ),
    ),
  );

  getFavoriteGrants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFavoriteGrants),
      switchMap(() =>
        this.grantService.getFavoriteGrants().pipe(
          map((response) => getFavoriteGrantsSuccess({ response })),
          catchError((error) => handleError(error, getFavoriteGrantsFailure)),
        ),
      ),
    ),
  );

  getFileUploadResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFileUploadResults),
      switchMap((action) =>
        // check for validation results every 5 seconds
        interval(5000).pipe(
          takeUntil(
            // until timeout of 2 minutes
            timer(120000).pipe(
              map(() => {
                throw new HttpErrorResponse({
                  error: new Error(
                    'An error occurred while validating your files. Please try again later or contact support.',
                  ),
                  status: StatusCodes.REQUEST_TIMEOUT,
                });
              }),
            ),
          ),
          // or until file upload results contain an endDate
          takeUntil(this.fileUploadResultsComplete$),
          map(() => action),
          switchMap(({ grantId }) =>
            this.grantService.getFileUploadResults(grantId).pipe(
              filter(
                (response: FileUploadResultApiResponse) =>
                  !!response?.data[0]?.endDate || response?.data.length === 0,
              ),
              map((response) => {
                // if the process finishes and the data array is not empty,
                // but is not marked 'completed: true' and no validation
                // errors exist, throw an error
                if (
                  response?.data.length &&
                  !response?.data[0]?.completed &&
                  !response?.data[0]?.errors.length
                ) {
                  throw new HttpErrorResponse({
                    error: new Error(
                      'An error occurred while validating your files. Please try again later or contact support.',
                    ),
                    status: StatusCodes.INTERNAL_SERVER_ERROR,
                  });
                }

                this.fileUploadResultsComplete$.next(true);
                return getFileUploadResultsSuccess({ response });
              }),
              catchError((error) => {
                this.fileUploadResultsComplete$.next(true);
                return handleError(error, getFileUploadResultsFailure);
              }),
            ),
          ),
        ),
      ),
    ),
  );

  getGrant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGrant),
      switchMap(({ payload }) =>
        this.grantService.getGrant(payload).pipe(
          map(
            (grant) => getGrantSuccess({ grant }),
            catchError((error) => handleError(error, getGrantFailure)),
          ),
        ),
      ),
    ),
  );

  getGrantDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGrantDetails),
      switchMap(({ payload }) =>
        this.grantService.getGrantDetails(payload).pipe(
          map(
            (grant) => getGrantDetailsSuccess({ grant }),
            catchError((error) => handleError(error, getGrantDetailsFailure)),
          ),
        ),
      ),
    ),
  );

  getGrantDetailsSilent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGrantDetailsSilent),
      switchMap(({ payload }) =>
        this.grantService.getGrantDetails(payload).pipe(
          map(
            (grant) => getGrantDetailsSilentSuccess({ grant }),
            catchError((error) =>
              handleError(error, getGrantDetailsSilentFailure),
            ),
          ),
        ),
      ),
    ),
  );

  getGrantList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGrantList),
      switchMap(({ active }) =>
        this.grantService.getGrantList(active).pipe(
          map((grants) => getGrantListSuccess({ grants })),
          catchError((error) => handleError(error, getGrantListFailure)),
        ),
      ),
    ),
  );

  getGrantListByUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGrantListByUser),
      switchMap(() =>
        this.grantService.getGrantListByUser().pipe(
          map((grantList) => getGrantListByUserSuccess({ grantList })),
          catchError((error) => handleError(error, getGrantListByUserFailure)),
        ),
      ),
    ),
  );

  getGrantSubmissionsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGrantSubmissionsList),
      switchMap(() =>
        this.grantService.getGrantSubmissions().pipe(
          map((grantSubmissions) =>
            getGrantSubmissionsListSuccess({ grantSubmissions }),
          ),
          catchError((error) => handleError(error, getGrantListFailure)),
        ),
      ),
    ),
  );

  getGrantSubmissionTableOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGrantSubmissionTableOverview),
      switchMap(({ grantId }) =>
        this.grantService.getGrantSubmissionTableOverview(grantId).pipe(
          map((grantSubmissionTableOverview) =>
            getGrantSubmissionTableOverviewSuccess({
              grantSubmissionTableOverview,
            }),
          ),
          catchError((error) =>
            handleError(error, getGrantSubmissionTableOverviewFailure),
          ),
        ),
      ),
    ),
  );

  getNumberOfSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getNumberOfSites),
      switchMap(({ grantId }) =>
        this.grantService.getNumberOfSites(grantId).pipe(
          map(
            (numberOfSites) => getNumberOfSitesSuccess({ numberOfSites }),
            catchError((error) => handleError(error, getNumberOfSitesFailure)),
          ),
        ),
      ),
    ),
  );

  getSelectedGrant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSelectedGrant),
      switchMap(() =>
        this.grantService.getUserGrantSelection().pipe(
          map((selectedGrant) => getSelectedGrantSuccess({ selectedGrant })),
          catchError((error) => handleError(error, getSelectedGrantFailure)),
        ),
      ),
    ),
  );

  getSubmissionComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSubmissionComment),
      switchMap(({ grantId, reportingPeriodId }) =>
        this.grantService.getSubmissionComment(grantId, reportingPeriodId).pipe(
          map((submissionComment) =>
            getSubmissionCommentSuccess({ submissionComment }),
          ),
          catchError((error) =>
            handleError(error, getSubmissionCommentFailure),
          ),
        ),
      ),
    ),
  );

  getSubrecipients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSubrecipients),
      switchMap(({ grantId }) =>
        this.grantService.getSubrecipients(grantId).pipe(
          map((subrecipients) => getSubrecipientsSuccess({ subrecipients })),
          catchError((error) => handleError(error, getSubrecipientsFailure)),
        ),
      ),
    ),
  );

  getTableOverview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTableOverview),
      switchMap(({ grantId }) =>
        this.grantService.getTableOverview(grantId).pipe(
          map((tableOverview) => getTableOverviewSuccess({ tableOverview })),
          catchError((error) => handleError(error, getTableOverviewFailure)),
        ),
      ),
    ),
  );

  returnSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(returnGrantSubmission),
      switchMap(({ grantId, payload }) =>
        this.grantService.updateGrantSubmission(grantId, payload).pipe(
          map(
            (response) => returnGrantSubmissionSuccess({ response }),
            catchError((error) =>
              handleError(error, returnGrantSubmissionFailure),
            ),
          ),
        ),
      ),
    ),
  );

  setSelectedGrant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setSelectedGrant),
      switchMap(({ grantId, subrecipientId }) =>
        this.grantService.setUserGrantSelection(grantId, subrecipientId).pipe(
          map((selectedGrant) => setSelectedGrantSuccess({ selectedGrant })),
          catchError((error) => handleError(error, setSelectedGrantFailure)),
        ),
      ),
    ),
  );

  submitGrant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(submitGrant),
      switchMap(({ grantId }) =>
        this.grantService.submitGrant(grantId).pipe(
          map(() => submitGrantSuccess()),
          catchError((error) => handleError(error, submitGrantFailure)),
        ),
      ),
    ),
  );

  updateFavoriteGrants$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateFavoriteGrants),
      switchMap(({ payload }) =>
        this.grantService.updateFavoriteGrants(payload).pipe(
          map(() =>
            updateFavoriteGrantsSuccess({ favoriteGrants: payload.grantIds }),
          ),
          catchError((error) =>
            handleError(error, updateFavoriteGrantsFailure),
          ),
        ),
      ),
    ),
  );

  updateCustomReportingPeriod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateCustomReportingPeriod),
      switchMap(({ reportingPayload }) =>
        this.grantService.updateCustomReportingPeriod(reportingPayload).pipe(
          map(() => updateCustomReportingPeriodSuccess()),
          catchError((error) =>
            handleError(error, updateCustomReportingPeriodFailure),
          ),
        ),
      ),
    ),
  );

  updateNumberOfSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateNumberOfSites),
      switchMap(({ payload }) =>
        this.grantService.updateNumberOfSites(payload).pipe(
          map(() => updateNumberOfSitesSuccess({ grantId: payload.grantId })),
          catchError((error) => handleError(error, updateNumberOfSitesFailure)),
        ),
      ),
    ),
  );

  updateSubmissionComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSubmissionComment),
      switchMap(({ grantId, comment }) =>
        this.grantService.updateSubmissionComment(grantId, comment).pipe(
          map(() => updateSubmissionCommentSuccess()),
          catchError((error) =>
            handleError(error, updateSubmissionCommentFailure),
          ),
        ),
      ),
    ),
  );

  private fileUploadResultsComplete$ = new Subject<boolean>();

  constructor(
    private actions$: Actions,
    private grantService: GrantService,
    private store$: Store,
  ) {}
}
