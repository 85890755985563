import { State as AppState } from '@app/store/app-state';
import { INotification } from '@core/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { notificationFeatureKey } from './reducer';
import { State } from './state';

const getAddError = (state: State): string => state.addError;
const getAddLoading = (state: State): boolean => state.addLoading;
const getNotifications = (state: State): INotification[] => state.notifications;
const getNotificationsError = (state: State): string =>
  state.notificationsError;
const getNotificationsLoading = (state: State): boolean =>
  state.notificationsLoading;
const getRemoveError = (state: State): string => state.removeError;
const getRemoveLoading = (state: State): boolean => state.removeLoading;
const getResetError = (state: State): string => state.resetError;
const getResetLoading = (state: State): boolean => state.resetLoading;

export const notificationsState = createFeatureSelector<AppState, State>(
  notificationFeatureKey,
);

export const selectAddError = createSelector(notificationsState, getAddError);
export const selectAddLoading = createSelector(
  notificationsState,
  getAddLoading,
);

export const selectNotifications = createSelector(
  notificationsState,
  getNotifications,
);

export const selectNotificationsError = createSelector(
  notificationsState,
  getNotificationsError,
);
export const selectNotificationsLoading = createSelector(
  notificationsState,
  getNotificationsLoading,
);

export const selectRemoveError = createSelector(
  notificationsState,
  getRemoveError,
);
export const selectRemoveLoading = createSelector(
  notificationsState,
  getRemoveLoading,
);

export const selectResetError = createSelector(
  notificationsState,
  getResetError,
);
export const selectResetLoading = createSelector(
  notificationsState,
  getResetLoading,
);
