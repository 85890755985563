import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import {
  AppStoreState,
  DashboardActions,
  DashboardSelectors,
} from '@app/store';
import { CardDetails } from '@core/models';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CardDetailsGuard  {
  private selectCardDetails$: Observable<CardDetails> = this.store$.select(
    DashboardSelectors.selectCardDetails,
  );

  constructor(
    private store$: Store<AppStoreState.State>,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    this.store$.dispatch(
      DashboardActions.getCardDetails({
        cardId: route.params.cardId,
      }),
    );

    return this.selectCardDetails$.pipe(
      skipWhile((card) => !card),
      map((card) =>
        card?.detailsAvailable ? true : this.router.parseUrl('/dashboard'),
      ),
    );
  }
}
