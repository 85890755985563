import { Pipe, PipeTransform } from '@angular/core';
import { FparDataColumn } from '@core/models';

@Pipe({
  name: 'requiredColumn',
})
export class RequiredColumnPipe implements PipeTransform {
  transform(column: FparDataColumn): string {
    return !column.missingAllowed
      ? `<strong>${column.systemColumnDesc} *</strong>`
      : column.systemColumnDesc;
  }
}
