import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { NotificationEffects } from './effects';
import { notificationFeatureKey, reducer } from './reducer';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([NotificationEffects]),
    StoreModule.forFeature(notificationFeatureKey, reducer),
  ],
})
export class NotificationModule {}
