<div
  *ngIf="(reportingPeriodStateLoading$ | async) === false"
  class="fpar-banner"
>
  <ng-container *ngIf="!submissionOpen && !validationOpen">
    The data submission period is closed for any data submissions! Accepted data
    and previous year data submissions are available to view in the
    <span [routerLink]="['/table-archive']">Table Archive</span>.
  </ng-container>
  <ng-container *ngIf="submissionOpen">
    The data submission period is now open!
  </ng-container>
  <ng-container *ngIf="!submissionOpen && validationOpen">
    The pre-submission period is now open! You may validate your data prior to
    the submission period in the Data Portal.
  </ng-container>
</div>
