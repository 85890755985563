import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResourceAccessApiResponse, ResourceAccessPayload } from '@core/models';
import { environment as env } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResourceAccessService {
  private apiUrl = env.apiURL;
  private baseUrl = 'api/v1/';
  private resources = 'role-permissions';

  constructor(private http: HttpClient) {}

  public getResources(
    payload: ResourceAccessPayload,
  ): Observable<ResourceAccessApiResponse> {
    return this.http.get<ResourceAccessApiResponse>(
      `${this.apiUrl}${this.baseUrl}${this.resources}`,
      {
        params: { ...payload },
      },
    );
  }
}
