import {
  ApiError,
  GrantCoverSheetAdminProjectDirector,
  GrantCoverSheetContactPerson,
  GrantCoverSheetMetaData,
} from '@core/models';

export interface State {
  adminProjectDirector: GrantCoverSheetAdminProjectDirector;
  adminProjectDirectorError: ApiError;
  adminProjectDirectorLoading: boolean;
  contactPerson: GrantCoverSheetContactPerson;
  contactPersonError: ApiError;
  contactPersonLoading: boolean;
  metaData: GrantCoverSheetMetaData;
  metaDataError: ApiError;
  metaDataLoading: boolean;
}

export const initialState: State = {
  adminProjectDirector: null,
  adminProjectDirectorError: null,
  adminProjectDirectorLoading: false,
  contactPerson: null,
  contactPersonError: null,
  contactPersonLoading: false,
  metaData: null,
  metaDataError: null,
  metaDataLoading: null,
};
