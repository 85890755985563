<div class="action-bar">
  <div action-bar-left>
    <fieldset>
      <mat-form-field
        class="search-box mat-mdc-form-field-100-percent"
        [ngStyle]="{ 'max-width': searchWidth }"
        *ngIf="!hideSearch"
      >
        <mat-icon matSuffix>search</mat-icon>
        <mat-label>Search</mat-label>
        <input
          matInput
          title="search"
          (keyup)="applyFilter($event.target.value)"
          #input
        />
      </mat-form-field>

      <ng-content select="[action-left-content]"></ng-content>
    </fieldset>
  </div>
  <div action-bar-right>
    <ng-content select="[action-right-content]"></ng-content>
  </div>
</div>

<div
  class="scroll-container"
  [ngClass]="skipScrollMargin ? '' : 'include-margin'"
>
  <mat-table
    *ngIf="dataSource && options"
    [dataSource]="dataSource"
    matSort
    [matSortActive]="sortActive"
    [matSortDirection]="sortDirection"
  >
    <ng-container matColumnDef="selectRow">
      <mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          *ngIf="multiSelect"
          color="primary"
          (change)="$event ? toggleAllRows() : null"
          [checked]="selection.hasValue() && allRowsSelected()"
          [indeterminate]="selection.hasValue() && !allRowsSelected()"
          [aria-label]="inputAriaLabel()"
          [matTooltip]="inputAriaLabel()"
          [ngClass]="{ 'wrap-header-text': options.wrapHeaderText }"
        >
        </mat-checkbox>
      </mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-checkbox
          *ngIf="multiSelect"
          color="primary"
          (click)="$event.stopPropagation()"
          (change)="$event ? onRowSelected(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="inputAriaLabel(row)"
        >
        </mat-checkbox>

        <mat-radio-button
          *ngIf="singleSelect && !multiSelect"
          color="primary"
          (click)="$event.stopPropagation()"
          (change)="$event ? onRowSelected(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="inputAriaLabel(row)"
        >
        </mat-radio-button>
      </mat-cell>
      <mat-footer-cell *matFooterCellDef></mat-footer-cell>
    </ng-container>

    <ng-container
      *ngFor="let col of tableColumns; let i = index"
      [matColumnDef]="col.column"
    >
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        scope="col"
        [disabled]="disableSort || col.disableSort"
        [ngStyle]="getColumnStyle(col)"
        [ngClass]="{ 'wrap-header-text': options.wrapHeaderText }"
        [matTooltip]="col.headerTooltip"
        [attr.aria-label]="col.headerTooltip"
        tabindex="0"
      >
        {{ col.headerName }}
        <mat-icon 
          class="header-icon" 
          *ngIf="col.headerIcon" 
          [matTooltip]="col.headerIconTooltip"
          [attr.aria-label]="col.headerIconTooltip"
          tabindex="0"
        >
          {{ col.headerIcon }}
        </mat-icon>
      </mat-header-cell>
      <ng-container *appSecurableResource="col.permissions">
        <mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          scope="col"
          [disabled]="col.columnAction"
          [ngStyle]="getColumnStyle(col)"
          [ngClass]="{ 'wrap-header-text': options.wrapHeaderText }"
        >
          {{ col.headerName }}
        </mat-header-cell>
      </ng-container>

      <ng-container *ngIf="!col.columnAction">
        <mat-cell
          *matCellDef="let element; let row"
          [matTooltip]="inputAriaLabel(row)"
          matTooltipPosition="below"
          [ngStyle]="getColumnStyle(col)"
          [class.highlightedRow]="readyForReviewRow(element.reviewStatus)"
          [attr.aria-label]="inputAriaLabel(row)"
          tabindex="0"
        >
          <ng-container
            *ngIf="
              options.isHtml || col.isHtml;
              then htmlContent;
              else stringContent
            "
          >
          </ng-container>
          <ng-template #htmlContent>
            <div [innerHtml]="getElementValue(element, col)"></div>
          </ng-template>
          <ng-template #stringContent>
            <mat-icon
              *ngIf="getIcon(element, col) as columnIcon"
              class="column-icon"
              [ngClass]="getIconColor(element, col)"
              role="icon"
              tabindex="0"
              [attr.aria-label]="columnIcon"
              >{{ columnIcon }}</mat-icon
            >
            <span>{{ getElementValue(element, col) }}</span>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container *ngIf="col.columnAction">
        <ng-container *appSecurableResource="col.permissions">
          <mat-cell
            *matCellDef="let element"
            class="mdc-action-cell"
            [ngStyle]="getColumnStyle(col)"
          >
            <button mat-button (click)="col.columnAction.action(element)">
              <mat-icon *ngIf="col.columnAction.icon">{{
                col.columnAction.icon
              }}</mat-icon
              ><span>{{ col.columnAction.label }}</span>
            </button>
          </mat-cell>
        </ng-container>
      </ng-container>

      <ng-container>
        <mat-footer-cell *matFooterCellDef>
          <span>{{ options.tableFooter[i] || '' }}</span>
        </mat-footer-cell>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="tableTenFootnote">
      <mat-footer-cell *matFooterCellDef>
        <span
          >* Data associated with clinical breast exams and referrals is no
          longer collected.</span
        >
      </mat-footer-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      matRipple
      [matRippleDisabled]="
        !selectableRows || (isRowSelectable && !isRowSelectable(row))
      "
      (click)="onRowSelected(row)"
      [ngClass]="getRowClass(row)"
    ></mat-row>
    <ng-container *ngIf="options.tableFooter">
      <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
    </ng-container>
    <ng-container *ngIf="showTableTenFootnote">
      <mat-footer-row *matFooterRowDef="['tableTenFootnote']"></mat-footer-row>
    </ng-container>
  </mat-table>
</div>

<mat-paginator
  [pageSize]="10"
  [pageSizeOptions]="pageSizeOptions"
  *ngIf="!hidePaginator"
>
</mat-paginator>
