import { Action, createReducer, on } from '@ngrx/store';
import {
  getAdminProjectDirector,
  getAdminProjectDirectorFailure,
  getAdminProjectDirectorSuccess,
  getContactPerson,
  getContactPersonFailure,
  getContactPersonSuccess,
  getMetaData,
  getMetaDataFailure,
  getMetaDataSuccess,
  updateAdminProjectDirector,
  updateAdminProjectDirectorFailure,
  updateAdminProjectDirectorSuccess,
  updateContactPerson,
  updateContactPersonFailure,
  updateContactPersonSuccess,
} from './actions';
import { initialState, State } from './state';

export const grantProfileCoverSheetFeatureKey = 'grantProfileCoverSheet';

const GrantProfileCoverSheetReducer = createReducer(
  initialState,
  on(getAdminProjectDirector, (state) => ({
    ...state,
    adminProjectDirectorLoading: true,
  })),
  on(getAdminProjectDirectorFailure, (state, { error, message }) => ({
    ...state,
    adminProjectDirector: null,
    adminProjectDirectorError: { error, message },
    adminProjectDirectorLoading: false,
  })),
  on(getAdminProjectDirectorSuccess, (state, { adminProjectDirector }) => ({
    ...state,
    adminProjectDirector: adminProjectDirector.data[0]
      ? adminProjectDirector.data[0]
      : null,
    adminProjectDirectorLoading: false,
  })),

  on(getContactPerson, (state) => ({ ...state, contactPersonLoading: true })),
  on(getContactPersonFailure, (state, { error, message }) => ({
    ...state,
    contactPerson: null,
    contactPersonError: { error, message },
    contactPersonLoading: false,
  })),
  on(getContactPersonSuccess, (state, { contactPerson }) => ({
    ...state,
    contactPerson: contactPerson.data[0] ? contactPerson.data[0] : null,
    contactPersonLoading: false,
  })),

  on(getMetaData, (state) => ({ ...state, metaDataLoading: true })),
  on(getMetaDataFailure, (state, { error, message }) => ({
    ...state,
    metaData: null,
    metaDataError: { error, message },
    metaDataLoading: false,
  })),
  on(getMetaDataSuccess, (state, { metaData }) => ({
    ...state,
    metaData: metaData.data[0] ? metaData.data[0] : null,
    metaDataError: null,
    metaDataLoading: false,
  })),

  on(updateAdminProjectDirector, (state) => ({
    ...state,
    adminProjectDirectorLoading: true,
  })),
  on(updateAdminProjectDirectorFailure, (state, { error, message }) => ({
    ...state,
    adminProjectDirectorError: { error, message },
    adminProjectDirectorLoading: false,
  })),
  on(updateAdminProjectDirectorSuccess, (state) => ({
    ...state,
    adminProjectDirectorLoading: false,
  })),

  on(updateContactPerson, (state) => ({
    ...state,
    contactPersonLoading: true,
  })),
  on(updateContactPersonFailure, (state, { error, message }) => ({
    ...state,
    contactPersonError: { error, message },
    contactPersonLoading: false,
  })),
  on(updateContactPersonSuccess, (state) => ({
    ...state,
    contactPersonLoading: false,
  })),
);

export const reducer = (state: State | undefined, action: Action) =>
  GrantProfileCoverSheetReducer(state, action);
