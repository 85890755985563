import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'militaryTo12Hour',
})
export class MilitaryTo12HourPipe implements PipeTransform {
  transform(time: string): string {
    const [hours, minutes] = time.split(':');
    let formattedTime = '';

    // Convert hours to 12-hour format
    let hour = parseInt(hours, 10);
    const ampm = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12 || 12;

    formattedTime = `${hour}:${minutes} ${ampm}`;

    return formattedTime;
  }
}
