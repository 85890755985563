/* eslint-disable @typescript-eslint/naming-convention */
import { HttpErrorResponse } from '@angular/common/http';
import {
  Account,
  AccountApiResponse,
  AccountUpdatePayload,
} from '@core/models';
import { CognitoErrorResponse, CognitoUserData } from '@core/services';
import { createAction, props } from '@ngrx/store';

export enum AccountActionTypes {
  GET_ACCOUNT_FAILURE = '[Account] Get Account Failure',
  GET_ACCOUNT_REQUEST = '[Account] Get Account Request',
  GET_ACCOUNT_SUCCESS = '[Account] Get Account Success',
  GET_USER_DATA_FAILURE = '[Account] Get User Data Failure',
  GET_USER_DATA_REQUEST = '[Account] Get User Data Request',
  GET_USER_DATA_SUCCESS = '[Account] Get User Data Success',
  GET_USER_DETAILS_FAILURE = '[Account] Get User Details Failure',
  GET_USER_DETAILS_REQUEST = '[Account] Get User Details Request',
  GET_USER_DETAILS_SUCCESS = '[Account] Get User Details Success',
  UPDATE_ACCOUNT_FAILURE = '[Account] Update Account Failure',
  UPDATE_ACCOUNT_REQUEST = '[Account] Update Account Request',
  UPDATE_ACCOUNT_SUCCESS = '[Account] Update Account Success',
}

export const getAccountRequest = createAction(
  AccountActionTypes.GET_ACCOUNT_REQUEST,
);
export const getAccountFailure = createAction(
  AccountActionTypes.GET_ACCOUNT_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getAccountSuccess = createAction(
  AccountActionTypes.GET_ACCOUNT_SUCCESS,
  props<{ account: AccountApiResponse }>(),
);

export const getUserData = createAction(
  AccountActionTypes.GET_USER_DATA_REQUEST,
);
export const getUserDataFailure = createAction(
  AccountActionTypes.GET_USER_DATA_FAILURE,
  props<{ error: CognitoErrorResponse; message: string }>(),
);
export const getUserDataSuccess = createAction(
  AccountActionTypes.GET_USER_DATA_SUCCESS,
  props<{ userData: CognitoUserData }>(),
);

export const getUserDetails = createAction(
  AccountActionTypes.GET_USER_DETAILS_REQUEST,
);
export const getUserDetailsFailure = createAction(
  AccountActionTypes.GET_USER_DETAILS_FAILURE,
  props<{ error: CognitoErrorResponse; message: string }>(),
);
export const getUserDetailsSuccess = createAction(
  AccountActionTypes.GET_USER_DETAILS_SUCCESS,
  props<{ userDetails: Account }>(),
);

export const updateAccount = createAction(
  AccountActionTypes.UPDATE_ACCOUNT_REQUEST,
  props<{ payload: AccountUpdatePayload }>(),
);
export const updateAccountFailure = createAction(
  AccountActionTypes.UPDATE_ACCOUNT_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const updateAccountSuccess = createAction(
  AccountActionTypes.UPDATE_ACCOUNT_SUCCESS,
);
