import { Injectable } from '@angular/core';

import { ErrorStrings } from '@app/shared/enums';
import { AppStoreState, GrantActions, GrantSelectors } from '@app/store';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GrantUploadGuard  {
  constructor(private store$: Store<AppStoreState.State>) {}
  canDeactivate(): Observable<boolean> {
    return combineLatest([
      this.store$.select(GrantSelectors.selectGrantFileUpload),
      this.store$.select(GrantSelectors.selectFileUploadResults),
    ]).pipe(
      map(([fileUpload, result]) =>
        !!(fileUpload?.encounters?.file || fileUpload?.labs?.file) &&
        (!result?.completed || (result?.completed && result?.errors?.length))
          ? confirm(ErrorStrings.GRANT_UPLOAD_IN_PROGRESS)
            ? this.clearUpload()
            : false
          : true,
      ),
    );
  }

  private clearUpload(): boolean {
    this.store$.dispatch(GrantActions.clearFileUploadData());
    return true;
  }
}
