<mat-slide-toggle
  color="primary"
  [(ngModel)]="toggleState"
  [disabled]="disabled"
  (change)="toggleChange()"
>
  Only My Grants
</mat-slide-toggle>
<mat-icon
  tabindex="0"
  matTooltip="{{ tooltipMessage }}"
  matTooltipPosition="above"
  role="tooltip"
  [attr.aria-label]="tooltipMessage"
  >info</mat-icon
>
