import { createAction, props } from '@ngrx/store';

export enum UserActionTypes {
  SET_ACTIVE_ADMIN_MENU_FAILURE = '[User] Set Active Admin Menu Failure',
  SET_ACTIVE_ADMIN_MENU_REQUEST = '[User] Set Active Admin Menu',
  SET_ACTIVE_ADMIN_MENU_SUCCESS = '[User] Set Active Admin Menu Success',
  SET_ACTIVE_MENU_FAILURE = '[User] Set Active Menu Failure',
  SET_ACTIVE_MENU_REQUEST = '[User] Set Active Menu',
  SET_ACTIVE_MENU_SUCCESS = '[User] Set Active Menu Success',
}

export const setActiveAdminMenu = createAction(
  UserActionTypes.SET_ACTIVE_ADMIN_MENU_REQUEST,
  props<{ payload: string }>(),
);
export const setActiveAdminMenuFailure = createAction(
  UserActionTypes.SET_ACTIVE_ADMIN_MENU_FAILURE,
  props<{ message: string }>(),
);
export const setActiveAdminMenuSuccess = createAction(
  UserActionTypes.SET_ACTIVE_ADMIN_MENU_SUCCESS,
  props<{ activeAdminMenu: string }>(),
);

export const setActiveMenu = createAction(
  UserActionTypes.SET_ACTIVE_MENU_REQUEST,
  props<{ payload: string }>(),
);
export const setActiveMenuFailure = createAction(
  UserActionTypes.SET_ACTIVE_MENU_FAILURE,
  props<{ message: string }>(),
);
export const setActiveMenuSuccess = createAction(
  UserActionTypes.SET_ACTIVE_MENU_SUCCESS,
  props<{ activeMenu: string }>(),
);
