import { Renderer2 } from '@angular/core';
import { FileUploadResult } from './../../core/models/file-upload-result.models';
import { exportAsCsv } from './export-csv';

export const DownloadWarnings = (
  fileUploadResults: FileUploadResult,
  renderer: Renderer2,
): void => {
  const warnings = fileUploadResults.warnings.map((warning) =>
    warning.messages.map((message) => ({ fileType: warning.type, message })),
  );
  const flatWarnings = [].concat(...warnings);

  const timestamp = fileUploadResults.endDate
    .toString()
    .replace(/[ :]/g, '_')
    .split('.')[0];

  exportAsCsv(
    JSON.stringify(flatWarnings),
    'data-validation-warnings',
    renderer,
    timestamp,
  );
};
