import { Injectable } from '@angular/core';
import { GrantSubmission } from '@core/models';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReviewService {
  constructor() {}

  public getSelectedGrant(): Observable<GrantSubmission | null> {
    const currentGrant = localStorage.getItem('currentGrant');
    return of(currentGrant ? JSON.parse(currentGrant) : null);
  }

  public setSelectedGrant(grant: GrantSubmission): Observable<GrantSubmission> {
    localStorage.setItem('currentGrant', JSON.stringify(grant));
    return of(grant);
  }
}
