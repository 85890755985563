import { FparTable, GrantSubmission } from '@core/models';

export interface State {
  currentGrant: GrantSubmission;
  currentGrantError: string;
  currentTable: FparTable;
  currentTableError: string;
  getSetSelectedGrantError: string;
}

export const initialState: State = {
  currentGrant: null,
  currentGrantError: '',
  currentTable: null,
  currentTableError: '',
  getSetSelectedGrantError: '',
};
