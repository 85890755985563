import { Injectable } from '@angular/core';
import { handleError } from '@core/helpers/format-error';
import { Account } from '@core/models';
import { AccountService, CognitoUserData } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  getAccountFailure,
  getAccountRequest,
  getAccountSuccess,
  getUserData,
  getUserDataFailure,
  getUserDataSuccess,
  getUserDetails,
  getUserDetailsFailure,
  getUserDetailsSuccess,
  updateAccount,
  updateAccountFailure,
  updateAccountSuccess,
} from './actions';

@Injectable()
export class AccountEffects {
  getAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAccountRequest),
      switchMap(() =>
        this.accountService.getAccount().pipe(
          map((account) => getAccountSuccess({ account })),
          catchError((error) => handleError(error, getAccountFailure)),
        ),
      ),
    ),
  );

  getUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserData),
      switchMap(() =>
        this.accountService.getUserData().pipe(
          map(
            (userData: CognitoUserData) => getUserDataSuccess({ userData }),
            catchError((error) => handleError(error, getUserDataFailure)),
          ),
        ),
      ),
    ),
  );

  getUserDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserDetails),
      switchMap(() =>
        this.accountService.getUserDetails().pipe(
          map(
            (userDetails: Account) => getUserDetailsSuccess({ userDetails }),
            catchError((error) => handleError(error, getUserDetailsFailure)),
          ),
        ),
      ),
    ),
  );

  updateAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateAccount),
      switchMap(({ payload }) =>
        this.accountService.updateAccount(payload).pipe(
          map(() => updateAccountSuccess()),
          catchError((error) => handleError(error, updateAccountFailure)),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private accountService: AccountService,
  ) {}
}
