import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, KeyDatePayload, KeyDatesApiResponse } from '@core/models';
import { environment as env } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KeyDateService {
  private baseUrl = `${env.apiURL}api/v1/key-dates`;

  constructor(private http: HttpClient) {}

  public create(payload: KeyDatePayload): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.baseUrl, payload);
  }

  public delete(id: number | string): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(`${this.baseUrl}/${id}`);
  }

  public getAll(): Observable<KeyDatesApiResponse> {
    return this.http.get<KeyDatesApiResponse>(this.baseUrl);
  }

  public update(
    id: number | string,
    payload: KeyDatePayload,
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(`${this.baseUrl}/${id}`, payload);
  }
}
