import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { NotificationTypes } from '@app/shared/enums';
import { NotificationActions, ResourceAccessSelectors } from '@app/store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ResourceAccessGuard  {
  constructor(
    private router: Router,
    private store$: Store,
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    return this.store$
      .select(ResourceAccessSelectors.selectResourceAccess)
      .pipe(
        filter((permissions) => !!permissions),
        map((permissions) => {
          if (
            route.data.permissions.every(
              (permission: string) => permissions[permission],
            )
          ) {
            return true;
          } else {
            this.store$.dispatch(NotificationActions.reset());
            this.store$.dispatch(
              NotificationActions.add({
                notificationType: NotificationTypes.DANGER,
                notificationText:
                  'Your role does not have permission to access this resource.',
              }),
            );
            return this.router.parseUrl('/');
          }
        }),
      );
  }
}
