import { HttpErrorResponse } from '@angular/common/http';
import {
  ApiResponse,
  DataQualityResultsApiResponse,
  FparTableApiResponse,
  FparTableCommentApiResponse,
  FparTableCommentPayload,
  FparTableIssueApiResponse,
  FparTableIssueCommentActionResponse,
  FparTableIssueCommentPayload,
  FparTableIssuePayload,
  FparTablePayload,
} from '@core/models';
import { createAction, props } from '@ngrx/store';

export enum FparTableActionTypes {
  ADD_TABLE_COMMENT_REQUEST = '[FPAR Table] Add Table Comment Request',
  ADD_TABLE_COMMENT_FAILURE = '[FPAR Table] Add Table Comment Failure',
  ADD_TABLE_COMMENT_SUCCESS = '[FPAR Table] Add Table Comment Success',

  ADD_TABLE_ISSUE_COMMENT_REQUEST = '[FPAR Table] Add Table Issue Comment Request',
  ADD_TABLE_ISSUE_COMMENT_FAILURE = '[FPAR Table] Add Table Issue Comment Failure',
  ADD_TABLE_ISSUE_COMMENT_SUCCESS = '[FPAR Table] Add Table Issue Comment Success',

  DATA_QUALITY_CHECK_REQUEST = '[FPAR Table] Data Quality Check Request',
  DATA_QUALITY_CHECK_FAILURE = '[FPAR Table] Data Quality Check Failure',
  DATA_QUALITY_CHECK_SUCCESS = '[FPAR Table] Data Quality Check Success',

  DATA_QUALITY_RESULTS_REQUEST = '[FPAR Table] Data Quality Results Request',
  DATA_QUALITY_RESULTS_FAILURE = '[FPAR Table] Data Quality Results Failure',
  DATA_QUALITY_RESULTS_SUCCESS = '[FPAR Table] Data Quality Results Success',

  DELETE_TABLE_COMMENT_REQUEST = '[FPAR Table] Delete Table Comment Request',
  DELETE_TABLE_COMMENT_FAILURE = '[FPAR Table] Delete Table Comment Failure',
  DELETE_TABLE_COMMENT_SUCCESS = '[FPAR Table] Delete Table Comment Success',

  DELETE_TABLE_ISSUE_COMMENT_REQUEST = '[FPAR Table] Delete Table Issue Comment Request',
  DELETE_TABLE_ISSUE_COMMENT_FAILURE = '[FPAR Table] Delete Table Issue Comment Failure',
  DELETE_TABLE_ISSUE_COMMENT_SUCCESS = '[FPAR Table] Delete Table Issue Comment Success',

  GET_TABLE_REQUEST = '[FPAR Table] Get Table Request',
  GET_TABLE_FAILURE = '[FPAR Table] Get Table Failure',
  GET_TABLE_SUCCESS = '[FPAR Table] Get Table Success',

  GET_TABLE_COMMENTS_REQUEST = '[FPAR Table] Get Table Comments Request',
  GET_TABLE_COMMENTS_FAILURE = '[FPAR Table] Get Table Comments Failure',
  GET_TABLE_COMMENTS_SUCCESS = '[FPAR Table] Get Table Comments Success',

  GET_TABLE_ISSUES_REQUEST = '[FPAR Table] Get Table Issues Request',
  GET_TABLE_ISSUES_FAILURE = '[FPAR Table] Get Table Issues Failure',
  GET_TABLE_ISSUES_SUCCESS = '[FPAR Table] Get Table Issues Success',

  GET_TABLE_ISSUE_COMMENTS_REQUEST = '[FPAR Table] Get Table Issue Comments Request',
  GET_TABLE_ISSUE_COMMENTS_FAILURE = '[FPAR Table] Get Table Issue Comments Failure',
  GET_TABLE_ISSUE_COMMENTS_SUCCESS = '[FPAR Table] Get Table Issue Comments Success',

  HOLD_TABLE_ISSUE_REQUEST = '[FPAR Table] Hold Table Issue Request',
  HOLD_TABLE_ISSUE_FAILURE = '[FPAR Table] Hold Table Issue Failure',
  HOLD_TABLE_ISSUE_SUCCESS = '[FPAR Table] Hold Table Issue Success',

  UPDATE_TABLE_REQUEST = '[FPAR Table] Update Table Request',
  UPDATE_TABLE_FAILURE = '[FPAR Table] Update Table Failure',
  UPDATE_TABLE_SUCCESS = '[FPAR Table] Update Table Success',

  UPDATE_TABLE_COMMENT_REQUEST = '[FPAR Table] Update Table Comment Request',
  UPDATE_TABLE_COMMENT_FAILURE = '[FPAR Table] Update Table Comment Failure',
  UPDATE_TABLE_COMMENT_SUCCESS = '[FPAR Table] Update Table Comment Success',

  UPDATE_TABLE_ISSUE_REQUEST = '[FPAR Table] Update Table Issue Request',
  UPDATE_TABLE_ISSUE_FAILURE = '[FPAR Table] Update Table Issue Failure',
  UPDATE_TABLE_ISSUE_SUCCESS = '[FPAR Table] Update Table Issue Success',

  UPDATE_TABLE_ISSUE_COMMENT_REQUEST = '[FPAR Table] Update Table Issue Comment Request',
  UPDATE_TABLE_ISSUE_COMMENT_FAILURE = '[FPAR Table] Update Table Issue Comment Failure',
  UPDATE_TABLE_ISSUE_COMMENT_SUCCESS = '[FPAR Table] Update Table Issue Comment Success',
}

export const addTableComment = createAction(
  FparTableActionTypes.ADD_TABLE_COMMENT_REQUEST,
  props<{
    grantId: number;
    tableId: number;
    payload: FparTableCommentPayload;
  }>(),
);
export const addTableCommentFailure = createAction(
  FparTableActionTypes.ADD_TABLE_COMMENT_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const addTableCommentSuccess = createAction(
  FparTableActionTypes.ADD_TABLE_COMMENT_SUCCESS,
);

export const addTableIssueComment = createAction(
  FparTableActionTypes.ADD_TABLE_ISSUE_COMMENT_REQUEST,
  props<{
    grantId: number;
    tableId: number;
    checkId: string;
    payload: FparTableIssueCommentPayload;
  }>(),
);
export const addTableIssueCommentFailure = createAction(
  FparTableActionTypes.ADD_TABLE_ISSUE_COMMENT_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const addTableIssueCommentSuccess = createAction(
  FparTableActionTypes.ADD_TABLE_ISSUE_COMMENT_SUCCESS,
);

export const dataQualityCheck = createAction(
  FparTableActionTypes.DATA_QUALITY_CHECK_REQUEST,
  props<{ grantId: number }>(),
);
export const dataQualityCheckFailure = createAction(
  FparTableActionTypes.DATA_QUALITY_CHECK_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const dataQualityCheckSuccess = createAction(
  FparTableActionTypes.DATA_QUALITY_CHECK_SUCCESS,
  props<{ response: ApiResponse }>(),
);

export const dataQualityResults = createAction(
  FparTableActionTypes.DATA_QUALITY_RESULTS_REQUEST,
  props<{ grantId: number }>(),
);
export const dataQualityResultsFailure = createAction(
  FparTableActionTypes.DATA_QUALITY_RESULTS_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const dataQualityResultsSuccess = createAction(
  FparTableActionTypes.DATA_QUALITY_RESULTS_SUCCESS,
  props<{ response: DataQualityResultsApiResponse }>(),
);

export const deleteTableComment = createAction(
  FparTableActionTypes.DELETE_TABLE_COMMENT_REQUEST,
  props<{
    grantId: number;
    tableId: number;
    commentId: number;
  }>(),
);
export const deleteTableCommentFailure = createAction(
  FparTableActionTypes.DELETE_TABLE_COMMENT_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const deleteTableCommentSuccess = createAction(
  FparTableActionTypes.DELETE_TABLE_COMMENT_SUCCESS,
);

export const deleteTableIssueComment = createAction(
  FparTableActionTypes.DELETE_TABLE_ISSUE_COMMENT_REQUEST,
  props<{
    grantId: number;
    tableId: number;
    checkId: string;
    commentId: number;
  }>(),
);
export const deleteTableIssueCommentFailure = createAction(
  FparTableActionTypes.DELETE_TABLE_ISSUE_COMMENT_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const deleteTableIssueCommentSuccess = createAction(
  FparTableActionTypes.DELETE_TABLE_ISSUE_COMMENT_SUCCESS,
);

export const getTable = createAction(
  FparTableActionTypes.GET_TABLE_REQUEST,
  props<{ grantId: number; tableId: number }>(),
);
export const getTableFailure = createAction(
  FparTableActionTypes.GET_TABLE_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getTableSuccess = createAction(
  FparTableActionTypes.GET_TABLE_SUCCESS,
  props<{ response: FparTableApiResponse }>(),
);

export const getTableComments = createAction(
  FparTableActionTypes.GET_TABLE_COMMENTS_REQUEST,
  props<{ grantId: number; tableId: number }>(),
);
export const getTableCommentsFailure = createAction(
  FparTableActionTypes.GET_TABLE_COMMENTS_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getTableCommentsSuccess = createAction(
  FparTableActionTypes.GET_TABLE_COMMENTS_SUCCESS,
  props<{ response: FparTableCommentApiResponse }>(),
);

export const getTableIssues = createAction(
  FparTableActionTypes.GET_TABLE_ISSUES_REQUEST,
  props<{ grantId: number; tableId: number }>(),
);
export const getTableIssuesFailure = createAction(
  FparTableActionTypes.GET_TABLE_ISSUES_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getTableIssuesSuccess = createAction(
  FparTableActionTypes.GET_TABLE_ISSUES_SUCCESS,
  props<{ response: FparTableIssueApiResponse }>(),
);

export const getTableIssueComments = createAction(
  FparTableActionTypes.GET_TABLE_ISSUE_COMMENTS_REQUEST,
  props<{
    grantId: number;
    tableId: number;
    checkId: string;
    reportingPeriodId?: number | null;
  }>(),
);
export const getTableIssueCommentsFailure = createAction(
  FparTableActionTypes.GET_TABLE_ISSUE_COMMENTS_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getTableIssueCommentsSuccess = createAction(
  FparTableActionTypes.GET_TABLE_ISSUE_COMMENTS_SUCCESS,
  props<{ response: FparTableIssueCommentActionResponse }>(),
);

export const holdTableIssue = createAction(
  FparTableActionTypes.HOLD_TABLE_ISSUE_REQUEST,
  props<{
    grantId: number;
    tableId: number;
    checkId: string;
    payload: FparTableIssueCommentPayload;
  }>(),
);
export const holdTableIssueFailure = createAction(
  FparTableActionTypes.HOLD_TABLE_ISSUE_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const holdTableIssueSuccess = createAction(
  FparTableActionTypes.HOLD_TABLE_ISSUE_SUCCESS,
);

export const updateTable = createAction(
  FparTableActionTypes.UPDATE_TABLE_REQUEST,
  props<{
    grantId: number;
    tableId: number;
    payload: FparTablePayload;
  }>(),
);
export const updateTableFailure = createAction(
  FparTableActionTypes.UPDATE_TABLE_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const updateTableSuccess = createAction(
  FparTableActionTypes.UPDATE_TABLE_SUCCESS,
);

export const updateTableComment = createAction(
  FparTableActionTypes.UPDATE_TABLE_COMMENT_REQUEST,
  props<{
    grantId: number;
    tableId: number;
    payload: FparTableCommentPayload;
  }>(),
);
export const updateTableCommentFailure = createAction(
  FparTableActionTypes.UPDATE_TABLE_COMMENT_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const updateTableCommentSuccess = createAction(
  FparTableActionTypes.UPDATE_TABLE_COMMENT_SUCCESS,
);

export const updateTableIssue = createAction(
  FparTableActionTypes.UPDATE_TABLE_ISSUE_REQUEST,
  props<{
    grantId: number;
    issueId: number;
    payload: FparTableIssuePayload;
  }>(),
);
export const updateTableIssueFailure = createAction(
  FparTableActionTypes.UPDATE_TABLE_ISSUE_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const updateTableIssueSuccess = createAction(
  FparTableActionTypes.UPDATE_TABLE_ISSUE_SUCCESS,
  props<{ payload: FparTableIssuePayload }>(),
);

export const updateTableIssueComment = createAction(
  FparTableActionTypes.UPDATE_TABLE_ISSUE_COMMENT_REQUEST,
  props<{
    grantId: number;
    tableId: number;
    checkId: string;
    commentId: number;
    payload: FparTableIssueCommentPayload;
  }>(),
);
export const updateTableIssueCommentFailure = createAction(
  FparTableActionTypes.UPDATE_TABLE_ISSUE_COMMENT_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const updateTableIssueCommentSuccess = createAction(
  FparTableActionTypes.UPDATE_TABLE_ISSUE_COMMENT_SUCCESS,
);
