import { Injectable } from '@angular/core';

import { FparTableContainerComponent } from '@app/grant-portal/fpar-tables/fpar-table/fpar-table.component';
import { AppStoreState, AuthenticationSelectors } from '@app/store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FparTableGuard
  
{
  constructor(private store$: Store<AppStoreState.State>) {}

  public canDeactivate(
    component: FparTableContainerComponent,
  ): Observable<boolean> {
    return this.store$
      .select(AuthenticationSelectors.selectSignedOutFromInterceptor)
      .pipe(
        map((signedOutFromInterceptor) => {
          if (component.isDirty() && !signedOutFromInterceptor) {
            return confirm(
              'Are you sure you want to abandon your changes on the current form?',
            );
          } else {
            return true;
          }
        }),
      );
  }
}
