import { NgModule } from '@angular/core';
import { CamelCaseToSpacesPipe } from './camel-case-to-spaces.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { DataQualityIssuesPipe } from './data-quality-issues.pipe';
import { FullGrantDisplayPipe } from './full-grant-display.pipe';
import { IssuesNotOnHoldPipe } from './issues-not-on-hold.pipe';
import { MilitaryTo12HourPipe } from './military-to-twelve-hour-time.pipe';
import { RequiredColumnPipe } from './required-column.pipe';
import { SpacesToCamelCasePipe } from './spaces-to-camel-case.pipe';
import { TableStatusIconPipe } from './table-status-icon.pipe';
import { TablesWithIssuesPipe } from './tables-with-issues.pipe';

const PIPES = [
  CamelCaseToSpacesPipe,
  CapitalizePipe,
  DataQualityIssuesPipe,
  FullGrantDisplayPipe,
  IssuesNotOnHoldPipe,
  RequiredColumnPipe,
  SpacesToCamelCasePipe,
  TableStatusIconPipe,
  TablesWithIssuesPipe,
  MilitaryTo12HourPipe,
];

@NgModule({
  declarations: PIPES,
  exports: PIPES,
  providers: PIPES,
})
export class PipesModule {}
