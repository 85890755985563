import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Account,
  AccountApiResponse,
  AccountUpdatePayload,
  ApiResponse,
} from '@core/models';
import { environment as env } from 'environments/environment';
import { Observable } from 'rxjs';
import {
  CognitoAccount,
  CognitoService,
  CognitoUserData,
} from './cognito.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(
    private http: HttpClient,
    private cognito: CognitoService,
  ) {}

  public get(): Observable<Account> {
    return new Observable<Account>();
  }

  public getAccount(): Observable<AccountApiResponse> {
    return this.http.get<AccountApiResponse>(
      `${env.apiURL}api/v1/user-profile`,
    );
  }

  public getUserData(): Observable<CognitoUserData> {
    return this.cognito.getUserData();
  }

  public getUserDetails(): Observable<CognitoAccount> {
    return this.cognito.getUserDetails();
  }

  public updateAccount(payload: AccountUpdatePayload): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      `${env.apiURL}api/v1/user-profile`,
      payload,
    );
  }

  private getStorageItem(field: string): string {
    return localStorage.getItem(field) || '';
  }

  private getUserInfo(field: string): string {
    return this.getStorageItem(field);
  }
}
