import {
  ConfirmSignInResponse,
  NewUserResponse,
  SignInResponse,
} from '@app/auth/auth.models';
import { ApiError, StoreError } from '@core/models';
import { CognitoResponse } from '@core/services';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export interface State {
  associateSoftwareToken: CognitoResponse;
  associateSoftwareTokenError: StoreError;
  associateSoftwareTokenLoading: boolean;
  changeExpiredPasswordError: ApiError;
  changeExpiredPasswordLoading: boolean;
  confirmSignIn: ConfirmSignInResponse;
  confirmSignInError: StoreError;
  confirmSignInLoading: boolean;
  confirmCustomSignIn: ConfirmSignInResponse;
  confirmCustomSignInError: StoreError;
  confirmCustomSignInLoading: boolean;
  inputVerificationCode: CognitoResponse;
  inputVerificationCodeError: StoreError;
  inputVerificationCodeLoading: boolean;
  mfa: string;
  mfaError: StoreError;
  mfaLoading: boolean;
  resetPassword: CognitoResponse;
  resetPasswordError: StoreError;
  resetPasswordLoading: boolean;
  sendPasswordResetCode: any;
  sendPasswordResetCodeError: StoreError;
  sendPasswordResetCodeLoading: boolean;
  setMFASelection: any;
  setMFASelectionError: StoreError;
  setMFASelectionLoading: boolean;
  setNewUser: NewUserResponse;
  setNewUserError: StoreError;
  setNewUserLoading: boolean;
  signedOutFromInterceptor: boolean;
  signIn: SignInResponse;
  signInError: StoreError;
  signInLoading: boolean;
  signOutError: StoreError;
  signOutLoading: boolean;
  updateUserAttributes: CognitoResponse;
  updateUserAttributesLoading: boolean;
  updateUserAttributesError: StoreError;
  verifyAttribute: CognitoResponse;
  verifyAttributeLoading: boolean;
  verifyAttributeError: StoreError;
  verifySoftwareToken: CognitoUserSession;
  verifySoftwareTokenError: StoreError;
  verifySoftwareTokenLoading: boolean;
}

export const initialState: State = {
  associateSoftwareToken: null,
  associateSoftwareTokenError: null,
  associateSoftwareTokenLoading: false,
  changeExpiredPasswordError: null,
  changeExpiredPasswordLoading: false,
  confirmSignIn: null,
  confirmSignInError: null,
  confirmSignInLoading: false,
  confirmCustomSignIn: null,
  confirmCustomSignInError: null,
  confirmCustomSignInLoading: false,
  inputVerificationCode: null,
  inputVerificationCodeError: null,
  inputVerificationCodeLoading: false,
  mfa: null,
  mfaError: null,
  mfaLoading: false,
  resetPassword: null,
  resetPasswordError: null,
  resetPasswordLoading: false,
  sendPasswordResetCode: null,
  sendPasswordResetCodeError: null,
  sendPasswordResetCodeLoading: false,
  setMFASelection: null,
  setMFASelectionError: null,
  setMFASelectionLoading: false,
  setNewUser: null,
  setNewUserError: null,
  setNewUserLoading: false,
  signedOutFromInterceptor: false,
  signIn: null,
  signInError: null,
  signInLoading: false,
  signOutError: null,
  signOutLoading: false,
  updateUserAttributes: null,
  updateUserAttributesError: null,
  updateUserAttributesLoading: false,
  verifyAttribute: null,
  verifyAttributeError: null,
  verifyAttributeLoading: false,
  verifySoftwareToken: null,
  verifySoftwareTokenError: null,
  verifySoftwareTokenLoading: false,
};
