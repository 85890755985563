import { UntypedFormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class CustomTelehealthErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null): boolean {
    const invalidControl = !!control?.invalid;
    const invalidParent = !!control?.parent?.errors?.maxSitesExceeded;

    return invalidControl || invalidParent;
  }
}

export class CustomDateErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null): boolean {
    const invalidControl = !!(
      control?.errors && !!(control?.dirty || control?.touched)
    );
    const invalidParent = !!control?.parent?.errors?.invalidDateRange;

    return invalidControl || invalidParent;
  }
}

export class NewPasswordMatchesOldErrorStateMatcher
  implements ErrorStateMatcher
{
  isErrorState(control: UntypedFormControl | null): boolean {
    const invalidControl = !!(
      control?.errors && !!(control?.dirty || control?.touched)
    );

    const invalidParent =
      !!control?.parent?.errors?.newPasswordMatchesOld &&
      !!(control?.parent?.dirty || control?.parent?.touched);

    return invalidControl || invalidParent;
  }
}
