import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss'],
})
export class FormErrorsComponent implements OnInit {
  @Input()
  field: UntypedFormControl | UntypedFormGroup;
  @Input() parentField: UntypedFormControl | UntypedFormGroup;
  @Input()
  message: string;

  constructor() {}

  ngOnInit(): void {}

  public getErrorMessage(): string {
    const field = this.field;
    const parentField = this.parentField;
    if (field) {
      if (field.hasError('required')) {
        return 'This field is required';
      }
      if (field.hasError('whitespace')) {
        return 'This field can not be entered as all whitespace';
      }
      if (field.hasError('email')) {
        return 'This email address is not valid';
      }
      if (field.hasError('minlength')) {
        return `The minimum length for this field is ${field.errors.minlength.requiredLength} characters`;
      }
      if (field.hasError('maxlength')) {
        return `The maximum length for this field is ${field.errors.maxlength.requiredLength} characters`;
      }
      if (field.hasError('min')) {
        return `This field requires a value greater than or equal to ${field.errors.min.min}`;
      }
      if (field.hasError('max')) {
        return `This field requires a value less than or equal to ${field.errors.max.max}`;
      }
      if (field.hasError('pattern')) {
        const message =
          this.message || 'This fails to match the required pattern';
        return message;
      }
      if (field.hasError('invalidDateRange')) {
        return 'Invalid date range: End date must be after start date';
      }
      if (field.hasError('whiteSpaceError')) {
        return 'No leading or trailing spaces';
      }
      if (field.hasError('newPasswordMatchesOld')) {
        return 'Cannot match old password';
      }
    }
    if (parentField) {
      if (parentField.hasError('newPasswordMatchesOld')) {
        return 'Cannot match old password';
      }
    }
  }
}
