import { HttpErrorResponse } from '@angular/common/http';
import {
  ApiError,
  ReportingPeriodApiResponse,
  ReportingPeriodPayload,
  ReportingPeriodStateApiResponse,
} from '@core/models';
import { createAction, props } from '@ngrx/store';

export enum ReportingPeriodActionTypes {
  CREATE_REPORTING_PERIOD_FAILURE = '[Reporting Period] Create Reporting Period Failure',
  CREATE_REPORTING_PERIOD_REQUEST = '[Reporting Period] Create Reporting Period Request',
  CREATE_REPORTING_PERIOD_SUCCESS = '[Reporting Period] Create Reporting Period Success',
  DELETE_REPORTING_PERIOD_FAILURE = '[Reporting Period] Delete Reporting Period Failure',
  DELETE_REPORTING_PERIOD_REQUEST = '[Reporting Period] Delete Reporting Period Request',
  DELETE_REPORTING_PERIOD_SUCCESS = '[Reporting Period] Delete Reporting Period Success',
  GET_REPORTING_PERIODS_FAILURE = '[Reporting Period] Get Reporting Periods Failure',
  GET_REPORTING_PERIODS_REQUEST = '[Reporting Period] Get Reporting Periods Request',
  GET_REPORTING_PERIODS_SUCCESS = '[Reporting Period] Get Reporting Periods Success',
  GET_REPORTING_PERIOD_STATE_REQUEST = '[Reporting Period] Get Reporting Period State Request',
  GET_REPORTING_PERIOD_STATE_FAILURE = '[Reporting Period] Get Reporting Period State Failure',
  GET_REPORTING_PERIOD_STATE_SUCCESS = '[Reporting Period] Get Reporting Period State Success',
  UPDATE_REPORTING_PERIOD_FAILURE = '[Reporting Period] Update Reporting Period Failure',
  UPDATE_REPORTING_PERIOD_REQUEST = '[Reporting Period] Update Reporting Period Request',
  UPDATE_REPORTING_PERIOD_SUCCESS = '[Reporting Period] Update Reporting Period Success',
}

export const createReportingPeriod = createAction(
  ReportingPeriodActionTypes.CREATE_REPORTING_PERIOD_REQUEST,
  props<{ payload: ReportingPeriodPayload }>(),
);
export const createReportingPeriodFailure = createAction(
  ReportingPeriodActionTypes.CREATE_REPORTING_PERIOD_FAILURE,
  props<ApiError>(),
);
export const createReportingPeriodSuccess = createAction(
  ReportingPeriodActionTypes.CREATE_REPORTING_PERIOD_SUCCESS,
);
export const deleteReportingPeriod = createAction(
  ReportingPeriodActionTypes.DELETE_REPORTING_PERIOD_REQUEST,
  props<{ id: number | string }>(),
);
export const deleteReportingPeriodFailure = createAction(
  ReportingPeriodActionTypes.DELETE_REPORTING_PERIOD_FAILURE,
  props<ApiError>(),
);
export const deleteReportingPeriodSuccess = createAction(
  ReportingPeriodActionTypes.DELETE_REPORTING_PERIOD_SUCCESS,
);
export const getReportingPeriods = createAction(
  ReportingPeriodActionTypes.GET_REPORTING_PERIODS_REQUEST,
);
export const getReportingPeriodsFailure = createAction(
  ReportingPeriodActionTypes.GET_REPORTING_PERIODS_FAILURE,
  props<ApiError>(),
);
export const getReportingPeriodsSuccess = createAction(
  ReportingPeriodActionTypes.GET_REPORTING_PERIODS_SUCCESS,
  props<{ response: ReportingPeriodApiResponse }>(),
);
export const getReportingPeriodState = createAction(
  ReportingPeriodActionTypes.GET_REPORTING_PERIOD_STATE_REQUEST,
);
export const getReportingPeriodStateSuccess = createAction(
  ReportingPeriodActionTypes.GET_REPORTING_PERIOD_STATE_SUCCESS,
  props<{ reportingPeriodState: ReportingPeriodStateApiResponse }>(),
);
export const getReportingPeriodStateFailure = createAction(
  ReportingPeriodActionTypes.GET_REPORTING_PERIOD_STATE_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const updateReportingPeriod = createAction(
  ReportingPeriodActionTypes.UPDATE_REPORTING_PERIOD_REQUEST,
  props<{ id: number | string; payload: ReportingPeriodPayload }>(),
);
export const updateReportingPeriodFailure = createAction(
  ReportingPeriodActionTypes.UPDATE_REPORTING_PERIOD_FAILURE,
  props<ApiError>(),
);
export const updateReportingPeriodSuccess = createAction(
  ReportingPeriodActionTypes.UPDATE_REPORTING_PERIOD_SUCCESS,
);
