import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Resource } from '@app/shared/enums';
import { ResourceAccessSelectors } from '@app/store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SupportStaffGuard  {
  constructor(
    private router: Router,
    private store$: Store,
  ) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | boolean | UrlTree {
    const data = route.data;
    const path = '/settings';

    return this.store$
      .select(ResourceAccessSelectors.selectResourceAccess)
      .pipe(
        filter((resources) => !!resources),
        map((resources) =>
          !resources[Resource.AccessSystemAdmin] ||
          (resources[Resource.AccessSystemAdmin] && data?.allowSupportStaff)
            ? true
            : this.router.parseUrl(path),
        ),
      );
  }
}
