import {
  ApiError,
  KeyDatePayload,
  KeyDatesApiResponse,
  OverviewReportApiResponse,
  RemovedUserReportApiResponse,
  ReportingPeriodApiResponse,
  ReportingPeriodPayload,
} from '@core/models';
import { createAction, props } from '@ngrx/store';

export enum SettingsActionTypes {
  CLOSE_SUBMISSION_PERIOD_FAILURE = '[Settings] Close Submission Period Failure',
  CLOSE_SUBMISSION_PERIOD_REQUEST = '[Settings] Close Submission Period Request',
  CLOSE_SUBMISSION_PERIOD_SUCCESS = '[Settings] Close Submission Period Success',
  CREATE_KEY_DATE_FAILURE = '[Settings] Create Key Date Failure',
  CREATE_KEY_DATE_REQUEST = '[Settings] Create Key Date Request',
  CREATE_KEY_DATE_SUCCESS = '[Settings] Create Key Date Success',
  CREATE_REPORTING_PERIOD_FAILURE = '[Settings] Create Reporting Period Failure',
  CREATE_REPORTING_PERIOD_REQUEST = '[Settings] Create Reporting Period Request',
  CREATE_REPORTING_PERIOD_SUCCESS = '[Settings] Create Reporting Period Success',
  DELETE_KEY_DATE_FAILURE = '[Settings] Delete Key Date Failure',
  DELETE_KEY_DATE_REQUEST = '[Settings] Delete Key Date Request',
  DELETE_KEY_DATE_SUCCESS = '[Settings] Delete Key Date Success',
  DELETE_REPORTING_PERIOD_FAILURE = '[Settings] Delete Reporting Period Failure',
  DELETE_REPORTING_PERIOD_REQUEST = '[Settings] Delete Reporting Period Request',
  DELETE_REPORTING_PERIOD_SUCCESS = '[Settings] Delete Reporting Period Success',
  FORCE_GRANT_SUBMISSION_FAILURE = '[Settings] Force Grant Submission Failure',
  FORCE_GRANT_SUBMISSION_REQUEST = '[Settings] Force Grant Submission Request',
  FORCE_GRANT_SUBMISSION_SUCCESS = '[Settings] Force Grant Submission Success',
  GENERATE_DASHBOARD_DATA_FAILURE = '[Settings] Generate Dashboard Data Failure',
  GENERATE_DASHBOARD_DATA_REQUEST = '[Settings] Generate Dashboard Data Request',
  GENERATE_DASHBOARD_DATA_SUCCESS = '[Settings] Generate Dashboard Data Success',
  GET_REMOVED_USER_REPORT_FAILURE = '[Settings] Get Removed User Report Failure',
  GET_REMOVED_USER_REPORT_REQUEST = '[Settings] Get Removed User Report Request',
  GET_REMOVED_USER_REPORT_SUCCESS = '[Settings] Get Removed User Report Success',
  GET_KEY_DATES_FAILURE = '[Settings] Get Key Dates Failure',
  GET_KEY_DATES_REQUEST = '[Settings] Get Key Dates Request',
  GET_KEY_DATES_SUCCESS = '[Settings] Get Key Dates Success',
  GET_OVERVIEW_REPORT_FAILURE = '[Settings] Get Overview Report Failure',
  GET_OVERVIEW_REPORT_REQUEST = '[Settings] Get Overview Report Request',
  GET_OVERVIEW_REPORT_SUCCESS = '[Settings] Get Overview Report Success',
  GET_REPORTING_PERIODS_FAILURE = '[Settings] Get Reporting Periods Failure',
  GET_REPORTING_PERIODS_REQUEST = '[Settings] Get Reporting Periods Request',
  GET_REPORTING_PERIODS_SUCCESS = '[Settings] Get Reporting Periods Success',
  UPDATE_KEY_DATE_FAILURE = '[Settings] Update Key Date Failure',
  UPDATE_KEY_DATE_REQUEST = '[Settings] Update Key Date Request',
  UPDATE_KEY_DATE_SUCCESS = '[Settings] Update Key Date Success',
  UPDATE_REPORTING_PERIOD_FAILURE = '[Settings] Update Reporting Period Failure',
  UPDATE_REPORTING_PERIOD_REQUEST = '[Settings] Update Reporting Period Request',
  UPDATE_REPORTING_PERIOD_SUCCESS = '[Settings] Update Reporting Period Success',
}

export const closeSubmissionPeriod = createAction(
  SettingsActionTypes.CLOSE_SUBMISSION_PERIOD_REQUEST,
  props<{ sendNotificationEmail: boolean }>(),
);

export const closeSubmissionPeriodFailure = createAction(
  SettingsActionTypes.CLOSE_SUBMISSION_PERIOD_FAILURE,
  props<ApiError>(),
);

export const closeSubmissionPeriodSuccess = createAction(
  SettingsActionTypes.CLOSE_SUBMISSION_PERIOD_SUCCESS,
);

export const createKeyDate = createAction(
  SettingsActionTypes.CREATE_KEY_DATE_REQUEST,
  props<{ payload: KeyDatePayload }>(),
);
export const createKeyDateFailure = createAction(
  SettingsActionTypes.CREATE_KEY_DATE_FAILURE,
  props<ApiError>(),
);
export const createKeyDateSuccess = createAction(
  SettingsActionTypes.CREATE_KEY_DATE_SUCCESS,
);

export const createReportingPeriod = createAction(
  SettingsActionTypes.CREATE_REPORTING_PERIOD_REQUEST,
  props<{ payload: ReportingPeriodPayload }>(),
);
export const createReportingPeriodFailure = createAction(
  SettingsActionTypes.CREATE_REPORTING_PERIOD_FAILURE,
  props<ApiError>(),
);
export const createReportingPeriodSuccess = createAction(
  SettingsActionTypes.CREATE_REPORTING_PERIOD_SUCCESS,
);

export const deleteKeyDate = createAction(
  SettingsActionTypes.DELETE_KEY_DATE_REQUEST,
  props<{ id: number | string }>(),
);
export const deleteKeyDateFailure = createAction(
  SettingsActionTypes.DELETE_KEY_DATE_FAILURE,
  props<ApiError>(),
);
export const deleteKeyDateSuccess = createAction(
  SettingsActionTypes.DELETE_KEY_DATE_SUCCESS,
);

export const deleteReportingPeriod = createAction(
  SettingsActionTypes.DELETE_REPORTING_PERIOD_REQUEST,
  props<{ id: number | string }>(),
);
export const deleteReportingPeriodFailure = createAction(
  SettingsActionTypes.DELETE_REPORTING_PERIOD_FAILURE,
  props<ApiError>(),
);
export const deleteReportingPeriodSuccess = createAction(
  SettingsActionTypes.DELETE_REPORTING_PERIOD_SUCCESS,
);

export const forceGrantSubmission = createAction(
  SettingsActionTypes.FORCE_GRANT_SUBMISSION_REQUEST,
);
export const forceGrantSubmissionFailure = createAction(
  SettingsActionTypes.FORCE_GRANT_SUBMISSION_FAILURE,
  props<ApiError>(),
);
export const forceGrantSubmissionSuccess = createAction(
  SettingsActionTypes.FORCE_GRANT_SUBMISSION_SUCCESS,
);

export const generateDashboardData = createAction(
  SettingsActionTypes.GENERATE_DASHBOARD_DATA_REQUEST,
  props<{ id: number | string }>(),
);

export const generateDashboardDataFailure = createAction(
  SettingsActionTypes.GENERATE_DASHBOARD_DATA_FAILURE,
  props<ApiError>(),
);

export const generateDashboardDataSuccess = createAction(
  SettingsActionTypes.GENERATE_DASHBOARD_DATA_SUCCESS,
);

export const getRemovedUserReport = createAction(
  SettingsActionTypes.GET_REMOVED_USER_REPORT_REQUEST,
);

export const getRemovedUserReportFailure = createAction(
  SettingsActionTypes.GET_REMOVED_USER_REPORT_FAILURE,
  props<ApiError>(),
);

export const getRemovedUserReportSuccess = createAction(
  SettingsActionTypes.GET_REMOVED_USER_REPORT_SUCCESS,
  props<{ response: RemovedUserReportApiResponse }>(),
);

export const getKeyDates = createAction(
  SettingsActionTypes.GET_KEY_DATES_REQUEST,
);
export const getKeyDatesFailure = createAction(
  SettingsActionTypes.GET_KEY_DATES_FAILURE,
  props<ApiError>(),
);
export const getKeyDatesSuccess = createAction(
  SettingsActionTypes.GET_KEY_DATES_SUCCESS,
  props<{ response: KeyDatesApiResponse }>(),
);

export const getOverviewReport = createAction(
  SettingsActionTypes.GET_OVERVIEW_REPORT_REQUEST,
);

export const getOverviewReportFailure = createAction(
  SettingsActionTypes.GET_OVERVIEW_REPORT_FAILURE,
  props<ApiError>(),
);

export const getOverviewReportSuccess = createAction(
  SettingsActionTypes.GET_OVERVIEW_REPORT_SUCCESS,
  props<{ response: OverviewReportApiResponse }>(),
);

export const getReportingPeriods = createAction(
  SettingsActionTypes.GET_REPORTING_PERIODS_REQUEST,
);
export const getReportingPeriodsFailure = createAction(
  SettingsActionTypes.GET_REPORTING_PERIODS_FAILURE,
  props<ApiError>(),
);
export const getReportingPeriodsSuccess = createAction(
  SettingsActionTypes.GET_REPORTING_PERIODS_SUCCESS,
  props<{ response: ReportingPeriodApiResponse }>(),
);

export const updateKeyDate = createAction(
  SettingsActionTypes.UPDATE_KEY_DATE_REQUEST,
  props<{ id: number | string; payload: KeyDatePayload }>(),
);
export const updateKeyDateFailure = createAction(
  SettingsActionTypes.UPDATE_KEY_DATE_FAILURE,
  props<ApiError>(),
);
export const updateKeyDateSuccess = createAction(
  SettingsActionTypes.UPDATE_KEY_DATE_SUCCESS,
);

export const updateReportingPeriod = createAction(
  SettingsActionTypes.UPDATE_REPORTING_PERIOD_REQUEST,
  props<{ id: number | string; payload: ReportingPeriodPayload }>(),
);
export const updateReportingPeriodFailure = createAction(
  SettingsActionTypes.UPDATE_REPORTING_PERIOD_FAILURE,
  props<ApiError>(),
);
export const updateReportingPeriodSuccess = createAction(
  SettingsActionTypes.UPDATE_REPORTING_PERIOD_SUCCESS,
);
