<div
  *ngIf="(submissionCommentLoading$ | async) === false"
  class="animate__animated animate__faster animate__fadeIn data-submission-comments"
>
  <h3 class="mat-h2">Data Submission Comments (Optional)</h3>
  <div *ngIf="submissionComment?.commentId" class="last-edit-information">
    <div class="author">
      <h4 class="mat-h4">
        {{ submissionComment.nameFirst }} {{ submissionComment.nameLast }}
      </h4>
      <div class="role">
        <mat-icon>person</mat-icon><span>{{ submissionComment.roleName }}</span>
      </div>
    </div>
    <div class="date">
      <span>
        {{
          submissionComment.lastUpdated + '+0000' | date: 'M/dd/yyyy h:mm:ss a'
        }}</span
      >
    </div>
  </div>
  <form [formGroup]="commentForm">
    <mat-form-field>
      <textarea
        cdkTextareaAutosize
        formControlName="comment"
        matInput
        maxlength="5000"
        [placeholder]="placeholder"
        [readonly]="!canEditComments"
      ></textarea>
    </mat-form-field>
  </form>
  <div *ngIf="!viewOnly" class="form-actions">
    <button
      mat-stroked-button
      type="button"
      (click)="saveComment()"
      [disabled]="!canSave()"
    >
      Save
    </button>
    <button
      mat-stroked-button
      type="button"
      (click)="deleteComment()"
      [disabled]="!canDelete()"
    >
      Delete
    </button>
  </div>
</div>
