import { ResourceAccessObject } from '@core/models';

export interface State {
  resourceAccess: ResourceAccessObject;
  resourceAccessError: string;
  resourceAccessLoading: boolean;
}

export const initialState: State = {
  resourceAccess: null,
  resourceAccessError: '',
  resourceAccessLoading: false,
};
