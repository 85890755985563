import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  FparTableActions,
  FparTableSelectors,
  NotificationActions,
} from '@app/store';
import { IssueOnHoldPanelData } from '@core/models';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  templateUrl: './issue-on-hold.component.html',
  styleUrls: ['./issue-on-hold.component.scss'],
})
export class IssueOnHoldPanelComponent implements OnDestroy, OnInit {
  public saving$: Observable<boolean>;
  public form: UntypedFormGroup;

  private destroyed$ = new Subject<boolean>();

  constructor(
    private actions$: Actions,
    private fb: UntypedFormBuilder,
    private panel: MatDialogRef<IssueOnHoldPanelComponent>,
    @Inject(MAT_DIALOG_DATA) private panelData: IssueOnHoldPanelData,
    private store$: Store,
  ) {
    this.saving$ = this.store$.select(
      FparTableSelectors.selectHoldTableIssueLoading,
    );

    this.actions$
      .pipe(
        ofType(FparTableActions.holdTableIssueSuccess),
        takeUntil(this.destroyed$),
        tap(() => {
          this.close();
        }),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      comment: ['', [Validators.required]],
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public close(): void {
    this.panel.close();
  }

  public save(): void {
    if (this.form.invalid) {
      return;
    }

    const payload = this.form.value;
    const { grantId, tableId, checkId } = this.panelData;

    this.store$.dispatch(NotificationActions.reset());
    this.store$.dispatch(
      FparTableActions.holdTableIssue({ grantId, tableId, checkId, payload }),
    );
  }
}
