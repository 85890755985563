import { State as RootState } from '@app/store/app-state';
import {
  ApiError,
  CustomReportingPeriod,
  FileUploadResult,
  FparDataColumns,
  FparSubmissionComment,
  Grant,
  GrantFileUpload,
  GrantFileUploadStatus,
  GrantNumberOfSites,
  GrantSubmission,
  GrantSubmissionTableOverview,
  GrantSubmissionTableStatus,
  Subrecipient,
  UserGrantItem,
  UserGrantSelection,
  UserUploadedFiles,
} from '@core/models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { grantFeatureKey } from './reducer';
import { State } from './state';

const getAcceptSubmissionError = (state: State): ApiError =>
  state.acceptSubmissionError;
const getAcceptSubmissionLoading = (state: State): boolean =>
  state.acceptSubmissionLoading;

const getCustomReportingPeriod = (state: State): CustomReportingPeriod =>
  state.customReportingPeriod;
const getCustomReportingPeriodError = (state: State): ApiError =>
  state.customReportingPeriodError;
const getCustomReportingPeriodLoading = (state: State): boolean =>
  state.customReportingPeriodLoading;

const getDataColumns = (state: State): FparDataColumns => state.dataColumns;
const getDataColumnsError = (state: State): ApiError => state.dataColumnsError;
const getDataColumnsLoading = (state: State): boolean =>
  state.dataColumnsLoading;
const getFavoriteGrants = (state: State): number[] => state.favoriteGrants;
const getFavoriteGrantsError = (state: State): ApiError =>
  state.favoriteGrantsError;
const getFavoriteGrantsLoading = (state: State): boolean =>
  state.favoriteGrantsLoading;
const getFileUploadResults = (state: State): FileUploadResult =>
  state.fileUploadResults;
const getFileUploadResultsError = (state: State): ApiError =>
  state.fileUploadResultsError;
const getFileUploadResultsLoading = (state: State): boolean =>
  state.fileUploadResultsLoading;
const getFileUploadTaskError = (state: State): ApiError =>
  state.fileUploadTaskError;
const getFileUploadTaskLoading = (state: State): boolean =>
  state.fileUploadTaskLoading;
const getGrant = (state: State): Grant => state.grant;
const getGrantError = (state: State): ApiError => state.grantError;
const getGrantLoading = (state: State): boolean => state.grantLoading;
const getGrantDetails = (state: State): UserGrantItem => state.grantDetails;
const getGrantDetailsError = (state: State): ApiError =>
  state.grantDetailsError;
const getGrantDetailsLoading = (state: State): boolean =>
  state.grantDetailsLoading;
const getGrantFileUpload = (state: State): GrantFileUpload =>
  state.grantFileUpload;
const getGrantFileUploadStatus = (state: State): GrantFileUploadStatus[] =>
  state.grantFileUploadStatus;
const getGrantHasOpenIssues = (state: State): boolean =>
  state.grantHasOpenIssues;
const getGrantList = (state: State): UserGrantItem[] => state.grantList;
const getGrantListError = (state: State): ApiError => state.grantListError;
const getGrantListLoading = (state: State): boolean => state.grantListLoading;
const getGrants = (state: State): Grant[] => state.grants;
const getGrantsError = (state: State): ApiError => state.grantsError;
const getGrantsLoading = (state: State): boolean => state.grantsLoading;
const getGrantSubmissions = (state: State): GrantSubmission[] =>
  state.grantSubmissions;
const getGrantSubmissionsError = (state: State): ApiError =>
  state.grantSubmissionsError;
const getGrantSubmissionsLoading = (state: State): boolean =>
  state.grantSubmissionsLoading;
const getGrantSubmissionTableOverview = (
  state: State,
): GrantSubmissionTableOverview => state.grantSubmissionTableOverview;
const getGrantSubmissionTableOverviewError = (state: State): ApiError =>
  state.grantSubmissionTableOverviewError;
const getGrantSubmissionTableOverviewLoading = (state: State): boolean =>
  state.grantSubmissionTableOverviewLoading;

const getNumberOfSites = (state: State): GrantNumberOfSites =>
  state.numberOfSites;
const getNumberOfSitesError = (state: State): ApiError =>
  state.numberOfSitesError;
const getNumberOfSitesLoading = (state: State): boolean =>
  state.numberOfSitesLoading;

const getReturnSubmissionError = (state: State): ApiError =>
  state.returnSubmissionError;
const getReturnSubmissionLoading = (state: State): boolean =>
  state.returnSubmissionLoading;
const getSelectedGrant = (state: State): UserGrantSelection =>
  state.selectedGrant;
const getSelectedGrantError = (state: State): string =>
  state.selectedGrantError;
const getSelectedGrantLoading = (state: State): boolean =>
  state.selectedGrantLoading;

const getSubmissionComment = (state: State): FparSubmissionComment =>
  state.submissionComment;
const getSubmissionCommentError = (state: State): ApiError =>
  state.submissionCommentError;
const getSubmissionCommentLoading = (state: State): boolean =>
  state.submissionCommentLoading;

const getSubmitGrantError = (state: State): ApiError => state.submitGrantError;
const getSubmitGrantLoading = (state: State): boolean =>
  state.submitGrantLoading;
const getSubrecipients = (state: State): Subrecipient[] => state.subrecipients;
const getSubrecipientsError = (state: State): ApiError =>
  state.subrecipientsError;
const getSubrecipientsLoading = (state: State): boolean =>
  state.subrecipientsLoading;
const getTableOverview = (
  state: State,
): GrantSubmission<GrantSubmissionTableStatus> => state.tableOverview;
const getTableOverviewError = (state: State): ApiError =>
  state.tableOverviewError;
const getTableOverviewLoading = (state: State): boolean =>
  state.tableOverviewLoading;
const getUpdateCustomReportingPeriodError = (state: State): ApiError =>
  state.customReportingPeriodError;
const getUpdateCustomReportingPeriodLoading = (state: State): boolean =>
  state.customReportingPeriodLoading;
const getUpdateMyGrantsError = (state: State): ApiError =>
  state.updateMyGrantsError;
const getUpdateMyGrantsLoading = (state: State): boolean =>
  state.updateMyGrantsLoading;
const getUpdateNumberOfSitesError = (state: State): ApiError =>
  state.numberOfSitesError;
const getUpdateNumberOfSitesLoading = (state: State): boolean =>
  state.numberOfSitesLoading;
const getUserUploadedFiles = (state: State): UserUploadedFiles => ({
  combined: state.grantFileUpload?.combined?.file || null,
  encounters: state.grantFileUpload?.encounters?.file || null,
  labs: state.grantFileUpload?.labs?.file || null,
});

export const selectGrantState = createFeatureSelector<RootState, State>(
  grantFeatureKey,
);

export const selectAcceptSubmissionError = createSelector(
  selectGrantState,
  getAcceptSubmissionError,
);
export const selectAcceptSubmissionLoading = createSelector(
  selectGrantState,
  getAcceptSubmissionLoading,
);

export const selectCustomReportingPeriod = createSelector(
  selectGrantState,
  getCustomReportingPeriod,
);
export const selectCustomReportingPeriodError = createSelector(
  selectGrantState,
  getCustomReportingPeriodError,
);
export const selectCustomReportingPeriodLoading = createSelector(
  selectGrantState,
  getCustomReportingPeriodLoading,
);

export const selectDataColumns = createSelector(
  selectGrantState,
  getDataColumns,
);
export const selectDataColumnsError = createSelector(
  selectGrantState,
  getDataColumnsError,
);
export const selectDataColumnsLoading = createSelector(
  selectGrantState,
  getDataColumnsLoading,
);

export const selectFileUploadResults = createSelector(
  selectGrantState,
  getFileUploadResults,
);
export const selectFileUploadResultsError = createSelector(
  selectGrantState,
  getFileUploadResultsError,
);
export const selectFileUploadResultsLoading = createSelector(
  selectGrantState,
  getFileUploadResultsLoading,
);

export const selectFileUploadTaskError = createSelector(
  selectGrantState,
  getFileUploadTaskError,
);
export const selectFileUploadTaskLoading = createSelector(
  selectGrantState,
  getFileUploadTaskLoading,
);

export const selectGrant = createSelector(selectGrantState, getGrant);
export const selectGrantError = createSelector(selectGrantState, getGrantError);
export const selectGrantLoading = createSelector(
  selectGrantState,
  getGrantLoading,
);

export const selectGrantDetails = createSelector(
  selectGrantState,
  getGrantDetails,
);
export const selectGrantDetailsError = createSelector(
  selectGrantState,
  getGrantDetailsError,
);
export const selectGrantDetailsLoading = createSelector(
  selectGrantState,
  getGrantDetailsLoading,
);

export const selectGrantFileUpload = createSelector(
  selectGrantState,
  getGrantFileUpload,
);

export const selectGrantHasOpenIssues = createSelector(
  selectGrantState,
  getGrantHasOpenIssues,
);

export const selectGrantList = createSelector(selectGrantState, getGrantList);
export const selectGrantListError = createSelector(
  selectGrantState,
  getGrantListError,
);
export const selectGrantListLoading = createSelector(
  selectGrantState,
  getGrantListLoading,
);

export const selectGrants = createSelector(selectGrantState, getGrants);
export const selectGrantsError = createSelector(
  selectGrantState,
  getGrantsError,
);
export const selectGrantsLoading = createSelector(
  selectGrantState,
  getGrantsLoading,
);

export const selectGrantSubmissions = createSelector(
  selectGrantState,
  getGrantSubmissions,
);
export const selectGrantSubmissionsError = createSelector(
  selectGrantState,
  getGrantSubmissionsError,
);
export const selectGrantSubmissionsLoading = createSelector(
  selectGrantState,
  getGrantSubmissionsLoading,
);

export const selectGrantSubmissionTableOverview = createSelector(
  selectGrantState,
  getGrantSubmissionTableOverview,
);
export const selectGrantSubmissionTableOverviewError = createSelector(
  selectGrantState,
  getGrantSubmissionTableOverviewError,
);
export const selectGrantSubmissionTableOverviewLoading = createSelector(
  selectGrantState,
  getGrantSubmissionTableOverviewLoading,
);

export const selectGetSubrecipients = createSelector(
  selectGrantState,
  getSubrecipients,
);
export const selectGetSubrecipientsError = createSelector(
  selectGrantState,
  getSubrecipientsError,
);
export const selectGetSubrecipientsLoading = createSelector(
  selectGrantState,
  getSubrecipientsLoading,
);

export const selectGrantUploadStatus = createSelector(
  selectGrantState,
  getGrantFileUploadStatus,
);

export const selectFavoriteGrants = createSelector(
  selectGrantState,
  getFavoriteGrants,
);
export const selectFavoriteGrantsError = createSelector(
  selectGrantState,
  getFavoriteGrantsError,
);
export const selectFavoriteGrantsLoading = createSelector(
  selectGrantState,
  getFavoriteGrantsLoading,
);

export const selectNumberOfSites = createSelector(
  selectGrantState,
  getNumberOfSites,
);
export const selectNumberOfSitesLoading = createSelector(
  selectGrantState,
  getNumberOfSitesLoading,
);
export const selectNumberOfSitesError = createSelector(
  selectGrantState,
  getNumberOfSitesError,
);

export const selectReturnSubmissionError = createSelector(
  selectGrantState,
  getReturnSubmissionError,
);
export const selectReturnSubmissionLoading = createSelector(
  selectGrantState,
  getReturnSubmissionLoading,
);

export const selectSelectedGrant = createSelector(
  selectGrantState,
  getSelectedGrant,
);
export const selectSelectedGrantError = createSelector(
  selectGrantState,
  getSelectedGrantError,
);
export const selectSelectedGrantLoading = createSelector(
  selectGrantState,
  getSelectedGrantLoading,
);

export const selectSubmissionComment = createSelector(
  selectGrantState,
  getSubmissionComment,
);
export const selectSubmissionCommentError = createSelector(
  selectGrantState,
  getSubmissionCommentError,
);
export const selectSubmissionCommentLoading = createSelector(
  selectGrantState,
  getSubmissionCommentLoading,
);

export const selectSubmitGrantError = createSelector(
  selectGrantState,
  getSubmitGrantError,
);
export const selectSubmitGrantLoading = createSelector(
  selectGrantState,
  getSubmitGrantLoading,
);

export const selectTableOverview = createSelector(
  selectGrantState,
  getTableOverview,
);
export const selectTableOverviewError = createSelector(
  selectGrantState,
  getTableOverviewError,
);
export const selectTableOverviewLoading = createSelector(
  selectGrantState,
  getTableOverviewLoading,
);

export const selectUpdateCustomReportingPeriodError = createSelector(
  selectGrantState,
  getUpdateCustomReportingPeriodError,
);
export const selectUpdateCustomReportingPeriodLoading = createSelector(
  selectGrantState,
  getUpdateCustomReportingPeriodLoading,
);

export const selectUpdateMyGrantsError = createSelector(
  selectGrantState,
  getUpdateMyGrantsError,
);
export const selectUpdateMyGrantsLoading = createSelector(
  selectGrantState,
  getUpdateMyGrantsLoading,
);

export const selectUpdateNumberOfSitesLoading = createSelector(
  selectGrantState,
  getUpdateNumberOfSitesLoading,
);
export const selectUpdateNumberOfSitesError = createSelector(
  selectGrantState,
  getUpdateNumberOfSitesError,
);

export const selectUserUploadedFiles = createSelector(
  selectGrantState,
  getUserUploadedFiles,
);
