<div class="mat-h1">Table Comments</div>
<div class="comments-container">
  <ng-container *ngFor="let comment of comments; let i = index">
    <app-fpar-comment
      [comment]="comment"
      [saving]="saving$ | async"
      [readOnly]="readOnly"
      [tableFormIsDirty]="tableFormIsDirty"
      (save)="saveComment($event, grant.selectedGrantId, grant.tableId)"
      (edit)="setEditingComment($event)"
      (delete)="deleteComment($event, grant.selectedGrantId, grant.tableId)"
    >
    </app-fpar-comment>
  </ng-container>
  <app-fpar-comment
    *ngIf="!readOnly"
    [saving]="saving$ | async"
    [tableFormIsDirty]="tableFormIsDirty"
    (save)="saveComment($event, grant.selectedGrantId, grant.tableId)"
  >
  </app-fpar-comment>
</div>
