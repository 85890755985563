import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { TableSubmissionStatus } from '../enums';

@Pipe({
  name: 'tableStatusIcon',
})
export class TableStatusIconPipe implements PipeTransform {
  private statusIcons: Record<TableSubmissionStatus, string> = {
    [TableSubmissionStatus.Completed]: 'check_circle',
    [TableSubmissionStatus.InProgress]: 'pending',
    [TableSubmissionStatus.NotRequired]: 'not_interested',
    [TableSubmissionStatus.NotStarted]: '',
  };

  public transform(status: string): SafeHtml {
    const style: string =
      status.replace(' ', '-').toLowerCase() ===
      TableSubmissionStatus.Completed.toLowerCase()
        ? 'icon-color-green'
        : '';
    const icon: string = this.statusIcons[status];
    const matIcon: SafeHtml = icon
      ? `<span class=\"material-icons-outlined ${style}\">${icon}</span>`
      : '';
    return `${matIcon} <span>${status}</span>`.trimLeft();
  }
}
