import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DashboardEffects } from './effects';
import { dashboardFeatureKey, reducer } from './reducer';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([DashboardEffects]),
    StoreModule.forFeature(dashboardFeatureKey, reducer),
  ],
})
export class DashboardModule {}
