<div class="data-validation-messages">
  <div class="data-validation-error-header">
    <mat-icon class="header-icon warning">error_outline</mat-icon>
    <h2 class="mat-h2">Data validation warnings</h2>
  </div>
  <div
    class="download-progress"
    *ngIf="(fileUploadResultsLoading$ | async) && isPanel"
  >
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    <span>Retrieving latest warnings</span>
  </div>
  <div *ngIf="loaded">
    <ng-container *ngIf="!validationIsOpen; else validationExplorerWarnings">
      <p>
        The system identified
        <span class="bold-italics">{{ warningCountMessage }}</span
        >.
        <span *ngIf="!errors"
          >Your data was successfully aggregated into FPAR tables, but invalid
          values were excluded from table counts.</span
        >
      </p>
      <p *ngIf="!errors">
        Please review these warnings to determine if they require remediation.
        If your data are correct, you may proceed.
      </p>
    </ng-container>
    <ng-template #validationExplorerWarnings>
      <p>
        FPAR has validated your data and found
        <span class="bold-italics">{{ warningCountMessage }}</span
        >. You can download a file of validation warnings, fix your data and
        resubmit a new file.
      </p>
    </ng-template>

    <div class="data-validation-warning-messages">
      <h3>List of data warnings</h3>

      <div *ngFor="let warningType of warnings">
        <p>{{ warningType.type }} data warnings:</p>
        <ul>
          <li *ngFor="let message of warningType.messages">
            {{ message }}
          </li>
        </ul>
      </div>

      <button
        class="export"
        [color]="exportButtonClass"
        mat-flat-button
        (click)="exportWarnings()"
      >
        Export Warnings
      </button>
      <button
        *ngIf="isPanel"
        class="close"
        color="white"
        mat-flat-button
        (click)="close()"
      >
        Close
      </button>
    </div>
  </div>
</div>
