import { Injectable } from '@angular/core';
import { handleError } from '@core/helpers';
import { FparReportService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  getFparReport,
  getFparReportFailure,
  getFparReportSuccess,
} from './actions';

@Injectable()
export class FparReportEffects {
  getFparReport$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFparReport),
      switchMap(({ grantId, reportingPeriodId }) =>
        this.fparReportService.getFparReport(grantId, reportingPeriodId).pipe(
          map((response) => getFparReportSuccess({ response })),
          catchError((error) => handleError(error, getFparReportFailure)),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private fparReportService: FparReportService,
  ) {}
}
