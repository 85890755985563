<form [formGroup]="form" autocomplete="off">
  <ng-container>
    <div class="column-mapping">
      <mat-accordion class="mat-elevation-z0">
        <mat-expansion-panel [expanded]="panelOpen" (opened)="panelOpen = true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{ tableName }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <mat-table [dataSource]="columnList">
            <ng-container matColumnDef="dataElement">
              <mat-header-cell
                scope="col"
                role="columnheader"
                tabindex="0"
                aria-label="FPAR DATA ELEMENT"
                *matHeaderCellDef
                >FPAR DATA ELEMENT</mat-header-cell
              >
              <mat-cell
                *matCellDef="let element"
                tabindex="0"
                [attr.aria-label]="element | requiredColumn"
                [innerHTML]="element | requiredColumn"
              ></mat-cell>
            </ng-container>
            <ng-container matColumnDef="dataColumn">
              <mat-header-cell
                scope="col"
                role="columnheader"
                tabindex="0"
                aria-label="COLUMN IN DATA UPLOAD"
                *matHeaderCellDef
                >COLUMN IN DATA UPLOAD</mat-header-cell
              >
              <mat-cell *matCellDef="let element">
                <app-field-mapping
                  [columnId]="element.columnId"
                  [missingAllowed]="element.missingAllowed"
                  [validateDate]="element.validateDate"
                  [validateCode]="element.validateCode"
                  [validCodes]="element.validCodes"
                  [fieldNames]="fileData.fieldNames"
                  [selectForm]="form"
                  [selectedValues]="selectedValuesMap"
                  [systemColumnName]="element.systemColumnName"
                  [mappedColumns]="matchedColNames"
                  (changeSelection)="addOption($event.value)"
                ></app-field-mapping>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="legend">
              <mat-footer-cell *matFooterCellDef colspan="2" class="footer">
                * Field requires a mapped column from the file.
              </mat-footer-cell>
            </ng-container>
            <mat-header-row
              *matHeaderRowDef="displayedColumns"
            ></mat-header-row>
            <mat-row
              scope="row"
              *matRowDef="let row; columns: displayedColumns"
            ></mat-row>
            <mat-footer-row *matFooterRowDef="['legend']"></mat-footer-row>
          </mat-table>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </ng-container>
</form>
