import { Action, createReducer, on } from '@ngrx/store';
import { signInSuccess } from '../authentication/actions';
import {
  getAccountFailure,
  getAccountRequest,
  getAccountSuccess,
  getUserData,
  getUserDataFailure,
  getUserDataSuccess,
  getUserDetails,
  getUserDetailsFailure,
  getUserDetailsSuccess,
  updateAccount,
  updateAccountFailure,
  updateAccountSuccess,
} from './actions';
import { initialState, State } from './state';

export const accountFeatureKey = 'account';

const accountReducer = createReducer(
  initialState,
  on(getAccountRequest, (state) => ({ ...state, accountLoading: true })),
  on(getAccountFailure, (state, { error, message }) => ({
    ...state,
    account: null,
    accountError: { error, message },
    accountLoading: false,
  })),
  on(getAccountSuccess, (state, { account }) => ({
    ...state,
    account: account.data[0],
    accountLoading: false,
  })),

  on(getUserData, (state) => ({ ...state, userDataLoading: true })),
  on(getUserDataFailure, (state, { error, message }) => ({
    ...state,
    userData: null,
    userDataError: { error, message },
    userDataLoading: false,
  })),
  on(getUserDataSuccess, (state, { userData }) => ({
    ...state,
    userData,
    userDataLoading: false,
  })),

  on(getUserDetails, (state) => ({ ...state, userDetailsLoading: true })),
  on(getUserDetailsFailure, (state, { error, message }) => ({
    ...state,
    userDetails: null,
    userDetailsError: { error, message },
    userDetailsLoading: false,
  })),
  on(getUserDetailsSuccess, (state, { userDetails }) => ({
    ...state,
    userDetails,
    userDetailsLoading: false,
  })),
  on(signInSuccess, (state, { response }) => ({
    ...state,
    mfaType: response.results.challengeName,
  })),
  on(updateAccount, (state) => ({ ...state, updateAccountLoading: true })),
  on(updateAccountFailure, (state, { error, message }) => ({
    ...state,
    updateAccountError: { error, message },
    updateAccountLoading: false,
  })),
  on(updateAccountSuccess, (state) => ({
    ...state,
    updateAccountLoading: false,
  })),
);

export const reducer = (state: State | undefined, action: Action) =>
  accountReducer(state, action);
