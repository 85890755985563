import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AccountSelectors, AppStoreState } from '@app/store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard  {
  constructor(
    private router: Router,
    private store$: Store<AppStoreState.State>,
  ) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return this.store$.select(AccountSelectors.selectAccount).pipe(
      filter((account) => !!account),
      map((account) => (account?.isAdmin ? true : this.router.parseUrl('/'))),
    );
  }
}
