import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationTypes } from '@app/shared/enums';
import { AppStoreState, NotificationActions } from '@app/store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-amendment-upload',
  templateUrl: './amendment-upload.component.html',
  styleUrls: ['./amendment-upload.component.scss'],
})
export class AmendmentUploadComponent {
  @Input() disabled = false;
  @Output() addFile = new EventEmitter<File>();

  public validFile: File;

  constructor(private store$: Store<AppStoreState.State>) {}

  public byteConverter(bytes: number): number {
    const k = 1024;
    const convertedBytes = +(bytes / k).toFixed(2);
    return convertedBytes;
  }

  public drag(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();
  }

  public drop(event: DragEvent): void {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    this.selectAmendmentFile(file);
  }

  public selectAmendmentFile(file: File): void {
    if (file.type === 'application/pdf') {
      this.validFile = file;
      this.addFile.emit(file);
    } else {
      this.validFile = null;
      this.store$.dispatch(
        NotificationActions.add({
          notificationType: NotificationTypes.DANGER,
          notificationText:
            'The file you chose is not a PDF file. Please choose a different file.',
        }),
      );
      this.addFile.emit(null);
    }
  }
}
