import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class PanelService {
  constructor(public dialog: MatDialog) {}
  /*
   * Opens a MatDialog with some standard configuration items
   *
   * @param component - The panel component template, typed with T
   * @param data - The panel data, if null, the panel displays an empty form/no history
   * @param config - An optional override for the panel config
   *
   * @returns the MatDialogRef with a generic typed result. The panel components handle submitting and send the response as true/false
   */
  public open<T, D = any>(
    component: ComponentType<any>,
    data: D,
    config: MatDialogConfig<D> = {},
  ): MatDialogRef<any, T> {
    if (!component) {
      return;
    }

    return this.dialog.open<any, D, T>(component, {
      autoFocus: false,
      data,
      disableClose: config.disableClose || true,
      height: config.height,
      maxHeight: config.maxHeight || '100vh',
      maxWidth: config.maxWidth || '100vw',
      minWidth: config.minWidth || '20rem',
      panelClass: 'app-panel',
      restoreFocus: false,
      width: config.width || '90vw',
    });
  }
}
