/* eslint-disable @typescript-eslint/naming-convention */
import { HttpErrorResponse } from '@angular/common/http';
import {
  GrantCoverSheetAdminProjectDirector,
  GrantCoverSheetAdminProjectDirectorApiResponse,
  GrantCoverSheetContactPerson,
  GrantCoverSheetContactPersonApiResponse,
  GrantCoverSheetMetaDataApiResponse,
} from '@core/models';
import { createAction, props } from '@ngrx/store';

export enum GrantCoverSheetActionTypes {
  GET_ADMIN_PROJECT_DIRECTOR_FAILURE = '[Grant Profile Cover Sheet] Get Admin Project Director Failure',
  GET_ADMIN_PROJECT_DIRECTOR_REQUEST = '[Grant Profile Cover Sheet] Get Admin Project Director Request',
  GET_ADMIN_PROJECT_DIRECTOR_SUCCESS = '[Grant Profile Cover Sheet] Get Admin Project Director Success',

  GET_CONTACT_PERSON_FAILURE = '[Grant Profile Cover Sheet] Get Contact Person Failure',
  GET_CONTACT_PERSON_REQUEST = '[Grant Profile Cover Sheet] Get Contact Person Request',
  GET_CONTACT_PERSON_SUCCESS = '[Grant Profile Cover Sheet] Get Contact Person Success',

  GET_METADATA_FAILURE = '[Grant Profile Cover Sheet] Get Metadata Failure',
  GET_METADATA_REQUEST = '[Grant Profile Cover Sheet] Get Metadata Request',
  GET_METADATA_SUCCESS = '[Grant Profile Cover Sheet] Get Metadata Success',

  UPDATE_ADMIN_PROJECT_DIRECTOR_FAILURE = '[Grant Profile Cover Sheet] Update Admin Project Director Failure',
  UPDATE_ADMIN_PROJECT_DIRECTOR_REQUEST = '[Grant Profile Cover Sheet] Update Admin Project Director Request',
  UPDATE_ADMIN_PROJECT_DIRECTOR_SUCCESS = '[Grant Profile Cover Sheet] Update Admin Project Director Success',

  UPDATE_CONTACT_PERSON_FAILURE = '[Grant Profile Cover Sheet] Update Contact Person Failure',
  UPDATE_CONTACT_PERSON_REQUEST = '[Grant Profile Cover Sheet] Update Contact Person Request',
  UPDATE_CONTACT_PERSON_SUCCESS = '[Grant Profile Cover Sheet] Update Contact Person Success',
}

export const getAdminProjectDirector = createAction(
  GrantCoverSheetActionTypes.GET_ADMIN_PROJECT_DIRECTOR_REQUEST,
  props<{ grantId: number }>(),
);
export const getAdminProjectDirectorFailure = createAction(
  GrantCoverSheetActionTypes.GET_ADMIN_PROJECT_DIRECTOR_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getAdminProjectDirectorSuccess = createAction(
  GrantCoverSheetActionTypes.GET_ADMIN_PROJECT_DIRECTOR_SUCCESS,
  props<{
    adminProjectDirector: GrantCoverSheetAdminProjectDirectorApiResponse;
  }>(),
);

export const getContactPerson = createAction(
  GrantCoverSheetActionTypes.GET_CONTACT_PERSON_REQUEST,
  props<{ grantId: number }>(),
);
export const getContactPersonFailure = createAction(
  GrantCoverSheetActionTypes.GET_CONTACT_PERSON_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);

export const getContactPersonSuccess = createAction(
  GrantCoverSheetActionTypes.GET_CONTACT_PERSON_SUCCESS,
  props<{ contactPerson: GrantCoverSheetContactPersonApiResponse }>(),
);

export const getMetaData = createAction(
  GrantCoverSheetActionTypes.GET_METADATA_REQUEST,
  props<{ grantId: number }>(),
);
export const getMetaDataFailure = createAction(
  GrantCoverSheetActionTypes.GET_METADATA_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getMetaDataSuccess = createAction(
  GrantCoverSheetActionTypes.GET_METADATA_SUCCESS,
  props<{ metaData: GrantCoverSheetMetaDataApiResponse }>(),
);

export const updateAdminProjectDirector = createAction(
  GrantCoverSheetActionTypes.UPDATE_ADMIN_PROJECT_DIRECTOR_REQUEST,
  props<{ payload: GrantCoverSheetAdminProjectDirector }>(),
);
export const updateAdminProjectDirectorFailure = createAction(
  GrantCoverSheetActionTypes.UPDATE_ADMIN_PROJECT_DIRECTOR_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const updateAdminProjectDirectorSuccess = createAction(
  GrantCoverSheetActionTypes.UPDATE_ADMIN_PROJECT_DIRECTOR_SUCCESS,
);

export const updateContactPerson = createAction(
  GrantCoverSheetActionTypes.UPDATE_CONTACT_PERSON_REQUEST,
  props<{ payload: GrantCoverSheetContactPerson }>(),
);
export const updateContactPersonFailure = createAction(
  GrantCoverSheetActionTypes.UPDATE_CONTACT_PERSON_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const updateContactPersonSuccess = createAction(
  GrantCoverSheetActionTypes.UPDATE_CONTACT_PERSON_SUCCESS,
);
