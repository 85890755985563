<h1 mat-dialog-title>Select a Grant</h1>
<mat-dialog-content>
  <p>
    Your account has access to all grants listed below. Please select a grant to
    view or submit data:
  </p>
  <app-user-grant-list
    class="flex-column grant-list"
    (grantSelected)="setGrantSelection($event.grantId, $event.subrecipientId)"
    [grants]="grants$ | async"
  ></app-user-grant-list>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button (click)="close()">Cancel</button>
</mat-dialog-actions>
