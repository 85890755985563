<a
  href="#"
  class="issue-button"
  [class.active]="isOpen"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="togglePopover($event)"
>
  <mat-icon>error_outline</mat-icon><span>View Issue</span>
</a>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
>
  <mat-card class="popover-content">
    <mat-card-header>
      <mat-card-title>
        <span>Data Quality Issue</span>
        <button mat-icon-button (click)="closePopover()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-card-title>
      <mat-card-subtitle>
        {{ subIssue?.row }}, {{ subIssue?.column }}
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      {{ subIssue?.displayMessage }}
    </mat-card-content>
    <mat-card-actions>
      <span>
        <button mat-button color="primary" (click)="closePopover()">
          Close
        </button>
      </span>
      <span>
        <button mat-icon-button (click)="previousSubIssue()">
          <mat-icon>arrow_left</mat-icon>
        </button>
        <button mat-icon-button (click)="nextSubIssue()">
          <mat-icon>arrow_right</mat-icon>
        </button>
      </span>
    </mat-card-actions>
  </mat-card>
</ng-template>
