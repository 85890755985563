<div class="sub-issues flat" *ngIf="flatDisplay; else collapseableDisplay">
  <div class="title">Table Cells Impacted: {{ subIssues.length }}</div>
  <ng-container *ngTemplateOutlet="subIssueList"></ng-container>
</div>
<ng-template #collapseableDisplay>
  <mat-accordion class="sub-issues">
    <mat-expansion-panel [expanded]="panelOpen" (opened)="(panelOpen)">
      <mat-expansion-panel-header>
        <mat-panel-title class="title" [class.readonly]="canResolve">
          Table Cells Impacted: {{ subIssues.length }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container
        [ngTemplateOutlet]="subIssueList"
        [ngTemplateOutletContext]="{ showLink: true }"
      ></ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>

<ng-template #subIssueList let-showlink="showLink">
  <div *ngFor="let subIssue of subIssues; let i = index" class="sub-issue">
    <span>{{ i + 1 }}.</span>
    <span>
      <a
        *ngIf="showlink"
        href="#"
        (click)="setActiveSubIssue(subIssue, $event)"
        class="link"
        matTooltip="Go to cell"
      >
        <mat-icon> input </mat-icon>
        <span>{{ subIssue.column }} - {{ subIssue.row }}:</span>
      </a>
      <span class="description">{{ subIssue.displayMessage }}</span>
    </span>
  </div>
</ng-template>
