import { FparTable, GrantSubmission } from '@core/models';
import { createAction, props } from '@ngrx/store';

export enum PortalReviewActionTypes {
  CLEAR_SELECTED_GRANT_REQUEST = '[Portal Review] Clear Selected Grant Request',
  CLEAR_SELECTED_TABLE_REQUEST = '[Portal Review] Clear Selected Table Request',
  CLEAR_SELECTED_GRANT_SUCCESS = '[Portal Review] Clear Selected Grant Success',
  GET_SELECTED_GRANT_FAILURE = '[Portal Review] Get Selected Grant Failure',
  GET_SELECTED_GRANT_REQUEST = '[Portal Review] Get Selected Grant Request',
  GET_SELECTED_GRANT_SUCCESS = '[Portal Review] Get Selected Grant Success',
  GET_SELECTED_TABLE_REQUEST = '[Portal Review] Get Selected Table Request',
  SET_SELECTED_GRANT_FAILURE = '[Portal Review] Set Selected Grant Failure',
  SET_SELECTED_GRANT_REQUEST = '[Portal Review] Set Selected Grant Request',
  SET_SELECTED_GRANT_SUCCESS = '[Portal Review] Set Selected Grant Success',
  SET_SELECTED_TABLE_REQUEST = '[Portal Review] Set Selected Table Request',
}

export const clearSelectedGrant = createAction(
  PortalReviewActionTypes.CLEAR_SELECTED_GRANT_REQUEST,
);
export const clearSelectedGrantSuccess = createAction(
  PortalReviewActionTypes.CLEAR_SELECTED_GRANT_SUCCESS,
);
export const clearSelectedTable = createAction(
  PortalReviewActionTypes.CLEAR_SELECTED_TABLE_REQUEST,
);

export const getSelectedGrant = createAction(
  PortalReviewActionTypes.GET_SELECTED_GRANT_REQUEST,
);
export const getSelectedGrantFailure = createAction(
  PortalReviewActionTypes.GET_SELECTED_GRANT_FAILURE,
  props<{ message: string }>(),
);
export const getSelectedGrantSuccess = createAction(
  PortalReviewActionTypes.GET_SELECTED_GRANT_SUCCESS,
  props<{ currentGrant: GrantSubmission }>(),
);
export const getSelectedTable = createAction(
  PortalReviewActionTypes.GET_SELECTED_TABLE_REQUEST,
);

export const setSelectedGrant = createAction(
  PortalReviewActionTypes.SET_SELECTED_GRANT_REQUEST,
  props<{ grant: GrantSubmission }>(),
);
export const setSelectedGrantFailure = createAction(
  PortalReviewActionTypes.SET_SELECTED_GRANT_FAILURE,
  props<{ message: string }>(),
);
export const setSelectedGrantSuccess = createAction(
  PortalReviewActionTypes.SET_SELECTED_GRANT_SUCCESS,
  props<{ currentGrant: GrantSubmission }>(),
);
export const setSelectedTable = createAction(
  PortalReviewActionTypes.SET_SELECTED_TABLE_REQUEST,
  props<{ currentTable: FparTable }>(),
);
