import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  ChangeGrantSubmissionStatus,
  CustomReportingPeriodApiResponse,
  CustomReportingPeriodPayload,
  FavoriteGrantsApiResponse,
  FavoriteGrantsPayload,
  FileUploadPresignedPost,
  FileUploadResultApiResponse,
  FileUploadTaskApiResponse,
  FileUploadTaskPayload,
  FparDataColumnsApiResponse,
  GrantApiResponse,
  GrantListApiResponse,
  GrantNumberOfSites,
  GrantNumberOfSitesApiResponse,
  GrantRequestPayload,
  GrantSubmissionListApiResponse,
  GrantSubmissionModifyPayload,
  GrantSubmissionsTableOverviewApiResponse,
  GrantSubmissionTableOverviewApiResponse,
  PresignedFields,
  SubrecipientApiResponse,
  UploadFileType,
  UserGrantApiResponse,
  UserGrantSelection,
  UserUploadedFiles,
} from '@core/models';
import { environment as env } from 'environments/environment';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GrantService {
  private apiUrl = env.apiURL;
  private baseUrl = 'api/v1/';
  private customReportingPeriods = 'custom-reporting-periods';
  private fileUploadAccepted = 'file-upload-accepted';
  private fileUploadTaskUrl = 'file-upload-task';
  private grants = 'grants';
  private grantCoverSheet = 'grant-cover-sheet';
  private grantSubmissions = 'grant-submissions';
  private httpAws: HttpClient;
  private numberOfSites = 'number-of-sites';
  private readyForReview = 'ready-for-review';
  private submissionComment = 'submission-comment';
  private tableOverview = 'table-overview';
  private userGrants = 'user-grants';
  private upload = 'upload';

  constructor(
    private http: HttpClient,
    private handler: HttpBackend,
  ) {
    this.httpAws = new HttpClient(handler);
  }

  public acceptFileUpload(
    grantId: number,
    userAccepted: boolean,
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      `${this.apiUrl}${this.baseUrl}${this.grants}/${grantId}/${this.fileUploadAccepted}`,
      { userAccepted },
    );
  }

  public deleteCustomReportingPeriod(
    reportingPayload: CustomReportingPeriodPayload,
  ): Observable<any> {
    const { grantId } = reportingPayload;
    return this.http.delete(
      `${this.apiUrl}${this.baseUrl}${this.customReportingPeriods}/${grantId}`,
    );
  }

  public deleteSubmissionComment(
    grantId: number,
    commentId: number,
  ): Observable<any> {
    return this.http.delete(
      `${this.apiUrl}${this.baseUrl}${this.grantSubmissions}/${grantId}/${this.submissionComment}/${commentId}`,
    );
  }

  public fileUploadTask(
    grantId: number,
    payload: FileUploadTaskPayload,
  ): Observable<FileUploadTaskApiResponse> {
    return this.http.post<FileUploadTaskApiResponse>(
      `${this.apiUrl}${this.baseUrl}${this.grants}/${grantId}/${this.fileUploadTaskUrl}`,
      payload,
    );
  }

  public getCustomReportingPeriod(
    grantId: number,
  ): Observable<CustomReportingPeriodApiResponse> {
    return this.http.get<CustomReportingPeriodApiResponse>(
      `${this.apiUrl}${this.baseUrl}${this.customReportingPeriods}/${grantId}`,
    );
  }

  public getDataColumns(): Observable<FparDataColumnsApiResponse> {
    return this.http.get<FparDataColumnsApiResponse>(
      `${this.apiUrl}${this.baseUrl}${this.upload}/file-mapping-columns`,
    );
  }

  public getFavoriteGrants(): Observable<FavoriteGrantsApiResponse> {
    return this.http.get<FavoriteGrantsApiResponse>(
      `${this.apiUrl}${this.baseUrl}${this.grants}/favorites`,
    );
  }

  public getFileUploadResults(
    grantId: number,
  ): Observable<FileUploadResultApiResponse> {
    return this.http.get<FileUploadResultApiResponse>(
      `${this.apiUrl}${this.baseUrl}${this.grants}/${grantId}/upload-results`,
    );
  }

  public getGrant(payload: GrantRequestPayload): Observable<GrantApiResponse> {
    return this.http.get<GrantApiResponse>(
      `${this.apiUrl}${this.baseUrl}${this.grants}`,
      {
        params: { ...payload },
      },
    );
  }

  public getGrantDetails(
    payload: GrantRequestPayload,
  ): Observable<UserGrantApiResponse> {
    return this.http.get<UserGrantApiResponse>(
      `${this.apiUrl}${this.baseUrl}${this.userGrants}`,
      {
        params: { ...payload },
      },
    );
  }

  public getGrantList(active: boolean): Observable<GrantListApiResponse> {
    if (active) {
      return this.http.get<GrantListApiResponse>(
        `${this.apiUrl}${this.baseUrl}${this.grants}`,
        { params: { active } },
      );
    } else {
      return this.http.get<GrantListApiResponse>(
        `${this.apiUrl}${this.baseUrl}${this.grants}`,
      );
    }
  }

  // This method can return 1 or more grants
  public getGrantListByUser(): Observable<UserGrantApiResponse> {
    return this.http.get<UserGrantApiResponse>(
      `${this.apiUrl}${this.baseUrl}${this.userGrants}`,
    );
  }

  public getGrantSubmissions(): Observable<GrantSubmissionListApiResponse> {
    return this.http.get<GrantSubmissionListApiResponse>(
      `${this.apiUrl}${this.baseUrl}${this.grantSubmissions}`,
    );
  }

  public getGrantSubmissionTableOverview(
    grantId: number,
    reportingPeriodId?: number,
  ): Observable<GrantSubmissionsTableOverviewApiResponse> {
    return this.http.get<GrantSubmissionsTableOverviewApiResponse>(
      `${this.apiUrl}${this.baseUrl}${this.grantSubmissions}/${grantId}/${this.tableOverview}`,
      { params: { reportingPeriodId } },
    );
  }

  public getNumberOfSites(
    grantId: number,
  ): Observable<GrantNumberOfSitesApiResponse> {
    return this.http.get<GrantNumberOfSitesApiResponse>(
      `${this.apiUrl}${this.baseUrl}${this.grantCoverSheet}/${this.numberOfSites}`,
      {
        params: { grantId },
      },
    );
  }

  public getSubmissionComment(
    grantId: number,
    reportingPeriodId?: number,
  ): Observable<any> {
    return this.http.get(
      `${this.apiUrl}${this.baseUrl}${this.grantSubmissions}/${grantId}/${this.submissionComment}`,
      {
        params: { reportingPeriodId },
      },
    );
  }

  public getSubrecipients(
    grantId: number,
  ): Observable<SubrecipientApiResponse> {
    return this.http.get<SubrecipientApiResponse>(
      `${this.apiUrl}${this.baseUrl}${this.grants}/${grantId}/subrecipients`,
    );
  }

  public getTableOverview(
    grantId: number,
    reportingPeriodId?: number,
  ): Observable<GrantSubmissionTableOverviewApiResponse> {
    return this.http.get<GrantSubmissionTableOverviewApiResponse>(
      `${this.apiUrl}${this.baseUrl}${this.grants}/${grantId}/${this.tableOverview}`,
      { params: { reportingPeriodId } },
    );
  }

  public getUserGrantSelection(): Observable<UserGrantSelection | null> {
    const userGrantItem = localStorage.getItem('UserGrantSelection');
    return of(userGrantItem ? JSON.parse(userGrantItem) : null);
  }

  public grantSubmissionStatusChange(
    payload: ChangeGrantSubmissionStatus,
  ): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      `${this.apiUrl}${this.baseUrl}${this.grantSubmissions}/${this.readyForReview}`,
      payload,
    );
  }

  public postFilesToS3(
    s3PostConfig: FileUploadPresignedPost,
    userUploadedFiles: UserUploadedFiles,
  ): Observable<null[]> {
    const response: Observable<null>[] = [];

    if (userUploadedFiles.combined) {
      const {url, fields} = s3PostConfig.presignedPosts[UploadFileType.Combined];

      response.push(
        this.httpAws.post<null>(
          url,
          this.constructFormDataPayload(
            fields,
            userUploadedFiles.combined,
          ),
        ),
      );
    }

    if (userUploadedFiles.encounters) {
      const {url, fields} = s3PostConfig.presignedPosts[UploadFileType.Encounters];

      response.push(
        this.httpAws.post<null>(
          url,
          this.constructFormDataPayload(
            fields,
            userUploadedFiles.encounters,
          ),
        ),
      );
    }

    if (userUploadedFiles.labs) {
      const {url, fields} = s3PostConfig.presignedPosts[UploadFileType.Labs];

      response.push(
        this.httpAws.post<null>(
          url,
          this.constructFormDataPayload(
            fields,
            userUploadedFiles.labs,
          ),
        ),
      );
    }

    return forkJoin(response);
  }

  public setUserGrantSelection(
    grantId: number,
    subrecipientId: number,
  ): Observable<UserGrantSelection> {
    localStorage.setItem(
      'UserGrantSelection',
      JSON.stringify({
        grantId,
        subrecipientId,
      }),
    );
    return of({ grantId, subrecipientId });
  }

  public submitGrant(grantId: number): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      `${this.apiUrl}${this.baseUrl}${this.grants}/${grantId}/submit-grant`,
      null,
    );
  }

  public updateCustomReportingPeriod(
    reportingPayload: CustomReportingPeriodPayload,
  ): Observable<any> {
    const { grantId, ...payload } = reportingPayload;
    return this.http.put(
      `${this.apiUrl}${this.baseUrl}${this.customReportingPeriods}/${grantId}`,
      payload,
    );
  }

  public updateFavoriteGrants(
    payload: FavoriteGrantsPayload,
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      `${this.apiUrl}${this.baseUrl}${this.grants}/favorites`,
      payload,
    );
  }

  public updateGrantSubmission(
    grantId: number,
    payload: GrantSubmissionModifyPayload,
  ): Observable<ApiResponse> {
    return this.http.patch<ApiResponse>(
      `${this.apiUrl}${this.baseUrl}${this.grantSubmissions}/${grantId}`,
      payload,
    );
  }

  public userGrantSelected(): Observable<boolean> {
    return this.getUserGrantSelection().pipe(map((grant) => grant !== null));
  }

  public updateNumberOfSites(payload: GrantNumberOfSites): Observable<any> {
    return this.http.put(
      `${this.apiUrl}${this.baseUrl}${this.grantCoverSheet}/${this.numberOfSites}`,
      payload,
    );
  }

  public updateSubmissionComment(
    grantId: number,
    comment: string,
  ): Observable<any> {
    return this.http.put(
      `${this.apiUrl}${this.baseUrl}${this.grantSubmissions}/${grantId}/${this.submissionComment}`,
      { comment },
    );
  }

  private constructFormDataPayload(
    fields: PresignedFields,
    file: File,
  ): FormData {
    const payload = new FormData();
    Object.keys(fields).forEach((key) => payload.append(key, fields[key]));
    payload.append('file', file);

    return payload;
  }
}
