import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { GrantProfileCoverSheetEffects } from './effects';
import { grantProfileCoverSheetFeatureKey, reducer } from './reducer';

@NgModule({
  imports: [
    CommonModule,
    EffectsModule.forFeature([GrantProfileCoverSheetEffects]),
    StoreModule.forFeature(grantProfileCoverSheetFeatureKey, reducer),
  ],
})
export class GrantProfileCoverSheetModule {}
